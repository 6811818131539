import "../assets/scss/Dropdown.scss";

const Dropdown = (props) => {

  return (
    <div>
      <div className={"header-h6"} style={{ marginBottom: "10px" }}>
        {props.label}
      </div>
      <select
        className={"dropdown-container body-p7"}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled}
        {...props}
      >
        <option value="-">- Select -</option>
        {props.options.map((opt, index) => (
          <option key={index} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
