import { Fragment, useState, useEffect, useMemo } from "react";
import Navbar from "../../Component/Navbar";
import Dropdown from "../../Component/Dropdown";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import "../../App.scss";
import LowonganService from "../../Services/LowonganService";
import { ToastContainer } from "react-toastify";
import SearchInput from "../../Component/SearchInput";
import LamaranTable from "../../Component/LamaranTable";
import LamaranService from "../../Services/LamaranService";
import StatusDropdown from "../../Component/StatusDropdown";


const LamaranPage = () => {
  const [Terms, setTerms] = useState();

  const fetchTerm = async () => {
    try {
      const response = await LowonganService.getTerm();
      setTerms(response.data.data);
      setTahunAjaran(response.data.data.tahun_ajaran);
      setSemester(response.data.data.semester);
    } catch (error) {
      console.error("Failed to fetch term:", error);
    }
  };

  useEffect(() => {
    fetchTerm()
  }, []);
  
  

  const Options = useMemo(() => {
    if (Terms && Terms.length > 0) {
      return Terms.map(
        (term) =>
          `${term.tahun_ajaran} ${
            term.semester.charAt(0).toUpperCase() + term.semester.slice(1)
          } `,
      );
    }
    return [];
  }, [Terms]);

  const [selectedTerm, setSelectedTerm] = useState("");
  const [tahun_ajaran, setTahunAjaran] = useState();
  const [semester, setSemester] = useState();
  const [selectedStatus, setSelectedStatus] = useState("");
 

  useEffect(() => {
    if (Options.length > 0 && !selectedTerm) {
      setSelectedTerm(Options[0]);
    }
  }, [Options, selectedTerm]);

  const [lamaranList, setLamaranList] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  

  const fetchLamaranList = async () => {

    if (selectedStatus === "" || selectedStatus === "- Select -") {
      try {
        const response = await LamaranService.getAllLamaran(
          semester,
          tahun_ajaran,
        );
        setLamaranList(response.data.data);
      } catch (error) {
        console.error("Failed to fetch lamaran:", error);
      }
    } else {
      try {
        const response = await LamaranService.getAllLamaran(
          semester,
          tahun_ajaran,
          selectedStatus
        );
        setLamaranList(response.data.data);
      } catch (error) {
        console.error("Failed to fetch lamaran:", error);
      }
    }
  };
  
  useEffect(() => {
    if (semester !== undefined) {
      fetchLamaranList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semester]);

  const [search, setSearch] = useState('');

  const handleSearch = (event) => {
    setSearch(event.target.value)
  };

  let lamaranTableComponent;

  lamaranTableComponent = (
    <LamaranTable   
      items={[
        [
          "Nomor",
          "Nama Asisten",
          "Matakuliah",
          "Semester / Tahun Ajaran",
          "Profil",
          "Status",
          "Operasi",
        ],
      ]}
      lamaranList={lamaranList.filter((item) => {
        return search.toLowerCase() === '' ? item : item.nama_matkul.toLowerCase().includes(search.toLowerCase()) ||
        search.toLowerCase() === '' ? item : item.nama_asisten.toLowerCase().includes(search.toLowerCase())
      })}
      isDelete={isDelete}
      setIsDelete={setIsDelete}
    />
  );

  useEffect(() => {
    if (selectedTerm) {
      const newTahunAjaran = selectedTerm.split(" ")[0];
      const newSemester = selectedTerm.split(" ")[1].toLowerCase();
      setTahunAjaran(newTahunAjaran);
      setSemester(newSemester);
    }
  }, [Options, selectedTerm]);

 

  const handleDropdownChange = (event) => {
    if (event.target.value === "-") {
      const newSelectedTerm = Options[0];
      setSelectedTerm(newSelectedTerm);
    } else {
      setSelectedTerm(event.target.value);
    }
  };

  const handleStatusDropdownChange = (event) => {
    if (event.target.value === "-") {
      const newSelectedStatus = "melamar";
      setSelectedStatus(newSelectedStatus);
    } else {
      setSelectedStatus(event.target.value);
    }
  };


  const handleOnClickFilter = () => {
    fetchLamaranList();
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content"}>
          <div className={"lowongan-container tw-mb-8"}>
            <div className={"header-h4"}>Daftar Lamaran</div>
            <div
              className={
                "tw-flex tw-justify-between tw-items-end tw-gap-2 sm:tw-gap-4"
              }
            >
              <div className={"tw-flex tw-justify-start tw-items-end tw-gap-4 tw-flex-wrap"}>
              <label htmlFor="search">
                <SearchInput
                  name={"search"}
                  value={search}
                  onChange={handleSearch}
                />
                </label>

                <Dropdown
                  label={"Term"}
                  name={"term"}
                  value={selectedTerm}
                  onChange={handleDropdownChange}
                  options={Options}
                />

                <StatusDropdown
                  label={"Status"}
                  name={"status"}
                  value={selectedStatus}
                  onChange={handleStatusDropdownChange}
                />

                <Button
                  text={"Lihat"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleOnClickFilter}
                />
              </div>
            </div>
            {lamaranTableComponent}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default LamaranPage;