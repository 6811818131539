/* eslint-disable jsx-a11y/anchor-is-valid */
import "../assets/scss/Table/Table.scss";
import "../assets/scss/_variables.scss";
import "../App.scss";
import { useState } from "react";
import Pagination from "./Pagination";
import "../assets/scss/Pagination.scss";
import { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LowonganService from "../Services/LowonganService";
import { useNavigate } from "react-router-dom";
import LamaranService from "../Services/LamaranService";
import Button from "./Button";

const TableRow = ({ rowData, index, lowonganId }) => {
  const Navigate = useNavigate();
  const lamaranId = rowData.lamaran_id;
  const ProfileMahasiswa = () => {
    Navigate(`/profile/${rowData.mahasiswa_id}/${lowonganId}`);
  };
  const mahasiswaId = rowData.mahasiswa_id;
  const keterangan = rowData.keterangan;
  
  const [isRecommended, setIsRecommended] = useState(
    rowData.status === "direkomendasikan"
  );
  const [isAdmitted, setIsAdmitted] = useState(rowData.status === "diterima");
  const role = sessionStorage.getItem("role") || "mahasiswa";

  const handleRecommend = (e) => {
    const status = "direkomendasikan";
    const requestBody = {
      status,
    };
    LamaranService.gantiStatus(lamaranId, mahasiswaId, requestBody)
      .then((response) => {
        toast.success("Rekomendasi Berhasil", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            window.location.reload();
          },
        });
        setIsRecommended(true);
      })
      .catch((error) => {
        console.error("Rekomendasi Gagal", error);
        toast.error("Pelamar sudah diterima atau direkomendasikan di mata kuliah lain", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };
  const handleBatal = (e) => {
    const status = "batal rekomendasi";
    const requestBody = {
      status,
    };
    LamaranService.gantiStatus(lamaranId, mahasiswaId, requestBody)
      .then((response) => {
        toast.success("Pembatalan Berhasil", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            window.location.reload();
          },
        });
        setIsRecommended(false);
      })
      .catch((error) => {
        console.error("Pembatalan Gagal", error);
        toast.error("Pembatalan gagal", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleAccept = (e) => {
    const status = "diterima";
    const requestBody = {
      status,
    };
    LamaranService.gantiStatus(lamaranId, mahasiswaId, requestBody)
      .then((response) => {
        toast.success("Penerimaan Berhasil", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            window.location.reload();
          },
        });
        setIsAdmitted(true);
      })
      .catch((error) => {
        console.error("Penerimaan Gagal", error);
        toast.error("Pelamar sudah diterima di mata kuliah lain", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleReject = (e) => {
    const status = "ditolak";
    const requestBody = {
      status,
    };
    LamaranService.gantiStatus(lamaranId, mahasiswaId, requestBody)
      .then((response) => {
        toast.success("Penolakan Berhasil", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            window.location.reload();
          },
        });
        setIsAdmitted(false);
      })
      .catch((error) => {
        console.error("Penolakan Gagal", error);
        toast.error("Penolakan Gagal", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <tr>
      <td className="tw-text-center" width="10px">
        {index + 1}
      </td>
      <td className="tw-text-center tw-p-2">{rowData.nama_mahasiswa}</td>
      <td className="tw-text-center tw-p-2">{rowData.npm}</td>
      <td className="tw-text-center tw-p-2">{rowData.email}</td>
      <td className="tw-text-center tw-p-2">
        <div className="tw-flex tw-justify-center tw-gap-2 tw-text-xs tw-p-2">
          <div className="tw-flex tw-gap-2">
            <Button
              text={"Lihat"}
              onClick={ProfileMahasiswa}
              background={"#4D8AFF"}
              color={"#FFFFFF"}
            />
          </div>
        </div>
      </td>
      <td className="tw-text-center tw-p-2">{rowData.status}</td>
      <td className="tw-text-center tw-p-2">{rowData.keterangan}</td>
      <td className="tw-text-center tw-p-2">
        <div className="tw-flex tw-justify-center tw-gap-2 tw-text-xs tw-p-2">
          <div className="tw-flex tw-gap-2">
            {role === "dosen" && !keterangan && !isAdmitted && !isRecommended && (
              <Button
                text={"Rekomendasi"}
                onClick={handleRecommend}
                background={"#4D8AFF"}
                color={"#FFFFFF"}
              />
            )}

            {role === "dosen" && !keterangan ? (
              <>
                {isRecommended && !isAdmitted && (
                  <Button
                    text={"Batalkan"}
                    onClick={handleBatal}
                    background={"#FF0000"}
                    color={"#FFFFFF"}
                  />
                )}
              </>
            ) : (
              role === "admin" && (
                <>
                  {isRecommended && !isAdmitted && (
                    <Button
                      text={"Batalkan"}
                      onClick={handleBatal}
                      background={"#FF0000"}
                      color={"#FFFFFF"}
                    />
                  )}
                  {!isAdmitted && !isRecommended && (
                    <Button
                      text={"Rekomendasi"}
                      onClick={handleRecommend}
                      background={"#4D8AFF"}
                      color={"#FFFFFF"}
                    />
                  )}
                </>
              )
            )}
          </div>

          {role === "admin" && (
            <div className="tw-flex tw-gap-2">
              {isAdmitted ? (
                <Button
                  text={"Tolak"}
                  onClick={handleReject}
                  background={"#FF0000"}
                  color={"#FFFFFF"}
                />
              ) : (
                !isAdmitted && (
                  <Button
                    text={"Terima"}
                    onClick={handleAccept}
                    background={"#00CC00"}
                    color={"#FFFFFF"}
                  />
                )
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

const LamaranTableAdmin = ({ items, lamaranlist }) => {
  const headerItems = items[0];

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = lamaranlist.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(lamaranlist.length / recordsPerPage);

  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const id = pathParts[pathParts.length - 2];
  var lowonganid = "";
  if (/^\d+$/.test(id)) {
    lowonganid = id;
  } else {
    lowonganid = 1;
  }

  const [Header, setHeader] = useState(true);

  useEffect(() => {
    const fetchLowonganDetails = async () => {
      try {
        const response = await LowonganService.getLowonganById(lowonganid);
        const results = response.data.data;
        setHeader(
          `Pelamar Lowongan ${results.nama_matkul}, ${results.tahun_ajaran}, ${
            results.semester.charAt(0).toUpperCase() + results.semester.slice(1)
          }`
        );
      } catch (error) {
        console.error("Failed to fetch lowongan details:", error);
      }
    };

    fetchLowonganDetails();
  }, [lowonganid]);

  if (lamaranlist && lamaranlist.length > 0) {
    return (
      <div>
        <div className={"header-h4"} style={{ margin: "20px" }}>
          {Header}
        </div>
        <div className={"table-container-group2 container-shadow tw-pt-6"}>
          <table className={"tw-w-full tw-table-auto"}>
            <thead>
              <tr className={"table-header-group2"}>
                {headerItems.map((item, index) => {
                  let styleName;
                  if (index === 0) {
                    styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                  } else if (index === headerItems.length - 1) {
                    styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                  } else {
                    styleName = "tw-px-4 tw-py-2 tw-text-white";
                  }

                  return (
                    <th key={index} className={styleName}>
                      <div className="">{item}</div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 &&
                currentRecords.map((rowData, index) => (
                  <TableRow key={index} index={index} rowData={rowData} lowonganId={lowonganid} />
                ))}
            </tbody>
          </table>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className={"table-container container-shadow tw-pt-6"}>
        <h2>Belum ada lamaran yang masuk</h2>
      </div>
    );
  }
};
export default LamaranTableAdmin;
