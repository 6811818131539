import '../../../assets/group1/scss/Table/Table.scss';
import '../../../assets/group1/scss/_variables.scss';
import { useParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import mail from "../../../assets/group1/icons/message.svg";
import pencil from "../../../assets/group1/icons/pencil.svg";
import trash from "../../../assets/group1/icons/trash.svg";
import DeleteLog from '../DeleteLog';
import Tooltip from "@mui/material/Tooltip";

const LogsAssistant = (props) => {
    const { matkul_id } = useParams();
    const currentPathname = window.location.pathname;
    const [content, setContent] = useState([]);
    const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
    const [logToDelete, setLogToDelete] = useState(null);
    const token = sessionStorage.getItem("jwtToken");
    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);

    const openDeleteModal = (logId) => {
        setLogToDelete(logId);
        setDeleteModalVisible(true);
    };

    const closeDeleteModal = () => {
        setLogToDelete(null);
        setDeleteModalVisible(false);
    };

    const handleDeleteLog = () => {
        if (logToDelete) {
            fetch(`${process.env.REACT_APP_BASE_URL}/log/${logToDelete}/`, {
                method: 'DELETE',
                headers: headerData
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    setContent((prevContent) => prevContent.filter(item => item.id !== logToDelete));
                    closeDeleteModal();
                })
                .catch((error) => {
                    console.error('Error deleting log:', error);
                    closeDeleteModal();
                });
        }
    };

    function formatTime(timeString) {
        const time = new Date(`2000-01-01T${timeString}`);
        const hours = time.getHours().toString().padStart(2, '0');
        const minutes = time.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    useEffect(() => {
        setContent(props.content)
    }, [currentPathname, matkul_id, props.content, headerData]);

    return (
        <>
            {
                content ? (
                    currentPathname === `/matkul/${matkul_id}/log` ? (
                        content.map((item, index) => (
                            <tr key={index}>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    <div>
                                        {new Date(item["datetime"]).toLocaleDateString('en-GB', {
                                            year: 'numeric',
                                            month: 'long',
                                            day: 'numeric',
                                        })}
                                    </div>
                                    <div>
                                        {formatTime(item["start_time"])} - {formatTime(item["end_time"])}
                                    </div>
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {Math.floor(item["duration"] / 60)} hour(s) {(item["duration"] % 60) === 0 ? "" : `${item["duration"] % 60} minute(s)`}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["category"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{ width: '20%' }}>
                                    {item["description"]}
                                </td>
                                {(props.jenjang === "MTI") ?
                                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                        {item["location"]}
                                    </td> : ""}
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["status"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{ justifyContent: "center", gap: "15px", display: "flex" }}>
                                    {item["status"].toLowerCase() === "dilaporkan" ? (
                                        <>

                                            <a href={`/matkul/${matkul_id}/log/${props.jenjang}/${item.id}/update`}>
                                                <Tooltip title="Edit" placement="top" arrow>
                                                    <img src={pencil} alt={"edit"} />
                                                </Tooltip>
                                            </a>
                                            <a href={`/matkul/${matkul_id}/log/${item.id}/pesan`} style={{alignSelf:'center'}}>
                                                <Tooltip title="Send Message" placement="top" arrow>
                                                    <img src={mail} alt={"message"}
                                                        style={{ display: "flex" }} />
                                                </Tooltip>
                                            </a>
                                            <Tooltip title="Delete" placement="top" arrow>
                                                <img
                                                    src={trash}
                                                    alt={"delete"}
                                                    onClick={() => openDeleteModal(item.id)}
                                                    className={"pointer"}
                                                />
                                            </Tooltip>
                                            <DeleteLog
                                                isOpen={isDeleteModalVisible}
                                                onCancel={closeDeleteModal}
                                                onConfirm={handleDeleteLog}
                                            />
                                        </>
                                    ) : null}
                                </td>
                            </tr>
                        ))
                    ) : (
                        // Render normally
                        content.map((item, index) => (
                            <tr key={index}>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["id"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["course"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["semester"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["dosen"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["mahasiswa"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {new Date(item["datetime"]).toLocaleString()}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {formatTime(item["start_time"])}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {formatTime(item["end_time"])}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["duration"]} hour(s)
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["category"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["description"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    <input type="checkbox" />
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["status"]}
                                </td>
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["operation"]}
                                </td>
                            </tr>
                        )))
                ) : (
                    <div>
                        Empty
                    </div>
                )}
        </>
    );
}

export default LogsAssistant;
