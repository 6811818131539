/* eslint-disable jsx-a11y/anchor-is-valid */
import "../assets/scss/Table/Table.scss";
import "../assets/scss/_variables.scss";
import "../App.scss";

const TableRow = ({ statisticsJenjang, statisticsPengalaman }) => {
  return (
    <>
      <tr>
        <td className="tw-text-center tw-p-2">Mahasiswa S1</td>
        <td className="tw-text-center tw-p-2">{statisticsJenjang.mahasiswa_s1}</td>
        <td className="tw-text-center tw-p-2">Junior</td>
        <td className="tw-text-center tw-p-2">{statisticsPengalaman.junior}</td>
      </tr>
      <tr>
        <td className="tw-text-center tw-p-2">Lulus S1 / Mahasiswa S2</td>
        <td className="tw-text-center tw-p-2">{statisticsJenjang.lulus_s1_mahasiswa_s2}</td>
        <td className="tw-text-center tw-p-2">Menengah</td>
        <td className="tw-text-center tw-p-2">{statisticsPengalaman.menengah}</td>
      </tr>
      <tr>
        <td className="tw-text-center tw-p-2">Lulus S2 / Mahasiswa S3</td>
        <td className="tw-text-center tw-p-2">{statisticsJenjang.lulus_s2_mahasiswa_s3}</td>
        <td className="tw-text-center tw-p-2">Senior</td>
        <td className="tw-text-center tw-p-2">{statisticsPengalaman.senior}</td>
      </tr>
      <tr>
        <td className="tw-text-center tw-p-2">TA Kontrak</td>
        <td className="tw-text-center tw-p-2">{statisticsJenjang.ta_kontrak}</td>
        <td className="tw-text-center tw-p-2"></td>
        <td className="tw-text-center tw-p-2"></td>
      </tr>
      <tr>
        <td className="tw-text-center tw-p-2" style={{fontWeight: "bold"}}>Total</td>
        <td className="tw-text-center tw-p-2" style={{fontWeight: "bold"}}>{Object.values(statisticsJenjang).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</td>
        <td className="tw-text-center tw-p-2" style={{fontWeight: "bold"}}>Total</td>
        <td className="tw-text-center tw-p-2" style={{fontWeight: "bold"}}>{Object.values(statisticsPengalaman).reduce((accumulator, currentValue) => accumulator + currentValue, 0)}</td>
      </tr>
    </>
  );
};

const StatistikLamaranDiterimaTable = ({ items, isNotEmpty, statisticsJenjang, statisticsPengalaman }) => {
  const headerItems = items[0];

  if (isNotEmpty) {
    return (
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        <table className={"tw-w-full tw-table-auto"}>
          <thead>
            <tr className={"table-header-group2"}>
              {headerItems.map((item, index) => {
                let styleName;
                if (index === 0) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                } else if (index === headerItems.length - 1) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                } else {
                  styleName = "tw-px-4 tw-py-2 tw-text-white";
                }

                return (
                  <th key={index} className={styleName}>
                    <div className="">{item}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            <TableRow
              statisticsJenjang={statisticsJenjang}
              statisticsPengalaman={statisticsPengalaman}
            />
          </tbody>
        </table>
      </div>
    );
  } else {
    return (
      <div className={"table-container container-shadow tw-pt-6"}>
        <h2>Tidak ada statistik</h2>
      </div>
    );
  }
};

export default StatistikLamaranDiterimaTable;