import "../assets/scss/Buttons/Button.scss";

const Button = (props) => {
  const isDisabled = props.isDisabled ? props.isDisabled : false;
  const normalStyle =
    "button-container-group2 button-style tw-cursor-pointer tw-font-bold tw-flex tw-justify-center tw-text-center";
  const disableStyle = "button-container tw-font-bold button-disable";
  const handleClick = () => {
    if (!isDisabled) {
      if (props.onClick) {
        props.onClick();
      }
    }
  };

  return (
    <div
      style={{ background: props.background, color: props.color }}
      className={isDisabled ? disableStyle : normalStyle}
      onClick={handleClick}
      href={props.href}
      {...props}
    >
      {props.text}
    </div>
  );
};

export default Button;
