/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Component/Navbar";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/CreateLowongan.scss";
import LowonganService from "../../Services/LowonganService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../Component/TextInput";

const UpdateLowonganSpecialPage = () => {
  const navigate = useNavigate();
  const [itemName, setItemName] = useState("");
  const url = window.location.href;
  const idString = url.match(/\/lowongan\/items\/update\/(\d+)/)[1];
  const id = parseInt(idString);

  useEffect(() => {

    async function fetchItemName() {
      try {
        const response = await LowonganService.getItem();
        let foundItem = null;
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].id === id) {
            foundItem = response.data.data[i];
            break;
          }
        }

        if (foundItem) {
          setItemName(foundItem.nama);
        } else {
          console.error("Item with ID not found");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }

    fetchItemName();
  }, [id]);

  const handleSubmit = async (event) => {
    const requestBody = {
      id: id,
      nama: itemName,
    };

    try {
      const response = await LowonganService.updateLowonganSpesialItem(id, requestBody);

      if (response.status === 200) {
        toast.success("Lowongan spesial item berhasil diubah", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            navigate("/lowongan/items");
          },
        });
      } else {
        toast.error("Failed to update lowongan item");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred while updating the lowongan item");
    }
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"back medium-m8"} onClick={() => navigate(-1)}>
          ← Kembali
        </div>
        <div className={"content"}>
          <form className={"body-container"}>
            <h2>Ubah Lowongan Item</h2>
            <div className={"card-form-container container-shadow"}>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Nama Item Lowongan</div>
                <TextInput
                  label={"Add item"}
                  name={"Add item"}
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                />
              </div>

              <div
                className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
              >
                <Button
                  text={"Ubah"}
                  color={"#FFFFFF"}
                  style={{ marginRight: "200px" }}
                  onClick={handleSubmit}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default UpdateLowonganSpecialPage;
