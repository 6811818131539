export const REGEX_JENJANG = [
    [/^.{4}6.{24}$|^.{4}8.{5} - .3.{14}$/, 'S1-MIK'],
    [/^.{4}8.{5} - .4.{14}$/, 'MTI'],
    [/^.{4}9.{24}$/, 'S3']
];

export const getJenjang = (kode_matkul, kode_kurikulum, setJenjang) => {
    const kode = `${kode_matkul} - ${kode_kurikulum}`
    for (const [regex, jenjang] of REGEX_JENJANG) {
        if (new RegExp(regex).test(String(kode))) {
            setJenjang(jenjang)
        }
    }
}