import {useMemo} from "react";
import Button from "./Button";

const SelectAllButton = ({ ListLogId }) => {
    const token = sessionStorage.getItem("jwtToken");
    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);
    const handleApprove = () => {
        if (Array.isArray(ListLogId)) { 
            ListLogId.forEach((logId) => {
                if (logId !== null) {
                    fetch(`${process.env.REACT_APP_BASE_URL}/log/dosen/approve/${logId}/`, {
                        method: "POST",
                        headers: headerData
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then((data) => {
                            window.location.reload(true)
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            });
        }
    };

    const handleReject = () => {
        if (Array.isArray(ListLogId)) {
            ListLogId.forEach((logId) => {
                if (logId !== null) {
                    fetch(`${process.env.REACT_APP_BASE_URL}/log/dosen/reject/${logId}/`, {
                        method: "POST",
                        headers: headerData
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then((data) => {
                            window.location.reload(true)
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            });
        }
    };

    return (
        <div className={"table-buttons-container medium-m8"} style={{alignItems: 'center', width: '100%', placeContent: 'center'}}>
            <Button
                text={"Setujui yang ditandai"}
                onClick={handleApprove}
                href={"#"}
                background={"#00CC00"}
                color={"#FFFFFF"}
            />

            <Button
                text={"Tolak yang ditandai"}
                onClick={handleReject}
                href={"#"}
                background={"#FF0000"}
                color={"#FFFFF"}
            />

        </div>
    );
};

export default SelectAllButton;

