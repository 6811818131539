import '../../../assets/group1/scss/Table/TableAdmin.scss';
import '../../../assets/group1/scss/_variables.scss';

const HonorPerAsisten = (props) => {
  const monthNames = [
    'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
    'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
  ];
  return (
    <>
      {props.content.map((item, index) => (
        <tr key={index}>
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
            {item.npm}
          </td>
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
            {item.name}
          </td>          
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
            {item.username}
          </td>
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
            {monthNames[item.bulan - 1]}, {item.tahun}
          </td>
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
            {item.lowongan}
          </td>
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4" style={{ width: '12%' }}>
            {Math.floor(item["duration"] / 60)} hour(s) {(item["duration"] % 60) === 0 ? "" : `${item["duration"] % 60} minute(s)`}
          </td>
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
            {item.honor_amount.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
          </td>
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
            {item.jumlah_pembayaran.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
          </td>
          <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
            {item.status}
          </td>
        </tr>
      ))}

    </>
  );
};

export default HonorPerAsisten
