import PropTypes from "prop-types";
import "../assets/scss/Buttons/Button.scss";

const StatusButton = (props) => {
  const { isDisabled, onClick, background, color, href, text, margin } = props;

  const normalStyle = "button-container button-style tw-cursor-pointer tw-font-bold tw-flex tw-justify-center tw-text-center";
  const disableStyle = "button-container tw-font-bold button-disable";

  const handleClick = () => {
    if (!isDisabled && onClick) {
      onClick();
    }
  };

  const buttonStyle = {
    background: background,
    color: color,
    margin: margin,
  };

  return (
    <div
      style={buttonStyle}
      className={isDisabled ? disableStyle : normalStyle}
      onClick={handleClick}
      href={href}
    >
      {text}
    </div>
  );
};

StatusButton.propTypes = {
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  background: PropTypes.string,
  color: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  margin: PropTypes.string, // Add prop type for margin
};

export default StatusButton;
