/* eslint-disable-next-line no-unused-vars */
import { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from "react-router-dom";
import Navbar from '../../../Component/GroupOne/Navbar';
import DatePicker from '../../../Component/GroupOne/DatePicker';
import TimePicker from '../../../Component/GroupOne/TimePicker';
import Button from '../../../Component/GroupOne/Buttons/Button';
import SearchSelect from '../../../Component/GroupOne/SearchSelect';
import InputBox from '../../../Component/GroupOne/InputBox';
import dayjs from 'dayjs';
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import { toast, ToastContainer } from "react-toastify";


const AddLog = () => {
  const { matkul_id, jenjang } = useParams();
  const token = sessionStorage.getItem("jwtToken");
  const username = sessionStorage.getItem("username");
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);
  const locOptions = ['On-site', 'Off-site'];

  const [logData, setLogData] = useState({
    category_id: '',
    description: '',
    location: '',
    jenjang_lowongan: '',
    datetime: null,
    start_end_time: null,
    logCategory: ['data1', 'data2'],
  });


  const getLogCategory = useCallback(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/log/category/`, {
      method: 'GET',
      headers: headerData
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setLogData((prevLogData) => ({
          ...prevLogData, logCategory: data.filter((category) => {
            return category.jenjang === jenjang
          })
        }));
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [headerData]);

  useEffect(() => {
    getLogCategory();
  }, [getLogCategory]);


  const handleChange = (name, value) => {
    setLogData({
      ...logData,
      [name]: value
    });
  };

  const handleSave = () => {
    if (!logData.category_id || !logData.description || !logData.datetime || !logData.start_end_time) {
      toast.error("Semua input harus diisi", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const logDataToSend = {
      category: logData.category_id,
      description: logData.description,
      ...(jenjang === "MTI" && { location: logData.location }),
      jenjang_lowongan: jenjang,
      datetime: logData.datetime,
      start_time: logData.start_end_time[0],
      end_time: logData.start_end_time[1]
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/log/lowongan/${matkul_id}/`, {
      method: 'POST',
      headers: headerData,
      body: JSON.stringify(logDataToSend), // Convert logDataToSend to JSON
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        else{
          toast.success("Log berhasil ditambahkan", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          window.location.href = `/matkul/${matkul_id}/log`;
        }
      })
      .catch((error) => {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        console.error('Kesalahan:', error);
      });
  };

  const showLocation = () => {
    if (jenjang === "MTI") {
      return <SearchSelect
        placeholder='Pilih Moda'
        options={locOptions.map(loc => ({ value: loc, label: loc }))}
        label={'Moda'}
        name={'location'}
        onChange={(value) => { handleChange('location', value) }}
      />
    }
  }

  return (
    <Fragment>
      <div className={'view'}>
        <Navbar name={username} />
        <div className={'content-group1'}>
          <a href={`/matkul/${matkul_id}/log`}
            className={'back medium-m8'}
            onClick={() => {
              window.history.back();
              return false;
            }}>
            ← Kembali ke Daftar Log
          </a>
          <div className={'center-form-container'}>
            <form className={'body-container-group1'}>
              <div className={'card-form-container container-shadow'}>
                <SearchSelect
                  label={'Kategori Log'}
                  onChange={(value) => { handleChange('category_id', value) }}
                  placeholder='Pilih Kategori Log'
                  options={logData.logCategory.map(category => ({ value: category.id, label: category.name }))}
                  required={true}
                  optionFilterProp={'label'}
                />
                <InputBox
                  label={'Deskripsi'}
                  name={'description'}
                  onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                  placeholder="Masukkan Deskripsi"
                />
                {showLocation()}
                <DatePicker
                  label={'Tanggal'}
                  onChange={(date, dateString) => { handleChange('datetime', dateString) }}
                  placeholder={'Pilih Tanggal'}
                  format={'YYYY-MM-DD'}
                  disabledDate={(current) => {
                    return current > dayjs().endOf('day');
                  }}
                />
                <TimePicker
                  label={'Waktu Mulai'}
                  onChange={(time, timeString) => { handleChange('start_end_time', timeString) }}
                  placeholder={['Waktu Mulai', 'Waktu Selesai']}
                  format={'HH:mm'}
                />
                <Button text={"Simpan"}
                  onClick={handleSave}
                  background={"#4D8AFF"}
                  color={"#FFFFFF"}
                  margin={"0"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AddLog;

