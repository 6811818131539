import { useMemo } from "react";
import check from "../../../assets/group1/icons/check.svg"
import mark from "../../../assets/group1/icons/mark.svg"
import mail from "../../../assets/group1/icons/message.svg"
import Tooltip from "@mui/material/Tooltip";

const TableButtons = ({ logId, matkul_id }) => { // Pass the log ID as a prop
  const token = sessionStorage.getItem("jwtToken");
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);

  const handleApprove = () => {
    if (logId !== null) {
      fetch(`${process.env.REACT_APP_BASE_URL}/log/dosen/approve/${logId}/`, {
        method: "POST",
        headers: headerData,
        // You can include a request body if needed
        // body: JSON.stringify({ /* your data */ }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Network response was not ok :p ${headerData}`);
          }
          return response.json();
        })
        .then((data) => {
          window.location.reload(true)
        })
        .catch((error) => {
          // Handle errors, e.g., show an error message
        });
    }
  };

  const handleReject = () => {
    if (logId !== null) {
      fetch(`${process.env.REACT_APP_BASE_URL}/log/dosen/reject/${logId}/`, {
        method: "POST",
        headers: headerData
        // You can include a request body if needed
        // body: JSON.stringify({ /* your data */ }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          window.location.reload(true)
        })
        .catch((error) => {
          // Handle errors, e.g., show an error message
        });
    }
  };

  return (
    <div className={"table-buttons-container"} style={{ justifyContent: "center", gap: "15px" }}>

      <Tooltip title="Approve" placement="top" arrow>
        <img
          src={check}
          alt={"approve"}
          onClick={handleApprove}
          className="pointer"
        />
      </Tooltip>

      <Tooltip title="Reject" placement="top" arrow>
        <img
          src={mark}
          alt={"reject"}
          onClick={handleReject}
          className="pointer"
        />
      </Tooltip>

      <a href={`/matkul/${matkul_id}/log/${logId}/pesan/`} title={<span>Send Message</span>}>

        <Tooltip title="Send Message" placement="top" arrow>
          <img src={mail}
            alt={"message"}
            className="pointer"
          />
        </Tooltip>
      </a>
    </div>
  );
};

export default TableButtons;

