import { Fragment, useState, useMemo, useEffect } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import Button from "../../../Component/Button";
import '../../../assets/group1/scss/_variables.scss'
import { toast, ToastContainer } from "react-toastify";
import HonorService from '../../../Services/HonorService';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import NumInput from '../../../Component/GroupOne/NumInput';


const UpdateHonorRateMTI = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jenjangAsdos = searchParams.get("jenjang")
  const pengalamanAsdos = searchParams.get("pengalaman")
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [honorRates, setHonorRates] = useState([]);
  const [addHonorRates, setAddHonorRates] = useState({});
  const [honorRatesZero, setHonorRatesZero] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const honorRatesList = Object.values(honorRates);
    try {
      for (const honorRate of honorRatesList) {
        const pk = honorRate.pk;
        const requestBody = { amount: honorRate.amount };
        await HonorService.updateHonorRate(pk, requestBody);
      }
      if (addHonorRates.length !== 0) {
        const addHonorRatesList = Object.values(addHonorRates).map(value => value)
        const requestBody = {
          list_honor: addHonorRatesList
        };
        await HonorService.createHonorRate(id, requestBody)
      }
      toast.success("Honor Rate Berhasil Diperbarui", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => navigate(`/honor-rate-list/${id}`)
      });
    } catch (error) {
      toast.error("Honor Rate Gagal Diperbarui", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await HonorService.getCategory();
      const categoriesFilter = response.data.filter((category) => {
        return category.jenjang === "MTI"
      })
      setCategories(categoriesFilter)
      fetchHonorRate(categoriesFilter)
    } catch (error) {
      console.error("Failed to fetch category list:", error);
    }
  };

  const fetchHonorRate = async (categories) => {
    try {
      const response = await HonorService.getHonorByJenjangAndPengalaman(id, jenjangAsdos, pengalamanAsdos);
      setHonorRates(response.data.list_honor)
      const categoryHonorRateList = new Set()
      const honorRatesZeroList = []
      for (const i in response.data.list_honor) {
        categoryHonorRateList.add(response.data.list_honor[i].kategori)
      }
      for (const i in categories) {
        if (!categoryHonorRateList.has(categories[i].id)) {
          honorRatesZeroList.push(`${categories[i].id}-On-site`)
          honorRatesZeroList.push(`${categories[i].id}-Off-site`)
        }
      }
      setHonorRatesZero(honorRatesZeroList);
    } catch (error) {
      console.error("Failed to fetch honor rate:", error);
    }
  };

  useEffect(() => {
    fetchCategory()
  }, []);

  const categoriesInputComponent = useMemo(() => {
    if (honorRates.length === 0) {
      return
    }
    const handleCategoryChange = (id, location, value) => {
      if (honorRatesZero.includes(`${id}-${location}`)) {
        setAddHonorRates(prevAddHonorRates => ({
          ...prevAddHonorRates,
          [`${id}-${location}`]: {
            jenjang: "MTI",
            pendidikan_asdos: jenjangAsdos,
            pengalaman: pengalamanAsdos,
            kategori: id,
            location: location,
            amount: value,
          }
        }));
      }
      setHonorRates(prevHonorRates => prevHonorRates.map(honorRate => {
        if (honorRate.kategori === id && honorRate.location === location) {
          return { ...honorRate, amount: value };
        }
        return honorRate;
      }));
    }

    return <>{categories.map((category, i) => (
      <tr key={category.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px', width: '250px' }}>
          {category.name}
        </td>
        <td style={{ display: 'flex', alignItems: 'center', width: '165px', marginRight: "15px" }}>
          <p style={{ marginRight: '5px' }}>Rp.</p>
          <NumInput
            name={'On-site'}
            onChange={(e) => { handleCategoryChange(category.id, e.target.name, e.target.value) }}
            placeholder={honorRates.filter((honorRate) => {
              return honorRate.kategori === category.id && honorRate.location === 'On-site'
            })[0]?.amount || 0}
            required={true}
          />
          <p style={{ marginLeft: '5px' }}>,-</p>
        </td>
        <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
          <p style={{ marginRight: '5px' }}>Rp.</p>
          <NumInput
            name={'Off-site'}
            onChange={(e) => { handleCategoryChange(category.id, e.target.name, e.target.value) }}
            placeholder={honorRates.filter((honorRate) => {
              return honorRate.kategori === category.id && honorRate.location === 'Off-site'
            })[0]?.amount || 0}
            required={true}
          />
          <p style={{ marginLeft: '5px' }}>,-</p>
        </td>
      </tr>
    ))}</>
  }, [categories, honorRates])

  return (
    <Fragment>
      <div className={'view'}>
        <Navbar />
        <a href={`/honor-rate-list/${id}`} className={"back medium-m8"}>
          ← Kembali ke Daftar Honor Rate
        </a>
        <div>
          <div className={"frame-container tw-mb-8"}>
            <h2 className={"header-h4 center"}>Honor Rate per Jam</h2>
            <form onSubmit={handleSubmit}>
              <div className={'table-container-group2 container-shadow'}>
                <div style={{ alignItems: 'left' }} className={"tw-w-full tw-table-auto"}>
                  <h4 className="tw-text-base tw-font-medium sm:medium-m6">Jenjang: {jenjangAsdos}</h4>
                  <h4 className="tw-text-base tw-font-medium sm:medium-m6">Pengalaman: {pengalamanAsdos}</h4>
                </div>
                <table className={"tw-w-full tw-table-auto"}>
                  <thead>
                    <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <th style={{ display: 'flex', alignItems: 'center', marginRight: '10px', width: '250px' }}>
                        Kategori
                      </th>
                      <th style={{ width: '165px', marginRight: "15px" }}>
                        On-Site
                      </th>
                      <th style={{ width: '165px' }}>
                        Off-Site
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoriesInputComponent}
                  </tbody>
                </table>
                <div
                  className="button-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingLeft: 0,
                    paddingTop: 0,
                  }}
                >
                  <Button
                    data-testid={"Simpan"}
                    text={"Simpan"}
                    type="submit"
                    color={"#FFFFFF"}
                    onClick={handleSubmit}
                    role='button'
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default UpdateHonorRateMTI;
