import { Fragment, useMemo } from "react";
import Navbar from "../../Component/Navbar";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import "../../App.scss";


const DocumentationPage = () => {
  const role = sessionStorage.getItem("role") || "mahasiswa";
  const manualComponent = useMemo(() => {
    if (role === "mahasiswa") {
      return (
        <div className={"content"}>
          <div className={"lowongan-container tw-mb-8"}>
            <div className={"header-h4"}>
              <h4>Manual Mahasiswa</h4>
            </div>
            <div className={"table-container-group2 container-shadow tw-pt-6"}>
              <div className={"table-header-group2 tw-rounded-xl"}>
                <div className={"tw-px-4 tw-py-2 tw-text-white"}>
                  <div>Update Profil</div>
                </div>
              </div>
              <div className="manual-container">
                <ul>
                  <li>Arahkan <i>mouse</i> ke username Anda pada <i>navigation bar</i> dan pilih menu <b>Profil</b></li>
                  <li>Unggah foto profil terbaru Anda</li>
                  <li>Sesuaikan isi status dengan status mahasiswa Anda saat ini</li>
                  <li><i>(Opsional)</i> Unggah CV terbaru Anda</li>
                  <li>Tuliskan email aktif, nomor telepon, dan waktu kosong Anda pada bagian &quot;Kontak dan Waktu Kosong&quot;</li>
                  <li>Unggah foto halaman pertama buku tabungan Anda. Pastikan tulisan terbaca jelas, tidak terbalik, dan tidak terputar</li>
                  <li>Pastikan nama bank, no rekening, dan atas nama sesuai dengan informasi yang ada pada foto yang diunggah</li>
                  <li>Pastikan kembali kebenaran informasi yang Anda masukkan. Klik tombol <b>Simpan</b> untuk menyimpan perubahan</li>
                </ul>
                <p className="tw-pl-4"><b>Catatan:</b></p>
                <ul>
                  <li>Pastikan atas nama sesuai dengan nama pada foto buku tabungan. Kesalahan pada nama pemilik tabungan dapat menyebabkan keterlambatan pembayaran</li>
                  <li>Memasukkan rekening atas nama orang lain dapat menyebabkan <b>PEMBLOKIRAN</b> pada akun Siasisten Anda. Silahkan komunikasikan ke sekretariat jika ingin menggunakan rekening atas nama orang lain</li>
                  <li>Jika jenis tabungan anda <b>tidak menyertakan buku tabungan</b> sebagai kelengkapan dokumen, maka anda dapat mengunggah <i>screenshot</i> atau dokumen lain yang menunjukkan kepemilikan rekening atas nama Anda. Pastikan foto memiliki informasi nama pemilik rekening dan no rekening</li>
                  <li>Jika status mahasiswa Anda berubah (menjadi Lulus S1 dan lainnya) dan tetap ingin menggunakan akun ini, silahkan sesuaikan status Anda. Status mahasiswa mempengaruhi besaran honor yang diterima</li>
                </ul>
              </div>
            </div>

            <div className={"table-container-group2 container-shadow tw-pt-6"}>
              <div className={"table-header-group2 tw-rounded-xl"}>
                <div className={"tw-px-4 tw-py-2 tw-text-white"}>
                  <div>Melamar Ke Suatu Lowongan</div>
                </div>
              </div>
              <div className="manual-container">
                <ul>
                  <li>Pilih menu <b>List Lowongan Asisten</b></li>
                  <li>Klik tombol <b>Daftar</b> pada salah satu lowongan mata kuliah dan isi IPK dan jumlah SKS yang Anda ambil</li>
                  <li>Klik tombol <b>Daftar</b></li>
                </ul>
              </div>
            </div>

            <div className={"table-container-group2 container-shadow tw-pt-6"}>
              <div className={"table-header-group2 tw-rounded-xl"}>
                <div className={"tw-px-4 tw-py-2 tw-text-white"}>
                  <div>Mengisi Log</div>
                </div>
              </div>
              <div className="manual-container">
                <ul>
                  <li>Pilih menu <b>Log Asistensi Per Matakuliah</b></li>
                  <li>Klik tombol <b>Logs</b> pada salah satu mata kuliah</li>
                  <li>Klik tombol <b>Tambah Log</b></li>
                  <li>Masukkan informasi log asistensi Anda</li>
                  <li>Klik tombol <b>Simpan</b></li>
                </ul>
                <p className="tw-pl-4"><b>Catatan:</b></p>
                <ul>
                  <li>Log bulan n, hanya bisa diisi oleh asisten selama bulan n tersebut hingga tanggal 5 pada bulan n+1. Persetujuan oleh dosen, paling lambat dilakukan pada tanggal 10 pada bulan n+1</li>
                  <li>Untuk komunikasi antara dosen dan asisten tentang salah satu log, klik link pesan suatu log, pada halaman daftar log asisten. Pesan tersebut dapat dilihat dan dibalas oleh pasangan komunikasi pengguna</li>
                </ul>
              </div>
            </div>

            <div className={"table-container-group2 container-shadow tw-pt-6"}>
              <div className={"table-header-group2 tw-rounded-xl"}>
                <div className={"tw-px-4 tw-py-2 tw-text-white"}>
                  <div>Melihat Pembayaran Honor</div>
                </div>
              </div>
              <div className="manual-container">
                <ul>
                  <li>Pilih menu <b>Lihat Pembayaran Honor</b></li>
                  <li><i>Filter</i> berdasarkan tahun, bulan, dan status log asistensi</li>
                  <li>Klik tombol <b>Lihat Laporan</b></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }, [role]);

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        {manualComponent}
      </div>
    </Fragment>
  )
};

export default DocumentationPage;
