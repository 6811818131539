    /* eslint-disable no-unused-vars */
    import { Fragment, useState} from "react";
    import { useNavigate } from "react-router-dom";
    import Navbar from "../../Component/Navbar";
    import Button from "../../Component/Button";
    import "../../assets/scss/_variables.scss";
    import "../../assets/scss/CreateLowongan.scss";
    import LowonganService from "../../Services/LowonganService";
    import { toast, ToastContainer } from "react-toastify";
    import "react-toastify/dist/ReactToastify.css";
    import TextInput from "../../Component/TextInput";

    const CreateLowonganSpecialPage = () => {
    const navigate = useNavigate();
    const [itemName, setItemName] = useState("");

    const handleSubmit = async (event) => {

        // Prepare the request body
        const requestBody = {
        nama: itemName,
        };

        try {
            // Call the LowonganService to post the special item
            const response = await LowonganService.postLowonganSpecialItem(requestBody);
    
            // Handle success or show a toast message
            if (response.status === 200) {
                toast.success("Lowongan spesial item berhasil dibuat", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    onClose: () => {
                    navigate("/lowongan/items");
                    },
                });
            } else {
            toast.error("Failed to create lowongan item");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while creating the lowongan item");
        }
        };



    return (
        <Fragment>
        <div className={"view"}>
            <Navbar />
            <div className={"back medium-m8"} style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
            ← Kembali
            </div>
            <div className={"content"}>
            <form className={"body-container"}>
                <h2>Buat Lowongan Item</h2>
                <div className={"card-form-container container-shadow"}>
                <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className="header-h6">Nama Item Lowongan</div>
                    <TextInput
                        label={"Add item"}
                        name={"Add item"}
                        value={itemName}
                        onChange={(e) => setItemName(e.target.value)}
                    /> 
                    </div>

                    <div
                        className="button-container"
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingLeft: 0,
                            paddingTop: 0,
                        }}
                    >
                        <Button
                        text={"Buat"}
                        color={"#FFFFFF"}
                        onClick={handleSubmit}
                        />
                    </div>
                </div>
            </form>
            </div>
            </div>
        <ToastContainer />
        </Fragment>
    );
    };

    export default CreateLowonganSpecialPage;
