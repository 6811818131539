import '../../assets/group1/scss/Input/DatePicker.scss';
import { DatePicker as AntDDatePicker } from "antd";

const DatePicker = (props) => {
    return (
        <div>
            <div className={"header-h6"} style={{marginBottom: '10px'}}> {props.label} </div>
            <AntDDatePicker 
                className={"datepicker-container body-p7"}
                onChange={props.onChange}
                placeholder={props.placeholder}
                format={props.format}
                disabledDate={props.disabledDate}
                suffixIcon={null}
                value={props.value}
                {...props}
            />
        </div>
    );
}

export default DatePicker;