/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Component/Navbar";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/CreateLowongan.scss";
import GeneralService from "../../Services/GeneralService";
import LowonganService from "../../Services/LowonganService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiSelect from "../../Component/MultiSelect";
import SingleSelect from "../../Component/SingleSelect";
import NumInput from "../../Component/NumInput";
import TextInput from "../../Component/TextInput";

const CreateLowonganSpesialPage = () => {
  const navigate = useNavigate();
  const name = sessionStorage.getItem("name") || "John Doe";
  const username = sessionStorage.getItem("username") || "john.doe";
  const role = sessionStorage.getItem("role") || "admin";

  // tahun ajaran
  const [tahunAjaranOptions, setTahunAjaranOptions] = useState([]);
  const [tahunAjaranSelected, setTahunAjaranSelected] = useState();
  const [isTahunAjaranSelected, setIsTahunAjaranSelected] = useState(false);
  const handleTahunAjaranOnChange = (selected) => {
    setTahunAjaranSelected(selected);
    setIsTahunAjaranSelected(true);
  };

  // semester
  const semesterOptions = [
    {
      value: "ganjil",
      label: "Ganjil",
    },
    {
      value: "genap",
      label: "Genap",
    },
    {
      value: "pendek",
      label: "Pendek",
    },
  ];
  const [semesterSelected, setSemesterSelected] = useState();
  const [isSemesterSelected, setIsSemesterSelected] = useState(false);
  const handleSemesterOnChange = (selected) => {
    setSemesterSelected(selected);
    setIsSemesterSelected(true);
  };

  // nama lowongan spesial
  const [itemspesialOptions, setItemSpesialOptions] = useState([]);
  const [itemspesialSelected, setItemSpesialSelected] = useState();
  const [isitemspesialSelected, setIsItemSpesialSelected] = useState(false);
  const handleItemSpesialOnChange = (selected) => {
    setItemSpesialSelected(selected);
    setIsItemSpesialSelected(true);
  };

  // status
  const statusOptions = [
    {
      value: "Buka",
      label: "Buka",
    },
    {
      value: "Tutup",
      label: "Tutup",
    },
  ];
  const [statusSelected, setStatusSelected] = useState();
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const handleStatusOnChange = (selected) => {
    setStatusSelected(selected);
    setIsStatusSelected(true);
  };

  // jumlah asisten
  const [jumlahAsisten, setJumlahAsisten] = useState(1);
  const handleJumlahAsistenOnChange = (event) => {
    setJumlahAsisten(event.target.value);
  };

  // pengajar
  const [pengajarOptions, setPengajarOptions] = useState([]);
  const [pengajarSelected, setPengajarSelected] = useState((role === "admin") ? [] :[{value: {name: name, username: username}, label: name}]);
  const [isPengajarSelected, setIsPengajarSelected] = useState(false);
  const handlePengajarOnChange = (selected) => {
    setPengajarSelected(selected);
    setIsPengajarSelected(selected.length > 0 ? true : false);
  };
  const prodiOptions = [
    { value: "01.00.12.01", label: "S1 Reguler - Ilmu Komputer"},
    { value: "06.00.12.01", label: "S1 Reguler - Sistem Informasi"},
    { value: "02.00.12.01", label: "S1 KKI - Ilmu Komputer"},
    { value: "03.00.12.01", label: "S2 - Ilmu Komputer (MIK)"},
    { value: "04.00.12.01", label: "S2 - Teknologi Informasi (MTI)"},
  ]
  const [prodiSelected, setProdiSelected] = useState();
  const [isProdiSelected, setIsProdiSelected] = useState(false);
  const handleProdiOnChange = (selected) => {
    setProdiSelected(selected);
    setIsProdiSelected(true);
  };

  // syarat tambahan
  const [syaratTambahan, setSyaratTambahan] = useState("");
  const handleSyaratTambahanOnChange = (event) => {
    setSyaratTambahan(event.target.value);
  };

  useEffect(() => {
    const fetchPeriodeList = async () => {
      try {
        const response = await GeneralService.getPeriodeList();
        const results = response.data.data.results;
        const tahun = [];

        for (const result of results) {
          tahun.push(
            result.tahun + "/" + (parseInt(result.tahun) + 1).toString()
          );
        }
        const uniqueTahun = new Set(tahun);
        const tahunAjaran = [...uniqueTahun];
        const tahunAjaranOptions = [];
        for (const elem of tahunAjaran) {
          tahunAjaranOptions.push({ value: elem, label: elem });
        }
        setTahunAjaranOptions(tahunAjaranOptions);
      } catch (error) {
        console.error("Failed to fetch periode lists:", error);

        const tahunAjaran = ["2024/2025"];
        const tahunAjaranOptions = [];
        for (const elem of tahunAjaran) {
          tahunAjaranOptions.push({ value: elem, label: elem });
        }
        setTahunAjaranOptions(tahunAjaranOptions);
      }
    };

    const fetchDosenList = async () => {
      try {
        const response = await GeneralService.getDosen();
        const results = response.data.data;
        const newDosenOptions = [];

        for (const result of results) {
          newDosenOptions.push({ value: result, label: result["name"] });
        }
        setPengajarOptions(newDosenOptions);
      } catch (error) {
        console.error("Failed to fetch dosen lists:", error);
      }
    };

    const fetchItemSpesialList = async () => {
      try {
        const response = await LowonganService.getItem();
        const results = response.data.data;
        const newSpesialItemOptions = [];

        for (const result of results) {
          newSpesialItemOptions.push({ value: result, label: result["nama"] });
        }
        setItemSpesialOptions(newSpesialItemOptions);
      } catch (error) {
        console.error("Failed to fetch spesial item list", error);
      }
    };



    fetchPeriodeList();
    fetchDosenList();
    fetchItemSpesialList();
  }, []);

  const handleSubmit = (e) => {
    const item_id = itemspesialSelected["value"]["id"].toString();
    const tahun_ajaran = tahunAjaranSelected["value"];
    const semester = semesterSelected["value"].toLowerCase();
    const jumlah_asisten = parseInt(jumlahAsisten);
    const status = statusSelected["value"].toLowerCase();
    const lowongan_pembuka = pengajarSelected.map((pengajar) => ({
      username: pengajar.value.username,
    }));
    let kode_matkul;
    if (["01.00.12.01", "06.00.12.01", "02.00.12.01"].includes(prodiSelected["value"])) {
      kode_matkul = "SPE-6-SIAL"
    } else if (["03.00.12.01", "04.00.12.01"].includes(prodiSelected["value"])) {
      kode_matkul = "SPE-8-SIAL"
    }
    const kode_kurikulum = prodiSelected["value"] + "-2024";
    const syarat_tambahan = syaratTambahan;

    const requestBody = {
      item_id,
      tahun_ajaran,
      kode_matkul,
      kode_kurikulum,
      semester,
      jumlah_asisten,
      status,
      lowongan_pembuka,
      syarat_tambahan,
    };
    if (
      isitemspesialSelected &&
      isTahunAjaranSelected &&
      isSemesterSelected &&
      isProdiSelected &&
      jumlahAsisten > 0 &&
      pengajarSelected.length > 0
    ) {
      LowonganService.postLowonganSpecial(requestBody)
        .then((response) => {
          toast.success("Lowongan berhasil dibuat", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/lowongan");
            },
          });
        })
        .catch((error) => {
          console.error("Failed to create Lowongan Spesial:", error);

          // Show an error toast when there is an error during creation
          toast.error("Tolong Lengkapi Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <a href="/lowongan" style={{ cursor: 'pointer' }} className={"back medium-m8"}>
          ← Kembali
        </a>
        <div className={"content"}>
          <form className={"body-container"} onSubmit={handleSubmit}>
            <h2>Buat Lowongan Spesial</h2>

            <div className={"card-form-container container-shadow"}>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Nama Lowongan</div>
                  <SingleSelect
                    value={itemspesialSelected}
                    options={itemspesialOptions}
                    onChange={handleItemSpesialOnChange}
                  />
                </div>
              </div>
              
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Tahun Ajaran</div>
                  <SingleSelect
                    value={tahunAjaranSelected}
                    options={tahunAjaranOptions}
                    onChange={handleTahunAjaranOnChange}
                    isDisabled={!isitemspesialSelected}
                  />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Prodi</div>
                  <SingleSelect
                    value={prodiSelected}
                    options={prodiOptions}
                    onChange={handleProdiOnChange}
                    isDisabled={!isTahunAjaranSelected}
                  />
                </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Semester</div>
                <SingleSelect
                  value={semesterSelected}
                  options={semesterOptions}
                  onChange={handleSemesterOnChange}
                  isDisabled={!isTahunAjaranSelected}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Status</div>
                  <SingleSelect
                    value={statusSelected}
                    options={statusOptions}
                    onChange={handleStatusOnChange}
                    isDisabled={!isSemesterSelected} 
                  />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Jumlah Asisten</div>
                <NumInput
                  min={1}
                  value={jumlahAsisten}
                  onChange={handleJumlahAsistenOnChange}
                  isDisabled={!isStatusSelected}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Pengajar</div>
                <MultiSelect
                  value={pengajarSelected}
                  options={pengajarOptions}
                  onChange={handlePengajarOnChange}
                  isDisabled={!isStatusSelected}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Syarat Tambahan</div>
                <TextInput
                  label={"Syarat Tambahan"}
                  name={"syaratTambahan"}
                  value={syaratTambahan}
                  onChange={handleSyaratTambahanOnChange}
                />
              </div>

              <div
                className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
              >
                <Button
                  text={"Buat"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleSubmit}
                  isDisabled={
                    !(isSemesterSelected,
                    isTahunAjaranSelected,
                    isStatusSelected,
                    isProdiSelected,
                    isPengajarSelected,
                    isitemspesialSelected
                    )
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default CreateLowonganSpesialPage;
