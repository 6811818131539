import { appServerAxios } from "./http-common";

class HonorService {
  getMahasiswaLog(username) {
    return appServerAxios.get(`/log/asisten/${username}/`);
  }

  getMahasiswaProfile() {
    return appServerAxios.get(`/auth/profile`);
  }

  getHonor(honor) {
    return appServerAxios.get(`/honor/${honor}/`);
  }

  updateHonorRef(honor, data) {
    return appServerAxios.put(`/honor/ref/${honor}/`, data);
  }

  createHonor(data) {
    return appServerAxios.post(`/honor/`, data);
  }

  getCategory(data) {
    return appServerAxios.get(`/log/category/`, data);
  }

  createHonorRate(refPK, data) {
    return appServerAxios.post(`/honor/rate/${refPK}/`, data);
  }

  updateHonorRate(honor, data) {
    return appServerAxios.put(`/honor/${honor}/`, data);
  }

  getHonorByJenjangAndPengalaman(honor, jenjang, pengalaman) {
    return appServerAxios.get(`/honor/${honor}?jenjang=${jenjang}&pengalaman=${pengalaman}`);
  }

  getHonorPerAsisten(tahun, bulan, username, status) {
    return appServerAxios.get(`/honor/honor-per-asisten`, {
      params: {
        tahun: tahun,
        bulan: bulan,
        username: username,
        status: status,
      },
    })
  }

  getRekapFile(jenis, tahun, bulan, bank, jenjang) {
    return appServerAxios.get(`/honor/download-history-pembayaran`, {
      params: {
        jenis: jenis,
        tahun: tahun,
        bulan: bulan,
        bank: bank,
        jenjang: jenjang
      },
      responseType: 'blob'})
  }

  getHistoriPembayaran(tahun, bulan, bank, jenjang) {
    return appServerAxios.get(`/honor/history-pembayaran/${tahun}/${bulan}/${bank}/${jenjang}`)
  }

  getHistoriPembayaranPerson() {
    return appServerAxios.get(`/honor/history-pembayaran-change-person`)
  }

  putHistoriPembayaranPerson(data) {
    return appServerAxios.put(`/honor/history-pembayaran-change-person`, data)
  }
}

export default new HonorService();
