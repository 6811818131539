import { Fragment, useMemo, useState, useEffect } from "react";
import Navbar from "../../Component/Navbar";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import "../../App.scss";
import SanksiService from "../../Services/SanksiService";
import { ToastContainer } from "react-toastify";
import SanksiTable from "../../Component/SanksiTable";
import CreateSanksiForm from "../../Component/CreateSanksiForm";
import Spinner from "../../Component/Spinner";
import Button from "../../Component/Button";
import Dropdown from "../../Component/Dropdown";
import SearchInput from "../../Component/SearchInput";
import { useNavigate } from "react-router-dom";


const ReferensiSanksiPage = () => {
  const navigate = useNavigate();
  const [sanksiList, setSanksiList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedJenisSurat, setSelectedJenisSurat] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const jenisSurat = ["Keras", "Ringan", "Tidak ada"];


  const handleDropdownJenisSuratChange = (event) => {
    setCurrentPage(1)
    setSelectedJenisSurat(event.target.value);
  };

  const handleSearch = (event) => {
    setCurrentPage(1)
    setSearch(event.target.value);
  };

  const fetchSanksiList = async () => {
    setIsLoading(true);
    try {
      const response = await SanksiService.getAllSanksi();
      setSanksiList(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch sanksi list:", error);
    }
  };

  /* istanbul ignore next */
  const handleUploadSuccess = () => fetchSanksiList();
  /* istanbul ignore next */
  const handleOnRequestClose = () => setIsModalOpen(false)

  useEffect(() => {
    fetchSanksiList();
  }, []);

  useEffect(() => {
    fetchSanksiList();
  }, [isDelete]);

  const sanksiTableComponent = useMemo(() => {
    const mappingJenisSurat = {
      "Keras": "JenisSurat.KERAS",
      "Ringan": "JenisSurat.RINGAN",
      "Tidak ada": "JenisSurat.TIDAK_ADA",
    }
    const sortByDate =  (array) => array.sort((a, b) => {
      const dateA = new Date(a.start_date);
      const dateB = new Date(b.start_date);
      return dateB - dateA;
    })
    const jenisSurat = mappingJenisSurat[selectedJenisSurat]
    return <SanksiTable sanksiList={sanksiList.map(sanksi => {
      sortByDate(sanksi.related_pelanggaran)
      return sanksi
    }).sort((a, b) => {
      const dateA = new Date(a.related_pelanggaran[0].start_date);
      const dateB = new Date(b.related_pelanggaran[0].start_date);
      return dateB - dateA;
    }).filter((item) => {
      return ((
        (search.toLowerCase() === "" || item.npm.toLowerCase().includes(search.toLowerCase())) ||
        (search.toLowerCase() === "" || item.nama.toLowerCase().includes(search.toLowerCase()))) &&
        (!selectedJenisSurat || selectedJenisSurat === "-" || item.related_pelanggaran.some(pelanggaran => pelanggaran.jenis_surat === jenisSurat))
      );
    }).map((item) => {
      return {
        ...item,
        related_pelanggaran: item.related_pelanggaran.filter((pelanggaran) => {
          return (
            !selectedJenisSurat ||
            selectedJenisSurat === "-" ||
            pelanggaran.jenis_surat === jenisSurat
          );
        })
      }
    })
    } isDelete={isDelete} setIsDelete={setIsDelete} currentPage={currentPage} setCurrentPage={setCurrentPage} />;
  }, [currentPage, isDelete, sanksiList, search, selectedJenisSurat]);

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content"}>
          <div className={"lowongan-container tw-mb-8"}>
            <div className={"header-h4"}>
              <h4>Daftar Penerima Sanksi</h4>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end"}} className="tw-flex-wrap">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "end", gap: "10px", marginTop: "10px" }} className="tw-flex-wrap">
                  <label htmlFor="search">
                    <SearchInput
                      name={"search"}
                      value={search}
                      onChange={handleSearch}
                      aria-label="Search disini"
                    />
                  </label>
                  <Dropdown
                    label={"Jenis Surat"}
                    name={"jenis surat"}
                    value={selectedJenisSurat}
                    onChange={handleDropdownJenisSuratChange}
                    options={jenisSurat}
                    aria-label="Jenis Surat"
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginTop: "10px"  }} className="tw-flex-wrap">
                  <Button
                    data-testid={"Buat Manual"}
                    text={"Buat Manual"}
                    onClick={() => navigate("/sanksi/create")}
                    color={"#FFFFFF"}
                    size={"small-button"}
                  />
                  <Button
                    data-testid={"Upload File"}
                    text={"Upload File"}
                    onClick={() => setIsModalOpen(true)}
                    color={"#FFFFFF"}
                    size={"small-button"}
                  />
                </div>
              </div>
            </div>
            {isLoading ? <Spinner size={50} isLoading={isLoading} /> : sanksiTableComponent}
          </div>
        </div>
      </div>
      {/* istanbul ignore next */}
      <CreateSanksiForm isOpen={isModalOpen} onRequestClose={handleOnRequestClose} onUploadSuccess={handleUploadSuccess} />
      <ToastContainer />
    </Fragment>
  );
};

export default ReferensiSanksiPage;
