import "../assets/group1/scss/Input/InputOneRow.scss";
const InputOneRow = (props) => {
  return (
    <div>
      <input
        type="text"
        className={"inputonerow-container"}
        placeholder={props.text}
        value={props.value}
        onChange={props.onChange}
        {...props}
      />
    </div>
  );
};

export default InputOneRow;