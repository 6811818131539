// import divider from "../assets/icons/divider.svg";
import '../assets/scss/ProfileMahasiswa.scss';
import SingleSelect from "./SingleSelect.jsx";
import { ToastContainer } from "react-toastify";
import Button from "./Button";
import CvViewer from './CvViewer.jsx';


const ProfileMahasiswaComponent = ({ props, pengalamanOptions, informasiDasar, handlePengalamanOnChange, handleSubmit, handleDownloadCV }) => {
    return (
        <div className={"profile"}>
            <div className={"header-h4"}>{props.name}</div>
            <div className={"profile-info-container"}>
                <div className={"profile-image-container container-shadow"}>
                    <img src={process.env.REACT_APP_BACKEND_URL + props.foto_profil} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <div className={"profile-details-container container-shadow"}>
                    <div className={"profile-details-heading"}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="16" height="16" rx="4" fill="#4D8AFF" />
                        </svg>
                        <div className={"header-h7"}>
                            Profile Details
                        </div>
                    </div>
                    <div className={"profile-details-body"}>
                        <div className={"profile-details-body-divide tw-basis-5/12"}>
                            <div className={"profile-details-body-column"}>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base tw-font-medium sm:medium-m7"}>
                                        Nama
                                    </div>
                                </div>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base sm:body-p7"}>
                                        {props.nama}
                                    </div>
                                </div>
                            </div>
                            <div className={"profile-details-body-column"}>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base tw-font-medium sm:medium-m7"}>
                                        NPM
                                    </div>
                                </div>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base sm:body-p7"}>
                                        {props.npm}
                                    </div>
                                </div>
                            </div>
                            <div className={"profile-details-body-column"}>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base tw-font-medium sm:medium-m7"}>
                                        Detail Rekening
                                    </div>
                                </div>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base sm:body-p7"}>
                                        {props.nama_bank} {props.no_rek} {props.atas_nama}
                                    </div>
                                </div>
                            </div>
                            <div className={"profile-details-body-column"}>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base tw-font-medium sm:medium-m7"}>
                                        Email
                                    </div>
                                </div>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base sm:body-p7"}>
                                        {props.email}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"profile-details-body-divide tw-basis-5/12"}>
                            <div className={"profile-details-body-column"}>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base tw-font-medium sm:medium-m7"}>
                                        No Telepon
                                    </div>
                                </div>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base sm:body-p7"}>
                                        {props.telepon}
                                    </div>
                                </div>
                            </div>
                            <div className={"profile-details-body-column"}>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base tw-font-medium sm:medium-m7"}>
                                        Waktu Luang
                                    </div>
                                </div>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base sm:body-p7"}>
                                        {props.waktu_luang}
                                    </div>
                                </div>
                            </div>
                            <div className={"profile-details-body-column"}>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base tw-font-medium sm:medium-m7"}>
                                        IPK
                                    </div>
                                </div>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base sm:body-p7"}>
                                        {props.ipk}
                                    </div>
                                </div>
                            </div>
                            <div className={"profile-details-body-column tw-flex tw-items-center tw-gap-3"}>
                                <div className={"profile-details-body-content"}>
                                    <div className={"tw-text-base tw-font-medium sm:medium-m7"}>
                                        CV
                                    </div>
                                </div>
                                <div className={'profile-details-body-content'}>
                                    <div className={'tw-flex tw-items-center tw-gap-3'}>
                                        <CvViewer cvUrl={process.env.REACT_APP_BACKEND_URL + props.cv} />
                                        <Button text={'Unduh CV'} color={"#FFFFFF"} onClick={handleDownloadCV} />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br></br>
                    <div className={"header-h5"}>Riwayat Akademis</div>
                    <div className="profile-details-body">
                        <div className="overflow-x-auto max-w-3xl mx-auto">
                            <table className="w-full">
                                <thead>
                                    <tr>
                                        <th className="w-9/12"></th>
                                        <th className="w-3/12"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props && props.nilai && props.nilai.matkul_lowongan && (
                                        <tr className="odd:tw-bg-[#4d8aff] tw-border tw-border-gray-700">
                                            <td className="body-p8 tw-rounded-tl-xl tw-py-2 tw-px-4 tw-text-white tw-font-semibold tw-border tw-border-gray-700">
                                                {props.nilai.matkul_lowongan.tahun
                                                    ? `${props.nilai.matkul_lowongan.kode_matkul} - ${props.nilai.matkul_lowongan.nama_matkul} - Tahun ${props.nilai.matkul_lowongan.tahun} Term ${props.nilai.matkul_lowongan.term}`
                                                    : `${props.nilai.matkul_lowongan.kode_matkul} - ${props.nilai.matkul_lowongan.nama_matkul} - Belum Mengambil`}
                                            </td>
                                            <td className="body-p8 tw-rounded-tr-xl tw-py-2 tw-px-4 tw-text-white tw-font-semibold tw-border tw-border-gray-700">
                                                {props.nilai.matkul_lowongan.nilai}
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan="2" className="body-p8 tw-py-2 tw-px-4 tw-font-semibold tw-border-0">
                                            Prasyarat:
                                        </td>
                                    </tr>
                                    {props && props.nilai && props.nilai.prerequisites.map((item, index) => (
                                        <tr key={index} className="tw-bg-[#4d8aff] tw-bg-opacity-20">
                                            <td className="body-p8 tw-py-2 tw-px-4 tw-border tw-border-gray-700">
                                                {item.tahun
                                                    ? `${item.kode_matkul} - ${item.nama_matkul} - Tahun ${item.tahun} Term ${item.term}`
                                                    : `${item.kode_matkul} - ${item.nama_matkul} - Belum Mengambil`}
                                            </td>
                                            <td className="body-p8 tw-py-2 tw-px-4 tw-border tw-border-gray-700">
                                                {item.nilai}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={"header-h5"}>Riwayat Pelanggaran</div>
                    <div className='pelanggaran-wrap'>
                        {props && props.pelanggaran && props.pelanggaran.length > 0 ? (
                            props.pelanggaran.map((item) => (
                                <div key={item.id} className="container d-flex justify-content-center align-items-center height-vh">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-8">
                                            <div className="pelanggaran-container d-flex flex-column">
                                                <div className="bg-white">
                                                    <div className="flex-row d-flex">
                                                        <div className="d-flex flex-row justify-content-start ml-2">
                                                            <span className={`d-block font-weight-bold no-surat ${item.jenis_surat === 'JenisSurat.KERAS' ? 'keras' : ''}`}>
                                                                {item.no_surat}
                                                            </span><br />
                                                            <span className="date text-black-50">{item.start_date}</span>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <p className="pelanggaran-text">{item.bentuk_pelanggaran}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="pelanggaran-text">
                                {props.nama} tidak pernah melakukan pelanggaran akademis
                            </div>
                        )}
                    </div>
                    <div className="keterangan">
                        <div className="keterangan-item merah">
                            <div className="keterangan-box"></div>
                            <div className="keterangan-text">Surat Peringatan Keras</div>
                        </div>
                        <div className="keterangan-item hitam">
                            <div className="keterangan-box"></div>
                            <div className="keterangan-text">Surat Peringatan</div>
                        </div>
                    </div>
                    <div className={"header-h5"}>Penetapan Honor Rate Per Jam</div>
                    <div className="profile-details-body">
                        <div className="profile-details-body-column tw-basis-6/12">
                            <div className={"profile-details-body-content tw-basis-6/12"}>
                                <div className="tw-text-base tw-font-medium sm:medium-m7">
                                    Jenjang Pendidikan
                                </div>
                            </div>
                            <div className={"profile-details-body-content tw-basis-6/12"}>
                                <div className="tw-text-base sm:medium-m7">
                                    {props.jenjang_pendidikan}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-details-body">
                        <div className="profile-details-body-column tw-basis-6/12">
                            <div className={"profile-details-body-content tw-basis-6/12"}>
                                <div className="tw-text-base tw-font-medium sm:medium-m7">
                                    Pengalaman
                                </div>
                            </div>
                            <div className={"profile-details-body-content tw-basis-6/12"}>
                                <div className="tw-text-base sm:medium-m7">
                                    {props.pengalaman}
                                </div>
                            </div>
                        </div>
                    </div>   
                    <div className="profile-details-body">
                        <div className="profile-details-body-column tw-basis-6/12">
                            <div className={"profile-details-body-content tw-basis-6/12"}>
                                <div className="tw-text-base tw-font-medium sm:medium-m7">
                                    Ubah Pengalaman
                                </div>
                            </div>
                            <div className={"profile-details-body-content tw-basis-7/12"}>
                                <SingleSelect
                                    value={informasiDasar["pengalaman"]}
                                    onChange={handlePengalamanOnChange}
                                    options={pengalamanOptions}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="profile-details-body">
                        <div
                            className="button-container"
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                paddingLeft: 0,
                                paddingTop: 0,
                            }}
                        >
                            <Button
                                text={"Simpan"}
                                type="submit"
                                color={"#FFFFFF"}
                                onClick={handleSubmit}
                            />
                            <ToastContainer />
                        </div>
                    </div>
                    {/* </form>  */}
                </div>
            </div>
        </div>
    );
};

export default ProfileMahasiswaComponent;

//tes