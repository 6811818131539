import '../../../assets/group1/scss/Table/Table.scss';
import '../../../assets/group1/scss/_variables.scss';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import TableButtons from '../Buttons/TablesButton';

const Course = (props) => {
    const [selectedItems, setSelectedItems] = useState([])
    const { matkul_id } = useParams();
    const currentPathname = window.location.pathname;
    const [content, setContent] = useState([]);

    function formatTime(timeString) {
        const time = new Date(`2000-01-01T${timeString}`);
        const hours = time.getHours().toString().padStart(2, '0');
        const minutes = time.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    function checkboxHandler(e) {
        let isSelected = e.target.checked;
        let value = parseInt(e.target.value);
        if (isSelected) {
            setSelectedItems([...selectedItems, value]);
        } else {
            setSelectedItems((prevData) => {
                return prevData.filter((id) => id !== value);
            });
        }

    }

    useEffect(() => {
        if (props.onSelectedItemsChange) {
            props.onSelectedItemsChange(selectedItems);
        }
        // TODO: Create another if condition based on other developers endpoint
        setContent(props.content)
    }, [matkul_id, props.content, selectedItems]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <> {
            content ? (
                currentPathname === `/matkul/${matkul_id}/log` ? ( // Check if content is defined (not undefined)
                    content.map((item, index) => (
                        <tr key={index}>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                <a href={`/matkul/${matkul_id}/log/asdos/${item["mahasiswa"]["username"]}`} className={'nostyle'} style={{ color: '#4D8AFF', textDecoration: 'underline' }}>
                                    {item["mahasiswa"]["name"]}
                                </a>
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                <div>
                                    {new Date(item["datetime"]).toLocaleDateString('en-GB', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}
                                </div>
                                <div>
                                    {formatTime(item["start_time"])} - {formatTime(item["end_time"])}
                                </div>
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {Math.floor(item["duration"] / 60)} hour(s) {(item["duration"] % 60) === 0 ? "" : `${item["duration"] % 60} minute(s)`}
                            </td>

                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{width: '20%'}}>
                                {item["description"]}
                            </td>
                            {(props.jenjang === "MTI") ?
                                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                    {item["location"]}
                                </td> : ""}
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                <input type="checkbox" checked={selectedItems.includes(item.id)} value={item.id} onChange={checkboxHandler} />
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {item["status"]}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                <TableButtons logId={item["id"]} matkul_id={matkul_id} />
                            </td>
                        </tr>
                    ))
                ) : (
                    // Render normally
                    content.map((item, index) => (
                        <tr key={index} className={"table-content"}>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {item["course"]}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {item["semester"]}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {item["dosen"]}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {item["mahasiswa"]["name"]}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {new Date(item["datetime"]).toLocaleString()}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {formatTime(item["start_time"])} - {formatTime(item["end_time"])}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {item["duration"]} hour(s)
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{width: '20%'}}>
                                {item["description"]}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                <input type="checkbox" />
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                {item["status"]}
                            </td>
                            <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                                <TableButtons logId={""} name={""} />
                            </td>
                        </tr>
                    )))
            ) : (
                <div>
                    Empty
                </div>
            )}
        </>
    );
}

export default Course;