/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Component/Navbar";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/CreateLowongan.scss";
import GeneralService from "../../Services/GeneralService";
import LowonganService from "../../Services/LowonganService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiSelect from "../../Component/MultiSelect";
import SingleSelect from "../../Component/SingleSelect";
import NumInput from "../../Component/NumInput";
import TextInput from "../../Component/TextInput";
import Spinner from "../../Component/Spinner";

const CreateLowonganPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const name = sessionStorage.getItem("name") || "John Doe";
  const username = sessionStorage.getItem("username") || "john.doe";
  const role = sessionStorage.getItem("role") || "admin";

  // tahun ajaran
  const [tahunAjaranOptions, setTahunAjaranOptions] = useState([]);
  const [tahunAjaranSelected, setTahunAjaranSelected] = useState();
  const [isTahunAjaranSelected, setIsTahunAjaranSelected] = useState(false);
  const handleTahunAjaranOnChange = (selected) => {
    setTahunAjaranSelected(selected);
    setIsTahunAjaranSelected(true);
  };

  // semester
  const semesterOptions = [
    {
      value: "ganjil",
      label: "Ganjil",
    },
    {
      value: "genap",
      label: "Genap",
    },
    {
      value: "pendek",
      label: "Pendek",
    },
  ];
  const [semesterSelected, setSemesterSelected] = useState();
  const [isSemesterSelected, setIsSemesterSelected] = useState(false);
  const [isTermValid, setIsTermValid] = useState(true);
  const handleSemesterOnChange = (selected) => {
    setSemesterSelected(selected);
    setIsSemesterSelected(true);
  };

  //jenjang
  const jenjangOptions =[
    { value: "s1", label: "S1 - Sarjana"},
    { value: "s2", label: "S2 - Magister"},
    { value: "s3", label: "S3 - Doktor"}
  ];
  const [jenjangSelected, setJenjangSelected] = useState();
  const [isJenjangSelected, setIsJenjangSelected] = useState(false);
  const [isJenjangValid, setIsJenjangValid] = useState(true);
  const handleJenjangOnChange = (selected) => {
    setIsMatakuliahLoading(true);
    setJenjangSelected(selected);
    setIsJenjangSelected(true);
  };
  
  const prodiOptions = [
    { value: "01.00.12.01", label: "S1 Reguler - Ilmu Komputer"},
    { value: "06.00.12.01", label: "S1 Reguler - Sistem Informasi"},
    { value: "02.00.12.01", label: "S1 KKI - Ilmu Komputer"},
    { value: "03.00.12.01", label: "S2 - Ilmu Komputer (MIK)"},
    { value: "04.00.12.01", label: "S2 - Teknologi Informasi (MTI)"},
  ]
  const [prodiSelected, setProdiSelected] = useState();
  const [isProdiSelected, setIsProdiSelected] = useState(false);
  const [isProdiValid, setIsProdiValid] = useState(true);
  const handleProdiOnChange = (selected) => {
    setMatakuliahSelected(null);
    setIsMatakuliahSelected(false);
    setIsMatakuliahLoading(true);
    setProdiSelected(selected);
    setIsProdiSelected(true);
  };

  // matakuliah
  const [matakuliahOptions, setMatakuliahOptions] = useState([]);
  const [matakuliahSelected, setMatakuliahSelected] = useState();
  const [isMatakuliahLoading, setIsMatakuliahLoading] = useState(false);
  const [isMatakuliahDisabled, setIsMatakuliahDisabled] = useState(true);
  const [isMatakuliahSelected, setIsMatakuliahSelected] = useState(false);
  const handleMatakuliahOnChange = (selected) => {
    setMatakuliahSelected(selected);
    setIsMatakuliahSelected(true);
  };

  // status
  const statusOptions = [
    {
      value: "buka",
      label: "Buka",
    },
    {
      value: "tutup",
      label: "Tutup",
    },
  ];
  const [statusSelected, setStatusSelected] = useState();
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const handleStatusOnChange = (selected) => {
    setStatusSelected(selected);
    setIsStatusSelected(true);
  };

  // jumlah asisten
  const [jumlahAsisten, setJumlahAsisten] = useState(1);
  const handleJumlahAsistenOnChange = (event) => {
    setJumlahAsisten(event.target.value);
  };

  // pengajar
  const [pengajarOptions, setPengajarOptions] = useState([]);
  const [pengajarSelected, setPengajarSelected] = useState((role === "admin") ? [] :[{value: {name: name, username: username}, label: name}]);
  
  const [isPengajarSelected, setIsPengajarSelected] = useState(false);
  const handlePengajarOnChange = (selected) => {
    setPengajarSelected(selected);
    setIsPengajarSelected(selected.length > 0 ? true : false);
  };

  // matakuliah prasyarat
  const [matakuliahPrasyarat, setMatakuliahPrasyarat] = useState([]);
  const [isPrasyaratSiak, setIsPrasyaratSiak] = useState(false);
  const [isPrasyaratSiakLoading, setIsPrasyaratSiakLoading] = useState(false);
  const fetchPrasyarat = async () => {
    try {
      const response = await GeneralService.getMatakuliahPrasyarat(
        matakuliahSelected["value"]["kode_matakuliah"],
        matakuliahSelected["value"]["kode_kurikulum"]
      );
      const results = response.data.data;
      const newMatkulPrasyarat = [];
      for (const result of results) {
        const value = {
          kode_matakuliah: result.kd_mk,
          kode_kurikulum: result.kd_kur,
          nama_matakuliah: result.nm_mk,
        };
        const label = result.kd_mk + " - " + result.kd_kur + " " + result.nm_mk;
        newMatkulPrasyarat.push({ value: value, label: label });
      }
      setMatakuliahPrasyarat(newMatkulPrasyarat);
      setIsPrasyaratSiakLoading(false);
      return newMatkulPrasyarat;
    } catch (error) {
      console.error("Failed to fetch matakuliah prasyarat:", error);
    }
  };

  const handleCheckPrasyarat = async (e) => {
    setIsPrasyaratSiak(e.target.checked);
    if (e.target.checked) {
      setIsPrasyaratSiakLoading(true);
      const response = await fetchPrasyarat();
      setMatakuliahPrasyarat(response);
    }
  };
  const handleMatakuliahPrasyaratOnChange = (selectedOptions) => {
    setMatakuliahPrasyarat(selectedOptions);
  };

  // syarat tambahan
  const [syaratTambahan, setSyaratTambahan] = useState("");
  const handleSyaratTambahanOnChange = (event) => {
    setSyaratTambahan(event.target.value);
  };

  useEffect(() => {
    const fetchPeriodeList = async () => {
      try {
        const response = await GeneralService.getPeriodeList();
        const results = response.data.data.results;
        const tahun = [];

        for (const result of results) {
          tahun.push(
            result.tahun + "/" + (parseInt(result.tahun) + 1).toString()
          );
        }
        const uniqueTahun = new Set(tahun);
        const tahunAjaran = [...uniqueTahun];
        const tahunAjaranOptions = [];
        for (const elem of tahunAjaran) {
          tahunAjaranOptions.push({ value: elem, label: elem });
        }
        setTahunAjaranOptions(tahunAjaranOptions);
      } catch (error) {
        console.error("Failed to fetch periode lists:", error);
      }
    };

    const fetchDosenList = async () => {
      try {
        const response = await GeneralService.getDosen();
        const results = response.data.data;
        const newDosenOptions = [];

        for (const result of results) {
          newDosenOptions.push({ value: result, label: result["name"] });
        }
        setPengajarOptions(newDosenOptions);
      } catch (error) {
        console.error("Failed to fetch dosen lists:", error);
      }
    };

    fetchPeriodeList();
    fetchDosenList();
  }, []);

  useEffect(() => {
    const fetchMataKuliah = async () => {
      if (isTahunAjaranSelected && isSemesterSelected && isProdiSelected) {
        try {
          // const tahunAjaran = tahunAjaranSelected["value"].split("/")[0];
          // let semester;
          // if (semesterSelected["value"] === "ganjil") {
          //   semester = 1;
          // } else if (semesterSelected["value"] === "genap") {
          //   semester = 2;
          // } else {
          //   semester = 3;
          // }

          // let jenjang;
          // if (jenjangSelected["value"] === "s1") {
          //   jenjang = "s1";
          // } else if (jenjangSelected["value"] === "s2"){
          //   jenjang = "s2";
          // } else {
          //   jenjang = "s3";
          // }

          // // Impelentasi lama menggunakan siakngcs/kelas-list/
          // const response = await GeneralService.getKelasListPerTermJen(
          //   tahunAjaran,
          //   semester,
          //   jenjang,
          // );
          
          // Implementasi menggunakan siakngcs/matakuliah-list/
          const prodi = prodiSelected['value'];
          const kurikulum_arr = ['2024', '2020'];
          const kode_kurikulum_arr = kurikulum_arr.map(kurikulum => prodi + '-' + kurikulum);
          const results = [];

          for (const kode_kurikulum of kode_kurikulum_arr) {
            const response = await GeneralService.getMataKuliahList(kode_kurikulum);
            results.push(response.data.data);
          }
          
          // Gabungkan hasil dari setiap pemanggilan getMataKuliahList di sini
          const finalResult = results.flat();

          if (results.length === 0) {
            setIsTermValid(false);
            setIsJenjangValid(false);
            setIsMatakuliahDisabled(true);
            setIsProdiValid(false);
          } else {
            setIsTermValid(true);
            setIsJenjangValid(true);
            setIsMatakuliahDisabled(false);
            setIsProdiValid(true);
          }

          const matkul = [];
          const uniqueMatkul = new Set();

          for (const result of finalResult) {
            // const matakuliahInfo = result.nm_mk_cl;
            const value = {
              kode_matakuliah: result.kd_mk,
              kode_kurikulum: result.kd_kur,
              nama_matakuliah: result.nm_mk,
            };
            const label =
              result.kd_mk +
              " - " +
              result.kd_kur +
              " " +
              result.nm_mk;

            if (!uniqueMatkul.has(label)) {
              matkul.push({ value: value, label: label });
              uniqueMatkul.add(label);
            }
          }
          setMatakuliahOptions(matkul);
          setIsMatakuliahLoading(false);
        } catch (error) {
          console.error("Failed to fetch kelas lists:", error);
        }
      }
    };
    fetchMataKuliah();
  }, [
    isSemesterSelected,
    isTahunAjaranSelected,
    semesterSelected,
    tahunAjaranSelected,
    isProdiSelected,
    prodiSelected,
  ]);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    const nama_matkul = matakuliahSelected["value"]["nama_matakuliah"];
    const kode_matkul = matakuliahSelected["value"]["kode_matakuliah"];
    const kode_kurikulum = matakuliahSelected["value"]["kode_kurikulum"];
    const tahun_ajaran = tahunAjaranSelected["value"];
    // const jenjang_pendidikan = jenjangSelected["value"];
    const prodi = prodiSelected["value"];
    const semester = semesterSelected["value"].toLowerCase();
    const jumlah_asisten = parseInt(jumlahAsisten);
    const status = statusSelected["value"].toLowerCase();
    const lowongan_pembuka = pengajarSelected.map((pengajar) => ({
      username: pengajar.value.username,
    }));
    const syarat_tambahan = syaratTambahan;
    const prerequisites = matakuliahPrasyarat.map((prasyarat) => ({
      nama_matkul: prasyarat.value.nama_matakuliah,
      kode_matkul: prasyarat.value.kode_matakuliah,
      kode_kurikulum: prasyarat.value.kode_kurikulum,
    }));

    const requestBody = {
      nama_matkul,
      kode_matkul,
      kode_kurikulum,
      tahun_ajaran,
      semester,
      jumlah_asisten,
      status,
      lowongan_pembuka,
      syarat_tambahan,
      prerequisites,
      prodi,
      // jenjang_pendidikan,
    };

    if (
      isMatakuliahSelected &&
      isTahunAjaranSelected &&
      isProdiSelected &&
      isSemesterSelected &&
      jumlahAsisten > 0 &&
      pengajarSelected.length > 0
    ) {
      LowonganService.postLowonganList(requestBody)
        .then((response) => {
          // navigate("/lowongan");
          toast.success("Lowongan berhasil dibuat", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/lowongan");
            },
          });
        })
        .catch((error) => {
          console.error("Failed to create Lowongan:", error);

          toast.error("Tolong Lengkapi Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"back medium-m8"} style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
            ← Kembali
            </div>
        <div className={"content"}>
          <form className={"body-container"} onSubmit={handleSubmit}>
            <h2>Buat Lowongan</h2>
            {step === 1 && (
              <div className={"card-form-container container-shadow"}>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Tahun Ajaran</div>
                  <SingleSelect
                    value={tahunAjaranSelected}
                    options={tahunAjaranOptions}
                    onChange={handleTahunAjaranOnChange}
                  />
                </div>
                
                {/* Implementasi lama menggunakan jenjang S1-S2-S3
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Jenjang</div>
                  <SingleSelect
                    value={jenjangSelected}
                    options={jenjangOptions}
                    onChange={handleJenjangOnChange}
                    isDisabled={!isTahunAjaranSelected}
                  />
                </div> */}

                {
                // Implementasi baru menggunakan prodi
                }
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Prodi</div>
                  <SingleSelect
                    value={prodiSelected}
                    options={prodiOptions}
                    onChange={handleProdiOnChange}
                    isDisabled={!isTahunAjaranSelected}
                  />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Semester</div>
                  <SingleSelect
                    value={semesterSelected}
                    options={semesterOptions}
                    onChange={handleSemesterOnChange}
                    // isDisabled={!isJenjangSelected}
                    isDisabled={!isProdiSelected}
                  />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="tw-flex tw-flex-row tw-gap-4">
                    <div className="header-h6">Matakuliah</div>
                    <Spinner
                      size={20}
                      isLoading={
                        isMatakuliahLoading &&
                        isTahunAjaranSelected &&
                        isSemesterSelected &&
                        isProdiSelected
                      }
                    />
                  </div>
                  {!isTermValid && (
                    <div
                      style={{
                        color: "red",
                      }}
                    >
                      Term tidak valid
                    </div>
                  )}
                  <SingleSelect
                    value={matakuliahSelected}
                    options={matakuliahOptions}
                    onChange={handleMatakuliahOnChange}
                    isDisabled={isMatakuliahDisabled}
                  />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Status</div>
                  <SingleSelect
                    value={statusSelected}
                    options={statusOptions}
                    onChange={handleStatusOnChange}
                    isDisabled={!isMatakuliahSelected}
                  />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Jumlah Asisten</div>
                  <NumInput
                    min={1}
                    value={jumlahAsisten}
                    onChange={handleJumlahAsistenOnChange}
                    isDisabled={!isStatusSelected}
                  />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Pengajar</div>
                  <MultiSelect
                    value={pengajarSelected}
                    options={pengajarOptions}
                    onChange={handlePengajarOnChange}
                    isDisabled={!isStatusSelected}
                  />
                </div>

                <div
                  className="button-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingLeft: 0,
                    paddingTop: 0,
                  }}
                >
                  <Button
                    text={"Lanjut"}
                    onClick={handleNextStep}
                    color={"#FFFFFF"}
                    isDisabled={
                      !(
                        isTahunAjaranSelected &&
                        isSemesterSelected &&
                        isMatakuliahSelected &&
                        isStatusSelected &&
                        pengajarSelected &&
                        pengajarSelected.length > 0 &&
                        !isMatakuliahDisabled
                      )
                    }
                  />
                </div>
              </div>
            )}

            {step === 2 && (
              <div className={"card-form-container container-shadow"}>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Prasyarat</div>
                  <div className="tw-flex tw-flex-row tw-gap-4">
                    <label>
                      <input
                        type="checkbox"
                        name="prasyaratSiakng"
                        checked={isPrasyaratSiak}
                        onChange={handleCheckPrasyarat}
                      />
                      Prasyarat Siakng
                    </label>
                    <Spinner size={20} isLoading={isPrasyaratSiakLoading} />
                  </div>

                  <MultiSelect
                    value={matakuliahPrasyarat}
                    options={matakuliahOptions}
                    onChange={handleMatakuliahPrasyaratOnChange}
                  />
                </div>

                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Syarat Tambahan</div>
                  <TextInput
                    label={"Syarat Tambahan"}
                    name={"syaratTambahan"}
                    value={syaratTambahan}
                    onChange={handleSyaratTambahanOnChange}
                  />
                </div>

                <div className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: 0,
                  paddingTop: 0,
                }}>
                  <div
                    style={{
                      marginRight: "180px",
                    }}
                  >
                    <Button
                      text={"Kembali"}
                      onClick={handlePreviousStep}
                      color={"#FFFFFF"}
                    />
                  </div>
                  <Button
                    text={"Buat"}
                    type="submit"
                    color={"#FFFFFF"}
                    onClick={handleSubmit}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default CreateLowonganPage;
