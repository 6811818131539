import { useState, useMemo, useEffect } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import TableHonorRate from "../../../Component/GroupOne/Table/TableHonorRate";
import "../../../assets/group1/scss/_variables.scss";
import "../../../assets/group1/scss/Page/BelumTentuHonor.scss";
import DeleteHonor from '../../../Component/GroupOne/DeleteHonor';
import { toast, ToastContainer } from "react-toastify";
import { useParams } from 'react-router-dom';

const HonorRateList = () => {
  const { id } = useParams();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteIds, setDeleteIds] = useState(null);
  const token = sessionStorage.getItem("jwtToken");
  const [honorRates, setHonorRates] = useState({});

  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);

  const pendidikanAsdos = ["Lulus S1 / Mahasiswa S2", "Lulus S2 / Mahasiswa S3", "TA Kontrak"]
  const pengalamanAsdos = ["Junior", "Menengah", "Senior"]
  const honorRatesInitialData = [];

  pendidikanAsdos.forEach((jenjang, i) => {
    pengalamanAsdos.forEach((pengalaman, j) => {
      honorRatesInitialData.push({ id, jenjang, pengalaman });
    });
  });

  const fetchHonor = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/honor/${id}`, {
        headers: headerData,
      });

      if (response.ok) {
        const data = await response.json();
        const honorRatesSet = new Set();
        for (const key in data.list_honor) {
          const keyString = `${data.list_honor[key].pendidikan_asdos}-${data.list_honor[key].pengalaman}`
          if (keyString in honorRatesSet) {
            honorRatesSet[keyString].push(data.list_honor[key].pk);
          } else {
            honorRatesSet[keyString] = [data.list_honor[key].pk];
          }
        }
        setHonorRates(honorRatesSet);
      } else {
        console.error('Error fetching honor data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching honor data:', error);
    }
  };

  useEffect(() => {
    fetchHonor();
  }, [headerData]);

  const confirmDelete = (ids) => {
    setShowConfirmation(true);
    setDeleteIds(ids);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setDeleteIds(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      var response = null
      for (const index in deleteIds) {
        response = await fetch(`${process.env.REACT_APP_BASE_URL}/honor/rate/${deleteIds[index]}/`, {
          headers: headerData,
          method: 'DELETE',
        });

        if (!response.ok) {
          setShowConfirmation(false);
          toast.error('Honor Rate sedang digunakan sehingga tidak dapat dihapus', {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          break
        }
      }

      if (response.ok) {
        toast.success("Honor Rate Berhasil Dihapus", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            window.location.reload();
          },
        });
        setShowConfirmation(false);
      }
    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
    <div className="view">
      <Navbar />
      <a
        href="/honor-ref-list"
        className={'back medium-m8'}
        onClick={() => {
          window.history.back();
          return false;
        }}
      >
        ← Kembali ke Daftar Honor Ref
      </a>
      <div className="frame-container">
        <div className="header-container">
          <div className="title-container">
            <div className={'header-h4'}>Detail Honor Rate</div>
          </div>
        </div>

        <DeleteHonor
          isOpen={showConfirmation}
          onCancel={cancelDelete}
          onConfirm={handleDeleteConfirmation}
        />

        <TableHonorRate
          honorRatesInitialData={honorRatesInitialData}
          handleHapus={confirmDelete}
          honorRates={honorRates}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default HonorRateList;