import Filter from "../../../Component/GroupOne/Filter";
import Filter2 from "../../../Component/GroupOne/Filter2";
import Table from "../../../Component/GroupOne/Table";
import Button from "../../../Component/GroupOne/Buttons/Button";
/* eslint-disable-next-line no-unused-vars */
import { Fragment, useState, useMemo } from "react";
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import Navbar from "../../../Component/GroupOne/Navbar";
import { eachYearOfInterval, format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";


const LaporanPembayaranHonor = () => {
    const [selectedTahun, setSelectedTahun] = useState('Tahun');
    const [selectedBulan, setSelectedBulan] = useState('Bulan');
    const [selectedJenjang, setSelectedJenjang] = useState('Jenjang');
    const [bulanSelected, setBulanSelected] = useState("");
    const [tahunSelected, setTahunSelected] = useState("");
    const [jenjangSelected, setJenjangSelected] = useState("");
    const [content, setContent] = useState('');
    const [isContentEmpty, setIsContentEmpty] = useState('');
    const token = sessionStorage.getItem("jwtToken");
    const name = sessionStorage.getItem("name");
    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);
    const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();
    const bulanOptions = [
        "Bulan",
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
    ]

    const handleTahunChange = (tahun) => {
        setSelectedTahun(tahun);
    };

    const handleBulanChange = (bulan) => {
        setSelectedBulan(bulan);
    };

    const handleJenjangChange = (jenjang) => {
        setSelectedJenjang(jenjang);
    };

    const queryParams = new URLSearchParams({
        tahun: selectedTahun,
        bulan: selectedBulan,
        jenjang: selectedJenjang
    });

    const handleButtonClick = () => {
        if (selectedTahun === "Tahun" || selectedBulan === "Bulan" || selectedJenjang === "Jenjang") {
            toast.error("Tahun, bulan, dan jenjang harus dipilih terlebih dahulu", {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return
        }
        fetch(`${process.env.REACT_APP_BASE_URL}/honor/laporanpembayaranhonor/?${queryParams.toString()}`, {
            method: 'GET',
            headers: headerData,
        })
            .then((response) => response.json())
            .then((data) => {
                setContent(data.sort((a, b) => {
                    return a.mahasiswa__name.localeCompare(b.mahasiswa__name)
                }))
                setIsContentEmpty(data.length === 0)
                setBulanSelected(selectedBulan)
                setTahunSelected(selectedTahun)
                setJenjangSelected(selectedJenjang)
            })
            .catch((error) => console.error('Error sending message:', error));
    };

    return (
        <Fragment>
            <div className={"view"}>
                <Navbar name={name} />
                <div className={"content-group1"}>
                    <div className={"header-h5"}>Laporan Pembayaran Honor Asisten</div>
                    <div className={"divider"}></div>
                    <div className={"filters-container-perbulan"}>
                        <Filter text={"Tahun"} items={["Tahun", ...years]} onFilterChange={handleTahunChange} />
                        <Filter2 text={"Bulan"} items={bulanOptions} onFilterChange={handleBulanChange} />
                        <Filter text={"Jenjang"} items={["Jenjang", "S1-MIK", "MTI"]} onFilterChange={handleJenjangChange} />
                        <Button text={"Lihat Laporan"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleButtonClick} />



                    </div>

                    {content && content.length > 0 ? (
                        <>
                            <div className={"medium-m7"}>Daftar Laporan Pembayaran Honor Asisten</div>
                            <Table
                                items={['No', 'Matakuliah', 'Asisten', 'Periode Bayar', 'Jumlah Log', 'Jumlah Jam', 'Jumlah Pembayaran']}
                                user={'admin2'}
                                content={content}
                            />
                        </>
                    ) : (
                        isContentEmpty ? (
                            <>
                                <div className={"no-content"}>

                                    {`Tidak ada laporan pembayaran asisten pada: ${bulanOptions[bulanSelected]} ${tahunSelected} jenjang ${jenjangSelected}`}
                                </div>
                            </>
                        ) : null
                    )
                    }
                    <ToastContainer />
                </div>
            </div>
        </Fragment>
    );
};

export default LaporanPembayaranHonor;
