import { useEffect, useState } from "react";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import "../../App.scss";
import "../../assets/scss/Profile.scss";
import InputBox from "../../Component/InputBox";
import SingleSelect from "../../Component/SingleSelect";
import TextInput from "../../Component/TextInput";
import NumInput from "../../Component/NumInput";
import bankOptions from "./bankOptions";
import Button from "../../Component/Button";
import profpic from "../../assets/images/profpic-placeholder.png";
import FileUploader from "../../Component/FileUploader";
import CVUploader from "../../Component/CVUploader";
import Layout from "../../Component/Layout";
import AuthService from "../../Services/AuthService";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  const statusOptions = [
    { value: "Mahasiswa S1", label: "Mahasiswa S1" },
    { value: "Lulus S1 / Mahasiswa S2", label: "Lulus S1 / Mahasiswa S2" },
    { value: "Lulus S2 / Mahasiswa S3", label: "Lulus S2 / Mahasiswa S3" },
    { value: "TA Kontrak", label: "TA Kontrak" },
  ];

  const [informasiDasar, setInformasiDasar] = useState({
    nama: "",
    npm: "",
    status: "",
  });
  const [kontakWaktuKosong, setKontakWaktuKosong] = useState({
    email: "",
    telp: "",
    waktuKosong: "",
  });
  const [rekening, setRekening] = useState({
    bank: "",
    noRekening: "",
    atasNama: "",
  });
  const [profilePicture, setProfilePicture] = useState({
    file: null,
    image: profpic,
    isChange: false,
  });
  const [cvFile, setCvFile] = useState({
    file: null,
    image: profpic,
    isChange: false,
  });
  const [profileMessage, setProfileMessage] = useState();
  const [cvMessage, setCvMessage] = useState();
  const [cvFileName, setCvFileName] = useState("");
  const [bukuTabungan, setBukuTabungan] = useState({
    file: null,
    image: profpic,
    isChange: false,
  });
  const [tabunganMessage, setTabunganMessage] = useState();
  const [isAbleSubmit, setIsAbleSubmit] = useState(false);

  const handleProfilePictureUpload = (e) => {
    if (e.target.files[0] && e.target.files[0].size > 500 * 1024) {
      setProfileMessage("Ukuran file terlalu besar");
    } else {
      setProfilePicture({
        file: e.target.files[0],
        image: URL.createObjectURL(e.target.files[0]),
        isChange: true,
      });
      setProfileMessage();
    }
  };

  const handleCVUpload = (e) => {
    if (!e.target.files[0]) {
      setCvMessage(null);
      // setCvFileName(CVFileName);
      return;
    }

    if (e.target.files[0].size > 1024 * 1024) {
      setCvMessage("Ukuran file terlalu besar. Maksimum 1MB.");
      toast.error("CV belum berhasil diupload", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      setCvFile({
        file: e.target.files[0],
        isChange: true,
      });
      setCvFileName(e.target.files[0].name);
      setCvMessage(null);
      toast.success("CV berhasil diupload", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    }
  };

  const handleTabunganUpload = (e) => {
    if (e.target.files[0] && e.target.files[0].size > 500 * 1024) {
      setTabunganMessage("Ukuran file terlalu besar");
    } else if (e.target.files[0]) {
      setBukuTabungan({
        file: e.target.files[0],
        image: URL.createObjectURL(e.target.files[0]),
        isChange: true,
      });
      setTabunganMessage();
    }
  };

  const handleStatusOnChange = (selected) => {
    setInformasiDasar({
      ...informasiDasar,
      status: selected,
    });
  };

  const handleBankOnChange = (selected) => {
    setRekening({
      ...rekening,
      bank: selected,
    });
  };

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await AuthService.getProfileDetails();
        const results = response.data.data;
        setInformasiDasar({
          nama: results["name"],
          npm: results["kode_identitas"],
          status: {
            value: results["jenjang_pendidikan"],
            label: results["jenjang_pendidikan"],
          },
        });
        setKontakWaktuKosong({
          email: results["email"],
          telp: results["telepon"],
          waktuKosong: results["waktu_kosong"],
        });
        setRekening({
          bank: {
            value: results["nama_bank"],
            label: results["nama_bank"],
          },
          noRekening: results["no_rekening"],
          atasNama: results["atas_nama"],
        });

        if (results["foto_profil"]) {
          const profileUrlParts = results["foto_profil"].split("/");
          const profileApiIndex = profileUrlParts.indexOf("api");
          const url_profil = profileUrlParts
            .slice(profileApiIndex + 2)
            .join("/");
          setProfilePicture({
            file: process.env.REACT_APP_BACKEND_URL + "/" + url_profil,
            image: process.env.REACT_APP_BACKEND_URL + "/" + url_profil,
            isChange: false,
          });
        }

        if (results["foto_bank"]) {
          const bankUrlParts = results["foto_bank"].split("/");
          const bankApiIndex = bankUrlParts.indexOf("api");
          const url_bank = bankUrlParts.slice(bankApiIndex + 2).join("/");
          const token = sessionStorage.getItem("jwtToken");
          const headerData = new Headers();
          headerData.append('Content-type', 'application/json');
          headerData.append("Authorization", "Bearer " + token);

          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/${url_bank}`,
            {
              headers: headerData
            }
          );
          if (response.ok) {
            const responseData = await response.blob();
            const imageUrl = URL.createObjectURL(responseData);
            setBukuTabungan({
              file: imageUrl,
              image: imageUrl,
              isChange: false,
            });
          } else {
            console.error("Failed to fetch foto_bank");
          }
        }

        if (results["cv"]) {
          const cvUrlParts = results["cv"].split("/");
          const cvApiIndex = cvUrlParts.indexOf("api");
          const url_cv = cvUrlParts
            .slice(cvApiIndex + 2)
            .join("/");

          setCvFile({
            file: process.env.REACT_APP_BACKEND_URL + "/" + url_cv,
            isChange: false,
          });
          setCvFileName(cvUrlParts.pop());
        }

      } catch (error) {
        console.error("Failed to fetch profile details:", error);
      }
    };

    fetchDetails();
  }, []);

  useEffect(() => {
    const isAble =
      informasiDasar["status"]["value"] &&
      kontakWaktuKosong["email"] &&
      kontakWaktuKosong["telp"] &&
      rekening["bank"]["value"] &&
      rekening["noRekening"] &&
      rekening["atasNama"];

    const isAbleImage = !(
      profilePicture.image === profpic || bukuTabungan.image === profpic
    );

    setIsAbleSubmit(isAble ? isAbleImage : false);


  }, [
    bukuTabungan.image,
    informasiDasar,
    kontakWaktuKosong,
    profilePicture.image,
    rekening,
    cvFile,
  ]);

  const handleSubmit = () => {
    if (!/\S+@\S+\.\S+/.test(kontakWaktuKosong["email"])) {
      toast.error("Email tidak valid", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    let data = new FormData();
    data.append("jenjang_pendidikan", informasiDasar["status"]["value"]);
    data.append("email", kontakWaktuKosong["email"]);
    data.append("telepon", kontakWaktuKosong["telp"]);
    data.append("waktu_kosong", kontakWaktuKosong["waktuKosong"]);
    data.append("nama_bank", rekening["bank"]["value"]);
    data.append("no_rekening", rekening["noRekening"]);
    data.append("atas_nama", rekening["atasNama"]);
    if (profilePicture["isChange"]) {
      data.append("foto_profil", profilePicture["file"]);
    }
    if (bukuTabungan["isChange"]) {
      data.append("foto_bank", bukuTabungan["file"]);
    }
    if (cvFile["isChange"]) {
      data.append("cv", cvFile["file"]);
    }

    if (isAbleSubmit) {
      AuthService.upadteProfile(data)
        .then((response) => {
          toast.success("Profil berhasil di update", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          console.error("Failed to update profile:", error);
          toast.error("Ada masalah pada server kami", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Layout>
      <div className="header-h4">Profil Asisten</div>
      <div className={"back medium-m8"} style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
        ← Kembali
      </div>

      <div className="profile-item-container container-shadow tw-flex tw-flex-col tw-gap-5">
        <div className="header-h6">Informasi Dasar</div>

        <div className="tw-grid sm:tw-grid-cols-4 tw-w-full tw-gap-6 tw-grid-cols-1">
          <div className="tw-col-span-1 tw-flex tw-flex-col tw-gap-3 tw-w-full">
            <div className="header-h7">Foto Profil</div>

            <div className="profile-picture-container container-shadow">
              <a
                href={profilePicture["image"]}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={profilePicture["image"]}
                  alt="foto profil"
                  className="profile-picture-content"
                />
              </a>
            </div>

            <FileUploader handleOnChange={handleProfilePictureUpload} />

            {profileMessage ? (
              <div className="notes-text" style={{ color: "red" }}>
                {profileMessage}
              </div>
            ) : null}
            <div className="notes-text">max File size: 500KB</div>
          </div>

          <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-3 tw-w-full">
            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <div className="header-h7">Nama</div>
              <InputBox value={informasiDasar["nama"]} disabled={true} />
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <div className="header-h7">NPM</div>
              <InputBox value={informasiDasar["npm"]} disabled={true} />
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <div className="header-h7">Status</div>
              <SingleSelect
                value={informasiDasar["status"]}
                onChange={handleStatusOnChange}
                options={statusOptions}
              />
            </div>

            <div className="tw-col-span-4 tw-flex tw-flex-col tw-gap-3 tw-w-full">
              <div className="header-h7">CV</div>
              <div className="tw-flex tw-items-center tw-gap-3">
                <CVUploader handleOnChange={handleCVUpload} />
                <div className="tw-flex tw-items-center">
                  <div style={{overflowWrap: 'anywhere'}}>{cvFileName}</div>
                </div>
              </div>
              {cvMessage ? (
                <div className="notes-text" style={{ color: "red" }}>
                  {cvMessage}
                </div>
              ) : null}
              <div className="notes-text">max File size: 1MB</div>
            </div>

          </div>
        </div>
      </div>

      <div className="profile-item-container container-shadow tw-flex tw-flex-col tw-gap-5">
        <div className="header-h6" >Kontak & Waktu Kosong  </div>

        <div className="tw-col-span-3 tw-flex tw-flex-col tw-gap-3 tw-w-full">
          <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div className="header-h7">Email</div>
            <InputBox
              value={kontakWaktuKosong["email"]}
              onChange={(event) => {
                setKontakWaktuKosong({
                  ...kontakWaktuKosong,
                  email: event.target.value,
                });
              }}
            />
          </div>

          <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div className="header-h7">No Telepon</div>
            <NumInput
              value={kontakWaktuKosong["telp"]}
              onChange={(event) => {
                setKontakWaktuKosong({
                  ...kontakWaktuKosong,
                  telp: event.target.value,
                });
              }}
            />
          </div>

          <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
            <div className="header-h7">Waktu Kosong</div>
            <TextInput
              value={kontakWaktuKosong["waktuKosong"]}
              onChange={(event) => {
                setKontakWaktuKosong({
                  ...kontakWaktuKosong,
                  waktuKosong: event.target.value,
                });
              }}
              placeholder={"Berikan keterangan waktu dari jam secara spesifik, contohnya : 07.00 pagi - 12.00 siang"}
            />
          </div>
        </div>
      </div>

      <div className="profile-item-container container-shadow tw-flex tw-flex-col tw-gap-5">
        <div className="header-h6">Informasi Rekening</div>

        <div className="tw-grid sm:tw-grid-cols-4 tw-w-full tw-gap-6 tw-grid-cols-1">
          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-3 tw-w-full">
            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <div className="header-h7">Nama Bank</div>
              <SingleSelect
                onChange={handleBankOnChange}
                options={bankOptions}
                value={rekening["bank"]}
              />
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <div className="header-h7">Nomor Rekening</div>
              <NumInput
                value={rekening["noRekening"]}
                onChange={(event) => {
                  setRekening({
                    ...rekening,
                    noRekening: event.target.value,
                  });
                }}
              />
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full">
              <div className="header-h7">Atas Nama</div>
              <InputBox
                value={rekening["atasNama"]}
                onChange={(event) => {
                  setRekening({
                    ...rekening,
                    atasNama: event.target.value,
                  });
                }}
              />
              <div className="notes-text">
                Pastikan nama sesuai dengan foto yang diupload. Kesalahan pada
                nama pemilik tabungan dapat menyebabkan keterlambatan
                pembayaran.
              </div>
            </div>
          </div>

          <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-3 tw-w-full">
            <div className="header-h7">Foto Buku Tabungan</div>
            <div className="tabungan-container container-shadow">
              <a href={bukuTabungan["image"]} target="_blank" rel="noreferrer">
                <img
                  src={bukuTabungan["image"]}
                  alt="buku tabungan"
                  className="tabungan-content"
                />
              </a>
            </div>

            <FileUploader handleOnChange={handleTabunganUpload} />

            {tabunganMessage ? (
              <div className="notes-text" style={{ color: "red" }}>
                {tabunganMessage}
              </div>
            ) : null}
            <div className="notes-text">
              Foto halaman pertama buku tabungan yang berisi nomor rekening dan
              nama pemilik rekening.(max File size: 500KB)
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-container"
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingLeft: 0,
          paddingTop: 0,
        }}>
        <Button
          text={"Simpan"}
          type="submit"
          color={"#FFFFFF"}
          onClick={handleSubmit}
          isDisabled={!isAbleSubmit}
          data-bs-toggle="tooltip" data-bs-placement="right" title="Pastikan semua data telah terisi"
        />
      </div>
      <ToastContainer />
    </Layout>
  );
};
export default Profile;
