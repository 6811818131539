import '../../assets/group1/scss/Input/TimePicker.scss';
import { TimePicker as AntDTimePicker } from "antd";

const TimePicker = (props) => {
    return (
        <div>
            <div className={"header-h6"} style={{marginBottom: '10px'}}> {props.label} </div>
            <AntDTimePicker.RangePicker 
                className={"timepicker-container body-p7"}
                onChange={props.onChange}
                placeholder={props.placeholder}
                format={props.format}
                suffixIcon={null}
                value={props.value}
                minuteStep={15}
            />
        </div>
    );
}

export default TimePicker;