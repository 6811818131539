import '../../../assets/group1/scss/Table/TableAsdosHonor.scss';
import '../../../assets/group1/scss/_variables.scss';
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { REGEX_JENJANG } from '../../../Pages/GroupOne/log/logUtil';
import HonorService from '../../../Services/HonorService';

const HonorAssistant = (props) => {
  const [content, setContent] = useState([]);
  const [listKategori, setListKategori] = useState([]);
  const [loading, setLoading] = useState(true);
  let [searchParams] = useSearchParams();
  const statusFilter = searchParams.get("status");
  const tahunFilter = searchParams.get("tahun");
  const bulanFilter = searchParams.get("bulan");

  useState(() => {
    const fetchKategori = async () => {
      try {
        const response = await HonorService.getCategory();
        setListKategori(response.data || []);
      } catch (error) {
        console.error('Error fetching category:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchKategori();
  }, []);

  useEffect(() => {
    if (statusFilter && bulanFilter && tahunFilter) {
      const filteredData = props.content.filter((item) => {
        // Extract the month and year from the "datetime" property
        const itemMonth = new Date(item.datetime).getMonth() + 1; // Months are 0-indexed, so add 1
        const itemYear = new Date(item.datetime).getFullYear();

        // Compare the extracted month, year, and status with the corresponding filters
        return itemMonth.toString() === bulanFilter && itemYear.toString() === tahunFilter && item.status === statusFilter;
      });
      setContent(filteredData);
    } else if (bulanFilter && tahunFilter) {
      const filteredData = props.content.filter((item) => {
        // Extract the month and year from the "datetime" property
        const itemMonth = new Date(item.datetime).getMonth() + 1; // Months are 0-indexed, so add 1
        const itemYear = new Date(item.datetime).getFullYear();

        // Compare the extracted month and year with the bulanParam and tahunParam
        return itemMonth.toString() === bulanFilter && itemYear.toString() === tahunFilter;
      });
      setContent(filteredData);
    } else if (statusFilter && bulanFilter) {
      const filteredData = props.content.filter((item) => {
        // Extract the month from the "datetime" property
        const itemMonth = new Date(item.datetime).getMonth() + 1; // Months are 0-indexed, so add 1

        // Compare the extracted month with the bulanParam and status with statusFilter
        return itemMonth.toString() === bulanFilter && item.status === statusFilter;
      });
      setContent(filteredData);
    } else if (statusFilter && tahunFilter) {
      const filteredData = props.content.filter((item) => {
        // Extract the year from the "datetime" property
        const itemYear = new Date(item.datetime).getFullYear();

        // Compare the extracted year with the tahunParam and status with statusFilter
        return itemYear.toString() === tahunFilter && item.status === statusFilter;
      });
      setContent(filteredData);
    } else if (statusFilter) {
      const filteredContent = props.content.filter((item) => item.status === statusFilter);
      setContent(filteredContent);
    } else if (bulanFilter) {
      const filteredData = props.content.filter((item) => {
        // Extract the month from the "datetime" property
        const itemMonth = new Date(item.datetime).getMonth() + 1; // Months are 0-indexed, so add 1

        // Compare the extracted month with the bulanParam
        return itemMonth.toString() === bulanFilter;
      });
      setContent(filteredData);
    } else if (tahunFilter) {
      const filteredData = props.content.filter((item) => {
        // Extract the year from the "datetime" property
        const itemYear = new Date(item.datetime).getFullYear();

        // Compare the extracted year with the tahunParam
        return itemYear.toString() === tahunFilter;
      });
      setContent(filteredData);
    }
    else {
      setContent(props.content);
    }
  }, [props.content, bulanFilter, statusFilter, tahunFilter]);

  const monthlyHonor = {};

  // eslint-disable-next-line
  let totalHonorValue = 0;
  let totalHoursValue = 0;

  const getHonorAmount = (log) => {
    if (!loading){
      const honorRef = props.item[log.honor_reference];
      const lowongan = log.lowongan;
      const kode = `${lowongan.kode_matkul} - ${lowongan.kode_kurikulum}`

      for (const [regex, jenjang] of REGEX_JENJANG) {
        if (new RegExp(regex).test(String(kode))) {
          if (jenjang === 'S1-MIK' || jenjang === 'S3') {
            return honorRef.list_honor.filter(item =>
              item.pendidikan_asdos === props.user.jenjang_pendidikan
            )[0].amount
          } else {
            const kategori = log.category
            const location = log.location;
            // Find the corresponding category ID from listKategori
            const kategoriItem = listKategori.find(item => 
              item.name === kategori &&
              item.jenjang === jenjang
            );
            const kategoriId = kategoriItem ? kategoriItem.id : null;

            if (!kategoriId) {
              console.error('Category ID not found for kategori:', kategori);
              return 0;
            }
            const honor_rate = honorRef.list_honor.filter(item =>
              item.pendidikan_asdos === props.user.jenjang_pendidikan &&
              item.location === location &&
              item.pengalaman === props.user.pengalaman &&
              item.kategori === kategoriId
            )
            const honor_amount = honor_rate[0] ? honor_rate[0].amount : 0;

            return honor_amount
          }
        }
      }
      return 0;
    }
  };

  content.forEach((item) => {
    let lowonganName
    if (item["lowongan"]["is_spesial"] === true) {
      lowonganName = item["lowongan"]["nama"]
    } else {
      lowonganName = `${item["lowongan"]["nama"]} - ${item["lowongan"]["kode_matkul"]} - ${item["lowongan"]["kode_kurikulum"]}`;
    }
    const duration = parseInt(item["duration"], 10);
    const status = item["status"];
    const monthYear = new Date(item["datetime"]).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',
    });
    const honor = getHonorAmount(item)

    // Combine lowongan name, status, and monthYear to create a unique key
    const key = `${lowonganName}_${status}_${monthYear}_${honor}`;

    // Initialize the monthly honor to 0 if it's the first entry for this key
    monthlyHonor[key] = (monthlyHonor[key] || 0) + duration;
    totalHoursValue += duration / 60;
  });

  const usedMonthlyHonorKeys = new Set();

  return (
    <>
      {content.length !== 0 ? (
        <>
          {Object.keys(monthlyHonor).map((key, index) => {
            const [lowonganName, status, monthYear, honor] = key.split('_');

            // Check if the `key` has already been used
            if (usedMonthlyHonorKeys.has(key)) {
              return null; // Skip rendering the row if `key` is already used
            }

            // Add the `key` to the set of used keys
            usedMonthlyHonorKeys.add(key);
            var thisHonor = Number.parseInt(honor) * (Math.floor(monthlyHonor[`${lowonganName}_${status}_${monthYear}_${honor}`] / 60) + monthlyHonor[`${lowonganName}_${status}_${monthYear}_${honor}`] % 60 / 60)
            totalHonorValue += thisHonor

            return (
              <tr key={index}>
                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                  <div>
                    {monthYear}
                  </div>
                </td>
                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                  {lowonganName}
                </td>
                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                  {Math.floor(monthlyHonor[`${lowonganName}_${status}_${monthYear}_${honor}`] / 60)} hour(s) {monthlyHonor[`${lowonganName}_${status}_${monthYear}_${honor}`] % 60 === 0 ? "" : `${monthlyHonor[`${lowonganName}_${status}_${monthYear}_${honor}`] % 60} minute(s)`} 
                </td>
                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                  {(Number.parseInt(honor)).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                </td>
                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                  {(thisHonor).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                </td>
                <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                  {status}
                </td>
              </tr>
            )
          }
          )}
          <tr key="total-row">
            <td className={"tw-text-center tw-p-4"}>
            </td>
            <td className={"tw-text-center tw-p-4"} style={{fontWeight: "bold"}}>
              Total:
            </td>
            <td className={"tw-text-center tw-p-4"} style={{fontWeight: "bold"}}>
              {Math.floor(totalHoursValue)} hour(s) {Math.floor((totalHoursValue * 60) % 60)} minute(s)
            </td>
            <td className={"tw-text-center tw-p-4"}>
            </td>
            <td className={"tw-text-center tw-p-4"} style={{fontWeight: "bold"}}>
              {totalHonorValue.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
            </td>
            <td className={"tw-text-center tw-p-4"}>
            </td>
          </tr>
        </>
      ) : (
        <div>
          <p className={"header-h7"}>Tidak ada honor pada saat ini</p>
        </div>
      )}
    </>
  );
}

export default HonorAssistant;
