import "../assets/scss/TextSearchBar.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
const SearchInput = (props) => {
  return (
    <div className="search-input">
      <input type="text" placeholder="Search..."
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        {...props} />
      <FontAwesomeIcon icon={faSearch} className="search-icon" />
    </div>

  );
};

export default SearchInput;