/* eslint-disable jsx-a11y/anchor-is-valid */
import "../assets/scss/Table/Table.scss";
import "../assets/scss/_variables.scss";
import "../App.scss";
import { useState } from "react";
import Pagination from "./Pagination";
import "../assets/scss/Pagination.scss";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const SEMESTER_DICT = {
  "1": "Ganjil",
  "2": "Genap",
  "3": "Pendek"
}

const TableRow = ({ rowData, index }) => {
  const Navigate = useNavigate();
  const lowonganId = rowData.lowongan_id;
  const ProfileMahasiswa = () => {
    Navigate(`/profile/${rowData.mahasiswa_id}/${lowonganId}`);
  };

  return (
    <tr>
      <td className="tw-text-center">{index + 1}</td>
      <td className="tw-text-center tw-p-2">
        {rowData.nama_matkul}
      </td>
      <td className="tw-text-center tw-capitalize">
        {SEMESTER_DICT[rowData.semester]} <br></br>
        {rowData.tahun_ajaran}
      </td>
      <td className="tw-text-center tw-p-2">{rowData.nama_asisten}</td>
      <td className="tw-text-center tw-p-2">{rowData.email}</td>
      <td className="tw-text-center tw-p-2">{rowData.telepon}</td>
      <td className="tw-text-center">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            text={"Lihat"}
            onClick={ProfileMahasiswa}
            background={"#4D8AFF"}
            color={"#FFFFFF"}
          />
        </div>
      </td>
    </tr>
  );
};

const LamaranDiterimaTable = ({ items, lamaranList }) => {
  const headerItems = items[0];

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = lamaranList.slice(
    indexOfFirstRecord,
    indexOfLastRecord,
  );
  const nPages = Math.ceil(lamaranList.length / recordsPerPage);

  if (lamaranList && lamaranList.length > 0) {
    return (
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        <table className={"tw-w-full tw-table-auto"}>
          <thead>
            <tr className={"table-header-group2"}>
              {headerItems.map((item, index) => {
                let styleName;
                if (index === 0) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                } else if (index === headerItems.length - 1) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                } else {
                  styleName = "tw-px-4 tw-py-2 tw-text-white";
                }

                return (
                  <th key={index} className={styleName}>
                    <div className="">{item}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 &&
              currentRecords.map((rowData, index) => (
                <TableRow
                  key={index}
                  index={index}
                  rowData={rowData}
                />
              ))}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  } else {
    return (
      <div className={"table-container container-shadow tw-pt-6"}>
        <h2>Tidak ada lamaran yang tersedia</h2>
      </div>
    );
  }
};

export default LamaranDiterimaTable;