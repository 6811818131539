import { useRef } from "react";
import Button from "./Button";

export const FileUploader = ({ handleOnChange }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    handleOnChange(event);
  };

  return (
    <>
      <Button text={"Unggah Foto"} onClick={handleClick} color={"#FFFFFF"} />
      <input
        type="file"
        onChange={handleChange}
        ref={hiddenFileInput}
        accept="image/*"
        style={{ display: "none" }}
      />
    </>
  );
};

export default FileUploader;
