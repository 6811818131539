import Navbar from "../../../Component/GroupOne/Navbar";
import Filter3 from "../../../Component/GroupOne/Filter3";
import { Fragment, useState, useEffect } from 'react';
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import MataKuliahDosen from "../../../Component/GroupOne/MataKuliahDosen";
import LogService from "../../../Services/LogService";
import LowonganService from "../../../Services/LowonganService";

const AdminDosenMelihatListMatkul = () => {
  const [courses, setCourses] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  const [term, setTerm] = useState({ semester: '', year: '' });

  const handleTermChange = (selectedTerm) => {
    const splitTerm = selectedTerm.split(" - ");
    setTerm({ semester: splitTerm[1], year: splitTerm[0] })
  }

  const fetchTerm = async () => {
    try {
      const response = await LowonganService.getTerm();
      const data = response.data.data
      setTerm({ semester: data[0].semester, year: data[0].tahun_ajaran })
      setTermOptions(data.map(item => `${item.tahun_ajaran} - ${item.semester.charAt(0).toUpperCase() + item.semester.slice(1).toLowerCase()}`) || []);
    } catch (error) {
      console.error("Failed to fetch term:", error);
    }
  }

  useEffect(() => {
    fetchTerm()
  }, []);

  const fetchLowonganList = async () => {
    try {
      const response = await LogService.getLowonganList(
        term.year,
        term.semester.toLowerCase(),
      );
      setCourses(response.data.lowongan || [])
    } catch (error) {
      console.error("Failed to fetch lowongan:", error);
    }
  }

  useEffect(() => {
    if (term.semester === '') return
    fetchLowonganList()
  }, [term]);

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content-group1"}>
          <div className={"filter-title-container"}>
            <div className={"header-h5"}>Log Asistensi Per Mata Kuliah</div>
            <Filter3 text={"Term"} items={termOptions} onFilterChange={handleTermChange} />
          </div>
          <div className="notes-text">
            Log bulan n, hanya bisa diisi oleh asisten selama bulan n tersebut hingga tanggal 5 pada bulan n+1. <br />Persetujuan oleh dosen, paling lambat dilakukan pada tanggal 10 pada bulan n+1.
          </div>
          <div className={"divider"}></div>
          <div className={"list-of-matkul-container"}>
            {courses.map(obj => {
              return <MataKuliahDosen key={obj.id} matkulID={obj.kode_matkul} matkulID2={obj.kode_kurikulum} nama_matkul={obj.nama} tahun={`${term.year} - ${obj.semester.charAt(0).toUpperCase() + obj.semester.slice(1).toLowerCase()}`} nama_dosen={obj.dosen.join(", ")} id_matkul={obj.id} is_spesial={obj.is_spesial} />
            })}
          </div>
        </div>
      </div>
    </Fragment>
  );
};


export default AdminDosenMelihatListMatkul;
