/* eslint-disable jsx-a11y/anchor-is-valid */
import "../assets/scss/Table/Table.scss";
import "../assets/scss/_variables.scss";
import "../App.scss";
import { useState } from "react";
import Pagination from "./Pagination";
import "../assets/scss/Pagination.scss";
import Button from "./Button";
import { useNavigate } from "react-router-dom";

const TableRow = ({ rowData, index}) => {
  const Navigate = useNavigate();
  const ProfileMahasiswa = () => {
    Navigate(`/log/asdos/${rowData.username}`);
  };

  return (
    <tr>
      <td className="tw-text-center">{index + 1}</td>
      <td className="tw-text-center tw-p-2">{rowData.name}</td>
      <td className="tw-text-center tw-p-2">
        {rowData.kode_identitas}
      </td>
      <td className="tw-text-center tw-p-2">
        {rowData.jenjang_pendidikan}
      </td>
      <td className="tw-text-center tw-p-2">
        {rowData.pengalaman}
      </td>
      <td className="tw-text-center">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button
              text={"Lihat"}
              onClick={ProfileMahasiswa}
              background={"#4D8AFF"}
              color={"#FFFFFF"}
            />
      </div>
      </td>
    </tr>
  );
};

const MahasiswaTable = ({ items, mahasiswaList }) => {
  const headerItems = items[0];

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = mahasiswaList.slice(
    indexOfFirstRecord,
    indexOfLastRecord,
  );
  const nPages = Math.ceil(mahasiswaList.length / recordsPerPage);

  if (mahasiswaList && mahasiswaList.length > 0) {
    return (
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        <table className={"tw-w-full tw-table-auto"}>
          <thead>
            <tr className={"table-header-group2"}>
              {headerItems.map((item, index) => {
                let styleName;
                if (index === 0) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                } else if (index === headerItems.length - 1) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                } else {
                  styleName = "tw-px-4 tw-py-2 tw-text-white";
                }

                return (
                  <th key={index} className={styleName}>
                    <div className="">{item}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 &&
              currentRecords.map((rowData, index) => (
                <TableRow
                  key={index}
                  index={index}
                  rowData={rowData}
                />
              ))}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  } else {
    return (
      <div className={"table-container container-shadow tw-pt-6"}>
        <h2>Tidak ada mahasiswa</h2>
      </div>
    );
  }
};

export default MahasiswaTable;