import { Fragment, useEffect, useState } from "react";
import Navbar from "../../../Component/GroupOne/Navbar";
import "../../../assets/group1/scss/_variables.scss";
import "../../../assets/group1/scss/Card/DashboardCard.scss";
import DashboardCard from "../../../Component/GroupOne/Card/DashboardCard";
import LamaranService from "../../../Services/LamaranService";
import LowonganService from "../../../Services/LowonganService";

const DashboardHome = () => {
  // const { parameter } = useParams();
  const [logsCount, setLogsCount] = useState(0);
  const [tahun_ajaran, setTahunAjaran] = useState();
  const [semester, setSemester] = useState();

  const fetchTerm = async () => {
    try {
      const response = await LowonganService.getTerm();
      setTahunAjaran(response.data.data[0].tahun_ajaran);
      setSemester(response.data.data[0].semester);
    } catch (error) {
      console.error("Failed to fetch term:", error);
    }
  };

  useEffect(() => {
    fetchTerm();
  }, []);

  useEffect(() => {
    // Fetch data from the API
    fetch(`${process.env.REACT_APP_BASE_URL}/log/countDilaporkan/`)
        .then((response) => response.json())
        .then((data) => setLogsCount(data.count_dilaporkan_logs))
        .catch((error) => console.error("Error fetching data:", error));
  }, []); // The empty dependency array ensures that the effect runs only once


  const [lamaranList, setLamaranList] = useState([]);
  const statusmelamar = 'melamar';
  const statusrekomendasi = 'direkomendasikan';
  const [rekomendasiList, setRekomendasiList] = useState([]);


  const [countLamaran, setCountLamarList] = useState(0);
  const [countRekomendasi, setCountRekomendasiList] = useState(0);

  const fetchLamaranList = async () => {
      try {
        const response = await LamaranService.getAllLamaran(semester, tahun_ajaran, statusmelamar);
        setLamaranList(response.data.data);
      } catch (error) {
        console.error("Failed to fetch lamaran:", error);
      }
  };

  const fetchRekomendasiList = async () => {
    try {
      const response = await LamaranService.getAllLamaran(semester, tahun_ajaran, statusrekomendasi);
      setRekomendasiList(response.data.data);
    } catch (error) {
      console.error("Failed to fetch lamaran:", error);
    }
};


useEffect(() => {
  fetchRekomendasiList();
  fetchLamaranList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [tahun_ajaran, semester]);


useEffect(() => {
  setCountLamarList(lamaranList.length)
  setCountRekomendasiList(rekomendasiList.length)
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [lamaranList, rekomendasiList]);

 



  return (
      <Fragment>
        <div className={"view"}>
          <Navbar/>
          <div className={"content"}>
            <div className={"header-h5"}>Dashboard</div>
            <div className="cardcontainer">
              <DashboardCard
                  name="Pelamar Baru/Belum Diterima"
                  numbers={countLamaran}
                  link="/lamaran"
              />
              <DashboardCard
                  name="Pelamar yang Direkomendasikan"
                  numbers={countRekomendasi}
                  link="/lamaran"
              />
              <DashboardCard
                  name="Logs Belum Disetujui"
                  numbers={logsCount}
                  link="/admin/logsbelumsetujui"
              />
            </div>
          </div>
        </div>
      </Fragment>
  );
};

export default DashboardHome;

