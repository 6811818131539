import { Fragment, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Navbar from '../../../Component/GroupOne/Navbar';
import Button from "../../../Component/Button";
import '../../../assets/group1/scss/_variables.scss'
import { toast, ToastContainer } from "react-toastify";
import HonorService from '../../../Services/HonorService';
import InputOneRow from '../../../Component/InputOneRow';


const AddHonorRefMTI = () => {
  const navigate = useNavigate();

  const [nama, setNama] = useState();
  const [periode, setPeriode] = useState();
  const handleNamaOnChange = (e) => {
    setNama(e.target.value);
  };
  const handlePeriodeOnChange = (e) => {
    setPeriode(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (
      nama &&
      periode
    ) {
      const requestBody = {
        name: nama,
        periode: periode,
        list_honor: [
          {
            jenjang: "MTI",
            pendidikan_asdos: "Mahasiswa S1",
            pengalaman: "Junior",
            kategori: 1,
            location: "Off-site",
            amount: 0,
          }
        ]
      };
      await HonorService.createHonor(requestBody)
        .then((_) => {
          /* istanbul ignore next */
          toast.success("Honor Ref Berhasil Ditambahkan", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/honor-ref-list/");
            },
          });
        })
        .catch((_) => {
          toast.error("Honor Ref Belum Berhasil Ditambahkan", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Fragment>
      <div className={'view'}>
        <Navbar />
        <a href="/honor-ref-list" className={"back medium-m8"}>
          ← Kembali ke Daftar Honor Ref
        </a>
        <div className={'content'}>
          <form className={'body-container'} onSubmit={handleSubmit}>
            <h2 style={{textAlign: 'center'}}>Form Penambahan Honor Ref MTI</h2>
            <div className={'card-form-container container-shadow'}>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Nama</div>
                <InputOneRow
                  data-testid={"Nama"}
                  value={nama}
                  onChange={handleNamaOnChange}
                />
              </div>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Periode</div>
                <InputOneRow
                  data-testid={"Periode"}
                  value={periode}
                  onChange={handlePeriodeOnChange}
                />
              </div>
              <div
                className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
              >
                <Button
                  data-testid={"Simpan"}
                  text={"Simpan"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleSubmit}
                  isDisabled={
                    !(
                      nama &&
                      periode
                    )
                  }
                  role='button'
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AddHonorRefMTI;
