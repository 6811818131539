import '../../../assets/group1/scss/Table/Table.scss';
import '../../../assets/group1/scss/_variables.scss';
import LogsPerBulan from "../Logs/LogsPerBulan";

const TableLogPerBulan = ({ items , content, user}) => {

    const headerItems = items;


    return(
        <div className={"table-container-group2 container-shadow tw-pt-6"}>
            <table className="tw-w-full tw-table-auto">
                <thead>
                <tr className={"table-header-group2"}>
                    {headerItems.map((item, index) => {
                        let styleName;
                        if (index === 0) {
                            styleName = "tw-px-4 tw-py-4 tw-text-white tw-rounded-l-xl";
                        } else if (index === headerItems.length - 1) {
                            styleName = "tw-px-4 tw-py-4 tw-text-white tw-rounded-r-xl";
                        } else {
                            styleName = "tw-px-4 tw-py-4 tw-text-white";
                        }
        
                        return (
                            <th key={index} className={styleName}>
                                <div className="">{item}</div>
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                    <LogsPerBulan content={content}/>
                </tbody>
            </table>
        </div>
    );
}

export default TableLogPerBulan;