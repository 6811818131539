import { Fragment, useState, useMemo, useEffect } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import Button from '../../../Component/GroupOne/Buttons/Button';
import NumInput from '../../../Component/GroupOne/NumInput';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const EditHonorRef = () => {
  const { id } = useParams();
  const token = sessionStorage.getItem('jwtToken');
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append('Authorization', 'Bearer ' + token);
    return headers;
  }, [token]);

  const [honorRefData, setHonorRefData] = useState({
    pk_ref: '',
    name: '',
    periode: '',
    mahasiswa_s1: '',
    pk_mahasiswa_s1: '',
    lulus_s1_mahasiswa_s2: '',
    pk_lulus_s1_mahasiswa_s2: '',
    lulus_s2_mahasiswa_s3: '',
    pk_lulus_s2_mahasiswa_s3: '',
    ta_kontrak: '',
    pk_ta_kontrak: '',
  });

  const [kategoriPendidikan] = useState({
    mahasiswa_s1: 'Mahasiswa S1',
    lulus_s1_mahasiswa_s2: 'Lulus S1 / Mahasiswa S2',
    lulus_s2_mahasiswa_s3: 'Lulus S2 / Mahasiswa S3',
    ta_kontrak: 'TA Kontrak',
  });

  const [initialHonorRefData, setInitialHonorRefData] = useState({
    mahasiswa_s1: '',
    lulus_s1_mahasiswa_s2: '',
    lulus_s2_mahasiswa_s3: '',
    ta_kontrak: '',
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/honor/${id}/`, {
      method: 'GET',
      headers: headerData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setHonorRefData({
          name: data.name,
          periode: data.periode,
          pk_ref: data.pk,
          mahasiswa_s1: data.list_honor.find((item) => item.pendidikan_asdos === 'Mahasiswa S1').amount.toString(),
          pk_mahasiswa_s1: data.list_honor.find((item) => item.pendidikan_asdos === 'Mahasiswa S1').pk.toString(),
          lulus_s1_mahasiswa_s2: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S1 / Mahasiswa S2').amount.toString(),
          pk_lulus_s1_mahasiswa_s2: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S1 / Mahasiswa S2').pk.toString(),
          lulus_s2_mahasiswa_s3: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S2 / Mahasiswa S3').amount.toString(),
          pk_lulus_s2_mahasiswa_s3: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S2 / Mahasiswa S3').pk.toString(),
          ta_kontrak: data.list_honor.find((item) => item.pendidikan_asdos === 'TA Kontrak').amount.toString(),
          pk_ta_kontrak: data.list_honor.find((item) => item.pendidikan_asdos === 'TA Kontrak').pk.toString(),
        });
        setInitialHonorRefData({
          name: data.name,
          periode: data.periode,
          mahasiswa_s1: data.list_honor.find((item) => item.pendidikan_asdos === 'Mahasiswa S1').amount.toString(),
          lulus_s1_mahasiswa_s2: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S1 / Mahasiswa S2').amount.toString(),
          lulus_s2_mahasiswa_s3: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S2 / Mahasiswa S3').amount.toString(),
          ta_kontrak: data.list_honor.find((item) => item.pendidikan_asdos === 'TA Kontrak').amount.toString(),
        });
      })
      .catch((error) => {
        console.error('Kesalahan:', error);
      });
  }, [headerData, id]);

  const handleChange = (name, value) => {
    setHonorRefData({
      ...honorRefData,
      [name]: value,
    });
  };

  const handleSave = () => {
    if (!honorRefData.mahasiswa_s1 || !honorRefData.lulus_s1_mahasiswa_s2 || !honorRefData.lulus_s2_mahasiswa_s3 || !honorRefData.ta_kontrak) {
      alert('Data Setiap Kategori Tingkat Pendidikan Perlu Dilengkapi');
      return;
    }

    const categories = ['mahasiswa_s1', 'lulus_s1_mahasiswa_s2', 'lulus_s2_mahasiswa_s3', 'ta_kontrak'];
    const categories_pk = ['pk_mahasiswa_s1', 'pk_lulus_s1_mahasiswa_s2', 'pk_lulus_s2_mahasiswa_s3', 'pk_ta_kontrak'];
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      if (honorRefData[category] !== initialHonorRefData[category]) {
        const honorRefDataToSend = {
          jenjang: "S1-MIK",
          pendidikan_asdos: kategoriPendidikan[category],
          amount: honorRefData[category],
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/honor/${honorRefData[categories_pk[i]]}/`, {
          method: 'PUT',
          headers: headerData,
          body: JSON.stringify(honorRefDataToSend),
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            return response.json();
          })
          .then((data) => {
            toast.success('Honor Ref Berhasil Diperbarui', {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          })
          .catch((error) => {
            console.error('Kesalahan:', error);
            alert('Terjadi kesalahan saat menyimpan data');
            toast.error('Honor rate belum berhasil diubah', {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
          });

      }

    }

    const honorRefProfileDataToSend = {
      name: honorRefData.name,
      periode: honorRefData.periode,
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/honor/ref/${honorRefData.pk_ref}/`, {
      method: 'PUT',
      headers: headerData,
      body: JSON.stringify(honorRefProfileDataToSend),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        window.location.href = `/honor-ref-list/`;
        toast.success('Profil Honor ref berhasil diubah', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .catch((error) => {
        console.error('Kesalahan:', error.message);
        alert('Terjadi kesalahan saat menyimpan data');
        toast.error('Profil Honor ref belum berhasil diubah', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };


  return (
    <Fragment>
      <div className={'view'}>
        <Navbar />
        <a
          href="/honor-ref-list"
          className={'back medium-m8'}
          onClick={() => {
            window.history.back();
            return false;
          }}
        >
          ← Kembali ke Daftar Honor Ref
        </a>
        <div>
          <div className={'frame-container2 tw-mb-8'}>
            <form className={'body-container-group1'}>
              <div className={"header-h4 center"}>Form Pengubahan Honor Rate S1</div>
              <div className={'table-container-group2 container-shadow'}>
                <table className={"tw-w-full tw-table-auto"}>
                  <thead>
                    <tr>
                      <th className={"header-h6"}>Penetapan Rate Berdasarkan Tingkat Pendidikan</th>
                    </tr>
                  </thead>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Mahasiswa S1
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        label={'Keterangan'}
                        name={'mahasiswa_s1'}
                        value={honorRefData.mahasiswa_s1}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Lulus S1 / Mahasiswa S2
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'lulus_s1_mahasiswa_s2'}
                        value={honorRefData.lulus_s1_mahasiswa_s2}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        placeholder="Masukkan Keterangan"
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Lulus S2 / Mahasiswa S3
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'lulus_s2_mahasiswa_s3'}
                        value={honorRefData.lulus_s2_mahasiswa_s3}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        placeholder="Masukkan Keterangan"
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      TA Kontrak
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'ta_kontrak'}
                        value={honorRefData.ta_kontrak}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        placeholder="Masukkan Keterangan"
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={"header-h6 "} style={{ fontWeight: 'bold' }}>Profil Honor Rate</td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', width: '400px', height: '50px', }}>
                    <td style={{ width: '75px' }}>
                      <div className={"medium-m8"}>
                        Nama
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className={"login-input"}
                        name={'name'}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        value={honorRefData.name}
                      />
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', width: '400px', height: '50px' }}>
                    <td style={{ width: '75px' }}>
                      <div className={"medium-m8"}>
                        Periode
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className={"login-input"}
                        name={'periode'}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        value={honorRefData.periode}
                      />
                    </td>
                  </tr>
                </table>
                <div
                  className="button-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingLeft: 0,
                    paddingTop: 0,
                  }}>
                  <Button
                    text={"Simpan"}
                    onClick={handleSave}
                    background={"#4D8AFF"}
                    color={"#FFFFFF"}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default EditHonorRef;