import { Fragment, useState, useMemo, useEffect } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import NumInput from '../../../Component/GroupOne/NumInput';
import { useParams } from 'react-router-dom';

const EditHonorRef = () => {
  const { id } = useParams();
  const token = sessionStorage.getItem('jwtToken');
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append('Authorization', 'Bearer ' + token);
    return headers;
  }, [token]);

  const [honorRefData, setHonorRefData] = useState({
    pk_ref: '',
    name: '',
    periode: '',
    mahasiswa_s1: '',
    pk_mahasiswa_s1: '',
    lulus_s1_mahasiswa_s2: '',
    pk_lulus_s1_mahasiswa_s2: '',
    lulus_s2_mahasiswa_s3: '',
    pk_lulus_s2_mahasiswa_s3: '',
    ta_kontrak: '',
    pk_ta_kontrak: '',
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/honor/${id}/`, {
      method: 'GET',
      headers: headerData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setHonorRefData({
          name: data.name,
          periode: data.periode,
          pk_ref: data.pk,
          mahasiswa_s1: data.list_honor.find((item) => item.pendidikan_asdos === 'Mahasiswa S1').amount.toString(),
          pk_mahasiswa_s1: data.list_honor.find((item) => item.pendidikan_asdos === 'Mahasiswa S1').pk.toString(),
          lulus_s1_mahasiswa_s2: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S1 / Mahasiswa S2').amount.toString(),
          pk_lulus_s1_mahasiswa_s2: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S1 / Mahasiswa S2').pk.toString(),
          lulus_s2_mahasiswa_s3: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S2 / Mahasiswa S3').amount.toString(),
          pk_lulus_s2_mahasiswa_s3: data.list_honor.find((item) => item.pendidikan_asdos === 'Lulus S2 / Mahasiswa S3').pk.toString(),
          ta_kontrak: data.list_honor.find((item) => item.pendidikan_asdos === 'TA Kontrak').amount.toString(),
          pk_ta_kontrak: data.list_honor.find((item) => item.pendidikan_asdos === 'TA Kontrak').pk.toString(),
        });
      })
      .catch((error) => {
        console.error('Kesalahan:', error);
      });
  }, [headerData, id]);

  return (
    <Fragment>
      <div className={'view'}>
        <Navbar />
        <a
          href="/honor-ref-list"
          className={'back medium-m8'}
          onClick={() => {
            window.history.back();
            return false;
          }}
        >
          ← Kembali ke Daftar Honor Ref
        </a>
        <div>
          <div className={'frame-container2 tw-mb-8'}>
            <form className={'body-container-group1'}>
              <div className={"header-h4 center"}>Detail Referensi Honor</div>
              <div className={'table-container-group2 container-shadow'}>
                <table className={"tw-w-full tw-table-auto"}>
                  <thead>
                    <tr>
                      <th className={"header-h6"}>Penetapan Rate Berdasarkan Tingkat Pendidikan</th>
                    </tr>
                  </thead>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Mahasiswa S1
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'mahasiswa_s1'}
                        value={honorRefData.mahasiswa_s1}
                        disabled={true}
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Lulus S1 / Mahasiswa S2
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'lulus_s1_mahasiswa_s2'}
                        value={honorRefData.lulus_s1_mahasiswa_s2}
                        disabled={true}
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Lulus S2 / Mahasiswa S3
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'lulus_s2_mahasiswa_s3'}
                        value={honorRefData.lulus_s2_mahasiswa_s3}
                        disabled={true}
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      TA Kontrak
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'ta_kontrak'}
                        value={honorRefData.ta_kontrak}
                        disabled={true}
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={"header-h6 "} style={{ fontWeight: 'bold' }}>Profil Honor Rate</td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', width: '400px', height: '50px' }}>
                    <td style={{ width: '75px' }}>
                      <div className={"medium-m8"}>
                        Nama
                      </div>
                    </td>

                    <td>
                      <input
                        type="text"
                        className={"login-input"}
                        name={'name'}
                        disabled={true}
                        value={honorRefData.name}
                      />
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', width: '400px', height: '50px' }}>
                    <td style={{ width: '75px' }}>
                      <div className={"medium-m8"}>
                        Periode
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className={"login-input"}
                        name={'periode'}
                        disabled={true}
                        value={honorRefData.periode}
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment >
  );
};

export default EditHonorRef;