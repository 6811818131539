import "../assets/scss/InputBox.scss";

const NumInput = (props) => {
  const min = props.min || 0;
  // const max = props.max || 1000;
  const isDisabled = props.isDisabled ? props.isDisabled : false;

  return (
    <input
      type={"number"}
      className={"inputbox-container-group2"}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      min={min}
      max={props.max ? props.max : null}
      disabled={isDisabled}
    />
  );
};

export default NumInput;
