import { appServerAxios } from "./http-common";

class AuthService {
  login(username, password) {
    return appServerAxios.post("/auth/login/", { username, password });
  }

  getMe() {
    return appServerAxios.get("/auth/me/");
  }

  getAllMahasiswa() {
    return appServerAxios.get("/auth/data/names");
  }

  getProfileDetails() {
    return appServerAxios.get("/auth/profile/");
  }

  upadteProfile(body) {
    return appServerAxios.put("/auth/profile/", body);
  }

  upadtePengalaman(body) {
    return appServerAxios.put("/auth/change-category/", body);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
