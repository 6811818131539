import Filter from "../../../Component/GroupOne/Filter";
import Button from "../../../Component/GroupOne/Buttons/Button";
import { Fragment, useState, useMemo, useEffect } from "react";
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import Table from "../../../Component/GroupOne/Table";
import Navbar from "../../../Component/GroupOne/Navbar";
import mark from "../../../assets/group1/icons/mark.svg";
import { eachYearOfInterval, format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import ClaimPembayaranModal from "../../../Component/GroupOne/ClaimPembayaranModal";

const ClaimHonor = () => {
    const [selectedTahun, setSelectedTahun] = useState('');
    const [selectedBulan, setSelectedBulan] = useState('');
    const [selectedBank, setSelectedBank] = useState('');
    const [selectedJenjang, setSelectedJenjang] = useState('');
    const [bulanSelected, setBulanSelected] = useState('');
    const [tahunSelected, setTahunSelected] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalProps, setModalProps] = useState({ bulan: '', tahun: '', log: [] });
    const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();
    const [content, setContent] = useState('')
    const token = sessionStorage.getItem("jwtToken");
    const name = sessionStorage.getItem("name");

    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);


    const handleTahunChange = (tahun) => {
        setSelectedTahun(tahun);
    };

    const handleBulanChange = (bulan) => {
        setSelectedBulan(bulan);
    };

    const handleBankChange = (bank) => {
        setSelectedBank(bank);
    };

    const handleJenjangChange = (jenjang) => {
        setSelectedJenjang(jenjang);
    };

    const handleButtonClick = () => {
        const queryParams = new URLSearchParams();

        setBulanSelected(selectedBulan);
        setTahunSelected(selectedTahun);
        if (selectedTahun && selectedTahun !== "Tahun") {
            queryParams.set('tahun', selectedTahun);
        } else {
            return
        }

        if (selectedBulan && selectedBulan !== "Bulan") {
            queryParams.set('bulan', selectedBulan);
        } else {
            return
        }

        if (selectedBank && selectedBank !== "Bank") {
            queryParams.set('bank', selectedBank);
        } else {
            return
        }

        if (selectedJenjang && selectedJenjang !== "Jenjang") {
            queryParams.set('jenjang', selectedJenjang);
        } else {
            return
        }

        const queryString = queryParams.toString();

        fetch(`${process.env.REACT_APP_BASE_URL}/honor/log/belum-diklaim/?${queryString}`, {
            method: 'GET',
            headers: headerData
        })
            .then(response => response.json())
            .then(data => setContent(data.sort((a, b) => {
                // Sort based on name alphabetical order (case-insensitive)
                const nameComparison = a.mahasiswa.localeCompare(b.mahasiswa, undefined, { sensitivity: 'base' });
                if (nameComparison !== 0) {
                    return nameComparison;
                }

                // If same person, sort based on date (year dan month)
                const dateA = new Date(a.tahun, a.bulan - 1)
                const dateB = new Date(b.tahun, b.bulan - 1)
                return dateB - dateA
            })))
            .catch(error => console.error('Error fetching data:', error));
    };

    useEffect(() => {
    }, [])

    const handleButtonPembayaranClick = () => {
        if (!content || content.length === 0) {
            console.error("Tidak ada log untuk diproses");
            return;
        }
        setModalProps({
            bulan: selectedBulan,
            tahun: selectedTahun,
            log: content
        });
        setIsModalOpen(true);
    };

    const handleModalConfirm = () => {
        const promises = content.map(logGroup => 
            Promise.all(
                logGroup.logs_id.map(logId => 
                    fetch(`${process.env.REACT_APP_BASE_URL}/log/proses/${logId}/`, {
                        method: 'PUT',
                        headers: headerData,
                    })
                    .then(response => {
                        if (!response.ok) {
                            console.error(`Klaim pembayaran gagal untuk log ID ${logId}`);
                            return false;
                        }
                        return true;
                    })
                    .catch(error => {
                        console.error(`Error untuk log ID ${logId}:`, error);
                        return false;
                    })
                )
            ).then(results => {
                return results.every(result => result);
            })
        );

        Promise.all(promises).then(results => {
            const updatedContent = content.filter((log, index) => !results[index]);
            setContent(updatedContent);

            if (updatedContent.length === 0) {
                toast.success("Semua log berhasil diproses.", {
                    position: "top-center",
                    autoClose: 3000,
                    theme: "light",
                });
            } else {
                toast.error("Beberapa log tidak berhasil diproses.", {
                    position: "top-center",
                    autoClose: 3000,
                    theme: "light",
                });
            }
        });

        setIsModalOpen(false); // Tutup modal setelah konfirmasi
    };

    const handleModalCancel = () => setIsModalOpen(false); // Tutup modal jika dibatalkan

    return (
        <Fragment>
            <div className={"view"}>
                <Navbar name={name}/>
                <div className={"content-group1"}>
                    <div className={"header-h5"}>Klaim Pembayaran Honor Log Asisten</div>
                    <div className={"divider"}></div>
                    <div className={"medium-m7"}>Daftar Log Belum Diklaim</div>
                    <div className={"filters-container"}>
                        <Filter
                            text={"Tahun"}
                            items={["Tahun", ...years]}
                            onFilterChange={handleTahunChange}/>
                        <Filter
                            text={"Bulann"}
                            items={["Bulan", "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]}
                            onFilterChange={handleBulanChange}/>
                        {/* Change list of banks using bank list from backend */}
                        <Filter
                            text={"Bank"}
                            items={["Bank", "BNI", "Non-BNI"]}
                            onFilterChange={handleBankChange}/>
                        <Filter
                            text={"Bank"}
                            items={["Jenjang", "S1-MIK", "MTI"]}
                            onFilterChange={handleJenjangChange}/>
                        <Button text={"Lihat Log"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleButtonClick}/>
                        <Button text={"Lakukan Pembayaran"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleButtonPembayaranClick} />
                    </div>
                    {content && content.length > 0 ? (
                        <>
                            <Table
                                items={['No', 'Matakuliah', 'Asisten', 'Jumlah Jam', 'Honor Kotor', 'PPH 21', 'Honor Bersih', 'Bulan', 'Bank', 'Atas Nama', 'No. Rek']}
                                user={'admin'}
                                content={content}
                            />
                        </>
                    ) : (
                        (bulanSelected && bulanSelected !== "Bulan") || (tahunSelected && tahunSelected !== "Tahun") ? (
                            <>
                                <div className={"no-content"}>
                                    <img
                                        src={mark}
                                        className={"pointer enlarged"}
                                        alt="fail"
                                    />
                                    {`Tidak ada log asisten atau status log asisten sudah dibayar pada:  ${bulanSelected && bulanSelected !== "Bulan" ? bulanSelected : ""} ${tahunSelected && tahunSelected !== "Tahun" ? tahunSelected : ""}`}
                                </div>
                            </>
                        ) : <></>
                    )
                    }
                </div>
            </div>
            {isModalOpen && (
                <ClaimPembayaranModal 
                    isOpen={isModalOpen} 
                    onConfirm={handleModalConfirm} 
                    onCancel={handleModalCancel} 
                    bulan={modalProps.bulan} 
                    tahun={modalProps.tahun} 
                    log={modalProps.log} 
                />
            )}
            <ToastContainer />
        </Fragment>
    );
};

export default ClaimHonor;
