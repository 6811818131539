import '../../../assets/group1/scss/Buttons/Button.scss';

const Button = (props) => {
    return (
        <a href={props.href} onClick={props.onClick}>
            <div style={{ background: props.background, width: props.width, margin: props.margin || 'auto' }} className={"button-container button-style"}>
                <div style={{ color: props.color }} className={"button-text medium-m8"}>
                    {props.text}
                </div>
            </div>
        </a>
    );
}

export default Button;