import Filter from "../../../Component/GroupOne/Filter";
import Button from "../../../Component/GroupOne/Buttons/Button";
import { Fragment, useState, useMemo } from "react";
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import Navbar from "../../../Component/GroupOne/Navbar";
import { format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";

const LaporanPembayaranHonorAsistenPerMatakuliahPerSemester = () => {
  const [selectedSemester, setSelectedSemester] = useState('');
  const [selectedTahun, setSelectedTahun] = useState('');
  const [selectedBank, setSelectedBank] = useState('');
  const [selectedJenjang, setSelectedJenjang] = useState('');
  const token = sessionStorage.getItem("jwtToken");
  const name = sessionStorage.getItem("name");
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);
  const yearList = [];
  for (let year = format(new Date(), 'yyyy'); year >= 2003; year--) {
    const nextYear = parseInt(year) + 1;
    const yearRange = `${year}/${nextYear}`;
    yearList.push(yearRange);
  }


  const handleSemesterChange = (semester) => {
    setSelectedSemester(semester);
  };

  const handleTahunChange = (tahun) => {
    setSelectedTahun(tahun);
  };

  const handleBankChange = (bank) => {
    setSelectedBank(bank);
  };

  const handleJenjangChange = (jenjang) => {
    setSelectedJenjang(jenjang);
  };

  const queryParams = new URLSearchParams({
    tahun: selectedTahun,
    semester: selectedSemester,
    bank: selectedBank,
    jenjang: selectedJenjang
});

  const handleButtonClick = async () => {
    if (!selectedSemester || !selectedTahun || !selectedBank || !selectedJenjang) {
      toast.error("Tahun, bulan, bank, dan jenjang harus dipilih terlebih dahulu", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }

    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/honor/laporan-pembayaran-honor-asisten-per-matakuliah-per-semester/?${queryParams.toString()}`, {
      method: 'GET',
      headers: headerData
    })

    if (!response.ok) {
      await response.json()
      toast.error("Tidak ada data yang ditemukan!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${selectedBank}_Honor_Asisten_PerSemester_${selectedJenjang}_${selectedSemester}-${selectedTahun}.xlsx`); 
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar name={name} />
        <div className={"content-group1"}>
          <div className={"header-h5"}>Laporan Pembayaran Honor Asisten Per Matakuliah Per Semester</div>
          <div className={"divider"}></div>
          <div className={"filters-container-perbulan"}>
            <Filter
              text={"Semester"}
              items={["Ganjil", "Genap", "Pendek"]}
              placeholder={"Semester"}
              onFilterChange={handleSemesterChange} />
            <Filter
              text={"Tahun"}
              items={yearList}
              placeholder={"Tahun"}
              onFilterChange={handleTahunChange} />
            <Filter
              text={"Bank"}
              items={["BNI", "Non-BNI"]}
              placeholder={"Bank"}
              onFilterChange={handleBankChange} />
            <Filter
              text={"Jenjang"}
              items={["S1-MIK", "MTI"]}
              placeholder={"Jenjang"}
              onFilterChange={handleJenjangChange} />
            <Button text={"Download"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleButtonClick} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default LaporanPembayaranHonorAsistenPerMatakuliahPerSemester;

