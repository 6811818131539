import '../../../assets/group1/scss/Table/Table.scss';
import '../../../assets/group1/scss/_variables.scss';
import {useParams} from "react-router-dom";
import {useEffect, useState, useMemo} from "react";

const LogsPerBulan = (props) => {
    const { matkul_id } = useParams();
    const currentPathname = window.location.pathname;
    const [content, setContent] = useState([]);
    const token = sessionStorage.getItem("jwtToken");
    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);

    useEffect(() => {
        setContent(props.content)
    }, [currentPathname, matkul_id, props.content, headerData]);

    return (
        content ? (
                content.sort((a, b) => {
                    const dateA = new Date(a.datetime);
                    const dateB = new Date(b.datetime);
                    return dateB - dateA;
                }).map((item, index) => (
                    <tr key={index}>
                        <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                            <div>
                                {new Date(item["datetime"]).toLocaleDateString('en-GB', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}
                            </div>
                        </td>
                        <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                            {Math.floor(item["duration"] / 60)} hour(s) {(item["duration"] % 60) === 0 ? "" : `${item["duration"] % 60} minute(s)`}
                        </td>
                        <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{width: '40%'}}>
                            {item["description"]}
                        </td>
                        <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                            {item["status"]}
                        </td>
                    </tr>
                ))
            ) : (
            <div>
                Empty
            </div>
        )
    )
}

export default LogsPerBulan;