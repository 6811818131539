/* eslint-disable jsx-a11y/anchor-is-valid */
import "../assets/scss/Table/Table.scss";
import edit from "../assets/icons/edit.svg";
import trash from "../assets/icons/trash.svg";
import pelamar from "../assets/icons/pelamar.svg";
import "../assets/scss/_variables.scss";
import "../App.scss";
import LowonganService from "../Services/LowonganService";
import { toast } from "react-toastify";
import { useState } from "react";
import Pagination from "./Pagination";
import "../assets/scss/Pagination.scss";
import Modal from "./PopUpModal";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import StatusButton from "./StatusButton";

const TableRow = ({ rowData, index, isDelete, setIsDelete }) => {
  const navigate = useNavigate();
  const editurl = `/lowongan/${rowData.id}`;
  const editspecialurl = `/lowonganspesial/${rowData.id}`;
  const pelamarurl = `/lowongan/${rowData.id}/pelamar`;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const formattedNames = Array.isArray(rowData.lowongan_pembuka)
    ? rowData.lowongan_pembuka.join(", ")
    : rowData.lowongan_pembuka;

  const deleteData = () => {
    setShowConfirmModal(true);
  };

  const handleUpdateClick = async (e) => {
    if (rowData.status === "tutup") {
      try {
        const response = await LowonganService.getLowonganById(rowData.id);
        const body = response.data.data;
        body.status = "buka";
        LowonganService.updateLowongan(rowData.id, body);
        window.location.reload();
      } catch (error) {
        console.error("Failed to update status");
      }
    } else {
      try {
        const response = await LowonganService.getLowonganById(rowData.id);
        const body = response.data.data;
        body.status = "tutup";
        LowonganService.updateLowongan(rowData.id, body);
        window.location.reload();
      } catch (error) {
        console.error("Failed to update status");
      }
    }
  };

  const handleDeleteClick = async (e) => {
    try {
      if (rowData.is_spesial !== true) {
        const response = await LowonganService.deleteLowonganList(rowData.id);

        if (response.status === 200) {
          setIsDelete(!isDelete);
          toast.success("Lowongan berhasil didelete", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/");
            },
          });
        } else {
          console.error("Failed to delete Lowongan. Status:", response.status);
        }
      } else {
        const response = await LowonganService.deleteLowonganSpecialList(
          rowData.id
        );

        if (response.status === 200) {
          setIsDelete(!isDelete);
          toast.success("Lowongan spesial berhasil didelete", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/");
            },
          });
        } else {
          console.error(
            "Failed to delete Lowongan Spesial. Status:",
            response.status
          );
        }
      }
    } catch (error) {
      console.error("Failed to delete Lowongan:", error);
    }
  };

  return (
    <tr>
      <td className="tw-text-center">{index + 1}</td>
      <td className="tw-text-center tw-p-2">{rowData.nama_lowongan}</td>
      <td className="tw-text-center tw-p-2">{formattedNames}</td>
      <td className="tw-text-center tw-capitalize">
        {rowData.semester} <br></br>
        {rowData.tahun_ajaran}
      </td>
      <td className="tw-text-center">{rowData.jumlah_asisten}</td>
      <td className="tw-text-center">{rowData.jumlah_pelamar}</td>
      <td className="tw-text-center">{rowData.jumlah_pelamar_diterima}</td>
      <td className="tw-text-center">
        {
          parseInt(rowData.jumlah_pelamar_diterima) <= parseInt(rowData.jumlah_asisten) 
            ? parseInt(rowData.jumlah_asisten) - parseInt(rowData.jumlah_pelamar_diterima) 
            : 0
        }
      </td>
      <td className="flex items-center justify-center p-2">
        <div className="flex items-center space-x-2">
          <StatusButton
            text={rowData.status === "buka" ? "Buka" : "Tutup"}
            onClick={handleUpdateClick}
            background={rowData.status === "buka" ? "#00CC00" : "#FF0000"}
            color="#FFFFFF"
            margin="8px 8px"
          />
        </div>
      </td>

      <td className="tw-text-center">
        <div className="tw-flex tw-gap-2 tw-justify-center">
          {rowData.is_spesial && (
            <a href={editspecialurl} className="tw-flex">
              <Tooltip title="Edit" placement="top" arrow>
                <img
                  className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center"
                  src={edit}
                  alt={"edit"}
                />
              </Tooltip>
            </a>
          )}
          {!rowData.is_spesial && (
            <a href={editurl} className="tw-flex">
              <Tooltip title="Edit" placement="top" arrow>
                <img
                  className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center"
                  src={edit}
                  alt={"edit"}
                />
              </Tooltip>
            </a>
          )}
          <a className="tw-flex" onClick={deleteData}>
            <Tooltip title="Delete" placement="top" arrow>
              <img
                className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center tw-cursor-pointer"
                src={trash}
                alt={"delete"}
              />
            </Tooltip>
          </a>
          <a href={pelamarurl} className="tw-flex">
            <Tooltip title="Pelamar" placement="top" arrow>
              <img
                className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center tw-cursor-pointer"
                src={pelamar}
                alt={"pelamar"}
              />
            </Tooltip>
          </a>
        </div>
      </td>
      {showConfirmModal && (
        <Modal
          onConfirm={handleDeleteClick}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </tr>
  );
};

const LowonganTableAdmin = ({ items, lowonganList, isDelete, setIsDelete }) => {
  const headerItems = items[0];

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = lowonganList.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(lowonganList.length / recordsPerPage);

  if (lowonganList && lowonganList.length > 0) {
    return (
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        <table className={"tw-w-full tw-table-auto"}>
          <thead>
            <tr className={"table-header-group2"}>
              {headerItems.map((item, index) => {
                let styleName;
                if (index === 0) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                } else if (index === headerItems.length - 1) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                } else {
                  styleName = "tw-px-4 tw-py-2 tw-text-white";
                }

                return (
                  <th key={index} className={styleName}>
                    <div className="">{item}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 &&
              currentRecords.map((rowData, index) => (
                <TableRow
                  key={index}
                  index={index}
                  rowData={rowData}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                />
              ))}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  } else {
    return (
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        <h2>Tidak ada lowongan yang tersedia</h2>
      </div>
    );
  }
};

export default LowonganTableAdmin;
