import Navbar from "../../../Component/GroupOne/Navbar";
/* eslint-disable-next-line no-unused-vars */
import { Fragment, useState, useEffect, useMemo } from 'react';
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import MataKuliah from "../../../Component/GroupOne/MataKuliah";

const AsdosMelihatListMatkul = () => {
    const [courses, setCourses] = useState([]);
    const token = sessionStorage.getItem("jwtToken");
    const username = sessionStorage.getItem("username");
    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);

    useEffect(() => {
        // Fungsi ini akan dijalankan saat komponen pertama kali dimuat
        fetch(`${process.env.REACT_APP_BASE_URL}/log/lowongan`, {
            method: 'GET',
            headers: headerData
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                const courselist = data.lowongan;
                setCourses(courselist.sort((a, b) => {
                    return b.id-a.id
                }) || []);
            })
            .catch((error) => {
                console.error('Kesalahan:', error);
            });
    }, [headerData]);

    return (
        <Fragment>
            <div className={"view"}>
                <Navbar name={username} />
                <div className={"content-group1"}>
                    <div className={"header-h5"}>Log Asistensi Per Mata Kuliah</div>
                    <div className="notes-text">
                        Log bulan n, hanya bisa diisi oleh asisten selama bulan n tersebut hingga tanggal 5 pada bulan n+1. <br />Persetujuan oleh dosen, paling lambat dilakukan pada tanggal 10 pada bulan n+1.
                    </div>
                    <div className={"divider"}></div>
                    <div className={"list-of-matkul-container"}>
                        {courses.map((obj, index) => {
                            return (
                                <MataKuliah
                                    key={obj.id || index} 
                                    matkulID={obj.kode_matkul}
                                    matkulID2={obj.kode_kurikulum}
                                    nama={obj.nama}
                                    namaDosen={obj.dosen.join(", ")}
                                    tahun={`${obj.tahun_ajaran} - ${obj.semester.charAt(0).toUpperCase() + obj.semester.slice(1).toLowerCase()}`}
                                    id_matkul={obj.id}
                                    is_spesial={obj.is_spesial}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AsdosMelihatListMatkul


