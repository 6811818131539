import { useState } from "react";
import Pagination from "./Pagination";
import Button from "./Button";
import Modal from "react-modal";
import trash from "../assets/icons/trash.svg";
import edit from "../assets/icons/edit.svg";
import search from "../assets/icons/search.svg";
import Tooltip from "@mui/material/Tooltip";
import { default as PopUpModal } from "./PopUpModal";
import { useNavigate } from "react-router-dom";
import SanksiService from "../Services/SanksiService";
import { toast } from "react-toastify";

const SanksiTable = ({ sanksiList, isDelete, setIsDelete, currentPage, setCurrentPage }) => {
  const [recordsPerPage] = useState(10);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSanksi, setSelectedSanksi] = useState(null);
  const [selectedPelanggaran, setSelectedPelanggaran] = useState(null);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = sanksiList.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(sanksiList.length / recordsPerPage);

  const headerItems = ["Nama", "NPM", "Tanggal Berlaku", "Tanggal Berakhir", "Jenis Surat", "Aksi"];

  const navigate = useNavigate();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pelanggaranId, setPelanggaranId] = useState(-1);
  const deleteData = (id) => {
    setShowConfirmModal(true);
    setPelanggaranId(id);
  };
  const handleDeleteClick = async (e) => {
    try {
      const response = await SanksiService.deleteSanksi(pelanggaranId);

      if (response.status === 200) {
        setIsDelete(!isDelete);
        toast.success("Sanksi berhasil dihapus", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            navigate("/sanksi");
          },
        });
      }
    } catch (error) {
      console.error("Failed to delete Sanksi:", error);
    }
  };

  const handleDetailClick = (sanksi, pelanggaran) => {
    setSelectedSanksi(sanksi);
    setSelectedPelanggaran(pelanggaran);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedSanksi(null);
    setSelectedPelanggaran(null);
  };

  return (
    <div>
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        <table className={"tw-w-full tw-table-auto"}>
          <thead>
            <tr className={"table-header-group2"}>
              {headerItems.map((item, index) => {
                let styleName;
                if (index === 0) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                } else if (index === headerItems.length - 1) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                } else {
                  styleName = "tw-px-4 tw-py-2 tw-text-white";
                }

                return (
                  <th key={index} className={styleName}>
                    <div className="">{item}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 ? (
              currentRecords.map((sanksi, index) => (
                sanksi.related_pelanggaran.map((pelanggaran, pelanggaranIndex) => (
                  <tr key={`${index}-${pelanggaranIndex}`}>
                    <td className="tw-text-center tw-p-2">{sanksi.nama}</td>
                    <td className="tw-text-center tw-p-2">{sanksi.npm}</td>
                    <td className="tw-text-center tw-p-2">{pelanggaran.start_date}</td>
                    <td className="tw-text-center tw-p-2">{pelanggaran.end_date}</td>
                    <td className="tw-text-center tw-p-2">{pelanggaran.jenis_surat.split(".").pop()}</td>
                    <td className="tw-text-center tw-p-2">
                      <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                        <a className="tw-flex">
                          <Tooltip title="Lihat Detail" placement="top" arrow onClick={() => handleDetailClick(sanksi, pelanggaran)}>
                            <img
                              className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center tw-cursor-pointer"
                              src={search}
                              alt={"detail"}
                            />
                          </Tooltip>
                        </a>
                        <a className="tw-flex" href={`/sanksi/${sanksi.id}/${pelanggaran.id}`}>
                          <Tooltip title="Ubah" placement="top" arrow>
                            <img
                              className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center tw-cursor-pointer"
                              src={edit}
                              alt={"ubah"}
                            />
                          </Tooltip>
                        </a>
                        <a className="tw-flex" onClick={() => deleteData(pelanggaran.id)}>
                          <Tooltip title="Hapus" placement="top" arrow>
                            <img
                              className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center tw-cursor-pointer"
                              src={trash}
                              alt={"hapus"}
                            />
                          </Tooltip>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))
              ))
            ) : (
              <tr>
                <td colSpan={headerItems.length} className="tw-text-center tw-p-2">
                  Belum ada data sanksi
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { width: '30%', height: '40%', margin: 'auto' } }}>
        <h2>Detail Sanksi</h2>
        {selectedSanksi && selectedPelanggaran && (
          <div>
            <p>No Surat: {selectedPelanggaran.no_surat}</p>
            <p>Pelanggaran: {selectedPelanggaran.bentuk_pelanggaran}</p>
          </div>
        )}
        <Button text={"Close"} onClick={closeModal} color={"#FFFFFF"} size={"small-button"} />
      </Modal>
      {showConfirmModal && (
        <PopUpModal
          onConfirm={handleDeleteClick}
          onCancel={() => {
            setShowConfirmModal(false)
            setPelanggaranId(-1)
          }}
        />
      )}
    </div>
  );
};

export default SanksiTable;

