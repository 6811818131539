/* eslint-disable jsx-a11y/anchor-is-valid */
import "../../../assets/scss/Table/Table.scss";
import "../../../assets/scss/_variables.scss";
import "../../../App.scss";
import "../../../assets/scss/Pagination.scss";
import Button from "../../Button";
import { toast } from "react-toastify";
import HonorService from "../../../Services/HonorService";

const TableRow = ({ rowData, index }) => {
  const monthNames = [
    'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
    'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
  ];

  const handleDownload = async (jenis, filename) => {
    HonorService.getRekapFile(jenis, rowData.tahun, rowData.bulan, rowData.bank, rowData.jenjang).then((response) => {
      const href = URL.createObjectURL(response.data);


      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      toast.success('File berhasil diunduh', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }).catch((error) => {
      console.error("Failed to download data:", error);

      toast.error("Error ketika mengunduh data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  }

  const handleDownloadRekapHonor = () => {
    handleDownload("Honor", `${rowData.bank}_Honor_Asisten_${rowData.jenjang}_${rowData.bulan}-${rowData.tahun}.xlsx`)
  }

  const handleDownloadRekapInfo = () => {
    handleDownload("Info Bank", `${rowData.bank}_Info_Bank_${rowData.jenjang}_${rowData.bulan}-${rowData.tahun}.xlsx`)
  }


  return (
    <tr>
      <td className="tw-text-center">{index + 1}</td>
      <td className="tw-text-center tw-p-4">
        {monthNames[rowData.bulan - 1]}, {rowData.tahun}
      </td>
      <td className="tw-text-center tw-p-4">
        {rowData.bank}
      </td>
      <td className="tw-text-center tw-p-4">{rowData.tanggal_rekap}</td>
      <td className="tw-text-center tw-p-4">{rowData.total_mahasiswa}</td>
      <td className="tw-text-center tw-p-4">{rowData.total_per_bank.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}</td>
      <td className="tw-text-center">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            text={"Lihat Honor"}
            onClick={handleDownloadRekapHonor}
            background={"#4D8AFF"}
            color={"#FFFFFF"}
          />
        </div>
      </td><td className="tw-text-center">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            text={"Lihat Info Bank"}
            onClick={handleDownloadRekapInfo}
            background={"#4D8AFF"}
            color={"#FFFFFF"}
          />
        </div>
      </td>
    </tr>
  );
};

const HistoriPembayaranTable = ({ items, historiList, total }) => {
  if (historiList && historiList.length > 0) {
    return (
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        <table className={"tw-w-full tw-table-auto"}>
          <thead>
            <tr className={"table-header-group2"}>
              {items.map((item, index) => {
                let styleName;
                if (index === 0) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                } else if (index === items.length - 1) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                } else {
                  styleName = "tw-px-4 tw-py-2 tw-text-white";
                }

                return (
                  <th key={index} className={styleName}>
                    <div className="">{item}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {historiList.length > 0 &&
              historiList.map((rowData, index) => (
                <TableRow
                  key={index}
                  index={index}
                  rowData={rowData}
                />
              ))}
          </tbody>
          <tr key="total-row">
            <td className={"tw-text-center tw-p-4"} style={{ fontWeight: "bold" }} colSpan="5">
              Total:
            </td>
            <td className={"tw-text-center tw-p-4"}>
              {total.nilai.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
            </td>
            <td className={"tw-text-center tw-p-4"}>
            </td>
            <td className={"tw-text-center tw-p-4"}>
            </td>
          </tr>
          <tr key="total-row">
            <td className={"tw-text-center tw-p-4"} style={{ fontWeight: "bold" }} colSpan="6">
              {`Terbilang: ${total.terbilang}`}
            </td>
            <td className={"tw-text-center tw-p-4"}>
            </td>
            <td className={"tw-text-center tw-p-4"}>
            </td>
          </tr>
        </table>
      </div>
    );
  } else {
    return (
      <div className={"table-container container-shadow tw-pt-6"}>
        <h2>Tidak ada histori pembayaran yang tersedia</h2>
      </div>
    );
  }
};

export default HistoriPembayaranTable;