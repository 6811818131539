import { Link } from 'react-router-dom';
import "../../../assets/group1/scss/Table/TableHonorRef.scss";
import checkIcon from "../../../assets/group1/icons/check.svg";
import trashIcon from "../../../assets/group1/icons/trash.svg";
import editIcon from "../../../assets/group1/icons/pencil.svg";
import Button from '../Buttons/Button';
import Tooltip from "@mui/material/Tooltip";

const TableHonorRate = ({ honorRefs, handleHapus, handleStatusChange }) => {
  return (
    <div className={"table-container-group2 container-shadow tw-pt-6"}>
      <table className={"tw-w-full tw-table-auto"}>
        <thead>
          <tr className={"table-header-group2"}>
            <th className="tw-px-4 tw-py-4 tw-text-white tw-rounded-l-xl">Nama</th>
            <th className="tw-px-4 tw-py-4 tw-text-white ">Periode</th>
            <th className="tw-px-4 tw-py-4 tw-text-white ">Status</th>
            <th className="tw-px-4 tw-py-4 tw-text-white ">Detail</th>
            <th className="tw-px-4 tw-py-4 tw-text-white tw-rounded-r-xl">Operasi</th>
          </tr>
        </thead>
        <tbody>
          {honorRefs.map((honorRef) => (
            <tr key={honorRef.id}>
              <td className="tw-text-center tw-p-2">{honorRef.name}</td>
              <td className="tw-text-center tw-p-2">{honorRef.periode}</td>
              <td className="tw-text-center tw-p-2">{honorRef.is_active ? "Active" : "Inactive"}</td>
              <td className="tw-text-center tw-p-2">
                {honorRef.detail}
                {honorRef.list_honor[0].jenjang === "S1-MIK" ? (
                  <Button text={"Detail"} href={`/detail-honor-ref/${honorRef.pk}`} color={"#FFFFFF"} size={"small-button"} />
                ) : (
                  <Button text={"Detail"} href={`/honor-rate-list/${honorRef.pk}`} color={"#FFFFFF"} size={"small-button"} />
                )}
              </td>
              <td className="tw-text-center tw-p-2">
                <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                  <div className={'pointer'} style={{ alignContent: 'center' }}>
                    <Tooltip title="Update" placement="top" arrow>
                      <img
                        src={checkIcon}
                        alt="Update"
                        className="your-new-icon-class"
                        onClick={() => handleStatusChange(honorRef.pk, !honorRef.is_active)}
                      />
                    </Tooltip>
                  </div>

                  <div className={'pointer'} style={{ alignContent: 'center' }}>
                    <Tooltip title="Delete" placement="top" arrow>
                      <img
                        src={trashIcon}
                        alt="Delete"
                        className="your-new-icon-class"
                        onClick={() => handleHapus(honorRef.pk)}
                      />
                    </Tooltip>
                  </div>

                  <div className={'pointer'} style={{ alignContent: 'center' }}>
                    {honorRef.list_honor[0].jenjang !== "MTI" ? (
                      <Link to={`/edit-honor-ref/${honorRef.pk}`}>
                        <Tooltip title="Edit" placement="top" arrow>
                          <img
                            src={editIcon}
                            alt="Edit"
                            className="your-new-icon-class"
                          />
                        </Tooltip>
                      </Link>
                    ) : (<Link to={`/edit-honor-ref/mti/${honorRef.pk}`}>
                      <Tooltip title="Edit" placement="top" arrow>
                        <img
                          src={editIcon}
                          alt="Edit"
                          className="your-new-icon-class"
                        />
                      </Tooltip>
                    </Link>)}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableHonorRate;