import { appServerAxios } from "./http-common";

class ProfileService {
  getProfile(Id) {
    return appServerAxios.get(`/auth/profile/${Id}`);
  }

  getRiwayatAkademis(mahasiswa_id, lowongan_id) {
    return appServerAxios.get(`/auth/profile/${mahasiswa_id}/${lowongan_id}`)
  }
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new ProfileService();
