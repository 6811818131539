/* eslint-disable no-unused-vars */
import { useState } from "react";
import logo from "../assets/icons/logo.svg";
import profpic from "../assets/icons/profpic.svg";
import moneyIcon from "../assets/icons/honornpembayaran.svg";
import sanksiIcon from "../assets/icons/sanksi.svg";
import logIcon from "../assets/icons/log.svg";
import lowonganIcon from "../assets/icons/lowongan.svg";
import dashboardIcon from "../assets/icons/dashboard.png";
// import settingsIcon from "../assets/icons/settings.svg";
import userIcon from "../assets/icons/user.svg";
import { Twirl as Hamburger } from "hamburger-react";
import "../assets/scss/Navbar.scss";
import "../assets/scss/_variables.scss";
import "../App.scss";
import AuthService from "../Services/AuthService";
import { useEffect } from "react";
import { appServerAxios } from "../Services/http-common";

const Navbar = () => {
  const [role, setRole] = useState();
  const [name, setName] = useState("John Doe");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState();

  const onMouseEnterDropdown = () => {
    setShowDropdown(true);
  };

  const onMouseLeaveDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("jwtToken");
    appServerAxios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

    const getMe = async () => {
      try {
        const response = await AuthService.getMe();
        const results = response.data.data;
        setName(results.nama);
        setRole(results.role);
        sessionStorage.setItem("nama", results.nama);
        sessionStorage.setItem("role", results.role);
        sessionStorage.setItem("username", results.username);
        sessionStorage.setItem("isProfileUpdated", results.is_profile_updated);
        sessionStorage.setItem("jenjangPendidikan", results.jenjang_pendidikan);
        sessionStorage.setItem("pengalaman", results.pengalaman);
        if (results.foto_profil) {
          setProfilePicture(
            process.env.REACT_APP_BACKEND_URL + "/media/" + results.foto_profil
          );
        }
      } catch (error) {
        console.error("Failed to get me");
      }
    };
    getMe();
  }, []);

  const userrole = sessionStorage.getItem("role");
  const username = sessionStorage.getItem("name")
    ? sessionStorage.getItem("name")
    : "John Doe";

  return (
    <nav className="tw-flex tw-flex-col">
      <div className={"navtop"}>
        <a className={"title tw-text-lg tw-font-bold md:tw-text-2xl"} href="/">
          <img src={logo} className={"logo-container"} alt="Fasilkom UI" />
        </a>
        <div
          className={
            "tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2"
          }
        >
          <div
            className={"navhead"}
            onMouseEnter={onMouseEnterDropdown}
            onMouseLeave={onMouseLeaveDropdown}
          >
            <div className={"user tw-items-center"}>
              <div className={"tw-hidden user-details md:tw-flex"}>
                <div className={"name"}>{username}</div>
                <div className={"position tw-capitalize"}>{userrole}</div>
              </div>

              <div className="nav-profile-container">
                <img
                  src={profilePicture ? profilePicture : profpic}
                  alt="foto profil"
                  className="nav-profile-content"
                />
              </div>
            </div>

            {showDropdown && (
              <div
                className="navhead-dropdown"
                onMouseEnter={onMouseEnterDropdown}
                onMouseLeave={onMouseLeaveDropdown}
              >
                {userrole === "mahasiswa" ? (
                  <>
                    <a className={"navlinkdrop medium-m8"} href="/profile">
                      Profil
                    </a>
                    <a className={"navlinkdrop medium-m8"} href="/manual">
                      Manual
                    </a>
                  </>
                ) : null}
                <a className={"navlinkdrop medium-m8"} href="/login">
                  Log Out
                </a>
              </div>
            )}
          </div>

          <div className={"tw-flex md:tw-hidden"}>
            <Hamburger duration={0.35} toggled={isOpen} toggle={setOpen} />
          </div>
        </div>
      </div>
      <div className={`navdown ${isOpen ? "open" : ""}`}>
        {userrole === "mahasiswa" ? (
          <>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={lowonganIcon} alt={"Lowongan"} />
                Lowongan
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/lowongan">
                    List Lowongan Asisten
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={logIcon} alt={"Log"} />
                Log
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a
                    className={"navlinkdrop medium-m8"}
                    href="/asdos/listLowongan"
                  >
                    Log Asistensi Per Matakuliah
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={moneyIcon} alt={"Money"} />
                Honor dan Pembayaran
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a
                    className={"navlinkdrop medium-m8"}
                    href="/asdos/honor"
                  >
                    Lihat Pembayaran Honor
                  </a>
                </div>
              )}
            </div>
          </>
        ) : userrole === "dosen" ? (
          <>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >

            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={lowonganIcon} alt={"Lowongan"} />
                Lowongan
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a
                    className={"navlinkdrop medium-m8"}
                    href="/lowongan/create"
                  >
                    Buat Lowongan
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/lowongan">
                    List Lowongan Saya
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={logIcon} alt={"Log"} />
                Log
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a
                    className={"navlinkdrop medium-m8"}
                    href="/listLowongan"
                  >
                    Log Asistensi Per Matakuliah
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={moneyIcon} alt={"Money"} />
                Honor dan Pembayaran
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/laporanpembayaranhonor">
                    Laporan Pembayaran Honor Asisten
                  </a>
                </div>
              )}
            </div>
          </>
        ) : userrole === "finance" ? (
          <>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >

              <div className={"navitem"}>
                <img src={userIcon} alt={"Asisten"} />
                Asisten
              </div>

              {showDropdown && (
                <div
                  className={"dropdown-content"}
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/asistenditerima">
                    List Asisten Diterima
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={logIcon} alt={"Log"} />
                Log
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/logsperbulan">
                    List Log per Bulan
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={moneyIcon} alt={"Money"} />
                Honor dan Pembayaran
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/melihat-histori-pembayaran">
                    Histori Pembayaran
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/admin/klaimhonor">
                    Klaim Pembayaran Honor Log Asisten
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/laporan-pembayaran-honor-asisten-per-matakuliah-per-semester">
                    Laporan Pembayaran Honor Asisten Matakuliah Per Semester
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/laporanpembayaranhonor">
                    Laporan Pembayaran Honor Asisten
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/honor-per-asisten">
                    Lihat Pembayaran Honor Per Asisten
                  </a>
                  {/* <a className={"navlinkdrop medium-m8"} href="/admin/belumtentukanhonor">
                    List Asisten Belum Tentukan Honor
                  </a> */}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={dashboardIcon} alt={"Dashboard"} />
                Dashboard
              </div>
              {showDropdown && (
                <div
                  className={"dropdown-content"}
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/admin/dashboard">
                    Dashboard
                  </a>
                </div>
              )}
            </div>

            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >

              <div className={"navitem"}>
                <img src={userIcon} alt={"Asisten"} />
                Asisten
              </div>

              {showDropdown && (
                <div
                  className={"dropdown-content"}
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/lamaran">
                    List Lamaran Asisten
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/asistenditerima">
                    List Asisten Diterima
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/allmahasiswa">
                    Lihat Daftar Mahasiswa
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={lowonganIcon} alt={"Lowongan"} />
                Lowongan
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/lowongan">
                    List Lowongan
                  </a>
                  <a
                    className={"navlinkdrop medium-m8"}
                    href="/lowongan/create"
                  >
                    Buat Lowongan
                  </a>
                  <a
                    className={"navlinkdrop medium-m8"}
                    href="/lowonganspesial/create"
                  >
                    Buat Lowongan Spesial
                  </a>
                  <a
                    className={"navlinkdrop medium-m8"}
                    href="/lowongan/items/create"
                  >
                    Buat Item Lowongan Spesial
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/lowongan/items">
                    List Item Lowongan Spesial
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={logIcon} alt={"Log"} />
                Log
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/admin/logsbelumsetujui">
                    List Log Belum Disetujui
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/admin/logsperbulan">
                    List Log per Bulan
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/listLowongan">
                    List Log per Mata Kuliah
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/admin/kategorilog">
                    Tetapkan Kategori Log
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={moneyIcon} alt={"Money"} />
                Honor dan Pembayaran
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/melihat-histori-pembayaran">
                    Histori Pembayaran
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/admin/klaimhonor">
                    Klaim Pembayaran Honor Log Asisten
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/laporan-pembayaran-honor-asisten-per-matakuliah-per-semester">
                    Laporan Pembayaran Honor Asisten Matakuliah Per Semester
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/laporanpembayaranhonor">
                    Laporan Pembayaran Honor Asisten
                  </a>
                  <a className={"navlinkdrop medium-m8"} href="/honor-per-asisten">
                    Lihat Pembayaran Honor Per Asisten
                  </a>
                  {/* <a className={"navlinkdrop medium-m8"} href="/admin/belumtentukanhonor">
                    List Asisten Belum Tentukan Honor
                  </a> */}
                  <a className={"navlinkdrop medium-m8"} href="/honor-ref-list">
                    Tetapkan Periode Honor
                  </a>
                </div>
              )}
            </div>
            <div
              className={"navlink medium-m8"}
              onMouseEnter={onMouseEnterDropdown}
              onMouseLeave={onMouseLeaveDropdown}
            >
              <div className={"navitem"}>
                <img src={sanksiIcon} alt={"Sanksi"} />
                Sanksi
              </div>

              {showDropdown && (
                <div
                  className="dropdown-content"
                  onMouseEnter={onMouseEnterDropdown}
                  onMouseLeave={onMouseLeaveDropdown}
                >
                  <a className={"navlinkdrop medium-m8"} href="/sanksi">
                    Referensi Sanksi
                  </a>
                </div>
              )}

            </div>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
