import '../../assets/group1/scss/Table/TableAdmin.scss';
import '../../assets/group1/scss/_variables.scss';
import { useState, useEffect } from 'react';
import SelectAllButton from './Buttons/SelectAllButton';
import LogsAdmin from './Logs/LogsAdmin';

const TableAdmin = ({ items , user, content}) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const headerItems = items;
    const handleSelectedItemsChange = (newSelectedItems) => {
        setSelectedItems(newSelectedItems);
    }

    useEffect(() => {
    }, [selectedItems]);
    return(
        <div className={"table-container-group2 container-shadow tw-pt-6"}>
            <table className="tw-w-full tw-table-auto">
                <thead>
                <tr className={"table-header-group2"}>
                    {headerItems.map((item, index) => {
                        let styleName;
                        if (index === 0) {
                            styleName = "tw-px-4 tw-py-4 tw-text-white tw-rounded-l-xl";
                        } else if (index === headerItems.length - 1) {
                            styleName = "tw-px-4 tw-py-4 tw-text-white tw-rounded-r-xl";
                        } else {
                            styleName = "tw-px-4 tw-py-4 tw-text-white";
                        }
        
                        return (
                            <th key={index} className={styleName}>
                                <div className="">{item}</div>
                            </th>
                        );
                    })}
                </tr>
                </thead>
                <tbody>
                <LogsAdmin onSelectedItemsChange={handleSelectedItemsChange} content={content}/>
                </tbody>
            </table>
            <div className={"table-buttons-container medium-m8"} style={{alignItems: 'center', margin: 'auto'}}>

                {user === "admin" ? (

                    <>
                        <SelectAllButton ListLogId={selectedItems} />
                    </>
                ) : null}
            </div>
        </div>
    );
}

export default TableAdmin;
