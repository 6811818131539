import { useEffect, useState, useMemo } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import TableHonorRef from "../../../Component/GroupOne/Table/TableHonorRef";
import "../../../assets/group1/scss/_variables.scss";
import "../../../assets/group1/scss/Page/BelumTentuHonor.scss";
import DeleteHonor from '../../../Component/GroupOne/DeleteHonor';
import Button from '../../../Component/GroupOne/Buttons/Button';
import { toast, ToastContainer } from "react-toastify";

const HonorRefList = () => {
  const [honorRefs, setHonorRefs] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const token = sessionStorage.getItem("jwtToken");
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);

  const handleStatusChange = async (id, newStatus) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/honor/status/${id}/`, {
        method: 'PUT',
        headers:headerData,
        body: JSON.stringify({ is_active: newStatus }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      toast.success("Status Berhasil Diganti", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          window.location.reload();
        },
      });
      await fetchData();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/honor/`, {
      headers:headerData,
      });
      const data = await response.json();
      setHonorRefs(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const confirmDelete = (pk) => {
    setShowConfirmation(true);
    setDeleteId(pk);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
    setDeleteId(null);
  };

  const handleDeleteConfirmation = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/honor/${deleteId}/`, {
        headers: headerData,
        method: 'DELETE',
      });

      if (response.ok) {

        toast.success("Honor Referensi Berhasil Dihapus", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            window.location.reload();
          },
        });
        setShowConfirmation(false);
        
        await fetchData();

        
      } else {
        setShowConfirmation(false);
        toast.error('Honor Referensi sedang digunakan sehingga tidak dapat dihapus', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

    } catch (error) {
      console.error('Error deleting data:', error);
    }
  };

  return (
      <div className="view">
        <Navbar />
        <div className="frame-container">
          <div className="header-container">
            <div className="title-container">
              <div className={'header-h4'}>List Referensi Honor</div>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '10px', justifyContent: 'center', flexWrap: 'wrap' }}>
            <Button text={"Tambah Honor Ref"} href={`/add-honor-ref`} background={"#4D8AFF"} color={"#FFFFFF"}/>
            <Button text={"Tambah Honor Ref MTI"} href={`/add-honor-ref/MTI`} background={"#4D8AFF"} color={"#FFFFFF"}/>
          </div>

          <DeleteHonor
              isOpen = {showConfirmation}
              onCancel = {cancelDelete}
              onConfirm = {handleDeleteConfirmation}
          />

          <TableHonorRef
              honorRefs={honorRefs}
              handleHapus={confirmDelete}
              handleStatusChange={handleStatusChange}
          />
          <div className={'header-h6'} style={{marginBottom: "10px", textAlign: 'center'}}>Pastikan tiap jenjang hanya 1 yang aktif</div>
        </div>
        <ToastContainer />
      </div>
  );
};

export default HonorRefList;
