import { useEffect } from "react";
import "../assets/scss/Modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const Modal = ({ onConfirm, onCancel }) => {
  useEffect(() => {
    const modalContainer = document.querySelector(".modalContainer");
    const modalBackground = document.querySelector(".modalBackground");

    if (modalContainer && modalBackground) {
      modalContainer.style.top = "30%";
      modalBackground.style.opacity = 1;
    }
  }, []);

  const closeAndReset = () => {
    const modalContainer = document.querySelector(".modalContainer");
    const modalBackground = document.querySelector(".modalBackground");

    if (modalContainer && modalBackground) {
      modalContainer.style.top = "-100%";
      modalBackground.style.opacity = 0;

      setTimeout(() => {
        modalContainer.style.top = "50%";
        onCancel();
      }, 300);
    }
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer" style={{ paddingBottom: '50px' }}>
        <div className="titleCloseBtn">
          <button onClick={closeAndReset}>X</button>
        </div>

        <div className="title">
          <h1>Konfirmasi Penghapusan</h1>
        </div>
        <div className="warning-icon">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>
        <div className="body">
          <p>Apakah anda yakin ingin menghapus data ini?</p>
        </div>
        <div className="footer">
          <button onClick={onCancel} id="cancelBtn" >
            Tidak
          </button>
          <button
            onClick={() => {
              onConfirm();
              closeAndReset();
            }}
          >
            Iya
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
