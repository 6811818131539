/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect, useMemo } from "react";
import Navbar from "../../Component/Navbar";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import "../../App.scss";
import LamaranService from "../../Services/LamaranService";
import { ToastContainer } from "react-toastify";
import LamaranTableDiterimaAdmin from "../../Component/PelamarDiterimaTable";
import LamaranTableAdmin from "../../Component/PelamarTable";
import { useNavigate } from "react-router-dom";

const LowonganApplicantPage = () => {
  const navigate = useNavigate();
  const pathParts = window.location.pathname.split("/");
  const idIndex = pathParts.indexOf("lowongan");
  const lowonganid =
    idIndex !== -1 && idIndex + 1 < pathParts.length
      ? pathParts[idIndex + 1]
      : 1;

  const [lamaranDiterimaList, setLamaranDiterimaList] = useState([]);
  const fetchitemList = async () => {
    try {
      const response =
        await LamaranService.getPelamarDiterimaDetails(lowonganid);
      setLamaranDiterimaList(response.data.data);
    } catch (error) {
      console.error("Failed to fetch items:", error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchitemList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Items = useMemo(() => {
    if (lamaranDiterimaList && lamaranDiterimaList.length > 0) {
      return lamaranDiterimaList;
    }
    return [];
  }, [lamaranDiterimaList]);

  let itemTableComponent;

  if (Items && Items.length > 0) {
    itemTableComponent = (
      <LamaranTableDiterimaAdmin
        items={[["No", "Mata Kuliah", "Nama Pelamar", "NPM", "Email"]]}
        lamaranditerimalist={Items}
      />
    );
  }

  const [lamaranList, setLamaranList] = useState([]);
  const fetchitempelamarList = async () => {
    try {
      const response = await LamaranService.getPelamarDetails(lowonganid);
      setLamaranList(response.data.data);
    } catch (error) {
      console.error("Failed to fetch items:", error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchitempelamarList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const PelamarItems = useMemo(() => {
    if (lamaranList && lamaranList.length > 0) {
      return lamaranList;
    }
    return [];
  }, [lamaranList]);

  let pelamaritemTableComponent;

  if (PelamarItems && PelamarItems.length > 0) {
    pelamaritemTableComponent = (
      <LamaranTableAdmin
        items={[
          [
            "No",
            "Nama Pelamar",
            "NPM",
            "Email",
            "Profil",
            "Status",
            "Keterangan",
            "Operasi",
          ],
        ]}
        lamaranlist={PelamarItems}
      />
    );
  }

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"back medium-m8"} style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
            ← Kembali
            </div>
        <div className={"content"}>
          <div className={"lowongan-container tw-mb-8"}>
            <div
              className={
                "tw-flex tw-justify-between tw-items-end tw-gap-2 sm:tw-gap-4"
              }
            ></div>
            {lamaranDiterimaList.length > 0 && (
              <LamaranTableDiterimaAdmin
                items={[["No", "Mata Kuliah", "Nama Pelamar", "NPM", "Email"]]}
                lamaranditerimalist={lamaranDiterimaList}
              />
            )}
            {lamaranList.length > 0 && (
              <LamaranTableAdmin
                items={[
                  [
                    "No",
                    "Nama Pelamar",
                    "NPM",
                    "Email",
                    "Profil",
                    "Status",
                    "Keterangan",
                    "Operasi",
                  ],
                ]}
                lamaranlist={lamaranList}
              />
            )}
            {lamaranDiterimaList.length === 0 && lamaranList.length === 0 && (
              <div className={"table-container container-shadow tw-pt-6"}>
                <h2>Belum ada lamaran yang masuk</h2>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default LowonganApplicantPage;
