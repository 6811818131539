import '../../../assets/group1/scss/Table/Table.scss';
import '../../../assets/group1/scss/_variables.scss';

const LaporanPembayaran = (props) => {
    const monthNames = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];

    return (
        <>
            {props.content.map((item, index) => (
                <tr key={index}>
                    <td data-testid={`log-entry-${index}`} className="tw-text-center tw-p-4">
                        {index + 1}
                    </td>
                    <td data-testid={`log-entry-${index}`} className="tw-text-center tw-p-4">
                        {item.lowongan}
                    </td>
                    <td data-testid={`log-entry-${index}`} className="tw-text-center tw-p-4">
                        {item.mahasiswa__name}
                    </td>
                    <td data-testid={`log-entry-${index}`} className="tw-text-center tw-p-4">
                        {monthNames[item.bulan-1]}, {item.tahun}
                    </td>
                    <td data-testid={`log-entry-${index}`} className="tw-text-center tw-p-4">
                        {item.total_logs}
                    </td>
                    <td data-testid={`log-entry-${index}`} className="tw-text-center tw-p-4">
                        {Math.floor(item["total_duration"] / 60)} hour(s) {(item["total_duration"] % 60) === 0 ? "" : `${item["total_duration"] % 60} minute(s)`}
                    </td>
                    <td data-testid={`log-entry-${index}`} className="tw-text-center tw-p-4">
                        {item.total_pembayaran.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                    </td>
                    {/* Add the rest of your table cells using item properties */}
                </tr>
            ))}
        </>
    );
};

export default LaporanPembayaran

