import "../../../assets/group1/scss/Card/DashboardCard.scss";

const DashboardCard = ({name, numbers, link}) => {
  return (
    <a className="card-body custom-link" href={link}>
      <div className="number-placeholder">
        <div className="number">
            {numbers}
        </div>
      </div>
      <div className='title'>
        {name}
      </div>
    </a>
  );
};

export default DashboardCard;
