import { Fragment } from "react";
import Navbar from "./Navbar";
import "../assets/scss/_variables.scss";
import "../assets/scss/Lowongan.scss";
import "../App.scss";
import "../assets/scss/Profile.scss";

const Layout = ({ children }) => {
  return (
    <Fragment>
      <div className={"view tw-mb-16"}>
        <Navbar />
        <div className="content">{children}</div>
      </div>
    </Fragment>
  );
};

export default Layout;
