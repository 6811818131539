import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Component/Navbar";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/CreateLowongan.scss";
import SanksiService from "../../Services/SanksiService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SingleSelect from "../../Component/SingleSelect";
import InputBox from '../../Component/GroupOne/InputBox';
import InputOneRow from '../../Component/InputOneRow';
import DatePicker from '../../Component/GroupOne/DatePicker';
import dayjs from 'dayjs';


const SanksiUpdatePage = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const pelanggaranId = pathParts[pathParts.length - 1];
  const sanksiId = pathParts[pathParts.length - 2];

  const [nama, setNama] = useState('');
  const [npm, setNPM] = useState('');
  const [bentukPelanggaran, setBentukPelanggaran] = useState();
  const [noSurat, setNoSurat] = useState();
  const [jenisSurat, setJenisSurat] = useState();
  const today = dayjs().endOf('day').format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  /* istanbul ignore next */
  const handleNamaOnChange = (e) => {
    setNama(e.target.value);
  };
  /* istanbul ignore next */
  const handleNPMOnChange = (e) => {
    setNPM(e.target.value);
  };
  /* istanbul ignore next */
  const handleBentukPelanggaranOnChange = (e) => {
    setBentukPelanggaran(e.target.value);
  };
  /* istanbul ignore next */
  const handleNoSuratOnChange = (e) => {
    setNoSurat(e.target.value);
  };
  /* istanbul ignore next */
  const handleJenisSuratOnChange = (jenisSurat) => {
    setJenisSurat(jenisSurat);
  };
  /* istanbul ignore next */
  const handleStartDateOnChange = (_, dateString) => { setStartDate(dateString) };
  /* istanbul ignore next */
  const handleEndDateOnChange = (_, dateString) => { setEndDate(dateString) };
  /* istanbul ignore next */
  const handleDisabledEndDateOnChange = (current) => {
    return current < dayjs(startDate);
  }

  const jenisOptions = [
    {
      label: "Keras",
      value: "JenisSurat.KERAS",
    },
    {
      label: "Ringan",
      value: "JenisSurat.RINGAN",
    },
    {
      label: "Tidak ada",
      value: "JenisSurat.TIDAK_ADA",
    }
  ];

  const fetchSanksi = async () => {
    try {
      const response = await SanksiService.getSanksiDetail(sanksiId, pelanggaranId);
      const results = response.data;
      setNama(results.nama)
      setNPM(results.npm)
      setBentukPelanggaran(results.bentuk_pelanggaran)
      setNoSurat(results.no_surat)
      for (const option of jenisOptions) {
        if (option.value === results.jenis_surat) {
          setJenisSurat({
            value: option.value,
            label: option.label,
          })
        }
      }
      setStartDate(results.start_date)
      setEndDate(results.end_date)
    } catch (error) {
      console.error("Failed to fetch sanksi details:", error);
    }
  };

  useEffect(() => {
    fetchSanksi();
  }, [sanksiId, pelanggaranId]);

  const handleSubmit = async (e) => {
    if (
      nama &&
      !isNaN(+npm) &&
      bentukPelanggaran &&
      noSurat &&
      jenisSurat &&
      startDate &&
      endDate &&
      dayjs(endDate).isAfter(dayjs(startDate))
    ) {
      const jenisSuratLabel = jenisSurat.label;
      const requestBody = {
        nama: nama,
        npm: npm,
        bentuk_pelanggaran: bentukPelanggaran,
        id: pelanggaranId,
        no_surat: noSurat,
        jenis_surat: jenisSuratLabel,
        start_date: startDate,
        end_date: endDate,
      };
      await SanksiService.updateSanksi(sanksiId, requestBody)
        .then((response) => {
          /* istanbul ignore next */
          toast.success("Sanksi Berhasil Diedit", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/sanksi");
            },
          });
        })
        .catch((error) => {
          toast.error("Gagal Edit Sanksi", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <a href="/sanksi" className={"back medium-m8"}>
          ← Kembali
        </a>
        <div className={"content"}>
          <form className={"body-container"} onSubmit={handleSubmit}>
            <h2>Edit Sanksi</h2>
            <div className={"card-form-container container-shadow"}>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Nama</div>
                <InputOneRow
                  value={nama}
                  onChange={handleNamaOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">NPM</div>
                <InputOneRow
                  value={npm}
                  onChange={handleNPMOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Bentuk Pelanggaran</div>
                <InputBox
                  value={bentukPelanggaran}
                  onChange={handleBentukPelanggaranOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">No. Surat</div>
                <InputBox
                  value={noSurat}
                  onChange={handleNoSuratOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Jenis Surat</div>
                <SingleSelect
                  value={jenisSurat}
                  options={jenisOptions}
                  onChange={handleJenisSuratOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <DatePicker
                  value={dayjs(startDate, 'YYYY-MM-DD')}
                  label={'Start Date'}
                  /* istanbul ignore next */
                  onChange={handleStartDateOnChange}
                  placeholder={'Pilih Tanggal'}
                  format={'YYYY-MM-DD'}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <DatePicker
                  value={dayjs(endDate, 'YYYY-MM-DD')}
                  label={'End Date'}
                  /* istanbul ignore next */
                  onChange={handleEndDateOnChange}
                  placeholder={'Pilih Tanggal'}
                  format={'YYYY-MM-DD'}
                  /* istanbul ignore next */
                  disabledDate={handleDisabledEndDateOnChange}
                />
              </div>

              <div
                className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
              >
                <Button
                  data-testid={"Update"}
                  text={"Update"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleSubmit}
                  isDisabled={
                    !(
                      nama &&
                      npm &&
                      bentukPelanggaran &&
                      noSurat &&
                      jenisSurat &&
                      startDate &&
                      endDate
                    )
                  }
                  role='button'
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default SanksiUpdatePage;
