/* eslint-disable-next-line no-unused-vars */
import { Fragment } from "react";
import { useState } from "react";
import Navbar from "../../../Component/GroupOne/Navbar";
import "../../../assets/group1/scss/_variables.scss";
import "../../../assets/group1/scss/Page/LogPerBulan.scss";
import Button from "../../../Component/GroupOne/Buttons/Button";
import TableLogPerBulan from "../../../Component/GroupOne/Table/TableLogPerBulan";
import { eachYearOfInterval, format } from "date-fns";
import Filter from "../../../Component/GroupOne/Filter";
import Filter2 from "../../../Component/GroupOne/Filter2";
import { toast, ToastContainer } from "react-toastify";
import mark from "../../../assets/group1/icons/mark.svg";
import LogService from "../../../Services/LogService";

const AdminMelihatLogPerBulan = () => {
  const [logData, setLogData] = useState([]);
  const [selectedTahun, setSelectedTahun] = useState("Tahun");
  const [selectedBulan, setSelectedBulan] = useState("Bulan");
  const [selectedJenjang, setSelectedJenjang] = useState('Jenjang');
  const [bulanSelected, setBulanSelected] = useState("");
  const [tahunSelected, setTahunSelected] = useState("");
  const [jenjangSelected, setJenjangSelected] = useState("");
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const handleTahunChange = (tahun) => {
    setSelectedTahun(tahun);
  };

  const handleBulanChange = (bulan) => {
    setSelectedBulan(bulan);
  };

  const handleJenjangChange = (jenjang) => {
    setSelectedJenjang(jenjang);
  };

  const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();
  const bulanOptions = [
    "Bulan",
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ]

  const handleButtonClick = async () => {
    if (selectedTahun === "Tahun" || selectedBulan === "Bulan" || selectedJenjang === "Jenjang") {
      toast.error("Tahun, bulan, dan jenjang harus dipilih terlebih dahulu", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }

    try {
      const response = await LogService.getLogMonthly(
        selectedTahun,
        selectedBulan,
        selectedJenjang
      );
      setLogData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setTahunSelected(selectedTahun)
    setBulanSelected(selectedBulan)
    setJenjangSelected(selectedJenjang)
    setIsButtonPressed(true)
  };
  const groupedLogs = logData
    .sort((a, b) => a.mahasiswa__name.localeCompare(b.mahasiswa__name))
    .reduce((result, log) => {
      const key = `${log.mahasiswa__username}~${log.mahasiswa__name}~${log.lowongan__nama}`;

      if (!result[key]) {
        result[key] = [];
      }

      result[key].push({
        status: log.status,
        datetime: log.datetime,
        duration: log.duration,
        description: log.description,
      });
      return result;
    }, {});

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content-group1"}>
          <div className={"header-h5"}>Daftar Log Per Bulan</div>
          <div className={"divider"}></div>
          <div className={"filters-container-perbulan"}>
            <Filter text={"Tahun"} items={["Tahun", ...years]} onFilterChange={handleTahunChange} />
            <Filter2 text={"Bulan"} items={bulanOptions} onFilterChange={handleBulanChange} />
            <Filter text={"Jenjang"} items={["Jenjang", "S1-MIK", "MTI"]} onFilterChange={handleJenjangChange} />
            <Button
              text={"Lihat Log"}
              background={"#4D8AFF"}
              color={"#FFFFFF"}
              onClick={handleButtonClick}
            />
          </div>
          <div>
            {logData.length !== 0 ?
              Object.keys(groupedLogs).map((key) => {
                const [username, name, lowonganNama] = key.split('~');

                return (
                  <Fragment key={key}>
                    <div className={"title-container"}>
                      <div className="table-title header-h7">
                        <b>{name} - {username}</b>
                        <br></br>
                        {lowonganNama}
                      </div>
                    </div>
                    <TableLogPerBulan
                      items={[
                        "Tanggal",
                        "Durasi",
                        "Deskripsi Tugas",
                        "Status"
                      ]}
                      content={groupedLogs[key]}
                    />
                  </Fragment>
                );
              }) : isButtonPressed ?
                <>
                  <div className={"no-content"}>
                    <img
                      src={mark}
                      className={"pointer enlarged"}
                      alt="fail"
                    />
                    {`Tidak ada log pada: ${bulanOptions[bulanSelected]} ${tahunSelected} jenjang ${jenjangSelected}`}
                  </div>
                </> : ""}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment >
  );
};

export default AdminMelihatLogPerBulan;

