import { appServerAxios } from "./http-common";

class LamaranService {
  createLamaran(data) {
    return appServerAxios.post(`/lamaran/`, data);
  }

  getLamaranDetails(lowonganId) {
    return appServerAxios.get(`/lamaran/${lowonganId}`);
  }

  getAllLamaran(semester, tahun_ajaran, status) {
    return appServerAxios.get("/lamaran/pelamar", {
      params: {
        semester: semester,
        tahun_ajaran: tahun_ajaran,
        status: status,
      },
    })}

    getAllLamaranOnStatus(status) {
      return appServerAxios.get("/lamaran/pelamar", {
        params: {
          status: status,
        },
      })}
    
  getPelamarDiterimaDetails(lowonganId) {
    return appServerAxios.get(`/lamaran/tanpa_keterangan/${lowonganId}`);
  }

  getPelamarDetails(lowonganId) {
    return appServerAxios.get(`/lamaran/keterangan/${lowonganId}`);
  }

  gantiStatus(lamaranId,mahasiswaId,requestBody) {
    return appServerAxios.put(`/lamaran/update/status/${lamaranId}/${mahasiswaId}`,requestBody);
  }

  getDownloadAsistenDiterima(startDate, endDate) {
    return appServerAxios.get("/lamaran/download-asisten-diterima", {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
      responseType: 'blob',
    })}
    
  getAsistenDiterima(semester, tahun_ajaran) {
    return appServerAxios.get("/lamaran/asisten-diterima", {
      params: {
        semester: semester,
        tahun_ajaran: tahun_ajaran,
      },
    })}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LamaranService();
