// eslint-disable-next-line no-unused-vars
const bankOptions = [
  { value: "Allo Bank Indonesia", label: "Allo Bank Indonesia" },
  { value: "BPD Bali", label: "BPD Bali" },
  { value: "BPD Banten Tbk", label: "BPD Banten Tbk" },
  { value: "BPD Bengkulu", label: "BPD Bengkulu" },
  { value: "BPD DKI", label: "BPD DKI" },
  {
    value: "BPD Daerah Istimewa Yogyakarta",
    label: "BPD Daerah Istimewa Yogyakarta",
  },
  { value: "BPD Jambi", label: "BPD Jambi" },
  {
    value: "BPD Jawa Barat dan Banten Tbk",
    label: "BPD Jawa Barat dan Banten Tbk",
  },
  { value: "BPD Jawa Tengah", label: "BPD Jawa Tengah" },
  { value: "BPD Jawa Timur Tbk", label: "BPD Jawa Timur Tbk" },
  { value: "BPD Kalimantan Barat", label: "BPD Kalimantan Barat" },
  { value: "BPD Kalimantan Selatan", label: "BPD Kalimantan Selatan" },
  { value: "BPD Kalimantan Tengah", label: "BPD Kalimantan Tengah" },
  {
    value: "BPD Kalimantan Timur dan Kalimantan Utara",
    label: "BPD Kalimantan Timur dan Kalimantan Utara",
  },
  { value: "BPD Lampung", label: "BPD Lampung" },
  {
    value: "BPD Maluku dan Maluku Utara",
    label: "BPD Maluku dan Maluku Utara",
  },
  { value: "BPD Nusa Tengara Timur", label: "BPD Nusa Tengara Timur" },
  { value: "BPD Papua", label: "BPD Papua" },
  { value: "BPD Riau Kepri", label: "BPD Riau Kepri" },
  {
    value: "BPD Sulawesi Selatan dan Sulawesi Barat",
    label: "BPD Sulawesi Selatan dan Sulawesi Barat",
  },
  { value: "BPD Sulawesi Tengah", label: "BPD Sulawesi Tengah" },
  { value: "BPD Sulawesi Tenggar", label: "BPD Sulawesi Tenggar" },
  {
    value: "BPD Sulawesi Utara dan Gorontalo",
    label: "BPD Sulawesi Utara dan Gorontalo",
  },
  { value: "BPD Sumatera Barat", label: "BPD Sumatera Barat" },
  {
    value: "BPD Sumatera Selatan dan Bangka Belitung",
    label: "BPD Sumatera Selatan dan Bangka Belitung",
  },
  { value: "BPD Sumatera Utara", label: "BPD Sumatera Utara" },
  { value: "Bank ANZ Indonesia", label: "Bank ANZ Indonesia" },
  { value: "Bank Aceh Syariah", label: "Bank Aceh Syariah" },
  { value: "Bank Aladin Syariah Tbk", label: "Bank Aladin Syariah Tbk" },
  { value: "Bank Amar Indonesia", label: "Bank Amar Indonesia" },
  {
    value: "Bank Artha Graha Internasional Tbk",
    label: "Bank Artha Graha Internasional Tbk",
  },
  { value: "Bank BCA Syariah", label: "Bank BCA Syariah" },
  { value: "Bank BNP Paribas Indonesia", label: "Bank BNP Paribas Indonesia" },
  { value: "Bank BTPN Syariah Tbk", label: "Bank BTPN Syariah Tbk" },
  { value: "Bank BTPN Tbk", label: "Bank BTPN Tbk" },
  { value: "Bank Bisnis Internasional", label: "Bank Bisnis Internasional" },
  { value: "Bank Bumi Arta Tbk", label: "Bank Bumi Arta Tbk" },
  { value: "Bank CIMB Niaga Tbk", label: "Bank CIMB Niaga Tbk" },
  { value: "Bank CTBC Indonesia", label: "Bank CTBC Indonesia" },
  { value: "Bank Capital Indonesia Tbk", label: "Bank Capital Indonesia Tbk" },
  { value: "Bank Central Asia Tbk", label: "Bank Central Asia Tbk" },
  {
    value: "Bank China Construction Bank Indonesia Tbk",
    label: "Bank China Construction Bank Indonesia Tbk",
  },
  { value: "Bank Commonwealth", label: "Bank Commonwealth" },
  { value: "Bank DBS Indonesia", label: "Bank DBS Indonesia" },
  { value: "Bank Danamon Indonesia Tbk", label: "Bank Danamon Indonesia Tbk" },
  { value: "Bank Digital BCA", label: "Bank Digital BCA" },
  { value: "Bank DKI", label: "Bank DKI" },
  { value: "Bank Fama Internasional", label: "Bank Fama Internasional" },
  { value: "Bank Ganesha Tbk", label: "Bank Ganesha Tbk" },
  { value: "Bank HSBC Indonesia", label: "Bank HSBC Indonesia" },
  { value: "Bank IBK Indonesia Tbk", label: "Bank IBK Indonesia Tbk" },
  { value: "Bank ICBC Indonesia", label: "Bank ICBC Indonesia" },
  { value: "Bank INA Perdana Tbk", label: "Bank INA Perdana Tbk" },
  { value: "Bank Index Selindo", label: "Bank Index Selindo" },
  { value: "Bank Jabat Banten Syariah", label: "Bank Jabat Banten Syariah" },
  { value: "Bank Jago Tbk", label: "Bank Jago Tbk" },
  { value: "Bank Jasa Jakarta", label: "Bank Jasa Jakarta" },
  { value: "Bank Jasa Jakarta", label: "Bank Jasa Jakarta" },
  { value: "Bank Jtrust Indonesia Tbk", label: "Bank Jtrust Indonesia Tbk" },
  { value: "Bank KB Bukopin Tbk", label: "Bank KB Bukopin Tbk" },
  { value: "Bank KEB Hana Indonesia", label: "Bank KEB Hana Indonesia" },
  { value: "Bank MNC Internasional Tbk", label: "Bank MNC Internasional Tbk" },
  { value: "Bank Mandiri", label: "Bank Mandiri" },
  { value: "Bank Mandiri Taspen", label: "Bank Mandiri Taspen" },
  { value: "Bank Maspion Indonesia Tbk", label: "Bank Maspion Indonesia Tbk" },
  {
    value: "Bank Mayapada International Tbk",
    label: "Bank Mayapada International Tbk",
  },
  { value: "Bank Maybank Indonesia Tbk", label: "Bank Maybank Indonesia Tbk" },
  { value: "Bank Mayora", label: "Bank Mayora" },
  { value: "Bank Mega Syariah", label: "Bank Mega Syariah" },
  { value: "Bank Mega Tbk", label: "Bank Mega Tbk" },
  { value: "Bank Mesktika Dharma Tbk", label: "Bank Mesktika Dharma Tbk" },
  { value: "Bank Mizuho Indonesia", label: "Bank Mizuho Indonesia" },
  {
    value: "Bank Muamalat Indonesia Tbk",
    label: "Bank Muamalat Indonesia Tbk",
  },
  { value: "Bank Multiarta Sentosa", label: "Bank Multiarta Sentosa" },
  { value: "Bank NEO Commerce Tbk", label: "Bank NEO Commerce Tbk" },
  { value: "Bank NTB Syariah", label: "Bank NTB Syariah" },
  { value: "Bank Nationalnobu Tbk", label: "Bank Nationalnobu Tbk" },
  {
    value: "Bank Negara Indonesia (BNI46)",
    label: "Bank Negara Indonesia (BNI46)",
  },
  { value: "Bank OCBC NISP Tbk", label: "Bank OCBC NISP Tbk" },
  {
    value: "Bank Of India Indonesia Tbk",
    label: "Bank Of India Indonesia Tbk",
  },
  { value: "Bank Oke Indonesia Tbk", label: "Bank Oke Indonesia Tbk" },
  {
    value: "Bank Panin Dubai Syariah Tbk",
    label: "Bank Panin Dubai Syariah Tbk",
  },
  { value: "Bank Permata Tbk", label: "Bank Permata Tbk" },
  { value: "Bank QNB Indonesia Tbk", label: "Bank QNB Indonesia Tbk" },
  { value: "Bank Rakyat Indonesia", label: "Bank Rakyat Indonesia" },
  {
    value: "Bank Rakyat Indonesia Agroniaga Tbk",
    label: "Bank Rakyat Indonesia Agroniaga Tbk",
  },
  { value: "Bank Resona Perdania", label: "Bank Resona Perdania" },
  { value: "Bank SBI Indonesia", label: "Bank SBI Indonesia" },
  { value: "Bank Seabank Indonesia", label: "Bank Seabank Indonesia" },
  { value: "Bank Shinhan Indonesia", label: "Bank Shinhan Indonesia" },
  { value: "Bank Sinarmas Tbk", label: "Bank Sinarmas Tbk" },
  { value: "Bank Syariah Bukopin", label: "Bank Syariah Bukopin" },
  { value: "Bank Syariah Indonesia", label: "Bank Syariah Indonesia" },
  { value: "Bank Tabungan Negara", label: "Bank Tabungan Negara" },
  { value: "Bank UOB Indonesia", label: "Bank UOB Indonesia" },
  {
    value: "Bank Victoria International Tbk",
    label: "Bank Victoria International Tbk",
  },
  { value: "Bank Victoria Syariah", label: "Bank Victoria Syariah" },
  {
    value: "Bank Woori Saudara Indonesia 1906 Tbk",
    label: "Bank Woori Saudara Indonesia 1906 Tbk",
  },
  { value: "Bank Sahabat Sampoerna", label: "Bank Sahabat Sampoerna" },
  { value: "Pan Indonesia Bank Tbk", label: "Pan Indonesia Bank Tbk" },
  { value: "Prima Master Bank", label: "Prima Master Bank" },
  { value: "Hongkong Shanghai Bank", label: "Hongkong Shanghai Bank" },
];

export default bankOptions;
