import { Fragment } from "react";
import Navbar from "../../../Component/GroupOne/Navbar";
import "../../../assets/group1/scss/_variables.scss";
import "../../../assets/group1/scss/Page/BelumTentuHonor.scss"
import TableHonor from "../../../Component/GroupOne/Table/TableHonor";

const AdminBelumTentukanHonor = () => {
  // const { parameter } = useParams();
  const parameter = 'Aruni'

  return (
    <Fragment>
      <div className="view">
        <Navbar name={parameter} />
        <a href="/admin/dashboard" className={"medium-m8"} style={{ paddingLeft: '300px' }}>
            ← Back to Dashboard
          </a>
        <div className="frame-container">
            <div className="header-container">
                <div className="title-container">
                    <div className="header-h4">List Asisten Belum Tentukan Honor</div>
                </div>
                {/* <div className="filter-container-honor">
                    Filter
                </div> */}
            </div>
            <TableHonor/>
            
        
        </div>
        </div>

    </Fragment>
  );
};

export default AdminBelumTentukanHonor;
