import Navbar from "../../../Component/GroupOne/Navbar";
/* eslint-disable-next-line no-unused-vars */
import { Fragment, useEffect, useState, useMemo } from 'react';
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import TableCourse from "../../../Component/GroupOne/TableCourse";
import { useParams } from "react-router";
import { getJenjang } from "./logUtil";

const DosenMelihatLogPerMatkul = () => {
  const { matkul_id } = useParams();
  const [content, setContent] = useState([]);
  const [matkul, setMatkul] = useState([]);
  const username = sessionStorage.getItem("username");
  const token = sessionStorage.getItem("jwtToken");
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);
  const [jenjang, setJenjang] = useState([]);

  const fetchLog = async () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/log/lowongan/${matkul_id}/`, {
      method: 'GET',
      headers: headerData
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setContent(data.logs.sort((a, b) => {
          const dateA = new Date(a.datetime);
          const dateB = new Date(b.datetime);
          return dateB - dateA;
        }));
        setMatkul(data.lowongan)
        getJenjang(data.lowongan.kode_matkul, data.lowongan.kode_kurikulum, setJenjang)
      })
      .catch((error) => {
        console.error('Kesalahan:', error);
      });
  }

  useEffect(() => {
    fetchLog()
  }, [matkul_id, headerData]);


  return (
    <Fragment>
      <div className={"view"}>
        <Navbar name={username} />
        <div className={"content-group1"}>
          <a href={`/listLowongan`} className={"medium-m8"}>
            ← Kembali ke Daftar Matakuliah
          </a>
          <div className={"body-container-group1"}>
            <div className={"profile-group1"}>
              <div className={"header-h4"}>Daftar Log</div>
              <div className={"medium-m8"}>{matkul.nama}</div>
            </div>
            <div className={"logs-container"}>
              <TableCourse items={[
                [
                  "Nama",
                  "Tanggal Log",
                  "Durasi",
                  "Deskripsi",
                  ...(jenjang === "MTI" ? ["Moda"] : []),
                  "Tandai",
                  "Status",
                  "Operasi"]]}
                content={content} user={"dosen"} jenjang={jenjang}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DosenMelihatLogPerMatkul;
