import Button from './Button';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function CvViewer({ cvUrl }) {
  const handleView = async () => {
    const token = sessionStorage.getItem("jwtToken");
    const headerData = new Headers();
    headerData.append('Content-type', 'application/json');
    headerData.append("Authorization", "Bearer " + token);

    const response = await fetch(
      cvUrl,
      {
        headers: headerData
      }
    );

    if (!response.ok) {
      toast.error('Mahasiswa tidak mengupload file CV', {
        position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
      });
      return;
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.click();

    window.URL.revokeObjectURL(url);
  };

  if(!cvUrl || cvUrl.trim() === '') return null;

  return (
    <>
      <Button text={"Lihat CV"} onClick={handleView} color={"#FFFFFF"} size={"small-button"} />
      <ToastContainer />
    </>
  );
}

export default CvViewer;