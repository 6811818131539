import "../assets/scss/Dropdown.scss";

const StatusDropdown = (props) => {
  const { label, name, value, onChange, disabled } = props;
  const updatedOptions = ["- Select -", "melamar", "diterima", "direkomendasikan"];

  return (
    <div>
      <div className={"header-h6"} style={{ marginBottom: "10px" }}>
        {label}
      </div>
      <select
        className={"dropdown-container body-p7"}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {updatedOptions.map((opt, index) => (
          <option key={index} value={opt}>
            {opt}
          </option>
        ))}
      </select>
    </div>
  );
};

export default StatusDropdown;