import { Fragment, useState, useEffect } from "react";
import Navbar from "../../Component/Navbar";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import "../../App.scss";
import { ToastContainer } from "react-toastify";
import SearchInput from "../../Component/SearchInput";
import MahasiswaTable from "../../Component/MahasiswaTable";
import AuthService from "../../Services/AuthService";


const AllMahasiswaPage = () => {
  const [mahasiswaList, setMahasiswaList] = useState([]);
  const [search, setSearch] = useState('');

  const fetchmahasiswaList = async () => {
      try {
        const response = await AuthService.getAllMahasiswa();
        setMahasiswaList(response.data);
      } catch (error) {
        console.error("Failed to fetch lamaran:", error);
      }
  };

  useEffect(() => {
    fetchmahasiswaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value)
  };

  let mahasiswaTableComponent;

  mahasiswaTableComponent = (
    <MahasiswaTable
      items={[
        [
          "No",
          "Nama Asisten",
          "NPM",
          "Jenjang Pendidikan",
          "Pengalaman",
          "Profil"
        ],
      ]}
      mahasiswaList={mahasiswaList.filter((item) => {
        return search.toLowerCase() === '' ? item : item.name.toLowerCase().includes(search.toLowerCase()) ||
        search === '' ? item : item.kode_identitas.includes(search)
      })}
    />
  );

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content"}>
          <div className={"lowongan-container tw-mb-8"}>
            <div className={"header-h4"}>Daftar Mahasiswa</div>
            <div
              className={
                "tw-flex tw-justify-between tw-items-end tw-gap-2 sm:tw-gap-4"
              }
            >
              <div className={"tw-flex tw-justify-start tw-items-end tw-gap-4 tw-flex-wrap"}>
                <label htmlFor="search">
                  <SearchInput
                    name={"search"}
                    value={search}
                    onChange={handleSearch}
                  />
                </label>
              </div>
            </div>
            {mahasiswaTableComponent}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default AllMahasiswaPage;