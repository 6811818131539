/* eslint-disable-next-line no-unused-vars */
import { Fragment, useState, useMemo } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import Button from '../../../Component/GroupOne/Buttons/Button';
import NumInput from '../../../Component/GroupOne/NumInput';
import '../../../assets/group1/scss/_variables.scss'
import { toast, ToastContainer } from "react-toastify";


const AddHonorRef = () => {
  const token = sessionStorage.getItem("jwtToken");

  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);

  const [honorRefData, setHonorRefData] = useState({
    name: '',
    periode: '',
    mahasiswa_s1: '',
    lulus_s1_mahasiswa_s2: '',
    lulus_s2_mahasiswa_s3: '',
    ta_kontrak: '',
  });

  const handleChange = (name, value) => {
    setHonorRefData({
      ...honorRefData,
      [name]: value
    });
  };

  const handleSave = () => {
    if (!honorRefData.mahasiswa_s1 || !honorRefData.lulus_s1_mahasiswa_s2 || !honorRefData.lulus_s2_mahasiswa_s3 || !honorRefData.ta_kontrak) {
      alert('Data Setiap Kategori Tingkat Pendidikan Perlu Dilengkapi');
      return;
    }

    const honorRefDataToSend = {
      name: honorRefData.name,
      periode: honorRefData.periode,
      list_honor: [
        {
          jenjang: "S1-MIK",
          pendidikan_asdos: "Mahasiswa S1",
          amount: honorRefData.mahasiswa_s1,
        },
        {
          jenjang: "S1-MIK",
          pendidikan_asdos: "Lulus S1 / Mahasiswa S2",
          amount: honorRefData.lulus_s1_mahasiswa_s2,
        },
        {
          jenjang: "S1-MIK",
          pendidikan_asdos: "Lulus S2 / Mahasiswa S3",
          amount: honorRefData.lulus_s2_mahasiswa_s3,
        },
        {
          jenjang: "S1-MIK",
          pendidikan_asdos: "TA Kontrak",
          amount: honorRefData.ta_kontrak,
        },
      ],
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/honor/`, {
      method: 'POST',
      headers: headerData,
      body: JSON.stringify(honorRefDataToSend),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        window.location.href = `/honor-ref-list/`;
        toast.success('Honor Ref Berhasil Ditambahkan', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .catch((error) => {
        console.error('Kesalahan:', error);
        alert('Terjadi kesalahan saat menyimpan data');
        toast.error('Honor ref belum berhasil ditambahkan', {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  };

  return (
    <Fragment>
      <div className={'view'}>
        <Navbar />
        <a
          href="/honor-ref-list"
          className={'back medium-m8'}
          onClick={() => {
            window.history.back();
            return false;
          }}
        >
          ← Kembali ke Daftar Honor Ref
        </a>
        <div>
          <div className={'frame-container2 tw-mb-8'}>
            <form className={'body-container-group1'}>
              <div className={"header-h4 center"}>Form Penambahan Honor Ref</div>
              <div className={'table-container-group2 container-shadow'}>
                <table className={"tw-w-full tw-table-auto"}>
                  <thead>
                    <tr>
                      <th className={"header-h6"}>Penetapan Rate Berdasarkan Tingkat Pendidikan</th>
                    </tr>
                  </thead>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Mahasiswa S1
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        label={'Keterangan'}
                        name={'mahasiswa_s1'}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        placeholder="Masukkan Keterangan"
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Lulus S1 / Mahasiswa S2
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'lulus_s1_mahasiswa_s2'}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        placeholder="Masukkan Keterangan"
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      Lulus S2 / Mahasiswa S3
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'lulus_s2_mahasiswa_s3'}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        placeholder="Masukkan Keterangan"
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px' }}>
                      TA Kontrak
                    </td>
                    <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
                      <p style={{ marginRight: '5px' }}>Rp.</p>
                      <NumInput
                        name={'ta_kontrak'}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                        placeholder="Masukkan Keterangan"
                      />
                      <p style={{ marginLeft: '5px' }}>,-</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={"header-h6 "} style={{ fontWeight: 'bold' }}>Profil Honor Ref</td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', width: '400px', height: '50px' }}>
                    <td style={{ width: '75px' }}>
                      <div className={"medium-m8"}>
                        Nama
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className={"inputonerow-container"}
                        name={'name'}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                      />
                    </td>
                  </tr>
                  <tr style={{ display: 'flex', alignItems: 'center', width: '400px', height: '50px' }}>
                    <td style={{ width: '75px' }}>
                      <div className={"medium-m8"}>
                        Periode
                      </div>
                    </td>
                    <td>
                      <input
                        type="text"
                        className={"inputonerow-container"}
                        name={'periode'}
                        onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                      />
                    </td>
                  </tr>

                </table>
                <div
                  className="button-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingLeft: 0,
                    paddingTop: 0,
                  }}>
                  <Button
                    text={"Simpan"}
                    onClick={handleSave}
                    background={"#4D8AFF"}
                    color={"#FFFFFF"}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AddHonorRef;
