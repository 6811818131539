import { Fragment, useState, useEffect, useMemo } from 'react';
import Navbar from "../../../Component/GroupOne/Navbar";
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/LogCategory.scss';
import Button from '../../../Component/GroupOne/Buttons/LongButton';
import SingleSelect from "../../../Component/SingleSelect";
import Dropdown from '../../../Component/Dropdown';
import { toast, ToastContainer } from "react-toastify";
import { default as PopUpModal } from "../../../Component/PopUpModal";

const MelihatKategoriLog = () => {
  const { REACT_APP_BASE_URL: apiURL } = process.env
  const [categories, setCategory] = useState([]);
  const [inputText, setInputText] = useState('');
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [updateCategoryName, setUpdateCategoryName] = useState('');
  const [updateCategoryDescription, setUpdateCategoryDescription] = useState('');
  const [updateCategoryJenjang, setUpdateCategoryJenjang] = useState('');
  const [inputDescription, setInputDescription] = useState('');
  const [inputJenjang, setInputJenjang] = useState('');
  const [selectedJenjang, setSelectedJenjang] = useState();
  const [idLogCategory, setID] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const token = sessionStorage.getItem("jwtToken");
  // const username = sessionStorage.getItem("username");
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);
  const jenjangOptions = [
    {
      label: "S1-MIK",
      value: "S1-MIK",
    },
    {
      label: "MTI",
      value: "MTI",
    }
  ];
  const jenjang = ["S1-MIK", "MTI"];

  useEffect(() => {
    fetch(`${apiURL}/log/category/`, {
      method: 'GET',
      headers: headerData
    })
      .then(response => response.json())
      .then(data => setCategory(data))
      .catch(error => console.error('Error fetching data:', error));
  }, [apiURL, headerData]);

  const handleUpdateCategoryForm = (categoryName, categoryDescription, categoryJenjang, id) => {
    setIsUpdateMode(true);
    setUpdateCategoryName(categoryName);
    setUpdateCategoryDescription(categoryDescription);
    setUpdateCategoryJenjang({
      value: categoryJenjang,
      label: categoryJenjang,
    });
    setID(id)
  }

  const fetchCategory = async () => {
    try {
      const response = await fetch(`${apiURL}/log/category/`, {
        method: 'GET',
        headers: headerData
      })
      const data = await response.json()
      setCategory(data)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const handleUpdate = async () => {
    const postData = {
      name: updateCategoryName,
      description: updateCategoryDescription,
      jenjang: updateCategoryJenjang.label,
    };

    try {
      const response = await fetch(`${apiURL}/log/category/${idLogCategory}`, {
        method: 'PUT',
        headers: headerData,
        body: JSON.stringify(postData),
      })
      if (!response.ok) {
        toast.error("Gagal Update Kategori", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return
      }
      await response.json()
      setInputText('');
      setInputDescription('');
      setInputJenjang('');
      setIsUpdateMode(false);
      toast.success("Kategori Berhasil Diupdate", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Kesalahan:', error);
    }
    fetchCategory()
  };


  const handleButtonClick = async () => {
    const postData = {
      name: inputText,
      description: inputDescription,
      jenjang: inputJenjang.label,
    };

    try {
      const response = await fetch(`${apiURL}/log/category/`, {
        method: 'POST',
        headers: headerData,
        body: JSON.stringify(postData),
      })
      if (!response.ok) {
        toast.error("Gagal Tambah Kategori", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return
      }
      await response.json()
      setInputText('');
      setInputDescription('');
      setInputJenjang('');
      toast.success("Kategori Berhasil Ditambah", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Kesalahan:', error);
    }
    fetchCategory()
  };

  const deleteData = (id) => {
    setShowConfirmModal(true);
  };

  const handleDelete = async () => {
    try {
      const response = await fetch(`${apiURL}/log/category/${idLogCategory}/delete`, {
        method: 'DELETE',
        headers: headerData
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      fetchCategory()
      setIsUpdateMode(false);

    } catch (error) {
      console.error('Kesalahan:', error);
    }
  };

  const kategoriComponent = useMemo(() => {
    return <>
      {categories.filter((item) => {
        return ((
          (!selectedJenjang || selectedJenjang === "-" || item.jenjang === selectedJenjang))
        );
      }).map(category => (
        <div className={"kategori-card2"} key={category.id}>
          <div className={"text-message2"} style={{ cursor: 'pointer' }} onClick={() => handleUpdateCategoryForm(category.name, category.description, category.jenjang, category.id)}>
            <div className={"medium-m8"}>{category.name}</div>
          </div>
        </div>
      ))}
    </>
  }, [selectedJenjang, categories]);


  return (
    <Fragment>
      <div className={"view"}>
        <Navbar name="Admin" />
        <div className={"content"}>
          <div className={"body-container"}>
            <div className={"left-column"}>
              <div className={"profile2"}>
                <div className={"header-h4"}>Kategori Log</div>
              </div>
              <div className={"divider"}></div>
              <div className={"category-container"}>
                <div className={"left-align-table"}>
                  <div style={{
                    alignSelf: "start",
                    marginLeft: "-20px",
                    marginBottom: "20px"
                  }}>
                    <Dropdown
                      label={"Jenjang"}
                      name={"jenjang"}
                      value={selectedJenjang}
                      onChange={(e) => setSelectedJenjang(e.target.value)}
                      options={jenjang}
                      aria-label="Jenjang"
                    />
                  </div>
                  <div className={"kategori-container container-shadow"}>
                    <div className={"kategori-card"}>
                      <div className={"center"}>Kategori</div>
                    </div>
                    <div style={{ overflow: 'auto', maxHeight: '440px' }}>
                      {kategoriComponent}
                    </div>
                  </div>
                </div>
                <div className={"left-align-table"}>

                  {isUpdateMode ? (
                    <div className={"right-column container-shadow"}>
                      <div className={"header-h6 center"}>Update Kategori</div>
                      <br></br>
                      <div className={"medium-m8"} style={{ marginBottom: '10px' }}>
                        <label htmlFor="LogCategoryName">Nama</label>
                      </div>
                      <input
                        type="text"
                        className={"input-category-title-text"}
                        name="LogCategoryName"
                        onChange={(e) => setUpdateCategoryName(e.target.value)}
                        placeholder="Masukkan kategori"
                        value={updateCategoryName}
                      />
                      <div className={"medium-m8"} style={{ marginBottom: '10px' }}>
                        <label htmlFor="LogCategoryJenjang">Jenjang</label>
                      </div>
                      <SingleSelect
                        value={updateCategoryJenjang}
                        options={jenjangOptions}
                        onChange={(jenjang) => setUpdateCategoryJenjang(jenjang)}
                      />
                      <div className={"medium-m8"} style={{ marginBottom: '10px', marginTop: '15px' }}>
                        <label htmlFor="LogCategoryDescription">Deskripsi</label>
                      </div>
                      <textarea
                        className={"input-category-description-text"}
                        name="LogCategoryDescription"
                        onChange={(e) => setUpdateCategoryDescription(e.target.value)}
                        placeholder="Masukkan deskripsi"
                        value={updateCategoryDescription}
                      />
                      <div className={'container-center'}>
                        <Button
                          onClick={handleUpdate}
                          background="#4D8AFF"
                          color="#fff"
                          text="Update"
                        />
                        <Button
                          onClick={deleteData}
                          background="#FF0000"
                          color="#fff"
                          text="Delete"
                        />
                        <Button
                          onClick={() => setIsUpdateMode(false)}
                          background="#bbbbbb"
                          color="#fff"
                          text="Cancel"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={"right-column container-shadow"}>
                      <div className={"header-h6 center"}>Tambah Kategori</div>
                      <br></br>
                      <div className={"medium-m8"} style={{ marginBottom: '10px' }}>
                        <label htmlFor="LogCategoryName">Nama</label>
                      </div>
                      <input
                        type="text"
                        className={"input-category-title-text"}
                        name="LogCategoryName"
                        onChange={(e) => setInputText(e.target.value)}
                        placeholder="Masukkan kategori"
                        value={inputText}
                      />
                      <div className={"medium-m8"} style={{ marginBottom: '10px' }}>
                        <label htmlFor="LogCategoryJenjang">Jenjang</label>
                      </div>
                      <SingleSelect
                        value={inputJenjang}
                        options={jenjangOptions}
                        onChange={(jenjang) => setInputJenjang(jenjang)}
                      />
                      <div className={"medium-m8"} style={{ marginBottom: '10px', marginTop: '15px' }}>
                        <label htmlFor="LogCategoryDescription">Deskripsi</label>
                      </div>
                      <textarea
                        className={"input-category-description-text"}
                        name="LogCategoryDescription"
                        onChange={(e) => setInputDescription(e.target.value)}
                        placeholder="Masukkan deskripsi"
                        value={inputDescription}
                      />
                      <div className={'container-center'}>
                        <Button
                          onClick={handleButtonClick}
                          background="#4D8AFF"
                          color="#fff"
                          text="Tambah"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showConfirmModal && (
        <PopUpModal
          onConfirm={handleDelete}
          onCancel={() => {
            setShowConfirmModal(false)
          }}
        />
      )}
      <ToastContainer />
    </Fragment >
  );
};

export default MelihatKategoriLog;

