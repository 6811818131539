import '../../../assets/group1/scss/Table/TableAsdosHonor.scss';
import '../../../assets/group1/scss/_variables.scss';
import { useState, useEffect } from 'react';
import HonorAssistant from "../Honor/HonorAssistant";
import HonorService from '../../../Services/HonorService';

const TableAsdosHonor = ({ items, content, user }) => {
  const [item, setItem] = useState();
  const headerItems = items;
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (content.length !== 0) {
      const uniqueHonorRefs = Array.from(new Set(content.map(entry => entry.honor_reference)));
      var counter = 0
      uniqueHonorRefs.forEach(async (honor) => {
        await HonorService.getHonor(honor)
          .then((response) => {
            counter += 1
            /* istanbul ignore next */
            if (counter === uniqueHonorRefs.length) {
              setIsLoading(false)
            } else {
              setIsLoading(true)
            }
            setItem((prevItem) => {
              return {
                ...prevItem,
                [response.data.pk]: response.data,
              }
            });
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });
      });
    }
  }, [content]);

  return (
    <div className={"table-container-group2 container-shadow tw-pt-6"}>
      <table className="tw-w-full tw-table-auto">
        <thead>
          <tr className={"table-header-group2"}>
            {headerItems.map((item, index) => {
              let styleName;
              if (index === 0) {
                styleName = "tw-px-4 tw-py-4 tw-text-white tw-rounded-l-xl";
              } else if (index === headerItems.length - 1) {
                styleName = "tw-px-4 tw-py-4 tw-text-white tw-rounded-r-xl";
              } else {
                styleName = "tw-px-4 tw-py-4 tw-text-white";
              }

              return (
                <th key={index} className={styleName}>
                  <div className="">{item}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!isLoading && content && <HonorAssistant content={content} item={item} user={user} />}
        </tbody>
      </table>
    </div>
  );
}

export default TableAsdosHonor;