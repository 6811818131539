import { Fragment, useState } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import Button from '../../../Component/GroupOne/Buttons/Button';
import Filter from '../../../Component/GroupOne/Filter';
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/HonorPembayaran.scss';
import { eachYearOfInterval, format } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import HistoriPembayaranTable from '../../../Component/GroupOne/Honor/HistoriPembayaranTable';
import HonorService from '../../../Services/HonorService';
import { useNavigate } from "react-router-dom";

const HonorPembayaran = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [tahun, setTahun] = useState("Tahun");
  const [month, setMonth] = useState("Bulan");
  const [bank, setBank] = useState("Bank");
  const [jenjang, setJenjang] = useState("Jenjang");
  const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();

  const fetchData = async (tahun, month, bank, jenjang) => {
    try {
      const response = await HonorService.getHistoriPembayaran(tahun, month, bank, jenjang)
      setData(response.data);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleTahunChange = (tahun) => {
    setTahun(tahun)
  };

  const handleBulanChange = (bulan) => {
    setMonth(bulan)
  }

  const handleBankChange = (bank) => {
    setBank(bank)
  }

  const handleJenjangChange = (jenjang) => {
    setJenjang(jenjang)
  }

  const handleUbahButtonClick = () => {
    navigate('/ubah-nama-histori-pembayaran')
  }

  const handleButtonClick = () => {
    if (tahun === "Tahun" || month === "Bulan" || bank === "Bank" || jenjang === "Jenjang") {
      toast.error("Tahun, bulan, bank, dan jenjang harus dipilih terlebih dahulu", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    fetchData(tahun, month, bank, jenjang)
  }

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content-group1"}>
          <div className={"header-h5"}>Histori Pembayaran</div>
          <div style={{ display: "flex" }}>
            <Button text={"Ubah Nama yang Menyetujui/Mengajukan"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleUbahButtonClick} />
          </div>
          <div className={"divider"}></div>
          <div className={"filters-container"}>
            <Filter text={"Tahun"} items={["Tahun", ...years]} onFilterChange={handleTahunChange} />
            <Filter text={"Bulan"} items={["Bulan", "Januari", "Februari", "Maret", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]} onFilterChange={handleBulanChange} />
            <Filter text={"Bank"} items={["Bank", "Semua Bank", "BNI", "Non-BNI"]} onFilterChange={handleBankChange} />
            <Filter text={"Jenjang"} items={["Jenjang", "S1-MIK", "MTI"]} onFilterChange={handleJenjangChange} />
            <Button text={"Lihat Laporan"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleButtonClick} />
          </div>

          {data ?
            <HistoriPembayaranTable
              items={['No', 'Bulan, Tahun', 'Bank', 'Tanggal Rekap', 'Batch', 'Sub', 'Rekap Honor', 'Rekap Info Bank']}
              historiList={data.result_pembayaran}
              total={data.total}
            /> : ""}
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default HonorPembayaran;