  import "../assets/scss/Table/Table.scss";
  import "../assets/scss/_variables.scss";
  import "../App.scss";
  import { useState } from "react";
  import Pagination from "./Pagination";
  import "../assets/scss/Pagination.scss";

  const TableRow = ({ rowData, index}) => {
  
    return (
      <tr>
        <td className="tw-text-center" width="10px">{index + 1}</td>
        <td className="tw-text-center tw-p-2">
          {rowData.mata_kuliah}
        </td>
        <td className="tw-text-center tw-p-2">
          {rowData.asisten}
        </td>
        <td className="tw-text-center tw-p-2">
          {rowData.npm}
        </td>
        <td className="tw-text-center tw-p-2">
          {rowData.email}
        </td>
      </tr>
    );
  };

  const LamaranTableDiterimaAdmin = ({ items, lamaranditerimalist}) => {
    const headerItems = items[0];

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = lamaranditerimalist.slice(
      indexOfFirstRecord,
      indexOfLastRecord,
    );
    const nPages = Math.ceil(lamaranditerimalist.length / recordsPerPage);



    if (lamaranditerimalist && lamaranditerimalist.length > 0) {
      return (
        <div>
        <div className={"header-h4"} style={{ marginBottom: '20px', marginLeft:'20px' }}>Sudah diterima di lowongan lain</div>
        <div className={"table-container-group2 container-shadow tw-pt-6"}>

          <table className={"tw-w-full tw-table-auto"}>
            <thead>
              <tr className={"table-header-group2"}>
                {headerItems.map((item, index) => {
                  let styleName;
                  if (index === 0) {
                    styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                  } else if (index === headerItems.length - 1) {
                    styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                  } else {
                    styleName = "tw-px-4 tw-py-2 tw-text-white";
                  }

                  return (
                    <th key={index} className={styleName}>
                      <div className="">{item}</div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {currentRecords.length > 0 &&
                currentRecords.map((rowData, index) => (
                  <TableRow
                    key={index}
                    index={index}
                    rowData={rowData}
                  />
                ))}
            </tbody>
          </table>
          <Pagination
            nPages={nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
        </div>
      );
    } else {
      return (
        <div className={"table-container container-shadow tw-pt-6"}>
          <h2>Belum ada lamaran yang diterima</h2>
        </div>
      );
    }
  };

  export default LamaranTableDiterimaAdmin;
