import { useState, useEffect, useMemo } from "react";
import ProfileMahasiswaComponent from "../../Component/ProfileMahasiswa";
import Navbar from "../../Component/Navbar";
import ProfileService from "../../Services/ProfileService";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../../Services/AuthService";
import { useNavigate } from "react-router-dom";

const ProfileMahasiswa = () => {
  const navigate = useNavigate();
  const [isdisable, setIsDisable] = useState(true);
  const handleDownloadCV = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const headerData = new Headers();
      headerData.append('Content-type', 'application/json');
      headerData.append("Authorization", "Bearer " + token);
      
      const applicantProfileData = await ProfileService.getProfile(profileId);

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}${applicantProfileData.data.data[0]["cv"]}?forcedownload=1`,
        {
          headers: headerData
        }
      );

      if (!response.ok) {
        setIsDisable(false);
        toast.error("Mahasiswa tidak mengupload file CV", {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      })
        return;
      }

      const filename = applicantProfileData.data.data[0]["cv"].split("/").pop();

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();

      window.URL.revokeObjectURL(url);

      toast.success('CV berhasil diunduh', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      console.error('CV belum berhasil diunduh:', error);
      toast.error('CV belum berhasil diunduh', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };
  
  
  const pengalamanOptions = [
    { value: "Junior", label: "Junior" },
    { value: "Menengah", label: "Menengah" },
    { value: "Senior", label: "Senior" },
  ];

  const [informasiDasar, setInformasiDasar] = useState({
    nama: "",
    npm: "",
    pengalaman: "Junior",
  });

  const handlePengalamanOnChange = (selected) => {
    setInformasiDasar({
      ...informasiDasar,
      pengalaman: selected,
    });
  };

  const username = sessionStorage.getItem("username");
  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const id = pathParts[pathParts.length - 2];
  const id2 = pathParts[pathParts.length - 1];
  var profileId = "";
  if (/^\d+$/.test(id)) {
    profileId = id;
  } else {
    profileId = 1;
  }
  var lowonganId = "";
  if (/^\d+$/.test(id2)) {
    lowonganId = id2;
  } else {
    lowonganId = 1;
  }

  const [profileData, setProfileData] = useState();
  const [riwayatAkademis, setRiwayatAkademis] = useState();

  const fetchProfile = async () => {
    try {
      const response = await ProfileService.getProfile(profileId);
      setProfileData(response.data.data[0]);
    } catch (error) {
      console.error("Failed to fetch lowongan:", error);
    }
  };

  const fetchRiwayatAkademis = async () => {
    try {
      const response = await ProfileService.getRiwayatAkademis(profileId,lowonganId)
      setRiwayatAkademis(response.data.data)
    } catch (error) {
      console.error("Failed to fetch riwayat akademis:", error);
    }
  }

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchRiwayatAkademis();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [isAbleSubmit, setIsAbleSubmit] = useState(false);

  useEffect(() => {
  const isAble = informasiDasar["pengalaman"]["value"];
    setIsAbleSubmit(isAble ? true : false);
  }, [informasiDasar]);

  const handleSubmit = () => {
    let data = {"pk": profileId, "pengalaman":informasiDasar["pengalaman"]["value"]}

    if (isAbleSubmit) {
      AuthService.upadtePengalaman(data)
        .then((response) => {
          toast.success("Pengalaman berhasil di update", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        })
        .catch((error) => {
          console.error("Failed to update profile:", error);
          toast.error("Ada masalah pada server kami", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const fetchedProfile = useMemo(() => {
    if (profileData) {
      return profileData;
    }
    return [];
  }, [profileData]);

  const fetchedRiwayat = useMemo(() => {
    if (riwayatAkademis) {
      return riwayatAkademis;
    }
    return [];
  }, [riwayatAkademis]);

  const completeProfile = useMemo(() => {
    if (fetchedProfile && fetchedRiwayat) {
      return {...fetchedProfile, ...fetchedRiwayat};
    }
    return {}
  }, [fetchedProfile, fetchedRiwayat])


  let itemProfileComponent;
  if (Object.keys(completeProfile).length !== 0) {
    itemProfileComponent = 
    <ProfileMahasiswaComponent 
    props={completeProfile} 
    pengalamanOptions={pengalamanOptions}
    handleSubmit={handleSubmit}
    handlePengalamanOnChange={handlePengalamanOnChange}
    informasiDasar={informasiDasar}
    handleDownloadCV={handleDownloadCV}
    hide={isdisable}
    />;
  } else {
    itemProfileComponent = <div>Loading...</div>;
  }


  return (
    
      <div className={"view"}>
        <Navbar name={username} />
        <div className={"back medium-m8"} style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
            ← Kembali
            </div>
        <div className={"content"}>{itemProfileComponent}
        </div>
        <ToastContainer />                
      </div>
     
  );
};

export default ProfileMahasiswa;
