import '../../../assets/group1/scss/Table/TableAdmin.scss';
import '../../../assets/group1/scss/_variables.scss';

const LogBelumKlaim = (props) => {
    const monthNames = [
        'Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni',
        'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'
    ];
    return (
        <>
            {props.content.map((item, index) => (
                <tr key={index}>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {index + 1}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {item.lowongan}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {item.mahasiswa}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4" style={{width: '12%'}}>
                        {Math.floor(item["total_duration"] / 60)} hour(s) {(item["total_duration"] % 60) === 0 ? "" : `${item["total_duration"] % 60} minute(s)`}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {item.honor_kotor.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {item.pph21.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {item.honor_bersih.toLocaleString('id-ID', { style: 'currency', currency: 'IDR' })}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {/* Add your logic to format the month and year */}
                        {monthNames[item.bulan-1]}, {item.tahun}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {item.bank}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4" style={{width: '20%'}}>
                        {item.atas_nama}
                    </td>
                    <td data-testid={`log-entry`} className="tw-text-center tw-p-4">
                        {item.no_rek}
                    </td>
                </tr>
            ))}

        </>
    );
};

export default LogBelumKlaim
