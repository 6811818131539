import { Fragment, useEffect, useState, useMemo } from "react";
import Navbar from "../../../Component/GroupOne/Navbar";
import "../../../assets/group1/scss/_variables.scss";
import "../../../assets/group1/scss/Card/DashboardCard.scss";
import Filter3 from "../../../Component/GroupOne/Filter3";
import "../../../assets/group1/scss/Log.scss";
import mark from "../../../assets/group1/icons/mark.svg";
import TableAdmin from "../../../Component/GroupOne/TableAdmin";
import Button from "../../../Component/GroupOne/Buttons/Button";
import { eachYearOfInterval, format } from "date-fns";
import LogService from "../../../Services/LogService";
import SearchSelect2 from '../../../Component/GroupOne/SearchSelect2';
import { toast, ToastContainer } from "react-toastify";

const AdminLogsBelumDisetujui = () => {
  const filterData = JSON.parse(sessionStorage.getItem('BELUM_DISETUJUI_FILTER'))
  const [selectedBulan, setSelectedBulan] = useState((filterData && filterData.bulan) || "");
  const [selectedTahun, setSelectedTahun] = useState((filterData && filterData.tahun) || "");
  const [selectedMatkul, setSelectedMatkul] = useState((filterData && filterData.matkul) || "");
  const [bulanSelected, setBulanSelected] = useState("");
  const [tahunSelected, setTahunSelected] = useState("");
  const [matkulSelected, setMatkulSelected] = useState("");
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [courseOptions, setCourseOptions] = useState([]);
  const [content, setContent] = useState([]);

  const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();

  const bulanOptions = [
    "Bulan",
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ]

  const fetchAllLowongan = async () => {
    try {
      const response = await LogService.getAllLowonganLogBelumDilaporkan(
        "true",
      );
      const courseOptions = response.data.map(([matkul, matkul_lengkap]) => ({
        label: matkul_lengkap,
        value: matkul,
      }));
      setCourseOptions(courseOptions);
    } catch (error) {
      console.error("Failed to fetch lowongan name:", error);
    }
  }

  useEffect(() => {
    fetchAllLowongan()
  }, []);

  const tableComponent = useMemo(() => {
    return <TableAdmin
      items={[
        "Matakuliah",
        "Nama",
        "Term",
        "Tanggal",
        "Jam",
        "Deskripsi Tugas",
        "Status",
        "Tandai",
        "Operasi",
      ]}
      user={"admin"} content={content}
    />
  }, [content])

  const handleBulanChange = (bulan) => {
    setSelectedBulan(bulan);
  };

  const handleTahunChange = (tahun) => {
    setSelectedTahun(tahun);
  };


  const handleMatkulChange = (matkul) => {
    setSelectedMatkul(matkul);
  };

  useEffect(() => {
    if (selectedMatkul === "Mata Kuliah" || selectedMatkul === ""
      || selectedTahun === "Tahun" || selectedTahun === ""
      || selectedBulan === "0" || selectedBulan === ""
    ) {
      return
    }
    handleButtonClick()
  }, [selectedMatkul])

  const handleButtonClick = async () => {
    if (selectedMatkul === "Mata Kuliah" || selectedMatkul === ""
      || selectedTahun === "Tahun" || selectedTahun === ""
      || selectedBulan === "0" || selectedBulan === ""
    ) {
      toast.error("Mata kuliah, tahun, dan bulan harus dipilih terlebih dahulu", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }

    try {
      const response = await LogService.getLogBelumDilaporkan(
        selectedTahun,
        selectedBulan,
        selectedMatkul
      );
      setContent(response.data)
    } catch (error) {
      console.error("Failed to fetch lowongan name:", error);
    }
    const filterDataNow = { matkul: selectedMatkul, tahun: selectedTahun, bulan: selectedBulan }
    sessionStorage.setItem('BELUM_DISETUJUI_FILTER', JSON.stringify(filterDataNow))
    setMatkulSelected(selectedMatkul)
    setTahunSelected(selectedTahun)
    setBulanSelected(selectedBulan)
    setIsButtonPressed(true)
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content-group1"}>
          <div className={"body-container-group1"}>
            <div className={"logs-container"} style={{ gap: '40px' }}>
              <div className={"header-h5"}>Logs Belum Disetujui</div>
              <div className={"divider"}></div>
              <div className={"filters-container-perbulan"}>
                <SearchSelect2
                  onChange={handleMatkulChange}
                  placeholder='Mata Kuliah'
                  options={courseOptions}
                  required={true}
                  optionFilterProp={'label'}
                  width={'300px'}
                  value={selectedMatkul || undefined}
                />
                <Filter3
                  text={"Tahun"}
                  items={["Tahun", ...years]}
                  onFilterChange={handleTahunChange}
                  value={selectedTahun || undefined}
                />
                <Filter3
                  text={"Bulan"}
                  items={bulanOptions}
                  onFilterChange={handleBulanChange}
                  value={selectedBulan || undefined}
                />
                <Button
                  text={"Lihat Laporan"}
                  background={"#4D8AFF"}
                  color={"#FFFFFF"}
                  onClick={handleButtonClick}
                />
              </div>
              {content.length !== 0 ? tableComponent : isButtonPressed ?
                <>
                  <div className={"no-content"}>
                    <img
                      src={mark}
                      className={"pointer enlarged"}
                      alt="fail"
                    />
                    {`Tidak ada log ${matkulSelected} yang belum disetujui pada: ${bulanOptions[bulanSelected]} ${tahunSelected}`}
                  </div>
                </> : ""}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default AdminLogsBelumDisetujui;

