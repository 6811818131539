import { appServerAxios } from "./http-common";

class LogService {
  getLogBelumDilaporkan(tahun, bulan, matkul) {
    return appServerAxios.get("/log/dilaporkan", {
      params: {
        tahun: tahun,
        bulan: bulan,
        matkul: matkul,
      },
    })
  }

  getAllLowonganLogBelumDilaporkan(is_filter) {
    return appServerAxios.get("/log/dilaporkan", {
      params: {
        is_filter: is_filter,
      },
    })
  }

  getLogMonthly(tahun, bulan, jenjang) {
    return appServerAxios.get("/log/get/month", {
      params: {
        tahun: tahun,
        bulan: bulan,
        jenjang: jenjang
      },
    })
  }

  getLowonganList(tahun_ajaran, semester) {
    return appServerAxios.get("/log/lowongan", {
      params: {
        tahun_ajaran: tahun_ajaran,
        semester: semester,
      },
    });
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LogService();
