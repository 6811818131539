import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Component/Navbar";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/CreateLowongan.scss";
import GeneralService from "../../Services/GeneralService";
import LowonganService from "../../Services/LowonganService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiSelect from "../../Component/MultiSelect";
import SingleSelect from "../../Component/SingleSelect";
import NumInput from "../../Component/NumInput";
import TextInput from "../../Component/TextInput";
import Spinner from "../../Component/Spinner";

const LowonganUpdatePage = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const id = pathParts[pathParts.length - 1];
  var lowonganid = id;

  // tahun ajaran
  const [tahunAjaranSelected, setTahunAjaranSelected] = useState();

  // jenjang
  const [jenjangSelected, setJenjangSelected] = useState();
  const KODE_JENJANG_MAPPING = {
    6: "S1 - Sarjana",
    8: "S2 - Magister",
    9: "S3 - Doktor"
  }

  // semester
  const [semesterSelected, setSemesterSelected] = useState();
  const [prodiSelected, setProdiSelected] = useState();
  const KODE_KURIKULUM_TO_PRODI_MAPPING = {
    "01.00.12.01": "S1 Reguler - Ilmu Komputer",
    "02.00.12.01": "S1 KKI - Ilmu Komputer",
    "03.00.12.01": "S2 - Ilmu Komputer (MIK)",
    "04.00.12.01": "S2 - Teknologi Informasi (MTI)",
    "05.00.12.01": "S3 - Ilmu Komputer",
    "06.00.12.01": "S1 Reguler - Sistem Informasi",
    "07.00.12.01": "S1 Ekstensi - Sistem Informasi",
    "08.00.12.01": "S1 Paralel - Sistem Informasi",
    "09.00.12.01": "S1 Paralel - Ilmu Komputer",
  }

  // matakuliah
  const [matakuliahOptions, setMatakuliahOptions] = useState([]);
  const [matakuliahSelected, setMatakuliahSelected] = useState();

  // status
  const statusOptions = [
    {
      value: "buka",
      label: "Buka",
    },
    {
      value: "tutup",
      label: "Tutup",
    },
  ];
  const [statusSelected, setStatusSelected] = useState();
  /* istanbul ignore next */
  const handleStatusOnChange = (selected) => {
    setStatusSelected(selected);
  };

  // jumlah asisten
  const [jumlahAsisten, setJumlahAsisten] = useState(1);
  /* istanbul ignore next */
  const handleJumlahAsistenOnChange = (event) => {
    setJumlahAsisten(event.target.value);
  };

  // pengajar
  const [pengajarOptions, setPengajarOptions] = useState([]);
  const [pengajarSelected, setPengajarSelected] = useState();
  /* istanbul ignore next */
  const handlePengajarOnChange = (selected) => {
    setPengajarSelected(selected);
  };

  // matakuliah prasyarat
  const [matakuliahPrasyarat, setMatakuliahPrasyarat] = React.useState([]);
  const [isPrasyaratSiak, setIsPrasyaratSiak] = useState(false);
  const [isPrasyaratSiakLoading, setIsPrasyaratSiakLoading] = useState(false);
  const fetchPrasyarat = async (kode_matakuliah, kode_kurikulum) => {
    try {
      const response = await GeneralService.getMatakuliahPrasyarat(
        kode_matakuliah,
        kode_kurikulum
      );
      const results = response.data.data;
      const newMatkulPrasyarat = [];
      for (const result of results) {
        const value = {
          kode_matakuliah: result.kd_mk,
          kode_kurikulum: result.kd_kur,
          nama_matakuliah: result.nm_mk,
        };
        const label = result.kd_mk + " - " + result.kd_kur + " " + result.nm_mk;
        newMatkulPrasyarat.push({ value: value, label: label });
      }
      setMatakuliahPrasyarat(newMatkulPrasyarat);
      setIsPrasyaratSiakLoading(false)
      return newMatkulPrasyarat;
    } catch (error) {
      console.error("Failed to fetch matakuliah prasyarat:", error);
    }
  };
  const handleCheckPrasyarat = async (e) => {
    setIsPrasyaratSiak(e.target.checked);
    if (e.target.checked) {
      setIsPrasyaratSiakLoading(true);
      const response = await fetchPrasyarat(
        matakuliahSelected["value"]["kode_matakuliah"],
        matakuliahSelected["value"]["kode_kurikulum"]);
      setMatakuliahPrasyarat(response);
    }
  };
  /* istanbul ignore next */
  const handleMatakuliahPrasyaratOnChange = (selectedOptions) => {
    setMatakuliahPrasyarat(selectedOptions);
  };

  // syarat tambahan
  const [syaratTambahan, setSyaratTambahan] = useState("");
  /* istanbul ignore next */
  const handleSyaratTambahanOnChange = (event) => {
    setSyaratTambahan(event.target.value);
  };

  const fetchLowonganDetails = async () => {
    try {
      const response = await LowonganService.getLowonganById(lowonganid);
      const results = response.data.data;

      const jenjang = KODE_JENJANG_MAPPING[results.kode_matkul[4]];
      const prodi = KODE_KURIKULUM_TO_PRODI_MAPPING[results.kode_kurikulum.split('-')[0]]

      setTahunAjaranSelected({
        value: results.tahun_ajaran,
        label: results.tahun_ajaran,
      });
      setJenjangSelected({
        value: jenjang,
        label: jenjang,
      });
      setProdiSelected({
        value: prodi,
        label: prodi,
      })
      setSemesterSelected({
        value: results.semester,
        label:
          results.semester.charAt(0).toUpperCase() +
          results.semester.slice(1),
      });
      setMatakuliahSelected({
        value: {
          kode_matakuliah: results.kode_matkul,
          kode_kurikulum: results.kode_kurikulum,
          nama_matakuliah: results.nama_matkul,
        },
        label: `${results.kode_matkul} ${results.kode_kurikulum} ${results.nama_matkul}`,
      });
      setStatusSelected({
        value: results.status,
        label:
          results.status.charAt(0).toUpperCase() + results.status.slice(1),
      });
      setJumlahAsisten(results.jumlah_asisten);
      setPengajarSelected(
        results.lowongan_pembuka.map((item) => {
          return {
            value: item,
            label: item.name,
          };
        })
      );
      setMatakuliahPrasyarat(
        results.prerequisite.map((item) => {
          return {
            value: {
              kode_matakuliah: item.kode_matkul,
              kode_kurikulum: item.kode_kurikulum,
              nama_matakuliah: item.nama_matkul,
            },
            label: `${item.kode_matkul} ${item.kode_kurikulum} ${item.nama_matkul}`,
          };
        })
      );
      setSyaratTambahan(results.syarat_tambahan);
    } catch (error) {
      console.error("Failed to fetch lowongan details:", error);
    }
  };

  useEffect(() => {
    fetchLowonganDetails();
    fetchDosenList();
  }, [lowonganid]);

  const fetchDosenList = async () => {
    try {
      const response = await GeneralService.getDosen();
      const results = response.data.data;
      const newDosenOptions = [];

      for (const result of results) {
        newDosenOptions.push({ value: result, label: result["name"] });
      }
      setPengajarOptions(newDosenOptions);
    } catch (error) {
      console.error("Failed to fetch dosen lists:", error);
    }
  };
  
  const fetchMataKuliah = async () => {
    if (tahunAjaranSelected && semesterSelected) {
      try {
        const tahunAjaran = tahunAjaranSelected["value"].split("/")[0];
        let semester;
        if (semesterSelected["value"] === "ganjil") {
          semester = 1;
        } else if (semesterSelected["value"] === "genap") {
          semester = 2;
        } else {
          semester = 3;
        }
        const jenjang = jenjangSelected["value"].substring(0, 2).toLowerCase()

        const response = await GeneralService.getKelasListPerTermJen(
          tahunAjaran,
          semester,
          jenjang
        );
        const results = response.data.data;

        const matkul = [];
        const uniqueMatkul = new Set();

        for (const result of results) {
          const matakuliahInfo = result.nm_mk_cl;
          const value = {
            kode_matakuliah: matakuliahInfo.kd_mk,
            kode_kurikulum: matakuliahInfo.kd_kur,
            nama_matakuliah: matakuliahInfo.nm_mk,
          };
          const label =
            matakuliahInfo.kd_mk +
            " - " +
            matakuliahInfo.kd_kur +
            " " +
            matakuliahInfo.nm_mk;

          if (!uniqueMatkul.has(label)) {
            matkul.push({ value: value, label: label });
            uniqueMatkul.add(label);
          }
        }
        setMatakuliahOptions(matkul);
      } catch (error) {
        console.error("Failed to fetch kelas lists:", error);
      }
    }
  };

  useEffect(() => {
    fetchMataKuliah();
  }, [jenjangSelected, semesterSelected, tahunAjaranSelected]);

  const handleSubmit = (e) => {
    const nama_matkul = matakuliahSelected["value"]["nama_matakuliah"];
    const kode_matkul = matakuliahSelected["value"]["kode_matakuliah"];
    const kode_kurikulum = matakuliahSelected["value"]["kode_kurikulum"];
    const tahun_ajaran = tahunAjaranSelected["value"];
    const semester = semesterSelected["value"].toLowerCase();
    const jumlah_asisten = parseInt(jumlahAsisten);
    const status = statusSelected["value"].toLowerCase();
    const lowongan_pembuka = pengajarSelected.map((pengajar) => ({
      username: pengajar.value.username,
    }));
    const syarat_tambahan = syaratTambahan;
    const prerequisites = matakuliahPrasyarat.map((prasyarat) => ({
      nama_matkul: prasyarat.value.nama_matakuliah,
      kode_matkul: prasyarat.value.kode_matakuliah,
      kode_kurikulum: prasyarat.value.kode_kurikulum,
    }));

    const requestBody = {
      nama_matkul,
      kode_matkul,
      kode_kurikulum,
      tahun_ajaran,
      semester,
      jumlah_asisten,
      status,
      lowongan_pembuka,
      syarat_tambahan,
      prerequisites,
    };

    if (
      matakuliahSelected &&
      tahunAjaranSelected &&
      semesterSelected &&
      jumlahAsisten > 0 &&
      pengajarSelected &&
      pengajarSelected.length > 0
    ) {
      LowonganService.updateLowongan(lowonganid, requestBody)
        .then((response) => {
            /* istanbul ignore next */
          toast.success("Lowongan Berhasil Diedit", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/lowongan");
            },
          });
        })
        .catch((error) => {
          console.error("Gagal Edit Lowongan:", error);
          toast.error("Gagal Edit Lowongan", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <a href="/lowongan" className={"back medium-m8"}>
          ← Kembali
        </a>
        <div className={"content"}>
          <form className={"body-container"} onSubmit={handleSubmit}>
            <h2>Edit Lowongan</h2>
            <div className={"card-form-container container-shadow"}>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Tahun Ajaran</div>
                <SingleSelect
                  value={tahunAjaranSelected}
                  isDisabled={true}
                />
              </div>

              {/* <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Jenjang</div>
                <SingleSelect
                  value={jenjangSelected}
                  isDisabled={true}
                />
              </div> */}

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Prodi</div>
                <SingleSelect
                  value={prodiSelected}
                  isDisabled={true}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Semester</div>
                <SingleSelect
                  value={semesterSelected}
                  isDisabled={true}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Matakuliah</div>
                <SingleSelect
                  value={matakuliahSelected}
                  isDisabled={true}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Status</div>
                <SingleSelect
                  value={statusSelected}
                  options={statusOptions}
                  onChange={handleStatusOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Jumlah Asisten</div>
                <NumInput
                  min={1}
                  value={jumlahAsisten}
                  onChange={handleJumlahAsistenOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Pengajar</div>
                <MultiSelect
                  value={pengajarSelected}
                  options={pengajarOptions}
                  onChange={handlePengajarOnChange}
                  data_testid={'Dosen'}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Prasyarat</div>
                <div className="tw-flex tw-flex-row tw-gap-4">
                  <label>
                    <input
                      type="checkbox"
                      name="prasyaratSiakng"
                      checked={isPrasyaratSiak}
                      onChange={handleCheckPrasyarat}
                    />
                    Prasyarat Siakng
                  </label>
                  <Spinner size={20} isLoading={isPrasyaratSiakLoading} />
                </div>

                <MultiSelect
                  value={matakuliahPrasyarat}
                  options={matakuliahOptions}
                  onChange={handleMatakuliahPrasyaratOnChange}
                  data_testid='Mata Kuliah'
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Syarat Tambahan</div>
                <TextInput
                  label={"Syarat Tambahan"}
                  name={"syaratTambahan"}
                  value={syaratTambahan}
                  onChange={handleSyaratTambahanOnChange}
                />
              </div>

              <div
                className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
              >
                <Button
                  data-testid={"Simpan"}
                  text={"Simpan"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleSubmit}
                  isDisabled={
                    !(
                      matakuliahSelected &&
                      tahunAjaranSelected &&
                      semesterSelected &&
                      jumlahAsisten > 0 &&
                      pengajarSelected &&
                      pengajarSelected.length > 0
                    )
                  }
                  role='button'
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default LowonganUpdatePage;
