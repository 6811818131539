import "../assets/scss/TextBox.scss";
const TextInput = (props) => {
  const isDisabled = props.disabled || false;
  const { name, value, onChange, placeholder} = props;
  return (
    <textarea
      className={"TextBox-container"}
      id={name}
      name={name}
      value={value}
      onChange={onChange}
      disabled={isDisabled}
      placeholder={placeholder}
    ></textarea>
  );
};

export default TextInput;