import { Fragment, useState, useMemo, useEffect } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import '../../../assets/group1/scss/_variables.scss'
import HonorService from '../../../Services/HonorService';
import { useParams, useLocation } from 'react-router-dom';
import NumInput from '../../../Component/GroupOne/NumInput';


const DetailHonorRateMTI = () => {
  const { id } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const jenjangAsdos = searchParams.get("jenjang")
  const pengalamanAsdos = searchParams.get("pengalaman")

  const [categories, setCategories] = useState([]);
  const [honorRates, setHonorRates] = useState([]);

  const fetchCategory = async () => {
    try {
      const response = await HonorService.getCategory();
      setCategories(response.data.filter((category) => {
        return category.jenjang === "MTI"
      }))
    } catch (error) {
      console.error("Failed to fetch category:", error);
    }
  };

  const fetchHonorRate = async () => {
    try {
      const response = await HonorService.getHonorByJenjangAndPengalaman(id, jenjangAsdos, pengalamanAsdos);
      setHonorRates(response.data.list_honor)
    } catch (error) {
      console.error("Failed to fetch honor rate:", error);
    }
  };

  useEffect(() => {
    fetchHonorRate();
    fetchCategory();
  }, []);

  const categoriesInputComponent = useMemo(() => {
    if (honorRates.length === 0) {
      return
    }
    return <>{categories.map((category, i) => (
      <tr key={category.id} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <td className="tw-text-base tw-font-medium sm:medium-m7" style={{ marginRight: '10px', width: '250px' }}>
          {category.name}
        </td>
        <td style={{ display: 'flex', alignItems: 'center', width: '165px', marginRight: "15px" }}>
          <p style={{ marginRight: '5px' }}>Rp.</p>
          <NumInput
            name={'On-Site'}
            placeholder="Masukkan nominal"
            required={true}
            value={honorRates.filter((honorRate) => {
              return honorRate.kategori === category.id && honorRate.location === 'On-site'
            })[0]?.amount || 0}
            disabled={true}
          />
          <p style={{ marginLeft: '5px' }}>,-</p>
        </td>
        <td style={{ display: 'flex', alignItems: 'center', width: '165px' }}>
          <p style={{ marginRight: '5px' }}>Rp.</p>
          <NumInput
            name={'Off-Site'}
            placeholder="Masukkan nominal"
            required={true}
            value={honorRates.filter((honorRate) => {
              return honorRate.kategori === category.id && honorRate.location === 'Off-site'
            })[0]?.amount || 0}
            disabled={true}
          />
          <p style={{ marginLeft: '5px' }}>,-</p>
        </td>
      </tr>
    ))}</>
  }, [categories, honorRates])

  return (
    <Fragment>
      <div className={'view'}>
        <Navbar />
        <a href={`/honor-rate-list/${id}`} className={"back medium-m8"}>
          ← Kembali ke Daftar Honor Rate
        </a>
        <div>
          <div className={"frame-container tw-mb-8"}>
            <h2 className={"header-h4 center"}>Honor Rate per Jam</h2>
            <div className={'table-container-group2 container-shadow'}>
              <div style={{ alignItems: 'left' }} className={"tw-w-full tw-table-auto"}>
                <h4 className="tw-text-base tw-font-medium sm:medium-m6">Jenjang: {jenjangAsdos}</h4>
                <h4 className="tw-text-base tw-font-medium sm:medium-m6">Pengalaman: {pengalamanAsdos}</h4>
              </div>
              <table className={"tw-w-full tw-table-auto"}>
                <thead>
                  <tr style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <th style={{ display: 'flex', alignItems: 'center', marginRight: '10px', width: '250px' }}>
                      Kategori
                    </th>
                    <th style={{ width: '165px', marginRight: "15px" }}>
                      On-Site
                    </th>
                    <th style={{ width: '165px' }}>
                      Off-Site
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {categoriesInputComponent}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailHonorRateMTI;
