import Filter3 from "../../../Component/GroupOne/Filter3";
import Button from "../../../Component/GroupOne/Buttons/Button";
import { Fragment, useEffect, useState, useMemo } from "react";
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import Table from "../../../Component/GroupOne/Table";
import Profile from "../../../Component/GroupOne/Profile";
import Navbar from "../../../Component/GroupOne/Navbar";
import { useParams } from "react-router-dom";
import { eachYearOfInterval, format } from "date-fns";
import profpic from "../../../assets/images/profpic-placeholder.png";
import ProfileService from "../../../Services/ProfileService";
import SearchSelect2 from '../../../Component/GroupOne/SearchSelect2';

const DosenViewLogPerAsisten = () => {
    const filterData = JSON.parse(sessionStorage.getItem('DOSEN_VIEW_FILTER'))
    const { matkul_id, asdos_username } = useParams();
    const [selectedBulan, setSelectedBulan] = useState((filterData && asdos_username === filterData.username && filterData.bulan) || "");
    const [selectedStatus, setSelectedStatus] = useState((filterData && asdos_username === filterData.username && filterData.status) || "")
    const [selectedTahun, setSelectedTahun] = useState((filterData && asdos_username === filterData.username && filterData.tahun) || "")
    const [selectedMatkul, setSelectedMatkul] = useState((filterData && asdos_username === filterData.username && filterData.matkul) || "")
    const [courseOptions, setCourseOptions] = useState([]);
    const [content, setContent] = useState([]);
    const [filterContent, setFilterContent] = useState([]);
    const [userData, setUserData] = useState('');
    const [id, setId] = useState('');
    const token = sessionStorage.getItem("jwtToken");
    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);
    const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();

    useEffect(() => {
        // Fetch the list of courses from the API
        fetch(`${process.env.REACT_APP_BASE_URL}/log/asisten/${asdos_username}/`, {
            method: 'GET',
            headers: headerData, // Add the headers to the request
        })
            .then((response) => response.json())
            .then((data) => {
                // Extract the unique course names from the API response
                const uniqueCourses = [...new Set(data.logs.map((item) => item.lowongan.nama))];
                // Map course names to options format
                const courseOptions = uniqueCourses.map((course) => ({ label: course, value: course }));
                setCourseOptions(courseOptions);
                const newData = data.logs.sort((a, b) => {
                    const dateA = new Date(a.datetime);
                    const dateB = new Date(b.datetime);
                    return dateB - dateA;
                })
                setContent(newData)
                setFilterContent(newData)
                setId(data.id)
                fetchProfile(data.id)
            })
            .catch((error) => {
                console.error('Error fetching courses:', error);
            });
    }, [asdos_username, matkul_id, headerData]);

    useEffect(() => {
        if (selectedBulan !== '' || selectedTahun !== '' || selectedStatus !== '' || selectedMatkul !== '') {
            handleButtonClick();
        }
    }, [content]);

    const fetchProfile = async (id) => {
        try {
            const response = await ProfileService.getProfile(id)
            let data = response.data.data[0]
            if (data.foto_profil) {
                data.foto_profil = process.env.REACT_APP_BACKEND_URL + data.foto_profil
            } else {
                data.foto_profil = profpic
            }
            setUserData(data)
        }
        catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const handleBulanChange = (bulan) => {
        setSelectedBulan(bulan);
    };

    const handleTahunChange = (tahun) => {
        setSelectedTahun(tahun);
    };

    const handleStatusChange = (status) => {
        setSelectedStatus(status);
    };

    const handleMatkulChange = (matkul) => {
        setSelectedMatkul(matkul);
    };

    const handleButtonClick = () => {
        let filterContent = content;
        let filterDataNow = {}
        if (selectedBulan && selectedBulan !== "0") {
            filterContent = filterContent.filter((item) => {
                const itemMonth = new Date(item.datetime).getMonth() + 1;
                return itemMonth.toString() === selectedBulan;
            })
            filterDataNow = { ...filterDataNow, bulan: selectedBulan }
        }

        if (selectedStatus && selectedStatus !== "Status") {
            filterContent = filterContent.filter((item) => {
                return item.status === selectedStatus
            })
            filterDataNow = { ...filterDataNow, status: selectedStatus }
        }

        if (selectedTahun && selectedTahun !== "Tahun") {
            filterContent = filterContent.filter((item) => {
                const itemYear = new Date(item.datetime).getFullYear();
                return itemYear.toString() === selectedTahun;
            })
            filterDataNow = { ...filterDataNow, tahun: selectedTahun }
        }

        if (selectedMatkul) {
            filterContent = filterContent.filter((a) => a.lowongan.nama === selectedMatkul)
            filterDataNow = { ...filterDataNow, matkul: selectedMatkul }
        }

        if (!selectedBulan && !selectedStatus && !selectedTahun && !selectedMatkul) {
            filterContent = content
        }
        setFilterContent(filterContent)
        filterDataNow = { ...filterDataNow, username: asdos_username }
        sessionStorage.setItem('DOSEN_VIEW_FILTER', JSON.stringify(filterDataNow))
    };

    let profileComponent;
    if (userData.length !== 0) {
        profileComponent = <Profile props={userData} id={id} />
    } else {
        profileComponent = <div>Loading...</div>
    }

    return (
        <Fragment>
            <div className={"view"}>
                <Navbar />
                <a href={`/matkul/${matkul_id}/log`} className={"medium-m8"}>
                    ← Kembali
                </a>
                <div className={"content"}>
                    {profileComponent}
                    <div className={"body-container-group1"}>
                        <div className={"logs-container"}>
                            <div className={"header-h5"}>Sejarah Log</div>
                            <div className={"filters-container"}>
                                <SearchSelect2
                                    onChange={handleMatkulChange}
                                    placeholder='Mata Kuliah'
                                    options={courseOptions}
                                    required={true}
                                    optionFilterProp={'label'}
                                    width={'250px'}
                                    value={selectedMatkul || undefined}
                                />
                                <Filter3 text={"Tahun"} items={["Tahun", ...years]} onFilterChange={handleTahunChange} value={selectedTahun || undefined} />
                                <Filter3 text={"Bulan"} items={["Bulan", "Januari", "Februari", "Maret", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]} onFilterChange={handleBulanChange} value={selectedBulan || undefined} />
                                <Filter3 text={"Status"} items={["Status", "Dilaporkan", "Ditolak", "Telah Disetujui", "Diproses"]} onFilterChange={handleStatusChange} value={selectedStatus || undefined} />
                                <Button text={"Lihat Laporan"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleButtonClick} />
                            </div>
                            <Table items={["Tanggal Log", "Durasi", "Mata Kuliah", "Kategori", "Deskripsi",
                                "Tandai", "Status", "Operasi"]}
                                content={filterContent} user={"dosen"} />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default DosenViewLogPerAsisten;
