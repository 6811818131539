import {Fragment, useCallback, useEffect, useState} from "react";
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Login.scss';
import logo from "../../../assets/group1/images/logo.svg";
import { css } from "@emotion/react";
import Button from "../../../Component/GroupOne/Buttons/LongButton";
import { ClipLoader } from "react-spinners";

const LoginPage = ({ setUserRole }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const flag = sessionStorage.getItem("flag")

    const handleLogin = useCallback( async () => {
        setIsLoading(true);

        const requestBody = {
            username: username,
            password: password,
        };

        // Validate username and password before proceeding with the API call
        if (!username || !password) {
            setErrorMessage("Masukkan username dan password anda");
            setIsLoading(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/auth/login/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(requestBody),
            });

            if (response.ok) {
                setErrorMessage("Login berhasil!");
                const responseData = await response.json();
                sessionStorage.setItem('jwtToken', responseData.data.token);
                sessionStorage.setItem('role', responseData.data.role);
                sessionStorage.setItem('username', responseData.data.username);
                sessionStorage.setItem('name', responseData.data.nama);
                sessionStorage.setItem("jenjangPendidikan", responseData.data.jenjang_pendidikan);
                sessionStorage.setItem("pengalaman", responseData.data.pengalaman);

                const route = responseData.data.role === "mahasiswa" ? `/`
                    : responseData.data.role === "admin" ? 'admin/dashboard' 
                        : `/`;
                setUserRole(responseData.data.role);
                window.location.href = route;
            } else {
                setErrorMessage("Username atau password salah");
                console.error("Login failed:", "Incorrect username and password");
            }
        } catch (error) {
            setErrorMessage("An error occurred while logging in.");
            console.error("An error occurred while logging in:", error);
        } finally {
        setIsLoading(false);

    }
    }, [password, username, setUserRole]);

    useEffect(() => {
        const keyDownHandler = event => {

            if (event.key === 'Enter') {
                event.preventDefault();

                handleLogin().catch(error => {
                    setErrorMessage("An error occurred while logging in.");
                });
            }
        };

        document.addEventListener('keydown', keyDownHandler);
        if (flag === "false") {
            setErrorMessage("Sesi anda sudah habis");
        }
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };

    }, [flag, handleLogin]);

    const override = css`
        display: block;
        margin: 0 auto;
    `;

    return (
        <Fragment>
            <div className={"view"}>
                <div className={"login-form-container"}>
                    <a className={"login-title header-h6"} href="/">
                        <img src={logo} className={"tw-h-28 tw-w-42 md:tw-h-max md:tw-w-56"} alt="Fasilkom UI"/>
                    </a>
                    <div className={"login-form"}>
                        <div className={"semibold-h4"}>
                            Masuk ke akun anda
                        </div>
                        <div className={"login-input-container"}>
                            <div className={"medium-m8"}>
                                Username
                            </div>
                            <input
                                type="text"
                                className={"login-input"}
                                placeholder="Enter your username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className={"login-input-container"}>
                            <div className={"medium-m8"}>
                                Password
                            </div>
                            <input
                                type="password"
                                className={"login-input"}
                                placeholder="Enter your password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        {isLoading ? (
                            <ClipLoader color={"#4D8AFF"} loading={isLoading} css={override} size={30} />
                        ) : (
                            errorMessage && <div className={`medium-m9 ${errorMessage === "Login berhasil!" ? "success-message" : "error-message"}`}>{errorMessage}</div>
                        )}
                        <Button text={"Masuk"} onClick={handleLogin} color={"#FFFFFF"} custom={"No"}/>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default LoginPage;
