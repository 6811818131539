import '../../../assets/group1/scss/Table/Table.scss';
import '../../../assets/group1/scss/_variables.scss';
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState, useMemo } from "react";
import TableButtons from '../Buttons/TablesButton';


const LogsDosen = (props) => {
    const [selectedItems, setSelectedItems] = useState([])
    const { parameter } = useParams();
    const currentPathname = window.location.pathname;
    const [content, setContent] = useState([]);
    let [searchParams] = useSearchParams();
    const statusFilter = searchParams.get("status");
    const tahunFilter = searchParams.get("tahun");
    const bulanFilter = searchParams.get("bulan");
    const matkulFilter = searchParams.get("matkul");
    const token = sessionStorage.getItem("jwtToken");
    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);

    function formatTime(timeString) {
        const time = new Date(`2000-01-01T${timeString}`);
        const hours = time.getHours().toString().padStart(2, '0');
        const minutes = time.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    function checkboxHandler(e) {
        let isSelected = e.target.checked;
        let value = parseInt(e.target.value);
        if (isSelected) {
            setSelectedItems([...selectedItems, value]);
        } else {
            setSelectedItems((prevData) => {
                return prevData.filter((id) => id !== value);
            });
        }

    }


    useEffect(() => {
        if (props.onSelectedItemsChange) {
            props.onSelectedItemsChange(selectedItems);
        }

        if (statusFilter) {
            const filteredContent = props.content.filter((item) => item.status === statusFilter);
            setContent(filteredContent);
        } else if (matkulFilter) {
            const filteredContent = props.content.filter((item) => item.lowongan.nama === matkulFilter);
            setContent(filteredContent);
        } else if (bulanFilter) {
            const filteredData = props.content.filter((item) => {
                // Extract the month from the "datetime" property
                const itemMonth = new Date(item.datetime).getMonth() + 1; // Months are 0-indexed, so add 1

                // Compare the extracted month with the bulanParam
                return itemMonth.toString() === bulanFilter;
            });
            setContent(filteredData);
        } else if (tahunFilter) {
            const filteredData = props.content.filter((item) => {
                // Extract the year from the "datetime" property
                const itemYear = new Date(item.datetime).getFullYear();

                // Compare the extracted year with the tahunParam
                return itemYear.toString() === tahunFilter;
            });
            setContent(filteredData);
        } else {
            setContent(props.content);
        }
    }, [currentPathname, parameter, props, props.content, bulanFilter, statusFilter, tahunFilter, matkulFilter, selectedItems, headerData]);
    return (

        content ? (
            content.map((item, index) => (
                <tr key={index}>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        <div>
                            {new Date(item["datetime"]).toLocaleDateString('en-GB', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </div>
                        <div>
                            {formatTime(item["start_time"])} - {formatTime(item["end_time"])}
                        </div>
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        {Math.floor(item["duration"] / 60)} hour(s) {(item["duration"] % 60) === 0 ? "" : `${item["duration"] % 60} minute(s)`}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        {item["lowongan"]["nama"]}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        {item["category"]}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{width: '20%'}}>
                        {item["description"]}
                    </td>
                    {(props.jenjang === "MTI") ?
                        <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                            {item["location"]}
                        </td> : ""}
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        <input type="checkbox" checked={selectedItems.includes(item.id)} value={item.id} onChange={checkboxHandler} />
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        {item["status"]}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        {item["status"] !== "Diproses" ? (
                            <TableButtons logId={item["id"]} matkul_id={item["lowongan"]["id"]} />
                        ) : null}
                    </td>
                </tr>
            ))
        ) : (
            <div>
                Empty
            </div>
        )
    );
}

export default LogsDosen;

