import { Fragment, useState, useEffect, useMemo } from "react";
import Navbar from "../../Component/Navbar";
import Dropdown from "../../Component/Dropdown";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import "../../App.scss";
import LowonganService from "../../Services/LowonganService";
import { toast, ToastContainer } from "react-toastify";
import SearchInput from "../../Component/SearchInput";
import LamaranDiterimaTable from "../../Component/LamaranDiterimaTable";
import LamaranService from "../../Services/LamaranService";
import StatistikLamaranDiterimaTable from "../../Component/StatistikLamaranDiterimaTable";
import { eachYearOfInterval, format } from "date-fns";
import Filter from "../../Component/GroupOne/Filter";


const LamaranDiterimaPage = () => {
  const [Terms, setTerms] = useState();
  const [selectedBulan, setSelectedBulan] = useState('');
  const [selectedTahun, setSelectedTahun] = useState('');
  const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();

  const fetchTerm = async () => {
    try {
      const response = await LowonganService.getTerm();
      setTerms(response.data.data);
      setTahunAjaran(response.data.data.tahun_ajaran);
      setSemester(response.data.data.semester);
    } catch (error) {
      console.error("Failed to fetch term:", error);
    }
  };

  useEffect(() => {
    fetchTerm()
  }, []);

  const Options = useMemo(() => {
    if (Terms && Terms.length > 0) {
      return Terms.map(
        (term) =>
          `${term.tahun_ajaran} ${term.semester.charAt(0).toUpperCase() + term.semester.slice(1)
          } `,
      );
    }
    return [];
  }, [Terms]);

  const [selectedTerm, setSelectedTerm] = useState("");
  const [tahun_ajaran, setTahunAjaran] = useState();
  const [semester, setSemester] = useState();
  const [statisticsJenjang, setStatisticsJenjang] = useState();
  const [statisticsPengalaman, setStatisticsPengalaman] = useState();

  useEffect(() => {
    if (Options.length > 0 && !selectedTerm) {
      setSelectedTerm(Options[0]);
    }
  }, [Options, selectedTerm]);

  const [lamaranList, setLamaranList] = useState([]);
  const [isDelete, setIsDelete] = useState(false);

  const handleBulanChange = (bulan) => {
    setSelectedBulan(bulan);
  };

  const handleTahunChange = (tahun) => {
    setSelectedTahun(tahun);
  };

  const fetchLamaranList = async () => {
    try {
      const response = await LamaranService.getAsistenDiterima(
        semester,
        tahun_ajaran,
      );
      setStatisticsJenjang(response.data.data.statistics_jenjang)
      setStatisticsPengalaman(response.data.data.statistics_pengalaman)
      setLamaranList(response.data.data.diterima);
    } catch (error) {
      console.error("Failed to fetch lamaran:", error);
    }
  };

  useEffect(() => {
    if (semester !== undefined) {
      fetchLamaranList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [semester]);

  const [search, setSearch] = useState('');

  const handleSearch = (event) => {
    setSearch(event.target.value)
  };

  let lamaranTableComponent;

  lamaranTableComponent = (
    <LamaranDiterimaTable
      items={[
        [
          "No",
          "Matakuliah",
          "Semester / Tahun Ajaran",
          "Nama Asisten",
          "Email",
          "Telepon",
          "Profil",
        ],
      ]}
      lamaranList={lamaranList.filter((item) => {
        return search.toLowerCase() === '' ? item : item.nama_matkul.toLowerCase().includes(search.toLowerCase()) ||
          search.toLowerCase() === '' ? item : item.nama_asisten.toLowerCase().includes(search.toLowerCase())
      })}
      isDelete={isDelete}
      setIsDelete={setIsDelete}
    />
  );

  let statisticsTableComponent = (
    <StatistikLamaranDiterimaTable
      items={[
        [
          "Status",
          "Jumlah",
          "Pengalaman",
          "Jumlah",
        ],
      ]}
      isNotEmpty={lamaranList && lamaranList.length > 0}
      statisticsJenjang={statisticsJenjang}
      statisticsPengalaman={statisticsPengalaman}
    />
  );

  useEffect(() => {
    if (selectedTerm) {
      const newTahunAjaran = selectedTerm.split(" ")[0];
      const newSemester = selectedTerm.split(" ")[1].toLowerCase();
      setTahunAjaran(newTahunAjaran);
      setSemester(newSemester);
    }
  }, [Options, selectedTerm]);



  const handleDropdownChange = (event) => {
    if (event.target.value === "-") {
      const newSelectedTerm = Options[0];
      setSelectedTerm(newSelectedTerm);
    } else {
      setSelectedTerm(event.target.value);
    }
  };

  const handleOnClickFilter = () => {
    fetchLamaranList();
  };

  const handleDownload = async () => {
    if (!selectedTahun || selectedTahun === "Tahun" || !selectedBulan || selectedBulan === "0") {
      toast.error("Tahun dan Bulan harus dipilih terlebih dahulu", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }
    const startDate = new Date(selectedTahun, selectedBulan - 1, 11);
    const nextMonth = (selectedBulan % 12) + 1;
    const nextYear = selectedBulan === 12 ? selectedTahun + 1 : selectedTahun;
    const endDate = new Date(nextYear, nextMonth - 1, 10);
    const startDateString = startDate.toISOString().split('T')[0]
    const endDateString =  endDate.toISOString().split('T')[0]

    LamaranService.getDownloadAsistenDiterima(
      startDateString,
      endDateString,
    ).then((response) => {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      const fileName = `sk_asisten_${startDateString}_${endDateString}.xlsx`;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      toast.success('SK berhasil diunduh', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }).catch((error) => {
      console.error("Failed to download data:", error);

      toast.error("Error ketika mengunduh data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    });
  }

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content"}>
          <div className={"lowongan-container tw-mb-8"}>
            <div className={"header-h4"}>Daftar Asisten Diterima</div>
            <div
              className={
                "tw-flex tw-justify-between tw-items-end tw-gap-2 sm:tw-gap-4"
              }
            >
              <div className={"tw-flex tw-justify-start tw-items-end tw-gap-4 tw-flex-wrap"}>
                <Dropdown
                  label={"Term"}
                  name={"term"}
                  value={selectedTerm}
                  onChange={handleDropdownChange}
                  options={Options}
                />

                <Button
                  text={"Lihat"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleOnClickFilter}
                />
              </div>
            </div>
            <div className={"divider"}></div>
            <div className={"header-h5"}>Statistik</div>
            {statisticsTableComponent}
            <div className={"header-h5"}>Cetak SK</div>
            <div
              className={
                "tw-flex tw-justify-between tw-items-end tw-gap-2 sm:tw-gap-4"
              }
            >
              <div className={"filters-container-perbulan"}>
                <Filter text={"Bulan"} items={["Bulan", "Januari", "Februari", "Maret", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]} onFilterChange={handleBulanChange} />
                <Filter text={"Tahun"} items={["Tahun", ...years]} onFilterChange={handleTahunChange} />
                <Button
                  text={"Download"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleDownload}
                />
              </div>
            </div>
            <div className={"divider"}></div>
            <div
              className={
                "tw-flex tw-justify-between tw-items-end tw-gap-2 sm:tw-gap-4"
              }
            >
              <div className={"tw-flex tw-justify-start tw-items-end tw-gap-4 tw-flex-wrap"}>
                <label htmlFor="search">
                  <SearchInput
                    name={"search"}
                    value={search}
                    onChange={handleSearch}
                  />
                </label>
              </div>
            </div>
            {lamaranTableComponent}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default LamaranDiterimaPage;