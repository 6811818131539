/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from "react";
import Navbar from "../../Component/Navbar";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/CreateLowongan.scss";
import LowonganService from "../../Services/LowonganService";
import GeneralService from "../../Services/GeneralService";
import LamaranService from "../../Services/LamaranService";
import { useNavigate } from "react-router-dom";

const DetailLowonganPage = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const id = pathParts[pathParts.length - 1];
  var lowonganid = "";
  if (/^\d+$/.test(id)) {
    lowonganid = id;
  } else {
    lowonganid = 1;
  }

  const [namaLowongan, setNamaLowongan] = useState();
  const [syaratTambahan, setSyaratTambahan] = useState();
  const [ipk, setIpk] = useState();
  const [sksDiambil, setSksDiambil] = useState();
  const [namaAsisten, setNamaAsisten] = useState("");
  const [statusLamaran, setStatusLamaran] = useState("");
  const [nilaiMahasiswaData, setNilaiMahasiswaData] = useState();

  useEffect(() => {
    const fetchLowonganData = async () => {
      try {
        const response = await LowonganService.getLowonganById(lowonganid);
        const data = response.data.data;
        setNamaLowongan(data.nama_lowongan);
        setSyaratTambahan(data.syarat_tambahan);
        const namaAsistenFromsessionStorage = sessionStorage.getItem("nama");
        setNamaAsisten(namaAsistenFromsessionStorage);
      } catch (error) {
        console.error("Failed to fetch lowongan data:", error);
      }
    };
    fetchLowonganData();
  }, [lowonganid]);

  useEffect(() => {
    const fetchLamaranByID = async () => {
      try {
        const response = await LamaranService.getLamaranDetails(lowonganid);
        const data = response.data.data;
        setIpk(data.ipk);
        setSksDiambil(data.sks_diambil);
        setStatusLamaran(data.status);

        const namaAsistenFromsessionStorage = sessionStorage.getItem("nama");
        setNamaAsisten(namaAsistenFromsessionStorage);
      } catch (error) {
        console.error("Failed to fetch lowongan data:", error);
      }
    };
    fetchLamaranByID();
  }, [lowonganid]);

  useEffect(() => {
    const fetchNilaiMahasiswaData = async (username, lowonganid) => {
      try {
        const response = await GeneralService.getNilaiMahasiswa(
          username,
          lowonganid
        );
        const data = response.data.data;
        setNilaiMahasiswaData(data);
      } catch (error) {
        console.error("Failed to fetch nilai mahasiswa data:", error);
      }
    };

    const username = sessionStorage.getItem("username");
    fetchNilaiMahasiswaData(username, lowonganid);
  }, [lowonganid]);

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"back medium-m8"} style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
            ← Kembali
            </div>
        <div className={"content"}>
          <form className={"body-container"}>
            <h2>Detail Lowongan</h2>
            <div className={"card-form-container container-shadow"}>
              <>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Lowongan</div>
                  <div className="body-p7">{namaLowongan}</div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Nama Asisten</div>
                  <div className="body-p7">{namaAsisten}</div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">IPK</div>
                  <div className="body-p7">{ipk}</div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">SKS Diambil</div>
                  <div className="body-p7">{sksDiambil}</div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Status Lamaran</div>
                  <div className="body-p7">{statusLamaran}</div>
                </div>
              </>

              {nilaiMahasiswaData ? (
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Nilai Matakuliah Prasyarat</div>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "90%" }}></th>
                        <th style={{ width: "10%" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {nilaiMahasiswaData && nilaiMahasiswaData.prerequisites.map((item, index) => (
                        <tr key={index} className="odd:tw-bg-[#4d8aff] odd:tw-bg-opacity-20">
                          <td className="body-p8 tw-py-2 tw-px-4">
                            {item.tahun
                              ? `${item.kode_matkul} - ${item.nama_matkul} - Tahun ${item.tahun} Term ${item.term}`
                              : `${item.kode_matkul} - ${item.nama_matkul} - Belum Mengambil`}
                          </td>
                          <td className="body-p8">{item.nilai}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              <div className="tw-flex tw-flex-col tw-gap-2">
                {syaratTambahan && (
                  <>
                    <div className="header-h6">Syarat Tambahan</div>
                    <div className="body-p7">{syaratTambahan}</div>
                  </>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default DetailLowonganPage;
