/* eslint-disable-next-line no-unused-vars */
import { Fragment, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../../Component/GroupOne/Navbar';
import DatePicker from '../../../Component/GroupOne/DatePicker';
import TimePicker from '../../../Component/GroupOne/TimePicker';
import Button from '../../../Component/GroupOne/Buttons/Button';
import SearchSelect from '../../../Component/GroupOne/SearchSelect';
import InputBox from '../../../Component/GroupOne/InputBox';
import dayjs from 'dayjs';
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';


const UpdateLog = () => {
  const { matkul_id, jenjang, log_id } = useParams();
  const token = sessionStorage.getItem("jwtToken");
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);

  const [logData, setLogData] = useState({
    logCategory: [],
    categoryMap: {},
  });

  const [content, setContent] = useState([]);
  const locOptions = ['On-site', 'Off-site'];

  const getLogCategory = useCallback(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/log/category/`, {
      method: 'GET',
      headers: headerData
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        data = data.filter((category) => {
          return category.jenjang === jenjang
        })
        const categoryMap = data.reduce((map, category) => {
          map[category.name] = category.id;
          return map;
        }, {});
        setLogData((prevLogData) => ({ ...prevLogData, logCategory: data, categoryMap: categoryMap, }));
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [headerData]);

  const getLogData = useCallback(() => {

    fetch(`${process.env.REACT_APP_BASE_URL}/log/${log_id}/`, {
      method: 'GET',
      headers: headerData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setContent(data);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [log_id, headerData]);

  useEffect(() => {
    getLogCategory();
  }, [getLogCategory]);

  useEffect(() => {
    getLogData();
  }, [getLogData]);

  const handleChange = (name, value) => {
    setContent((content) => ({
      ...content,
      [name]: value
    }));
  };

  const handleSave = () => {
    if (!content["category"] || !content["description"]) {
      alert('Kategori Log dan Deskripsi harus diisi.');
      return;
    }

    content["category"] = typeof content["category"] === "string" ? logData.categoryMap[content["category"]] : content["category"];

    const logDataToSend = {
      category: content["category"],
      description: content["description"],
      ...(jenjang === "MTI" && { location: content["location"] }),
      jenjang_lowongan: jenjang,
      datetime: content["datetime"],
      start_time: content["start_time"],
      end_time: content["end_time"],
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/log/${log_id}/`, {
      method: 'PUT',
      headers: headerData,
      body: JSON.stringify(logDataToSend), // Convert logDataToSend to JSON
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        window.location.href = `/matkul/${matkul_id}/log`;
      })
      .catch((error) => {
        console.error('Kesalahan:', error);
      });
  };

  const showLocation = () => {
    if (jenjang === "MTI") {
      return <SearchSelect
        placeholder='Pilih Moda'
        options={locOptions.map(loc => ({ value: loc, label: loc }))}
        label={'Moda'}
        name={'location'}
        onChange={(value) => { handleChange('location', value) }}
        value={content["location"]}
      />
    }
  }

  return (
    <Fragment>
      <div className={'view'}>
        <Navbar />
        <div className={'content-group1'}>
          <a href={`/matkul/${matkul_id}/log`}
            className={'medium-m8'}
            onClick={() => {
              window.history.back();
              return false;
            }}>
            ← Kembali ke Daftar Log
          </a>
          <div className={'center-form-container'}>
            <form className={'body-container-group1'}>
              <div className={'card-form-container container-shadow'}>
                <SearchSelect
                  label={'Kategori Log'}
                  onChange={(value) => { handleChange('category', value) }}
                  placeholder='Pilih Kategori Log'
                  options={logData.logCategory.map(category => ({ value: category.id, label: category.name }))}
                  required={true}
                  optionFilterProp={'label'}
                  value={logData.categoryMap[content["category"]]}
                />
                <InputBox
                  label={'Deskripsi'}
                  name={'description'}
                  onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                  placeholder="Masukkan Deskripsi"
                  value={content["description"]}
                />
                {showLocation()}
                <DatePicker
                  label={'Tanggal'}
                  onChange={(date, dateString) => { handleChange('datetime', dateString) }}
                  placeholder={'Pilih Tanggal'}
                  format={'YYYY-MM-DD'}
                  disabledDate={(current) => {
                    return current > dayjs().endOf('day');
                  }}
                  value={dayjs(content["datetime"])}
                />
                <TimePicker
                  label={'Waktu Mulai'}
                  onChange={(time, timeString) => {
                    handleChange('start_time', timeString[0]);
                    handleChange('end_time', timeString[1])
                  }}
                  placeholder={['Waktu Mulai', 'Waktu Selesai']}
                  format={'HH:mm'}
                  value={[dayjs(content["start_time"], 'HH:mm'), dayjs(content["end_time"], 'HH:mm')]}
                />
                <Button text={"Simpan"}
                  onClick={handleSave}
                  background={"#4D8AFF"}
                  color={"#FFFFFF"}
                  margin={"0"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UpdateLog;
