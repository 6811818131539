import { appServerAxios } from "./http-common";

class SanksiService {
  createSanksi(data) {
    return appServerAxios.post("/sanksi/", data);
  }

  getSanksiDetail(sanksiId, pelanggaranId) {
    return appServerAxios.get(`/sanksi/${sanksiId}/${pelanggaranId}/`);
  }

  getAllSanksi() {
    return appServerAxios.get("/sanksi/");
  }
  
  updateSanksi(sanksiId, data) {
    return appServerAxios.put(`/sanksi/${sanksiId}/`, data);
  }

  deleteSanksi(pelanggaranId) {
    return appServerAxios.delete(`/sanksi/pelanggaran/${pelanggaranId}`);
  }
}

export default new SanksiService();
