import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom";
import "./index.scss";
import "@fortawesome/free-solid-svg-icons";
import "@fortawesome/react-fontawesome";

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();
