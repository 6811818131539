import "../assets/scss/Table/Table.scss";
import Button from "./Button";
import "../assets/scss/_variables.scss";
import "../App.scss";
import { useState, useEffect } from "react";
import Pagination from "./Pagination";
import "../assets/scss/Pagination.scss";
import { useNavigate } from "react-router-dom";
import ModalDaftarLowongan from "../Component/ModalDaftarLowongan";

export const REGEX_PRODI = [
  [/^.{1}1.{14}$/, 'S1 - Ilmu Komputer'],
  [/^.{1}6.{14}$/, 'S1 - Sistem Informasi'],
  [/^.{1}2.{14}$/, 'S1 KKI - Ilmu Komputer'],
  [/^.{1}3.{14}$/, 'S2 - Ilmu Komputer (MIK)'],
  [/^.{1}4.{14}$/, 'S2 - Teknologi Informasi (MTI)'],
  [/^.{1}5.{14}$/, 'S3 - Ilmu Komputer'],
];

const TableRow = ({ rowData, index, sanitizeDaftarButton }) => {
  let buttonText = "";
  if (rowData.lamaran_status === "direkomendasikan" || rowData.lamaran_status === "diterima" || rowData.lamaran_status === "melamar") {
    buttonText = "Detail";
  } else if (rowData.lamaran_status === "") {
    buttonText = "Daftar";
  }

  const formattedNames = Array.isArray(rowData.lowongan_pembuka)
    ? rowData.lowongan_pembuka.join(", ")
    : rowData.lowongan_pembuka;

  const getProdi = (kode_kurikulum) => {
    for (const [regex, prodi] of REGEX_PRODI) {
      if (new RegExp(regex).test(String(kode_kurikulum))) {
        return prodi
      }
    }
  }

  return (
    <tr>
      <td className="tw-text-center">{index + 1}</td>
      <td className="tw-text-center tw-p-2">{rowData.nama_lowongan}</td>
      <td className="tw-text-center tw-p-2">{formattedNames}</td>
      <td className="tw-text-center tw-capitalize">
        {rowData.semester} <br></br>
        {rowData.tahun_ajaran}
      </td>
      <td className="tw-text-center">{getProdi(rowData.kode_kurikulum)}</td>
      <td className="tw-text-center">{rowData.jumlah_asisten}</td>
      <td className="tw-text-center">{rowData.jumlah_pelamar}</td>
      <td className="tw-text-center">{rowData.jumlah_pelamar_diterima}</td>
      <td className="tw-text-center tw-capitalize">{rowData.status}</td>
      <td className="tw-text-center tw-capitalize">{rowData.lamaran_status}</td>
      <td className="tw-justify-center tw-gap-2 tw-text-xs tw-p-2">
        {(buttonText === "Detail" || buttonText === "Daftar") && rowData.status !== "tutup" && (
          <div className="tw-flex tw-gap-2">
            <Button
              text={buttonText}
              background={"#4D8AFF"}
              color={"#FFFFFF"}
              onClick={sanitizeDaftarButton}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

const LowonganTableMahasiswa = ({ items, lowonganList }) => {
  const headerItems = items[0];
  const isProfileUpdated = sessionStorage.getItem("isProfileUpdated");
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = lowonganList.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(lowonganList.length / recordsPerPage);
  const [showModal, setShowModal] = useState(false);

  const sanitizeDaftarButton = (rowData) => {
    const buttonUrl =
      rowData.lamaran_status === "melamar" || rowData.lamaran_status === "diterima"
        ? `/lowongan/detail/${rowData.id}`
        : `/lowongan/daftar/${rowData.id}`;
    if (isProfileUpdated === "false") {
      setShowModal(true);
    } else {
      navigate(buttonUrl);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      const isProfileUpdated = sessionStorage.getItem("isProfileUpdated");
      if (isProfileUpdated === "false") {
        setShowModal(true);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [lowonganList]);

  if (lowonganList && lowonganList.length > 0) {
    return (
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        {showModal && (
          <ModalDaftarLowongan
            onConfirm={() => navigate("/profile")}
            onCancel={() => setShowModal(false)}
          />
        )}
        <table className={"tw-w-full tw-table-auto"}>
          <thead className="">
            <tr className={"table-header-group2"}>
              {headerItems.map((item, index) => {
                let styleName;
                if (index === 0) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                } else if (index === headerItems.length - 1) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                } else {
                  styleName = "tw-px-4 tw-py-2 tw-text-white";
                }

                return (
                  <th key={index} className={styleName}>
                    <div className="">{item}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {lowonganList.length > 0 &&
              currentRecords.map((rowData, index) => (
                <TableRow key={index} index={index} rowData={rowData} sanitizeDaftarButton={() => sanitizeDaftarButton(rowData)} />
              ))}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  } else {
    return (
      <div className={"table-container container-shadow tw-pt-6"}>
        <h2>Tidak ada lowongan yang tersedia</h2>
      </div>
    );
  }
};

export default LowonganTableMahasiswa;
