import "../assets/scss/Buttons/Button.scss";
import Button from "./Button";

const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const maxButtons = 9;
    // const maxPagesToShow = Math.min(nPages, maxButtons);
    const halfMaxButtons = Math.floor(maxButtons / 2);
    let startPage, endPage;

    if (nPages <= maxButtons) {
      startPage = 1;
      endPage = nPages;
    } else if (currentPage <= halfMaxButtons) {
      startPage = 1;
      endPage = maxButtons;
    } else if (currentPage + halfMaxButtons >= nPages) {
      startPage = nPages - maxButtons + 1;
      endPage = nPages;
    } else {
      startPage = currentPage - halfMaxButtons;
      endPage = currentPage + halfMaxButtons;
    }

    return (
      <li style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', listStyle: 'none', gap: '5px' }}>
        {currentPage !== 1 && (
          <Button text={"Pertama"} onClick={() => goToPage(1)} color={"#FFFFFF"} size={"small-button"}>
            Pertama
          </Button>
        )}
        {currentPage !== 1 && (
          <Button text={"Kembali"} onClick={() => goToPage(currentPage - 1)} color={"#FFFFFF"} size={"small-button"}>
            Kembali
          </Button>
        )}
        {pageNumbers.slice(startPage - 1, endPage).map((number) => (
          <Button text={number} key={number} className={`button-container-group2 button-style tw-cursor-pointer tw-font-bold tw-flex tw-justify-center tw-text-center ${currentPage === number ? "active" : ""}`} onClick={() => goToPage(number)} color={"#FFFFFF"} size={"small-button"}>
            {number}
          </Button>
        ))}
        {currentPage !== nPages && (
          <Button text={"Selanjutnya"} onClick={() => goToPage(currentPage + 1)} color={"#FFFFFF"} size={"small-button"}>
            Berikut
          </Button>
        )}
        {currentPage !== nPages && (
          <Button text={"Terakhir"} onClick={() => goToPage(nPages)} color={"#FFFFFF"} size={"small-button"}>
            Terakhir
          </Button>
        )}
      </li>
    );
  };

  return (
    <nav style={{margin: 'auto'}}>
      <ul className="pagination">
        {renderPageNumbers()}
      </ul>
    </nav>
  );
};

export default Pagination;
