// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  width: 100%;
}

.header-h4 {
  font-size: 28px;
  font-weight: 700;
}

.header-h5 {
  font-size: 24px;
  font-weight: 700;
}

.header-h6 {
  font-size: 20px;
  font-weight: 700;
}

.header-h7 {
  font-size: 16px;
  font-weight: 700;
}

.header-h8 {
  font-size: 12px;
  font-weight: 700;
}

.medium-m8 {
  font-size: 12px;
  font-weight: 500;
}
@media (min-width: 768px) {
  .medium-m8 {
    font-size: 16px;
  }
}

.medium-m7 {
  font-size: 18px;
  font-weight: 500;
}

.body-p7 {
  font-size: 18px;
}

.view {
  gap: 40px;
  display: flex;
  flex-direction: column;
  overflow: visible;
}

.container-shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/assets/scss/_variables.scss"],"names":[],"mappings":"AAYA;EACE,WAAA;AAXF;;AAkBA;EACE,eAAA;EACA,gBALK;AAVP;;AAkBA;EACE,eAAA;EACA,gBAVK;AALP;;AAkBA;EACE,eAAA;EACA,gBAfK;AAAP;;AAkBA;EACE,eAAA;EACA,gBApBK;AAKP;;AAkBA;EACE,eAAA;EACA,gBAzBK;AAUP;;AAkBA;EAIE,eAAA;EACA,gBAhCO;AAcT;AAcE;EADF;IAEI,eAAA;EAXF;AACF;;AAgBA;EACE,eAAA;EACA,gBArCO;AAwBT;;AAgBA;EACE,eAAA;AAbF;;AAgBA;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,iBAAA;AAbF;;AAgBA;EACE,0CAAA;AAbF","sourcesContent":["// Colors\n$neutral-0: #ffffff;\n$neutral-40: #cecece;\n$neutral-50: #aeaeae;\n$neutral-60: #8e8e8e;\n$neutral-70: #717171;\n$neutral-100: #080808;\n$neutral-gray-3: #c5ced8;\n$canvas: #f8f5f2;\n$blue: #4d8aff;\n$blue-2: #7ba8ff;\n\nhtml {\n  width: 100%;\n}\n\n// Font Weight\n$bold: 700;\n$medium: 500;\n\n.header-h4 {\n  font-size: 28px;\n  font-weight: $bold;\n}\n\n.header-h5 {\n  font-size: 24px;\n  font-weight: $bold;\n}\n\n.header-h6 {\n  font-size: 20px;\n  font-weight: $bold;\n}\n\n.header-h7 {\n  font-size: 16px;\n  font-weight: $bold;\n}\n\n.header-h8 {\n  font-size: 12px;\n  font-weight: $bold;\n}\n\n.medium-m8 {\n  @media (min-width: 768px) {\n    font-size: 16px;\n  }\n  font-size: 12px;\n  font-weight: $medium;\n}\n\n.medium-m7 {\n  font-size: 18px;\n  font-weight: $medium;\n}\n\n.body-p7 {\n  font-size: 18px;\n}\n\n.view {\n  gap: 40px;\n  display: flex;\n  flex-direction: column;\n  overflow: visible;\n}\n\n.container-shadow {\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
