/* eslint-disable no-unused-vars */
import "./App.scss";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import RouteGuard from "./RouteGuard";
import LowonganPage from "./Pages/Lowongan Page";
import CreateLowonganPage from "./Pages/CreateLowongan";
import LowonganUpdatePage from "./Pages/Lowongan Update Page";
import LoginPage from "./Pages/GroupOne/authentication/LoginPage";
import CreateLowonganSpesialPage from "./Pages/CreateLowonganSpesial";
import LowonganSpesialItemPage from "./Pages/Lowongan Spesial Item Page";
import CreateLowonganSpecialItemPage from "./Pages/Create Lowongan Special Item";
import UpdateLowonganSpecialItemPage from "./Pages/UpdateLowongan Special Item";
import LowonganSpesialUpdatePage from "./Pages/Update Lowongan Special";
import DaftarLowonganPage from "./Pages/Daftar Lowongan Page";
import DetailLowonganPage from "./Pages/Detail Lowongan Page";
import LowonganApplicantPage from "./Pages/Show Lowongan Applicant";
import LamaranPage from "./Pages/Lamaran Page";
import LamaranDiterimaPage from "./Pages/Lamaran Diterima Page";
import Profile from "./Pages/Profile";
import { newHistory } from "./history";
import ProfileMahasiswa from "./Pages/ProfileMahasiswa";
import { useState } from "react";
import AsdosLihatPembayaranHonor from "./Pages/GroupOne/PembayaranHonor/AsdosLihatPembayaranHonor";
import MelihatPesanLog from "./Pages/GroupOne/log/pesanLog";
import UpdateLog from "./Pages/GroupOne/log/updateLog";
import AddLog from "./Pages/GroupOne/log/addLog";
import AsdosLogPage from "./Pages/GroupOne/log/asdosLog";
import AsdosMelihatListMatkul from "./Pages/GroupOne/log/asdosMelihatListMatkul";
import DosenMelihatLogPerMatkul from "./Pages/GroupOne/log/dosenMelihatLogPerMatkul";
import DosenViewLogPerAsisten from "./Pages/GroupOne/log/dosenViewLogPerAsisten";
import LaporanPembayaranHonor from "./Pages/GroupOne/PembayaranHonor/LaporanPembayaranHonor";
import ClaimHonor from "./Pages/GroupOne/PembayaranHonor/claimHonor";
import DashboardHome from "./Pages/GroupOne/Dashboard/DashboardHome";
import HonorRefList from "./Pages/GroupOne/PembayaranHonor/honorRefList";
import AddHonorRef from "./Pages/GroupOne/PembayaranHonor/addHonorRef";
import AddHonorRefMTI from "./Pages/GroupOne/PembayaranHonor/addHonorRefMTI";
import AddHonorRateMTI from "./Pages/GroupOne/PembayaranHonor/addHonorRateMTI";
import DetailHonorRef from "./Pages/GroupOne/PembayaranHonor/detailHonorRef";
import DetailHonorRefS2 from "./Pages/GroupOne/PembayaranHonor/detailHonorRefS2";
import DetailHonorRateMTI from "./Pages/GroupOne/PembayaranHonor/detailHonorRateMTI";
import EditHonorRef from "./Pages/GroupOne/PembayaranHonor/updateHonorRef";
import EditHonorRefMTI from "./Pages/GroupOne/PembayaranHonor/updateHonorRefMTI";
import EditHonorRateMTI from "./Pages/GroupOne/PembayaranHonor/updateHonorRateMTI";
import AdminMelihatListLogPerBulan from "./Pages/GroupOne/log/AdminMelihatListLogPerBulan";
import MelihatKategoriLog from "./Pages/GroupOne/log/logCategory";
import AdminLogsBelumDisetujui from "./Pages/GroupOne/admin/AdminLogsBelumDisetujui";
import AdminBelumTentukanHonor from "./Pages/GroupOne/admin/AdminBelumTentukanHonor";
import AdminDosenMelihatListMatkul from "./Pages/GroupOne/log/adminDosenMelihatListMatkul";
import LaporanPembayaranHonorAsistenPerMatakuliahPerSemester from './Pages/GroupOne/PembayaranHonor/LaporanPembayaranHonorAsistenPerMatakuliahPerSemester';
import HonorPembayaran from './Pages/GroupOne/finance/HonorPembayaran';
import ReferensiSanksiPage from "./Pages/Referensi Sanksi Page";
import SanksiUpdatePage from "./Pages/Sanksi Update Page";
import SanksiCreatePage from "./Pages/Sanksi Create Page";
import HonorRateList from "./Pages/GroupOne/PembayaranHonor/honorRateList";
import DocumentationPage from "./Pages/Documentation Page";
import AllMahasiswaPage from "./Pages/AllMahasiswa";
import AdminViewLogPerAsisten from "./Pages/GroupOne/log/adminViewLogPerAsisten";
import PembayaranHonorPerAsisten from "./Pages/GroupOne/PembayaranHonor/PembayaranHonorPerAsisten";
import UbahNamaHistoriPembayaran from "./Pages/GroupOne/finance/UbahNamaHistoriPembayaran";


function App() {
  const [userRole, setUserRole] = useState(sessionStorage.getItem("role"));

  return (
    <Router history={newHistory}>
      <Routes>
        <Route path={"/login"} element={<LoginPage setUserRole={setUserRole} />} />
        <Route path={"/login"} element={<LoginPage setUserRole={setUserRole} />} />
        <Route path={"/"} element={<RouteGuard />}>
          {userRole === "admin" && (
            <>
              <Route path={"/admin/dashboard"} element={<DashboardHome />} />
              <Route path={"/admin/logsbelumsetujui"} element={<AdminLogsBelumDisetujui />} />
              <Route path={"/admin/logsperbulan"} element={<AdminMelihatListLogPerBulan />} />
              <Route path={"/listLowongan"} element={<AdminDosenMelihatListMatkul />} />
              <Route path={"/matkul/:matkul_id/log"} element={<DosenMelihatLogPerMatkul />} />
              <Route path={"/matkul/:matkul_id/log/asdos/:asdos_username"} element={<DosenViewLogPerAsisten />} />
              <Route path={"/matkul/:matkul_id/log/:log_id/pesan"} element={<MelihatPesanLog />} />
              <Route path={"/admin/kategorilog"} element={<MelihatKategoriLog />} />

              <Route path={"/melihat-histori-pembayaran"} element={<HonorPembayaran />} />
              <Route path={"/ubah-nama-histori-pembayaran"} element={<UbahNamaHistoriPembayaran />} />
              <Route path={"/admin/klaimhonor"} element={<ClaimHonor />} />
              <Route path={"/laporan-pembayaran-honor-asisten-per-matakuliah-per-semester"} element={<LaporanPembayaranHonorAsistenPerMatakuliahPerSemester />} />
              <Route path={"/laporanpembayaranhonor"} element={<LaporanPembayaranHonor />} />
              <Route path={"/admin/belumtentukanhonor"} element={<AdminBelumTentukanHonor />} />

              <Route path={"/honor-ref-list"} element={<HonorRefList />} />
              <Route path={"/edit-honor-ref/:id"} element={<EditHonorRef />} />
              <Route path={"/edit-honor-ref/mti/:id"} element={<EditHonorRefMTI />} />
              <Route path={"/edit-honor-rate/mti/:id"} element={<EditHonorRateMTI />} />

              <Route path={"/add-honor-ref"} element={<AddHonorRef />} />
              <Route path={"/add-honor-ref/mti"} element={<AddHonorRefMTI />} />
              <Route path={"/add-honor-rate/mti/:id"} element={<AddHonorRateMTI />} />

              <Route path={"/detail-honor-ref/:id"} element={<DetailHonorRef />} />
              <Route path={"/detail-honor-ref/mti/:id"} element={<DetailHonorRefS2 />} />
              <Route path={"/detail-honor-rate/mti/:id"} element={<DetailHonorRateMTI />} />
              <Route path={"/honor-rate-list/:id"} element={<HonorRateList />} />


                
              <Route path={"/"} element={<Navigate to={"/lowongan"} />} />
              <Route path={"/lowongan"} element={<LowonganPage />} />
              <Route
                path={"/lowongan/create"}
                element={<CreateLowonganPage />}
              />
              <Route path={"/lowongan/:Id"} element={<LowonganUpdatePage />} />
              <Route
                path={"/lowongan/items"}
                element={<LowonganSpesialItemPage />}
              />
              <Route
                path={"/lowongan/items/create"}
                element={<CreateLowonganSpecialItemPage />}
              />
              <Route
                path={"/lowongan/items/update/:Id"}
                element={<UpdateLowonganSpecialItemPage />}
              />
              <Route
                path={"/lowonganspesial/create"}
                element={<CreateLowonganSpesialPage />}
              />
              <Route
                path={"/lowonganspesial/:Id"}
                element={<LowonganSpesialUpdatePage />}
              />
              <Route
                path={"/lowongan/:Id/pelamar"}
                element={<LowonganApplicantPage />}
              />
              <Route
                path={"/lamaran"}
                element={<LamaranPage />}
              />
              <Route path={"/asistenditerima"} element={<LamaranDiterimaPage />} />
              <Route path={"/allmahasiswa"} element={<AllMahasiswaPage />} />
              <Route path={"/log/asdos/:asdos_username"} element={<AdminViewLogPerAsisten />} />
              <Route path={"/honor-per-asisten"} element={<PembayaranHonorPerAsisten />} />
              <Route
                path={"/profile/:Id/:lowonganId"}
                element={<ProfileMahasiswa />}
              />

              <Route
                path={"/sanksi"}
                element={<ReferensiSanksiPage />}
              />
              <Route
                path={"/sanksi/:id/:id"}
                element={<SanksiUpdatePage />}
              />
              <Route
                path={"/sanksi/create"}
                element={<SanksiCreatePage />}
              />
            </>
          )}
          {userRole === "finance" && (
            <>
              <Route path={"/"} element={<Navigate to={"/asistenditerima"} />} />
              <Route path={"/asistenditerima"} element={<LamaranDiterimaPage />} />
              <Route path={"/logsperbulan"} element={<AdminMelihatListLogPerBulan />} />
              <Route path={"/melihat-histori-pembayaran"} element={<HonorPembayaran />} />
              <Route path={"/ubah-nama-histori-pembayaran"} element={<UbahNamaHistoriPembayaran />} />
              <Route path={"/admin/klaimhonor"} element={<ClaimHonor />} />
              <Route path={"/laporan-pembayaran-honor-asisten-per-matakuliah-per-semester"} element={<LaporanPembayaranHonorAsistenPerMatakuliahPerSemester />} />
              <Route path={"/laporanpembayaranhonor"} element={<LaporanPembayaranHonor />} />
              <Route path={"/honor-per-asisten"} element={<PembayaranHonorPerAsisten />} />
              <Route
                path={"/profile/:Id/:lowonganId"}
                element={<ProfileMahasiswa />}
              />
            </>
          )}
          {userRole === "dosen" && (
            <>
              <Route path={"/"} element={<Navigate to={"/lowongan"} />} />
              <Route path={"/lowongan"} element={<LowonganPage />} />
              <Route
                path={"/lowongan/create"}
                element={<CreateLowonganPage />}
              />
              <Route path={"/lowongan/:Id"} element={<LowonganUpdatePage />} />
              <Route
                path={"/lowonganspesial/:Id"}
                element={<LowonganSpesialUpdatePage />}
              />
              <Route
                path={"/lowongan/:Id/pelamar"}
                element={<LowonganApplicantPage />}
              />
              <Route path={"/profile/:Id/:lowonganId"} element={<ProfileMahasiswa />} />
              <Route path={"/listLowongan"} element={<AdminDosenMelihatListMatkul />} />
              <Route path={"/matkul/:matkul_id/log"} element={<DosenMelihatLogPerMatkul />} />
              <Route path={"/matkul/:matkul_id/log/asdos/:asdos_username"} element={<DosenViewLogPerAsisten />} />
              <Route path={"/matkul/:matkul_id/log/:log_id/pesan"} element={<MelihatPesanLog />} />

              <Route path={"/laporanpembayaranhonor"} element={<LaporanPembayaranHonor />} />

              <Route
                path={"/sanksi"}
                element={<ReferensiSanksiPage />}
              />
              <Route
                path={"/sanksi/:id/:id"}
                element={<SanksiUpdatePage />}
              />
            </>
          )}
          {userRole === "mahasiswa" && (
            <>
              <Route path={"/"} element={<Navigate to={"/lowongan"} />} />
              <Route path={"/lowongan"} element={<LowonganPage />} />
              <Route path={"/profile"} element={<Profile />} />
              <Route
                path={"/lowongan/daftar/:Id"}
                element={<DaftarLowonganPage />}
              />
              <Route
                path={"/lowongan/detail/:Id"}
                element={<DetailLowonganPage />}
              />
              <Route path={"/asdos/listLowongan"} element={<AsdosMelihatListMatkul />} />
              <Route path={"/matkul/:matkul_id/log"} element={<AsdosLogPage />} />
              <Route path={"/matkul/:matkul_id/log/:jenjang/add"} element={<AddLog />} />
              <Route path={"/matkul/:matkul_id/log/:jenjang/:log_id/update"} element={<UpdateLog />} />
              <Route path={"/matkul/:matkul_id/log/:log_id/pesan"} element={<MelihatPesanLog />} />
              <Route path={"/asdos/honor"} element={<AsdosLihatPembayaranHonor />} />
              <Route path={"/manual"} element={<DocumentationPage />} />
            </>
          )}
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
      </Routes>
    </Router>
  );
}
export default App;
