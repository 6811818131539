import '../../assets/group1/scss/Input/Filter.scss';

const Filter3 = ({items, text, placeholder, onFilterChange, value=undefined}) => {
    if (text === "Bulan") {
        const handleFilterChange = (event) => {
            const selectedMonth = event.target.value;
            onFilterChange(selectedMonth); // Call the callback to update the selected month
        };

        const monthMap = {
            "Bulan": 0,        // 0 for "Bulan" to handle the initial label
            "Januari": 1,
            "Februari": 2,
            "Maret": 3,
            "April": 4,
            "Mei": 5,
            "Juni": 6,
            "Juli": 7,
            "Agustus": 8,
            "September": 9,
            "Oktober": 10,
            "November": 11,
            "Desember": 12,
        };
        return (
            <div className={"filter"} style={{height: "27px", borderRadius: "12px"}}>
                <select className={"filter-container filter-text body-p8"} onChange={handleFilterChange} value={value}>
                    {Object.keys(monthMap).map((month) => (
                        <option key={monthMap[month]} value={monthMap[month]}>
                            {month}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
    if (text === 'Mata Kuliah') {
        const handleMatkulChange = (event) => {
            const selectedMatkul = event.target.value;
            onFilterChange(selectedMatkul);
        };
        const defaultOption = { label: 'Mata Kuliah', value: '' };

        return (
            <div className="filter" style={{height: "27px", borderRadius: "12px"}}>
                <select className="filter-container filter-text body-p8" onChange={handleMatkulChange}>
                    {[defaultOption, ...items].map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
    if (text === "Status" || text === "Tahun" || text === "Bank" || text === "Term" || text === "Bulann" || text==='Semester') {
        const handleFilterChange = (event) => {
            const selectedStatus = event.target.value;
            onFilterChange(selectedStatus); // Call the callback to update the selected status
        };

        return (
            <div className="filter" style={{height: "27px", borderRadius: "12px"}}>
                <select className="filter-container filter-text body-p8" onChange={handleFilterChange} value={value}>
                    {placeholder && (
                        <option value="" disabled selected>
                            {placeholder}
                        </option>
                    )}
                    {items.map((item, index) => (
                        <option key={index} value={item}>
                            {item}
                        </option>
                    ))}
                </select>
            </div>
        );
    } else {
        return (
            <div className="filter" style={{height: "27px"}}>
                <select className="filter-container filter-text body-p8" value={value}>
                    {items.map((item, index) => (
                        <option key={index} value={index}>
                            {item}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

export default Filter3;