import { Fragment, useState, useMemo, useEffect } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import NumInput from '../../../Component/GroupOne/NumInput';
import { useParams } from 'react-router-dom';

const EditHonorRefS2 = () => {
  const { id } = useParams();
  const token = sessionStorage.getItem('jwtToken');
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append('Authorization', 'Bearer ' + token);
    return headers;
  }, [token]);

  const pendidikanOptions = [
    { value: "Mahasiswa S1", label: "Mahasiswa S1" },
    { value: "Lulus S1 / Mahasiswa S2", label: "Lulus S1 / Mahasiswa S2" },
    { value: "Lulus S2 / Mahasiswa S3", label: "Lulus S2 / Mahasiswa S3" },
    { value: "TA Kontrak", label: "TA Kontrak" },
  ];

  const pengalamanOptions = [
    { value: "Junior", label: "Junior" },
    { value: "Menengah", label: "Menengah" },
    { value: "Senior", label: "Senior" },
  ];

  const lokasiOptions = [
    { value: "Onsite", label: "Onsite" },
    { value: "Offsite", label: "Online" },
  ];

  const [honorRefData, setHonorRefData] = useState({
    pk_ref: '',
    name: '',
    periode: '',
    mahasiswa_s1_junior_onsite: '',
    mahasiswa_s1_junior_offsite: '',
    lulus_s1_mahasiswa_s2_junior_onsite: '',
    lulus_s1_mahasiswa_s2_junior_offsite: '',
    lulus_s2_mahasiswa_s3_junior_onsite: '',
    lulus_s2_mahasiswa_s3_junior_offsite: '',
    ta_kontrak_junior_onsite: '',
    ta_kontrak_junior_offsite: '',
    mahasiswa_s1_menengah_onsite: '',
    mahasiswa_s1_menengah_offsite: '',
    lulus_s1_mahasiswa_s2_menengah_onsite: '',
    lulus_s1_mahasiswa_s2_menengah_offsite: '',
    lulus_s2_mahasiswa_s3_menengah_onsite: '',
    lulus_s2_mahasiswa_s3_menengah_offsite: '',
    ta_kontrak_menengah_onsite: '',
    ta_kontrak_menengah_offsite: '',
    mahasiswa_s1_senior_onsite: '',
    mahasiswa_s1_senior_offsite: '',
    lulus_s1_mahasiswa_s2_senior_onsite: '',
    lulus_s1_mahasiswa_s2_senior_offsite: '',
    lulus_s2_mahasiswa_s3_senior_onsite: '',
    lulus_s2_mahasiswa_s3_senior_offsite: '',
    ta_kontrak_senior_onsite: '',
    ta_kontrak_senior_offsite: '',
  });

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/honor/${id}/`, {
      method: 'GET',
      headers: headerData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const { list_honor } = data;

        const newHonorRefData = { ...honorRefData };

        pendidikanOptions.forEach((pendidikanOption) => {
          pengalamanOptions.forEach((pengalamanOption) => {
            lokasiOptions.forEach((lokasiOption) => {
              const key = `${pendidikanOption.value.toLowerCase().replace(/\s/g, '_')}_${pengalamanOption.value.toLowerCase().replace(/\s/g, '_')}_${lokasiOption.value.toLowerCase().replace(/\s/g, '_')}`.replace(/\/_/g, '');
              const pk_key = `pk_${key}`
              const honorData = list_honor.find((item) => item.pendidikan_asdos === pendidikanOption.value && item.pengalaman === pengalamanOption.value && item.location.replace(/-/g, '') === lokasiOption.value);
              if (honorData) {
                newHonorRefData[key] = honorData.amount.toString();
                newHonorRefData[pk_key] = honorData.pk.toString();
              } 
            });
          });
        });

        setHonorRefData({
          ...newHonorRefData,
          pk_ref: data.pk,
          name: data.name,
          periode: data.periode,
        });

      })
      .catch((error) => {
        console.error('Kesalahan:', error);
      });
  }, [headerData, id]);

  return (
    <Fragment>
      <div className={'view'}>
        <Navbar />
        <a
            href="/honor-ref-list"
            className={'back medium-m8'}
            onClick={() => {
              window.history.back();
              return false;
            }}
        >
          ← Kembali ke Daftar Honor Ref
        </a>
        <div className={'center-form-container'}>
          <form className={'body-container-group1'}>
            <div className={'card-form-container-s2 container-shadow'}>
              <div className={"header-h4 center"}>Detail Referensi Honor</div>
              <div className={"header-h6 "} style={{ fontWeight: 'bold' }}>Tabel Honor Rate</div>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    {pengalamanOptions.map((option) => (
                      <Fragment key={option.value}>
                        <th colSpan="2">{option.label}</th>
                      </Fragment>
                    ))}
                  </tr>
                  <tr>
                    <th></th>
                    <Fragment>
                      <th colSpan="1">Onsite</th>
                    </Fragment>
                    <Fragment>
                      <th colSpan="1">Offsite</th>
                    </Fragment>
                    <Fragment>
                      <th colSpan="1">Onsite</th>
                    </Fragment>
                    <Fragment>
                      <th colSpan="1">Offsite</th>
                    </Fragment>
                    <Fragment>
                      <th colSpan="1">Onsite</th>
                    </Fragment>
                    <Fragment>
                      <th colSpan="1">Offsite</th>
                    </Fragment>
                  </tr>
                </thead>
                <tbody>
                  {pendidikanOptions.map((pendidikanOption, index) => (
                    <tr key={index}>
                      <td style={{ maxWidth: '55px' }}>{pendidikanOption.label}</td>
                      {pengalamanOptions.map((pengalamanOption, index) => (
                        <Fragment key={index}>
                          {lokasiOptions.map((lokasiOption, index) => (
                            <Fragment key={index}>
                              <td style={{ maxWidth: '55px', padding:'10px' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  <p style={{ marginRight: '5px' }}>Rp.</p>

                                  <NumInput
                                    name={`${pendidikanOption.value.toLowerCase().replace(/\s/g, '_')}_${pengalamanOption.value.toLowerCase().replace(/\s/g, '_')}_${lokasiOption.value.toLowerCase().replace(/\s/g, '_')}`.replace(/\/_/g, '')}
                                    disabled={true}
                                    placeholder="Masukkan Keterangan"
                                    value={honorRefData[`${pendidikanOption.value.toLowerCase().replace(/\s/g, '_')}_${pengalamanOption.value.toLowerCase().replace(/\s/g, '_')}_${lokasiOption.value.toLowerCase().replace(/\s/g, '_')}`.replace(/\/_/g, '')]}
                                  />
                                  <p style={{ marginLeft: '5px' }}>,-</p>
                                </div>
                              </td>
                            </Fragment>
                          ))}
                        </Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className={"header-h6 "} style={{ fontWeight: 'bold' }}>Profil Honor Ref</div>
              <div style={{ display: 'flex', alignItems: 'center',justifyContent: 'left' }}>
                <div style={{ display: 'flex', alignItems: 'center', width: '400px', height: '50px', padding: '25px' }}>
                  <div style={{ width:'75px'}}>
                    <div className={"medium-m8"}>
                        Nama
                    </div>
                  </div>
                  <input
                      type="text"
                      className={"login-input"}
                      name={'name'}
                      disabled={true}
                      value={honorRefData.name}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '400px', height: '50px', padding: '25px' }}>
                  <div style={{ width:'75px'}}>
                    <div className={"medium-m8"}>
                        Periode
                    </div>
                  </div>
                  <input
                      type="text"
                      className={"login-input"}
                      name={'periode'}
                      disabled={true}
                      value={honorRefData.periode}
                  />
                </div>
              </div>
             
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EditHonorRefS2;