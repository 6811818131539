import Navbar from "../../../Component/GroupOne/Navbar";
/* eslint-disable-next-line no-unused-vars */
import { Fragment, useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import '../../../assets/group1/scss/Message.scss';
import InputBox from '../../../Component/GroupOne/InputBox';
import Button from '../../../Component/GroupOne/Buttons/Button';
import { format } from 'date-fns';

const MelihatPesanLog = () => {
    const { matkul_id, log_id } = useParams();
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const token = sessionStorage.getItem("jwtToken");
    const username = sessionStorage.getItem("username");
    const headerData = useMemo(() => {
        const headers = new Headers();
        headers.append('Content-type', 'application/json');
        headers.append("Authorization", "Bearer " + token);
        return headers;
    }, [token]);

    useEffect(() => {
        // Make an asynchronous request to fetch data from the API
        fetch(`${process.env.REACT_APP_BASE_URL}/log/${log_id}/message/`, {
            method: 'GET',
            headers: headerData
        })
            .then(response => response.json())
            .then(data => setMessages(data))
            .catch(error => console.error('Error fetching data:', error));
    }, [log_id, headerData]);

    const handleChange = (event) => {
        setInputMessage(event.target.value);
    };

    const handleSubmit = () => {
        // Check if the inputMessage is empty
        if (!inputMessage) {
            console.error('Input message is empty. Please enter a message.');
            return; // Exit the function to prevent the POST request
        }
        // Prepare the data for the POST request
        const postData = {
            message: inputMessage,
        };

        // Send the POST request
        fetch(`${process.env.REACT_APP_BASE_URL}/log/${log_id}/message/`, {
            method: 'POST',
            headers: headerData,
            body: JSON.stringify(postData),
        })
            .then((response) => response.json())
            .then((newMessage) => {
                // Update the state with the new message
                setMessages((prevMessages) => [newMessage, ...prevMessages]);
                // Clear the input
                setInputMessage('');
            })
            .catch((error) => console.error('Error sending message:', error));
    };

    return (
        <Fragment>
            <div className={"view"}>
                <Navbar name={username} />
                <div className={"content-group1"}>
                    {/* eslint-disable-next-line */}
                    <a href={`/matkul/${matkul_id}/log`}  className={"medium-m8"}>
                        ← Kembali ke Daftar Log
                    </a>
                    <div className={"body-container-group1"}>
                        <div className={"profile2"}>
                            <div className={"header-h4"}>Pesan Log</div>
                        </div>

                        <div className={"text-container"}>
                            <div className={"text-input-container"}>
                                <InputBox
                                    label={'Pesan'}
                                    name={'description'}
                                    value={inputMessage}
                                    onChange={handleChange}
                                    placeholder='Isi dengan Pesan'
                                />
                                <br></br>
                                <Button
                                    text={"Simpan"}
                                    onClick={handleSubmit}
                                    background={"#4D8AFF"}
                                    color={"#FFFFFF"}
                                    margin={"0"}
                                />
                            </div>
                            <div className={"text-message-container"}>
                                {messages.map(message => (
                                    <div className={"text-message"} key={message.id}>
                                        <div className={"text-message-header"}>
                                            <div className={"text-message-name"}>{message.author}</div>
                                            <div className={"text-message-date"}>{format(Date.parse(message.datetime), 'MMM dd yyyy HH:mm')}</div>
                                        </div>
                                        <div className={"body-p9"}>{message.message}</div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default MelihatPesanLog
