import { Fragment, useState, useEffect } from 'react';
import Navbar from '../../../Component/GroupOne/Navbar';
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/HonorPembayaran.scss';
import { toast, ToastContainer } from "react-toastify";
import HonorService from '../../../Services/HonorService';
import { useNavigate } from "react-router-dom";
import Button from "../../../Component/Button";
import "../../../assets/scss/_variables.scss";
import "../../../assets/scss/CreateLowongan.scss";
import "react-toastify/dist/ReactToastify.css";
import SingleSelect from "../../../Component/SingleSelect";
import InputOneRow from '../../../Component/InputOneRow';

const UbahNamaHistoriPembayaran = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [namaMenyetujui, setNamaMenyetujui] = useState();
  const [jabatanMenyetujui, setJabatanMenyetujui] = useState();
  const [namaMengajukan, setNamaMengajukan] = useState();
  const [jabatanMengajukan, setJabatanMengajukan] = useState();
  const [jenjang, setJenjang] = useState();
  const handleNamaMenyetujuiOnChange = (e) => {
    setNamaMenyetujui(e.target.value);
  };
  const handleNamaMengajukanOnChange = (e) => {
    setNamaMengajukan(e.target.value);
  };
  const handleJabatanMenyetujuiOnChange = (e) => {
    setJabatanMenyetujui(e.target.value);
  };
  const handleJabatanMengajukanOnChange = (e) => {
    setJabatanMengajukan(e.target.value);
  };
  const handleJenjangOnChange = (jenjang) => {
    setJenjang(jenjang);
  };

  const jenjangOptions = [
    { label: "S1-MIK", value: "S1-MIK" },
    { label: "MTI", value: "MTI" },
  ];

  const fetchData = async () => {
    try {
      const response = await HonorService.getHistoriPembayaranPerson();
      setData(response.data)
    } catch (error) {
      console.error("Failed to fetch sanksi details:", error);
    }
  };

  const changeDataDisplay = () => {
    setNamaMenyetujui("")
    setJabatanMenyetujui("")
    setNamaMengajukan("")
    setJabatanMengajukan("")
    for (const obj of data) {
      if (obj.jenjang === jenjang.value) {
        if (obj.aksi === "Menyetujui") {
          setNamaMenyetujui(obj.nama)
          setJabatanMenyetujui(obj.jabatan)
        } else if (obj.aksi === "Diajukan oleh") {
          setNamaMengajukan(obj.nama)
          setJabatanMengajukan(obj.jabatan)
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    changeDataDisplay();
  }, [jenjang]);

  const handleSubmit = async (e) => {
    if (
      namaMengajukan &&
      namaMenyetujui &&
      jabatanMengajukan &&
      jabatanMenyetujui
    ) {
      let requestBody = {
        nama: namaMenyetujui,
        jabatan: jabatanMenyetujui,
        jenjang: jenjang.value,
        aksi: "Menyetujui"
      };
      await HonorService.putHistoriPembayaranPerson(requestBody)
      requestBody = {
        nama: namaMengajukan,
        jabatan: jabatanMengajukan,
        jenjang: jenjang.value,
        aksi: "Diajukan oleh"
      };
      await HonorService.putHistoriPembayaranPerson(requestBody)
        .then(() => {
          /* istanbul ignore next */
          toast.success("Nama yang menyetujui/ mengajukan berhasil diubah", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/melihat-histori-pembayaran");
            },
          });
        })
        .catch((error) => {
          toast.error("Nama yang menyetujui/mengajukan gagal diubah", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <a href="/melihat-histori-pembayaran" className={"back medium-m8"}>
          ← Kembali
        </a>
        <div className={"content"}>
          <form className={"body-container"} onSubmit={handleSubmit}>
            <h2 style={{textAlign: "center"}}>Ubah Nama yang Menyetujui/Mengajukan</h2>
            <div className={"card-form-container container-shadow"}>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Jenjang</div>
                <SingleSelect
                  data_testid={"Jenjang"}
                  value={jenjang}
                  options={jenjangOptions}
                  onChange={handleJenjangOnChange}
                />
              </div>

              {jenjang ?
                <>
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className="header-h6">Menyetujui,</div>
                    <InputOneRow
                      text={"Nama"}
                      value={namaMenyetujui}
                      onChange={handleNamaMenyetujuiOnChange}
                    />
                    <InputOneRow
                      text={"Jabatan"}
                      value={jabatanMenyetujui}
                      onChange={handleJabatanMenyetujuiOnChange}
                    />
                  </div>
                  
                  <div className="tw-flex tw-flex-col tw-gap-2">
                    <div className="header-h6">Diajukan oleh,</div>
                    <InputOneRow
                      text={"Nama"}
                      value={namaMengajukan}
                      onChange={handleNamaMengajukanOnChange}
                    />
                    <InputOneRow
                      text={"Jabatan"}
                      value={jabatanMengajukan}
                      onChange={handleJabatanMengajukanOnChange}
                    />
                  </div>

                  <div
                    className="button-container"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingLeft: 0,
                      paddingTop: 0,
                    }}
                  >
                    <Button
                      data-testid={"Ubah"}
                      text={"Ubah"}
                      type="submit"
                      color={"#FFFFFF"}
                      onClick={handleSubmit}
                      isDisabled={
                        !(
                          namaMengajukan &&
                          namaMenyetujui &&
                          jabatanMengajukan &&
                          jabatanMenyetujui
                        )
                      }
                      role='button'
                    />
                  </div>
                </> :
                ""}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default UbahNamaHistoriPembayaran;