import { useState } from 'react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import SanksiService from '../Services/SanksiService';
import Button from "./Button";
import templatePelanggaran from "../assets/excel/Template Rekap Pelanggaran Mahasiswa.xlsx"

const CreateSanksiForm = ({ isOpen, onRequestClose, onUploadSuccess }) => {
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);

  const closeModal = () => {
    onRequestClose();
    setFile(null);
    setFileName('');
  };

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const handleUploadClick = () => {
    if (!file) {
      toast.error("Tidak ada file unggahan", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      let formData = new FormData();
      formData.append('sanksi_file', file);
      SanksiService.createSanksi(formData)
        .then((response) => {
          toast.success("File berhasil diunggah", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          onUploadSuccess();
          closeModal();
        })
        .catch((error) => {
          toast.error(error.response.data.data, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={{ content: { maxWidth: '600px', height: '320px', margin: 'auto' } }}>
      <div>
        <h2>Upload Daftar Penerima Sanksi</h2>
        <form>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <div className="notes-text tw-pb-2">Template file yang digunakan dapat diunggah melalui&nbsp;
            <u><a className="notes-text tw-pb-2" href={templatePelanggaran}>tautan berikut ini.</a></u>
            &nbsp;Pastikan ekstensi file adalah &apos;xlsx&apos;.</div>
            <div style={{ border: '2px dashed #999', padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label htmlFor="file-upload" className="custom-file-upload">
                  <input id="file-upload" type="file" accept=".xls,.xlsx" style={{ display: 'none' }} onChange={handleFileChange} />
                  <Button text={"Choose File"} color={"#FFFFFF"} size={"small-button"} style={{ marginBottom: "20px", marginTop: "20px", marginRight: '10px', width: '100px', color: 'white' }} />
                </label>
                {fileName && <p>{fileName}</p>}
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <Button text={"Upload"} color={"#FFFFFF"} size={"small-button"} style={{ marginRight: '15px', width: '100px', color: 'white' }} onClick={handleUploadClick} />
              <Button text={"Close"} onClick={closeModal} color={"#FFFFFF"} size={"small-button"} />
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CreateSanksiForm;
