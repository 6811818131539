import { Fragment, useState, useEffect, useMemo } from "react";
import Navbar from "../../Component/Navbar";
import Dropdown from "../../Component/Dropdown";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import LowonganTableAdmin from "../../Component/LowonganTable";
import LowonganTableMahasiswa from "../../Component/LowonganTableMahasiswa";
import "../../App.scss";
import LowonganService from "../../Services/LowonganService";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SearchInput from "../../Component/SearchInput";
import { useLocation } from "react-router-dom";

const LowonganPage = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const role = sessionStorage.getItem("role") || "mahasiswa";
  const username = sessionStorage.getItem("username") || "John Doe";

  const [Terms, setTerms] = useState();
  // add mew parameter called jenjang
  const [selectedJenjang, setSelectedJenjang] = useState();
  const jenjangPendidikan = ['S1', 'S2', 'S3'];

  const fetchTerm = async () => {
    try {
      const response = await LowonganService.getTerm();
      setTerms(response.data.data);
    } catch (error) {
      console.error("Failed to fetch term:", error);
    }
  };

  useEffect(() => {
    fetchTerm();
  }, []);

  const Options = useMemo(() => {
    if (Terms && Terms.length > 0) {
      return Terms.map(
        (term) =>
          `${term.tahun_ajaran} ${
            term.semester.charAt(0).toUpperCase() + term.semester.slice(1)
          } `
      );
    }
    return [];
  }, [Terms]);

  const [selectedTerm, setSelectedTerm] = useState("");
  const [tahunAjaran, setTahunAjaran] = useState(searchParams.get("tahun_ajaran") || "");
  const [semester, setSemester] = useState(searchParams.get("semester") || "");
  const [jenjang, setJenjang] = useState(searchParams.get("jenjang") || "");

  useEffect(() => {
    if (Options.length > 0 && !selectedTerm) {
      setSelectedTerm(Options[0]);
    }
  }, [Options, selectedTerm]);

  useEffect(() => {
    if (Options.length > 0 && !tahunAjaran) {
      setTahunAjaran(Options[0].split(" ")[0]);
    }
    if (Options.length > 0 && !semester) {
      setSemester(Options[0].split(" ")[1]);
    }
  }, [Options, tahunAjaran, semester]);

  const [lowonganList, setLowonganList] = useState([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isMhsMasaSanksi, setIsMasaSanksi] = useState(false)
  const [endDateSanksi, setEndDateSanksi] = useState('')
  const monthMap = {
    '01': 'Januari',
    '02': 'Februari',
    '03': 'Maret',
    '04': 'April',
    '05': 'Mei',
    '06': 'Juni',
    '07': 'Juli',
    '08': 'Agustus',
    '09': 'September',
    '10': 'Oktober',
    '11': 'November',
    '12': 'Desember'
  }
  const navigate = useNavigate();
  const fetchLowonganList = async () => {
    try {
      const response = await LowonganService.getLowonganList(
        role,
        username,
        tahunAjaran,
        semester.toLowerCase(), 
        jenjang
      );
      setLowonganList(response.data.data.lowongan);
    } catch (error) {
      const err_status = error.response.status
      const err_message = error.response.data.error
      const end_date = error.response.data.end_date
      if (err_status && err_message && err_status === 403 && 
          err_message.toLowerCase().includes('keras')){
        setIsMasaSanksi(true)
        setEndDateSanksi(end_date)
      } else {
        console.error("Failed to fetch lowongan:", error);
      }
    }
  };

  useEffect(() => {
    if (!semester || !tahunAjaran) return
    fetchLowonganList();
  }, [role, semester, tahunAjaran, username, jenjang]);

  useEffect(() => {
    if (semester) {
      const newSelectedTerm = `${tahunAjaran} ${
        semester.charAt(0).toUpperCase() + semester.slice(1)
      } `;
      setSelectedTerm(newSelectedTerm);
      setSelectedJenjang(jenjang)
    }
  }, [semester, tahunAjaran, jenjang]);

  const [search, setSearch] = useState("");

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDropdownChange = (event) => {
    if (event.target.value === "-") {
      const newSelectedTerm = Options[0];
      setSelectedTerm(newSelectedTerm);
    } else {
      setSelectedTerm(event.target.value);
    }
  };

  // add new function for filter jenjang
  const handleDropdownJenjangChange = (event) => {
    setSelectedJenjang(event.target.value);
  };

  const handleOnClickFilter = () => {
    const newTahunAjaran = selectedTerm.split(" ")[0];
    const newSemester = selectedTerm.split(" ")[1].toLowerCase();
    const newJenjang = selectedJenjang;

    if(selectedJenjang !== "-"){
      navigate(
        `/lowongan?semester=${newSemester}&tahun_ajaran=${newTahunAjaran}&jenjang=${newJenjang}`
      );
      setJenjang(newJenjang)
    }
    else{
      navigate(
        `/lowongan?semester=${newSemester}&tahun_ajaran=${newTahunAjaran}`
      );
      setJenjang("")
    }
    setSemester(newSemester)
    setTahunAjaran(newTahunAjaran)
  };

  let lowonganTableComponent;
  let createLowongan;

  if (role === "mahasiswa") {
    createLowongan = "";
  } else {
    createLowongan = (
      <Button
        text={"Buat Lowongan"}
        onClick={() => navigate("/lowongan/create")}
        color={"#FFFFFF"}
      />
    );
  }

  if (role === "mahasiswa") {
    if (!isMhsMasaSanksi){
      lowonganTableComponent = (
        <LowonganTableMahasiswa
          items={[
            [
              "No",
              "Nama Lowongan",
              "Dosen Pembuka",
              "Semester / Ajaran Tahun",
              "Prodi",
              "Jumlah Lowongan",
              "Jumlah Lamaran",
              "Jumlah Diterima",
              "Status",
              "Lamaran Status",
              "Operasi",
            ],
          ]}
          lowonganList={lowonganList.filter((item) => {
            return search.toLowerCase() === ""
              ? item
              : item.nama_lowongan.toLowerCase().includes(search);
          })}
        />
      );
    } else {
      let dateParts = endDateSanksi.split("-");

      // Mengambil bagian-bagian dari tanggal
      let year = dateParts[0];
      let month = monthMap[dateParts[1]];
      let day = dateParts[2];
      lowonganTableComponent = (<div className={"table-container container-shadow tw-pt-6 text-center"}>
          <h2>Anda tidak dapat melamar karena sedang dalam masa sanksi hingga {day} {month} {year}</h2>
        </div>);
    }
  } else if (role === "admin" || role === "dosen") {
    lowonganTableComponent = (
      <LowonganTableAdmin
        items={[
          [
            "No",
            "Nama Lowongan",
            "Dosen Pembuka",
            "Semester / Ajaran Tahun",
            "Jumlah Lowongan",
            "Jumlah Lamaran",
            "Jumlah Diterima",
            "Selisih",
            "Status",
            "Operasi",
          ],
        ]}
        lowonganList={lowonganList.filter((item) => {
          const searchLower = search.toLowerCase();
          return (
            searchLower === "" ||
            item.nama_lowongan.toLowerCase().includes(searchLower) ||
            item.lowongan_pembuka.some((elem) =>
              elem.toLowerCase().includes(searchLower)
            )
          );
        })}
        isDelete={isDelete}
        setIsDelete={setIsDelete}
      />
    );
  }

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content"}>
          <div className={"lowongan-container tw-mb-8"}>
            <div className={"header-h4"}>Daftar Lowongan</div>
            <div
              className={
                "tw-flex tw-justify-between tw-items-end tw-gap-2 sm:tw-gap-4"
              }
            > 
              <div className={"tw-flex tw-justify-start tw-items-end tw-gap-4 tw-flex-wrap"}>
                <label htmlFor="search">
                  <SearchInput
                    name={"search"}
                    value={search}
                    onChange={handleSearch}
                    aria-label="Search disini"
                  />
                </label>

                <Dropdown
                  label={"Term"}
                  name={"term"}
                  value={selectedTerm}
                  onChange={handleDropdownChange}
                  options={Options}
                  aria-label="Term"
                />
                {/* add new dropdown for jenjang */}
                <Dropdown
                  label={"Jenjang"}
                  name={"jenjang"}
                  value={selectedJenjang}
                  onChange={handleDropdownJenjangChange}
                  options={jenjangPendidikan}
                  aria-label="Jenjang"
                />  

                <Button
                  text={"Lihat"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleOnClickFilter}
                />
              </div>
              <div className={"tw-flex"}>{createLowongan}</div>
            </div>
            {lowonganTableComponent}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default LowonganPage;
