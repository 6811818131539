/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect, useMemo } from "react";
import Navbar from "../../Component/Navbar";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/Lowongan.scss";
import "../../App.scss";
import LowonganService from "../../Services/LowonganService";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import SpecialLowonganItemTable from "../../Component/SpecialLowonganItemTable";

const LowonganSpesialItemPage = () => {
  const role = sessionStorage.getItem("role") || "mahasiswa";
  const username = sessionStorage.getItem("username") || "John Doe";
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [itemList, setItemList] = useState();

  const handleDeleteClick = (item) => {
    setShowConfirmModal(true);
  };

  const fetchitemList = async () => {
    try {
      const response = await LowonganService.getItem();
      setItemList(response.data.data);
    } catch (error) {
      console.error("Failed to fetch items:", error);
    }
  };

  useEffect(() => {
    fetchitemList();
  }, []);

  const Items = useMemo(() => {
    if (itemList && itemList.length > 0) {
      return itemList;
    }
    return [];
  }, [itemList]);

  const navigate = useNavigate();

  let itemTableComponent;
  let createLowongan;

  createLowongan = (
    <Button
      text={"Buat Item"}
      onClick={() => navigate("/lowongan/items/create")}
      background={"#4D8AFF"}
      color={"#FFFFFF"}
    />
  );

  if (role === "admin") {
    if (Items && Items.length > 0) {
      itemTableComponent = (
        <SpecialLowonganItemTable
          items={[["Nomor", "Nama Item", "Operasi"]]}
          itemslist={Items}
        />
      );
    }
  } else {
    itemTableComponent = "";
  }

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content"}>
          <div className={"lowongan-container tw-mb-8"}>
            <div className={"header-h4"}>Daftar Item</div>
            <div
              className={
                "tw-flex tw-justify-between tw-items-end tw-gap-2 sm:tw-gap-4"
              }
            >
              <div className={"tw-flex"}>{createLowongan}</div>
            </div>
            {itemTableComponent}
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default LowonganSpesialItemPage;
