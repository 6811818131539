/* eslint-disable no-unused-vars */
import Select, { StylesConfig } from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const SingleSelect = (props) => {
  const isDisabled = props.isDisabled ? props.isDisabled : false;
  return (
    <div className="tw-w-full">
      <Select
        value={props.value}
        onChange={props.onChange}
        closeMenuOnSelect={true}
        components={animatedComponents}
        defaultValue={props.defaultValue}
        isDisabled={isDisabled}
        // the options passed in the form of array of objects
        // with value and label as the names
        options={props.options}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: "12px",
          }),
        }}
      />
    </div>
  );
};

export default SingleSelect;
