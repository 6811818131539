import Filter3 from "../../../Component/GroupOne/Filter3";
import Button from "../../../Component/GroupOne/Buttons/Button";
import { Fragment, useState, useEffect } from "react";
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import Table from "../../../Component/GroupOne/Table";
import Navbar from "../../../Component/GroupOne/Navbar";
import mark from "../../../assets/group1/icons/mark.svg";
import { eachYearOfInterval, format } from "date-fns";
import AuthService from "../../../Services/AuthService";
import HonorService from "../../../Services/HonorService";
import { toast, ToastContainer } from "react-toastify";
import SearchSelect2 from '../../../Component/GroupOne/SearchSelect2';

const PembayaranHonorPerAsisten = () => {
  const [selectedTahun, setSelectedTahun] = useState('');
  const [selectedBulan, setSelectedBulan] = useState('');
  const [selectedAsisten, setSelectedAsisten] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [bulanSelected, setBulanSelected] = useState('');
  const [tahunSelected, setTahunSelected] = useState('');
  const [asistenSelected, setAsistenSelected] = useState('');
  const [statusSelected, setStatusSelected] = useState('');
  const [usernameList, setUsernameList] = useState([]);
  const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();
  const [content, setContent] = useState('')

  const handleTahunChange = (tahun) => {
    setSelectedTahun(tahun);
  };

  const handleBulanChange = (bulan) => {
    setSelectedBulan(bulan);
  };

  const handleAsistenChange = (asisten) => {
    setSelectedAsisten(asisten);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleButtonClick = async () => {
    if (selectedTahun === "Tahun" || selectedTahun === "" || selectedBulan === "Bulan" || selectedBulan === ""
      || selectedAsisten === "Username" || selectedAsisten === "" || selectedStatus === "Status" || selectedStatus === "") {
      toast.error("Tahun, bulan, username, dan status harus dipilih terlebih dahulu", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return
    }

    try {
      const response = await HonorService.getHonorPerAsisten(selectedTahun, selectedBulan, selectedAsisten, selectedStatus)
      setContent(response.data.sort((a, b) => {
        const dateA = new Date(a.tahun, a.bulan - 1)
        const dateB = new Date(b.tahun, b.bulan - 1)
        return dateB - dateA
      }))
    } catch (error) {
      console.error('Error fetching data:', error)
    }

    setBulanSelected(selectedBulan);
    setTahunSelected(selectedTahun);
    setAsistenSelected(selectedAsisten);
    setStatusSelected(selectedStatus);
  };

  let tableComponent;
  tableComponent = (
    <Table
      items={['NPM', 'Nama', 'Username', 'Bulan', 'Matakuliah', 'Jumlah Jam', 'Honor Per Jam', 'Jumlah Pembayaran', 'Status']}
      user={'admin3'}
      content={content}
    />
  );

  const fetchAllMahasiswa = async () => {
    try {
      const response = await AuthService.getAllMahasiswa();
      setUsernameList(response.data.map(item => item.username));
    } catch (error) {
      console.error("Failed to fetch mahasiswa:", error);
    }
  };

  useEffect(() => {
    fetchAllMahasiswa();
  }, [])

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"content-group1"}>
          <div className={"header-h5"}>Lihat Pembayaran Honor Per Asisten</div>
          <div className={"divider"}></div>
          <div className={"filters-container-perbulan"}>
            <Filter3
              text={"Tahun"}
              items={["Tahun", ...years]}
              onFilterChange={handleTahunChange} />
            <Filter3
              text={"Bulann"}
              items={["Bulan", "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]}
              onFilterChange={handleBulanChange} />
            <SearchSelect2
              onChange={(value) => { handleAsistenChange(value)}}
              placeholder='Username'
              options={usernameList.map(username => ({ value: username, label: username }))}
              required={true}
              optionFilterProp={'label'}
              width={'200px'}
            />
            <Filter3
              text={"Status"}
              items={["Status", "Semua", "Dilaporkan", "Ditolak", "Telah Disetujui", "Diproses"]}
              onFilterChange={handleStatusChange} />
            <Button text={"Lihat Laporan"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleButtonClick} />
          </div>
          {content && content.length > 0 ? (
            <>
              {tableComponent}
            </>
          ) : (
            (bulanSelected && bulanSelected !== "Bulan") || (tahunSelected && tahunSelected !== "Tahun") || (asistenSelected && asistenSelected !== "Nama Asisten") || (statusSelected && statusSelected !== "Status") ? (
              <>
                <div className={"no-content"}>
                  <img
                    src={mark}
                    className={"pointer enlarged"}
                    alt="fail"
                  />
                  {`Tidak ada log asisten ${asistenSelected} dengan status ${statusSelected.toLowerCase()} pada: ${bulanSelected} ${tahunSelected}`}
                </div>
              </>
            ) : null
          )
          }
        </div>
        <ToastContainer />
      </div>
    </Fragment>
  );
};

export default PembayaranHonorPerAsisten;
