/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Component/Navbar";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/CreateLowongan.scss";
import GeneralService from "../../Services/GeneralService";
import LowonganService from "../../Services/LowonganService";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MultiSelect from "../../Component/MultiSelect";
import SingleSelect from "../../Component/SingleSelect";
import NumInput from "../../Component/NumInput";
import TextInput from "../../Component/TextInput";
import Spinner from "../../Component/Spinner";

const LowonganSpesialUpdatePage = () => {
  const navigate = useNavigate();
  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const id = pathParts[pathParts.length - 1];
  var lowonganid = "";
  if (/^\d+$/.test(id)) {
    lowonganid = id;
  } else {
    lowonganid = 1;
  }

  // tahun ajaran
  const [tahunAjaranSelected, setTahunAjaranSelected] = useState();

  // semester
  const semesterOptions = [
    {
      value: "ganjil",
      label: "Ganjil",
    },
    {
      value: "genap",
      label: "Genap",
    },
    {
      value: "pendek",
      label: "Pendek",
    },
  ];
  const [semesterSelected, setSemesterSelected] = useState();
  const [isTermValid, setIsTermValid] = useState(true);

  // nama lowongan spesial
  const [itemspesialOptions, setItemSpesialOptions] = useState([]);
  const [itemspesialSelected, setItemSpesialSelected] = useState();
  const handleItemSpesialOnChange = (selected) => {
    setItemSpesialSelected(selected);
  };

  
  const [prodiSelected, setProdiSelected] = useState();
  const KODE_KURIKULUM_TO_PRODI_MAPPING = {
    "01.00.12.01": "S1 Reguler - Ilmu Komputer",
    "02.00.12.01": "S1 KKI - Ilmu Komputer",
    "03.00.12.01": "S2 - Ilmu Komputer (MIK)",
    "04.00.12.01": "S2 - Teknologi Informasi (MTI)",
    "05.00.12.01": "S3 - Ilmu Komputer",
    "06.00.12.01": "S1 Reguler - Sistem Informasi",
    "07.00.12.01": "S1 Ekstensi - Sistem Informasi",
    "08.00.12.01": "S1 Paralel - Sistem Informasi",
    "09.00.12.01": "S1 Paralel - Ilmu Komputer",
  }

  // status
  const statusOptions = [
    {
      value: "Buka",
      label: "Buka",
    },
    {
      value: "Tutup",
      label: "Tutup",
    },
  ];
  const [statusSelected, setStatusSelected] = useState();
  const handleStatusOnChange = (selected) => {
    setStatusSelected(selected);
  };

  // jumlah asisten
  const [jumlahAsisten, setJumlahAsisten] = useState(1);
  const handleJumlahAsistenOnChange = (event) => {
    setJumlahAsisten(event.target.value);
  };

  // pengajar
  const [pengajarOptions, setPengajarOptions] = useState([]);
  const [pengajarSelected, setPengajarSelected] = useState();
  const handlePengajarOnChange = (selected) => {
    setPengajarSelected(selected);
  };

  // syarat tambahan
  const [syaratTambahan, setSyaratTambahan] = useState("");
  const handleSyaratTambahanOnChange = (event) => {
    setSyaratTambahan(event.target.value);
  };

  useEffect(() => {
    const fetchLowonganSpecialDetails = async () => {
      try {
        const response =
          await LowonganService.getLowonganSpecialById(lowonganid);
        const results = response.data.data[0];
        const prodi = KODE_KURIKULUM_TO_PRODI_MAPPING[results.kode_kurikulum.split('-')[0]]

        setItemSpesialSelected({
          value: { id: parseInt(results.item_id), nama: results.nama_lowongan },
          label: results.nama_lowongan,
        });
        setProdiSelected({
          value: prodi,
          label: prodi,
        })
        setTahunAjaranSelected({
          value: results.tahun_ajaran,
          label: results.tahun_ajaran,
        });
        setSemesterSelected({
          value: results.semester,
          label:
            results.semester.charAt(0).toUpperCase() +
            results.semester.slice(1),
        });
        setStatusSelected({
          value: results.status,
          label:
            results.status.charAt(0).toUpperCase() + results.status.slice(1),
        });
        setJumlahAsisten(results.jumlah_asisten);
        setPengajarSelected(
          results.lowongan_pembuka.map((item) => {
            return {
              value: item,
              label: item.name,
            };
          })
        );
        setSyaratTambahan(results.syarat_tambahan);
      } catch (error) {
        console.error("failed to fetch lowongan details:", error);
      }
    };

    fetchLowonganSpecialDetails();
  }, [lowonganid]);

  useEffect(() => {
    const fetchDosenList = async () => {
      try {
        const response = await GeneralService.getDosen();
        const results = response.data.data;
        const newDosenOptions = [];

        for (const result of results) {
          newDosenOptions.push({ value: result, label: result["name"] });
        }
        setPengajarOptions(newDosenOptions);
      } catch (error) {
        console.error("Failed to fetch dosen lists:", error);
      }
    };

    fetchDosenList();
  }, []);

  const handleSubmit = (e) => {
    const item_id = itemspesialSelected["value"]["id"].toString();
    const tahun_ajaran = tahunAjaranSelected["value"];
    const semester = semesterSelected["value"].toLowerCase();
    const jumlah_asisten = parseInt(jumlahAsisten);
    const status = statusSelected["value"].toLowerCase();
    const lowongan_pembuka = pengajarSelected.map((pengajar) => ({
      username: pengajar.value.username,
    }));
    const syarat_tambahan = syaratTambahan;

    const requestBody = {
      item_id,
      tahun_ajaran,
      semester,
      jumlah_asisten,
      status,
      lowongan_pembuka,
      syarat_tambahan,
    };
    if (
      itemspesialSelected &&
      tahunAjaranSelected &&
      semesterSelected &&
      jumlahAsisten > 0 &&
      pengajarSelected.length > 0 &&
      isTermValid
    ) {
      LowonganService.updateLowonganSpesial(lowonganid, requestBody)
        .then((response) => {
          toast.success("Lowongan spesial berhasil diedit", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            onClose: () => {
              navigate("/lowongan");
            },
          });
        })
        .catch((error) => {
          console.error("Failed to edit Lowongan Spesial:", error);

          // Show an error toast when there is an error during creation
          toast.error("Tolong Lengkapi Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        });
    } else {
      toast.error("Tolong Lengkapi Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <a href="/lowongan" className={"back medium-m8"}>
          ← Kembali
        </a>
        <div className={"content"}>
          <form className={"body-container"} onSubmit={handleSubmit}>
            <h2>Edit Lowongan Spesial</h2>

            <div className={"card-form-container container-shadow"}>
              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Nama Lowongan</div>
                  <SingleSelect
                    value={itemspesialSelected}
                    options={itemspesialOptions}
                    onChange={handleItemSpesialOnChange}
                    isDisabled={true}
                  />
                </div>
              </div>
              

              <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Prodi</div>
                  <SingleSelect
                    value={prodiSelected}
                    isDisabled={true}
                  />
                </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Tahun Ajaran</div>
                  <SingleSelect
                    value={tahunAjaranSelected}
                    isDisabled={true}
                  />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Semester</div>
                <SingleSelect
                  value={semesterSelected}
                  isDisabled={true}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Status</div>
                <SingleSelect
                  value={statusSelected}
                  options={statusOptions}
                  onChange={handleStatusOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Jumlah Asisten</div>
                <NumInput
                  min={1}
                  value={jumlahAsisten}
                  onChange={handleJumlahAsistenOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Pengajar</div>
                <MultiSelect
                  value={pengajarSelected}
                  options={pengajarOptions}
                  onChange={handlePengajarOnChange}
                />
              </div>

              <div className="tw-flex tw-flex-col tw-gap-2">
                <div className="header-h6">Syarat Tambahan</div>
                <TextInput
                  label={"Syarat Tambahan"}
                  name={"syaratTambahan"}
                  value={syaratTambahan}
                  onChange={handleSyaratTambahanOnChange}
                />
              </div>

              <div
                className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
              >
                <Button
                  text={"Simpan"}
                  color={"#FFFFFF"}
                  onClick={handleSubmit}
                  isDisabled={!isTermValid}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default LowonganSpesialUpdatePage;
