import "../assets/scss/InputBox.scss";

const InputBox = (props) => {
  const min = props.min || 0;
  const max = props.max || 1000;
  const isDisabled = props.disabled || false;
  return (
    <div>
      <input
        type={props.type || "text"}
        className={"inputbox-container-group2"}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        min={min}
        max={max}
        disabled={isDisabled}
      />
    </div>
  );
};

export default InputBox;
