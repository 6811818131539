import { appServerAxios } from "./http-common";

class GeneralService {
  getDosen() {
    return appServerAxios.get("/dosen/");
  }

  getAdmin() {
    return appServerAxios.get("/admin/");
  }

  getPeriodeList() {
    return appServerAxios.get("/periode-list/");
  }

  getKelasListPerTerm(tahun_ajaran, semester) {
    return appServerAxios.get(`/kelas-list/${tahun_ajaran}/${semester}/`);
  }

  
  getKelasListPerTermJen(tahun_ajaran, semester, jenjang) {
   return appServerAxios.get(`/kelas-list/${tahun_ajaran}/${semester}/${jenjang}`);
  }

  getMataKuliahList(kode_kurikulum) {
    return appServerAxios.get(`/matakuliah-list/${kode_kurikulum}/`)
  }

  getMatakuliahPrasyarat(kode_matkul, kode_kurikulum) {
    return appServerAxios.get(
      `/matakuliah/prasyarat/${kode_matkul}/${kode_kurikulum}/`
    );
  }

  getNilaiMahasiswa(username, lowonganId) {
    return appServerAxios.get(
      `/nilai-mahasiswa/${username}/${lowonganId}/`
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new GeneralService();
