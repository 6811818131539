import ClipLoader from "react-spinners/ClipLoader";

const Spinner = ({ size, isLoading }) => {
  return (
    <div>
      <ClipLoader
        color={"#4d8aff"}
        loading={isLoading}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Spinner;
