import '../../../assets/group1/scss/Table/TableAdmin.scss';
import '../../../assets/group1/scss/_variables.scss';
import { useEffect, useState } from "react";
import TableButtons from '../Buttons/TablesButton';


const LogsAdmin = (props) => {
    const [selectedItems, setSelectedItems] = useState([])

    function formatTime(timeString) {
        const time = new Date(`2000-01-01T${timeString}`);
        const hours = time.getHours().toString().padStart(2, '0');
        const minutes = time.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    function checkboxHandler(e) {
        let isSelected = e.target.checked;
        let value = parseInt(e.target.value);
        if (isSelected) {
            setSelectedItems([...selectedItems, value]);
        } else {
            setSelectedItems((prevData) => {
                return prevData.filter((id) => id !== value);
            });
        }

    }
    useEffect(() => {
        if (props.onSelectedItemsChange) {
            props.onSelectedItemsChange(selectedItems);
        }
    }, [selectedItems]);// eslint-disable-line react-hooks/exhaustive-deps

    return (

        props.content ? (
            // Render normally
            props.content.map((item, index) => (
                <tr key={index}>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{ width: '20%' }}>
                        {item["lowongan_name"]}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        {item["mahasiswa"].name}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{ textTransform: 'capitalize' }}>
                        {`${item["semester"]} ${item['tahun_ajaran']}`}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        {new Date(item["datetime"]).toLocaleDateString('en-GB', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{ width: '12%' }}>
                        {`${formatTime(item["start_time"])} - ${formatTime(item["end_time"])}`}<br />{`${Math.floor(item['duration'] / 60) ? Math.floor(item['duration'] / 60) + " hour(s)" : ""} ${item['duration'] % 60 ? item['duration'] % 60 + " minute(s)" : ""}`}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{ width: '20%' }}>
                        {item["description"]}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        {item["status"]}
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"} style={{ width: '2%' }}>
                        <input type="checkbox" checked={selectedItems.includes(item.id)} value={item.id} onChange={checkboxHandler} />
                    </td>
                    <td data-testid="log-entry" className={"tw-text-center tw-p-4"}>
                        <TableButtons logId={item["id"]} />
                    </td>
                </tr>
            ))
        ) : (
            <div>
                Empty
            </div>
        )
    );
}

export default LogsAdmin;
