import '../../assets/group1/scss/Profile.scss';
import Button from "../Button";
import CvViewer from '../CvViewer.jsx';
import { toast, ToastContainer } from "react-toastify";
import AuthService from "../../Services/AuthService";
import { useState, useEffect } from "react";
import SingleSelect from "./SingleSelect.jsx";

const Profile = (props) => {

  const handleDownloadCV = async () => {
    try {
      const token = sessionStorage.getItem("jwtToken");
      const headerData = new Headers();
      headerData.append('Content-type', 'application/json');
      headerData.append("Authorization", "Bearer " + token);
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}${props.props.cv}?forcedownload=1`,
        {
          headers: headerData
        }
      );

      if (!response.ok) {
        toast.error("Mahasiswa tidak mengupload file CV", {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        return;
      }

      const filename = props.props.cv.split("/").pop();

      const blob = await response.blob();

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      a.click();

      window.URL.revokeObjectURL(url);

      toast.success('CV berhasil diunduh', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    } catch (error) {
      console.error('CV belum berhasil diunduh:', error);
      toast.error('CV belum berhasil diunduh', {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    }
  };

  const pengalamanOptions = [
    { value: "Junior", label: "Junior" },
    { value: "Menengah", label: "Menengah" },
    { value: "Senior", label: "Senior" },
  ];

  const [informasiDasar, setInformasiDasar] = useState({
    nama: "",
    npm: "",
    pengalaman: { value: props.props.pengalaman, label: props.props.pengalaman },
  });
  const [selectedPengalaman, setSelectedPengalaman] = useState(props.props.pengalaman)
  useEffect(() => {}, [selectedPengalaman]);

  const handlePengalamanOnChange = (selected) => {
    setInformasiDasar({
      ...informasiDasar,
      pengalaman: selected,
    });
  };

  const handleSubmit = () => {
    let data = { "pk": props.id, "pengalaman": informasiDasar["pengalaman"]["value"] }

    AuthService.upadtePengalaman(data)
      .then((response) => {
        toast.success("Pengalaman berhasil di update", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setSelectedPengalaman(informasiDasar["pengalaman"]["value"])
      })
      .catch((error) => {
        console.error("Failed to update pengalaman:", error);
        toast.error("Pengalaman tidak berhasil di update", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      })
  };

  return (
    <>
      <div className={"profile"}>
        <div className={"header-h4"}>{props.props.name}</div>
        <div className={"profile-info-container"}>
          <div className={"profile-image-container container-shadow"}>
            <img src={props.props.foto_profil} alt="Profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
          </div>
          <div className={"profile-details-container container-shadow"}>
            <div className={"profile-details-heading"}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="16" height="16" rx="4" fill="#4D8AFF" />
              </svg>
              <div className={"header-h7"}>
                Profile Details
              </div>
            </div>
            <div className={"profile-details-body"}>
              <div className={"profile-details-body-divide tw-basis-5/12"}>
                <div className={"profile-details-body-column"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      Nama
                    </div>
                  </div>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base sm:body-p7"}>
                      {props.props.nama}
                    </div>
                  </div>
                </div>
                <div className={"profile-details-body-column"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      NPM
                    </div>
                  </div>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base sm:body-p7"}>
                      {props.props.npm}
                    </div>
                  </div>
                </div>
                <div className={"profile-details-body-column"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      Detail Rekening
                    </div>
                  </div>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base sm:body-p7"}>
                      {props.props.nama_bank} {props.props.no_rek} {props.props.atas_nama}
                    </div>
                  </div>
                </div>
                <div className={"profile-details-body-column"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      Email
                    </div>
                  </div>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base sm:body-p7"}>
                      {props.props.email}
                    </div>
                  </div>
                </div>
              </div>
              <div className={"profile-details-body-divide"}>

                <div className={"profile-details-body-column"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      Jenjang Pendidikan
                    </div>
                  </div>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base sm:body-p7"}>
                      {props.props.jenjang_pendidikan}
                    </div>
                  </div>
                </div>
                <div className={"profile-details-body-column"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      Pengalaman
                    </div>
                  </div>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base sm:body-p7"}>
                      {selectedPengalaman}
                    </div>
                  </div>
                </div>
                <div className={"profile-details-body-column"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      No Telepon
                    </div>
                  </div>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base sm:body-p7"}>
                      {props.props.telepon}
                    </div>
                  </div>
                </div>
                <div className={"profile-details-body-column"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      Waktu Luang
                    </div>
                  </div>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base sm:body-p7"}>
                      {props.props.waktu_luang}
                    </div>
                  </div>
                </div>
                <div className={"profile-details-body-column tw-flex tw-items-center tw-gap-3"}>
                  <div className={"profile-details-body-content"}>
                    <div className={"tw-text-base tw-font-medium sm:medium-m7"} style={{ width: '150px' }}>
                      CV
                    </div>
                  </div>
                  <div className={'profile-details-body-content'}>
                    <div className={'tw-flex tw-items-center tw-gap-3'}>
                      <CvViewer cvUrl={process.env.REACT_APP_BACKEND_URL + props.props.cv} />
                      <Button text={'Unduh CV'} color={"#FFFFFF"} onClick={handleDownloadCV} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <br></br>
            <div className={"header-h5"}>Riwayat Pelanggaran</div>
            <div className='pelanggaran-wrap'>
              {props.props && props.props.pelanggaran && props.props.pelanggaran.length > 0 ? (
                props.props.pelanggaran.map((item) => (
                  <div key={item.id} className="container d-flex justify-content-center align-items-center height-vh">
                    <div className="row d-flex justify-content-center">
                      <div className="col-md-8">
                        <div className="pelanggaran-container d-flex flex-column">
                          <div className="bg-white">
                            <div className="flex-row d-flex">
                              <div className="d-flex flex-row justify-content-start ml-2">
                                <span className={`d-block font-weight-bold no-surat ${item.jenis_surat === 'JenisSurat.KERAS' ? 'keras' : ''}`}>
                                  {item.no_surat}
                                </span><br />
                                <span className="date text-black-50">{item.start_date}</span>
                              </div>
                            </div>
                            <div className="mt-3">
                              <p className="pelanggaran-text">{item.bentuk_pelanggaran}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="pelanggaran-text">
                  {props.props.nama} tidak pernah melakukan pelanggaran akademis
                </div>
              )}
            </div>
            <div className="keterangan">
              <div className="keterangan-item merah">
                <div className="keterangan-box"></div>
                <div className="keterangan-text">Surat Peringatan Keras</div>
              </div>
              <div className="keterangan-item hitam">
                <div className="keterangan-box"></div>
                <div className="keterangan-text">Surat Peringatan</div>
              </div>
            </div>
            <div className={"header-h5"}>Penetapan Honor Rate Per Jam</div>
            <div className="profile-details-body">
              <div className="profile-details-body-column tw-basis-6/12">
                <div className={"profile-details-body-content tw-basis-6/12"}>
                  <div className="tw-text-base tw-font-medium sm:medium-m7" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                    Ubah Pengalaman
                  </div>
                </div>
                <div className={"profile-details-body-content tw-basis-7/12"}>
                  <SingleSelect
                    value={informasiDasar["pengalaman"]}
                    onChange={handlePengalamanOnChange}
                    options={pengalamanOptions}
                  />
                </div>
              </div>
            </div>
            <div className="profile-details-body">
              <div
                className="button-container"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: 0,
                  paddingTop: 0,
                }}
              >
                <Button
                  text={"Simpan"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={handleSubmit}
                />
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;