import "../../assets/group1/scss/InputBox.scss";

const NumInput = ({ label, name, value, onChange, placeholder, disabled, required = false }) => {
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData('text'));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  return (
    <div>
      <input
        type="number"
        className={"inputbox-container-group2"}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        min="0"
        onPaste={preventPasteNegative}
        placeholder={placeholder}
        onKeyPress={preventMinus}
      />
    </div>
  );
};

export default NumInput;
