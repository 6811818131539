import { Link, useNavigate } from 'react-router-dom';
import "../../../assets/group1/scss/Table/TableHonorRef.scss";
import trashIcon from "../../../assets/group1/icons/trash.svg";
import editIcon from "../../../assets/group1/icons/pencil.svg";
import Button from '../Buttons/Button';

const TableHonorRate = ({ honorRatesInitialData, handleHapus, honorRates }) => {
  const navigate = useNavigate();

  const handleDetailClick = (id, jenjang, pengalaman) => {
    navigate(`/detail-honor-rate/mti/${id}?jenjang=${jenjang}&pengalaman=${pengalaman}`);
  };

  const handleTambahClick = (id, jenjang, pengalaman) => {
    navigate(`/add-honor-rate/mti/${id}?jenjang=${jenjang}&pengalaman=${pengalaman}`);
    
};
  

  return (
    <div className={"table-container-group2 container-shadow tw-pt-6"}>
      <table className={"tw-w-full tw-table-auto"}>
        <thead>
          <tr className={"table-header-group2"}>
            <th className="tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl">Jenjang</th>
            <th className="tw-px-4 tw-py-2 tw-text-white ">Pengalaman</th>
            <th className="tw-px-4 tw-py-2 tw-text-white ">Aksi</th>
            <th className="tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl">Operasi</th>
          </tr>
        </thead>
        <tbody>
        {honorRatesInitialData.map((honorRate, i) => (
          <tr key={i}>
            <td className="tw-text-center tw-p-2">{honorRate.jenjang}</td>
            <td className="tw-text-center tw-p-2">{honorRate.pengalaman}</td>
            <td className="tw-text-center tw-p-2">
              {`${honorRate.jenjang}-${honorRate.pengalaman}` in honorRates ? (
                <Button text={"Detail"} onClick={() => handleDetailClick(honorRate.id, honorRate.jenjang, honorRate.pengalaman)} color={"#FFFFFF"} size={"small-button"} width="50px" />
              ) : (
                <Button text={"Tambah"} onClick={() => handleTambahClick(honorRate.id, honorRate.jenjang, honorRate.pengalaman)} color={"#FFFFFF"} background={"#00CC00"} size={"small-button"} width="50px" />
              )}
            </td>
            <td className="tw-text-center tw-p-2">
              {`${honorRate.jenjang}-${honorRate.pengalaman}` in honorRates && (
                <div style={{ display: "flex", justifyContent: "center", gap: "15px" }}>
                  <div className={'pointer'} style={{alignContent: 'center'}}>
                    <Link to={`/edit-honor-rate/mti/${honorRate.id}?jenjang=${honorRate.jenjang}&pengalaman=${honorRate.pengalaman}`}>
                      <img
                        src={editIcon}
                        alt="Edit"
                        className="your-new-icon-class"
                      />
                    </Link>
                  </div>
                  <div className={'pointer'} style={{alignContent: 'center'}}>
                    <img
                      src={trashIcon}
                      alt="Delete"
                      className="your-new-icon-class"
                      onClick={() => handleHapus(honorRates[`${honorRate.jenjang}-${honorRate.pengalaman}`])}
                    />
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableHonorRate;