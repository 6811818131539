import '../../assets/group1/scss/Input/InputBox.scss';
const InputBox = (props) => {
    return (
        <div>
            <div className={"header-h6"} style={{marginBottom: '10px'}}> 
                <label htmlFor={props.name}> {props.label} </label>
            </div>
            <textarea 
                className={"inputbox-container"}
                name={props.name}
                onChange={props.onChange}
                placeholder={props.placeholder}
                value={props.value}
                {...props}
            >
            </textarea>
        </div>
    );
}

export default InputBox;