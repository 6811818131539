import '../../assets/group1/scss/_variables.scss';
import '../../assets/group1/scss/MataKuliah.scss';
import userIcon from '../../assets/group1/icons/user.svg';
import calendarIcon from '../../assets/group1/icons/calendar.svg';
import LongButton from "./Buttons/LongButton";


const MataKuliah = (props) => {
    const {matkulID, matkulID2, nama, namaDosen, tahun, id_matkul, is_spesial} = props
    return (
        <div className={"matkul-container container-shadow"}>
            <div className={"matkul-header"}>
                {is_spesial ? 
                <>
                    <div className={"matkul-id medium-m9"}>SPESIAL</div>
                    <div className={"medium-m9"}></div>
                </>: 
                <>
                    <div className={"matkul-id medium-m9"}>{matkulID}</div>
                    <div className={"medium-m9"}>{matkulID2}</div>
                </>}
            </div>
            <div className={"matkul-details"}>
                <div className={"header-h7"}>
                    {nama}
                </div>
                <div className={"matkul-detail medium-m9"}>
                    <img src={userIcon} alt="userIcon" />
                    {namaDosen}
                </div>
                <div className={"matkul-detail medium-m9"}>
                    <img src={calendarIcon} alt="userIcon" />
                    {tahun}
                </div>
            </div>
            <LongButton text={"Logs"} href={`/matkul/${id_matkul}/log`} background={"#4D8AFF"} color={"#FFFFFF"}/>
        </div>
    );
}

export default MataKuliah;