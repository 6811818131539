import { Fragment, useEffect, useState } from "react";
import Navbar from "../../../Component/GroupOne/Navbar";
import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Page/LihatPembayaranHonor.scss';
import Filter from "../../../Component/GroupOne/Filter";
import Button from "../../../Component/GroupOne/Buttons/Button";
import TableAsdosHonor from "../../../Component/GroupOne/Table/TableAsdosHonor";
import { eachYearOfInterval, format } from "date-fns";
import HonorService from "../../../Services/HonorService";

const AsdosLihatPembayaranHonor = () => {
  const [content, setContent] = useState([]);
  const username = sessionStorage.getItem("username");
  const jenjang_pendidikan = sessionStorage.getItem("jenjangPendidikan");
  const pengalaman = sessionStorage.getItem("pengalaman");
  const user = {
    jenjang_pendidikan: jenjang_pendidikan,
    pengalaman: pengalaman
  }
  const [selectedBulan, setSelectedBulan] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('')
  const [selectedTahun, setSelectedTahun] = useState('')
  const years = eachYearOfInterval({ start: new Date('2018-01-01'), end: new Date() }).map(date => format(date, 'yyyy')).reverse();

  const handleBulanChange = (bulan) => {
    setSelectedBulan(bulan);
  };

  const handleTahunChange = (tahun) => {
    setSelectedTahun(tahun);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleButtonClick = () => {
    let updatedUrl = window.location.href;

    if (selectedBulan) {
      const bulanQuery = `bulan=${selectedBulan}`;
      updatedUrl = updateUrlQueryParameter(updatedUrl, 'bulan', bulanQuery);
    }

    if (selectedStatus) {
      const statusQuery = `status=${selectedStatus}`;
      updatedUrl = updateUrlQueryParameter(updatedUrl, 'status', statusQuery);
    }

    if (selectedTahun) {
      const statusQuery = `tahun=${selectedTahun}`;
      updatedUrl = updateUrlQueryParameter(updatedUrl, 'tahun', statusQuery);
    }

    if (!selectedBulan && !selectedStatus && !selectedTahun) {
      updatedUrl = removeQueryParameters(updatedUrl, ['bulan', 'status', 'tahun']);
    }

    window.location.href = updatedUrl;
  };

  const removeQueryParameters = (url, keys) => {
    keys.forEach((key) => {
      const regex = new RegExp(`[?&]${key}=[^&]+`, 'g');
      url = url.replace(regex, '');
    });

    // Remove any leading '?' or '&' characters left after removing the parameters
    url = url.replace(/^[?&]+/, '');

    return url;
  };

  const updateUrlQueryParameter = (url, key, value) => {
    /* istanbul ignore if */
    if (url.includes(`${key}=`)) {
      // If the URL already contains the query parameter, update it
      return url.replace(new RegExp(`${key}=[^&]+`), value);
    } else {
      // If the URL doesn't contain the query parameter, add it
      return `${url}${url.includes('?') ? '&' : '?'}${value}`;
    }
  };

  const fetchMahasiswaLog = async () => {
    await HonorService.getMahasiswaLog(username)
      .then((response) => {
        setContent(response.data.logs);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  useEffect(() => {
    fetchMahasiswaLog()
  }, [username]);

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar/>
        <div className={"content-group1"}>
          <div className={"header-h5"}>Pembayaran Honor</div>
          <div className={"divider"}></div>
          <div className={"filters-container-perbulan"}>
            <Filter text={"Tahun"} items={["Tahun", ...years]} onFilterChange={handleTahunChange} />
            <Filter text={"Bulan"} items={["Bulan", "Januari", "Februari", "Maret", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]} onFilterChange={handleBulanChange} />
            <Filter text={"Status"} items={["Status", "Dilaporkan", "Telah Disetujui", "Diproses", "Ditolak"]} onFilterChange={handleStatusChange} />
            <Button text={"Lihat Laporan"} background={"#4D8AFF"} color={"#FFFFFF"} onClick={handleButtonClick} />
          </div>
          <TableAsdosHonor items={["Waktu", "Mata Kuliah", "Jam", "Honor per Jam",
            "Total Honor", "Status"]} content={content} user={user}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default AsdosLihatPembayaranHonor;
