import "../assets/scss/Table/Table.scss";
import "../assets/scss/_variables.scss";
import "../App.scss";
import { useState } from "react";
import Pagination from "./Pagination";
import "../assets/scss/Pagination.scss";
import edit from "../assets/icons/edit.svg";
import trash from "../assets/icons/trash.svg";
import LowonganService from "../Services/LowonganService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Modal from "./PopUpModal";
import Tooltip from "@mui/material/Tooltip";

const TableRow = ({ rowData, index }) => {
  const navigate = useNavigate();
  const deleteData = () => {
    setShowConfirmModal(true);
  };
  const editData = () => {
    // Redirect to the update page with the ID as a query parameter
    navigate(`/lowongan/items/update/${rowData.id}`);
  };
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleDeleteClick = async (e) => {
    try {
      const response = await LowonganService.deleteLowonganSpecialItem(rowData.id);

      if (response.status === 200) {
        toast.success("Item lowongan spesial berhasil didelete", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          onClose: () => {
            window.location.reload();
          },
        });
      } else {
        console.error("Failed to delete Lowongan. Status:", response.status);
      }
    } catch (error) {
      console.error("Failed to delete Lowongan:", error);
    }
  };
  return (
    <tr>
      <td className="tw-text-center" width="10px">{index + 1}</td>
      <td className="tw-text-center tw-p-2">
        {rowData.nama}
      </td>
      <td className="tw-text-center">
        <div className="tw-flex tw-gap-2 tw-justify-center">
          <div onClick={editData}>

            <Tooltip title="Edit" placement="top" arrow>
              <img
                className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center tw-cursor-pointer"
                src={edit}
                alt={"edit"}
              />
            </Tooltip>
          </div>
          <div className="tw-flex" onClick={deleteData}>
            <Tooltip title="Edit" placement="top" arrow>
              <img
                className="tw-transition-transform tw-transform hover:tw-scale-90 hover:tw-scale-90 focus:tw-scale-90 focus:tw-scale-90 tw-duration-300 tw-self-center tw-cursor-pointer"
                src={trash}
                alt={"delete"}
              />
            </Tooltip>
          </div>
        </div>
      </td>
      {showConfirmModal && (
        <Modal
          onConfirm={handleDeleteClick}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </tr>
  );
};

const LowonganSpesialItemTable = ({ items, itemslist, isDelete, setIsDelete }) => {
  const headerItems = items[0];

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = itemslist.slice(
    indexOfFirstRecord,
    indexOfLastRecord,
  );
  const nPages = Math.ceil(itemslist.length / recordsPerPage);



  if (itemslist && itemslist.length > 0) {
    return (
      <div className={"table-container-group2 container-shadow tw-pt-6"}>
        <table className={"tw-w-full tw-table-auto"}>
          <thead>
            <tr className={"table-header-group2"}>
              {headerItems.map((item, index) => {
                let styleName;
                if (index === 0) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-l-xl";
                } else if (index === headerItems.length - 1) {
                  styleName = "tw-px-4 tw-py-2 tw-text-white tw-rounded-r-xl";
                } else {
                  styleName = "tw-px-4 tw-py-2 tw-text-white";
                }

                return (
                  <th key={index} className={styleName}>
                    <div className="">{item}</div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {currentRecords.length > 0 &&
              currentRecords.map((rowData, index) => (
                <TableRow
                  key={index}
                  index={index}
                  rowData={rowData}
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                />
              ))}
          </tbody>
        </table>
        <Pagination
          nPages={nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    );
  } else {
    return (
      <div className={"table-container container-shadow tw-pt-6"}>
        <h2>Tidak ada item</h2>
      </div>
    );
  }
};

export default LowonganSpesialItemTable;
