import { Outlet, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const RouteGuard = () => {
  let flag = false;

  const isJwtValid = () => {
    const jwtToken = sessionStorage.getItem("jwtToken");
    if (!jwtToken) {
      return false;
    }

    const decodedToken = jwtDecode(jwtToken);
    const expirationTime = decodedToken.exp;
    const currentTime = Math.floor(Date.now() / 1000);
    return currentTime < expirationTime;
  };

  if (isJwtValid()) {
    flag = true;
  }

  sessionStorage.setItem("flag", flag.toString());
  return flag ? <Outlet /> : <Navigate to="/login" />;
};

export default RouteGuard;
