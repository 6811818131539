import '../../assets/group1/scss/Input/SearchSelect.scss';
import { Select } from 'antd'

const SearchSelect = (props) => {
    return (
        <div>
            <div className={"header-h6"} style={{marginBottom: '10px'}}> {props.label} </div>
            <Select
                className={"searchselect-container body-p7"} 
                onChange={props.onChange}
                placeholder={props.placeholder}
                showSearch={true}
                filterOption={props.filterOption}
                options={props.options}
                bordered={false}
                optionFilterProp={props.optionFilterProp}
                value={props.value} 
            />   
        </div>
    );
}

export default SearchSelect;