import '../../assets/group1/scss/modal.scss';
import '../../assets/group1/scss/_variables.scss';
import Button from './Buttons/Button';

const DeleteLog = (props) => {
    if (!props.isOpen) return null;
    
    return (
        <div className={"modal"}>
            <div className={"modal__content"}>
                <div className={"modal__header"}>
                    <div className={"body-p7"}>Apakah anda yakin ingin menghapus log ini?</div>
                </div>
                <div className={"modal__body"}>
                    <Button text={"Batal"} onClick={props.onCancel} background={"#FF0000"} color={"#FFFFFF"} />
                    <Button text={"Setuju"} onClick={props.onConfirm} background={"#00CC00"} color={"#FFFFFF"} />
                </div>
            </div>
        </div>
    );
};

export default DeleteLog;