import '../../../assets/group1/scss/_variables.scss';
import '../../../assets/group1/scss/Log.scss';
import Navbar from "../../../Component/GroupOne/Navbar";
import Button from "../../../Component/GroupOne/Buttons/Button";
import Table from "../../../Component/GroupOne/Table";
import { useParams } from "react-router-dom";
import { Fragment, useEffect, useState, useMemo } from "react";
import { getJenjang } from './logUtil';

const AsdosLogPage = () => {
  const { matkul_id } = useParams();
  const [content, setContent] = useState([]);
  const [matkul, setMatkul] = useState([]);
  const username = sessionStorage.getItem("username");
  const token = sessionStorage.getItem("jwtToken");
  const headerData = useMemo(() => {
    const headers = new Headers();
    headers.append('Content-type', 'application/json');
    headers.append("Authorization", "Bearer " + token);
    return headers;
  }, [token]);
  const [jenjang, setJenjang] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/log/lowongan/${matkul_id}/`, {
      method: 'GET',
      headers: headerData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setContent(data.logs || []);
        setMatkul(data.lowongan || [])
        getJenjang(data.lowongan.kode_matkul, data.lowongan.kode_kurikulum, setJenjang)
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [matkul_id, headerData, token]);


  return (
    <Fragment>
      <div className={"view"}>
        <Navbar name={username} />
        <div className={"content-group1"}>
          <a href={`/asdos/listLowongan`} className={"medium-m8"}>
            ← Kembali ke Daftar Matakuliah
          </a>
          <div className={"header-h5"}>Daftar Log</div>
          <div className={"divider"}></div>
          <div className={"filters-container"}>
            <div className={"medium-m7"}>{matkul.nama}</div>
            <Button text={"Tambah Log"} href={`/matkul/${matkul_id}/log/${jenjang}/add`} background={"#4D8AFF"} color={"#FFFFFF"} />
          </div>
          <div className={"logs-container"}>
            <Table items={
              [
                "Tanggal Log",
                "Durasi",
                "Kategori",
                "Deskripsi",
                ...(jenjang === "MTI" ? ["Moda"] : []),
                "Status Log",
                "Operasi",
              ]
            } content={content} user={"asisten"} jenjang={jenjang} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AsdosLogPage
