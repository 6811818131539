/* eslint-disable no-unused-vars */
import { Fragment, useState, useEffect } from "react";
import Navbar from "../../Component/Navbar";
import Button from "../../Component/Button";
import "../../assets/scss/_variables.scss";
import "../../assets/scss/CreateLowongan.scss";
import LowonganService from "../../Services/LowonganService";
import GeneralService from "../../Services/GeneralService";
import NumInput from "../../Component/NumInput";
import LamaranService from "../../Services/LamaranService";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DaftarLowonganPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const pathname = window.location.pathname;
  const pathParts = pathname.split("/");
  const id = pathParts[pathParts.length - 1];
  var lowonganid = "";
  if (/^\d+$/.test(id)) {
    lowonganid = id;
  } else {
    lowonganid = 1;
  }
  const [namaLowongan, setNamaLowongan] = useState();
  const [syaratTambahan, setSyaratTambahan] = useState();
  const [namaAsisten, setNamaAsisten] = useState("");
  const [nilaiMahasiswaData, setNilaiMahasiswaData] = useState();
  const [lamaranData, setLamaranData] = useState({
    lowongan: lowonganid,
    ipk: "",
    sks_diambil: 0,
    status: "melamar",
    tanggal_daftar: "",
  });
  
  const createLamaran = async () => {
    try {
      await LamaranService.createLamaran(lamaranData);
      toast.success("Lowongan berhasil didaftar", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        onClose: () => {
          navigate("/lowongan");
        },
      });
    } catch (error) {
      console.error("Failed to create lamaran:", error);
      if (error.response && error.response.status === 403) {
        toast.error("Maaf, anda belum dapat mendaftar lowongan asisten dosen karena sedang berada pada masa sanksi", {
          position: "top-center",
        });
      } else {
        toast.error("Eror : Tidak berhasil mendaftar lamaran", {
          position: "top-center",
        });
      }
    }
  };

  useEffect(() => {
    const fetchLowonganData = async () => {
      try {
        const response = await LowonganService.getLowonganById(lowonganid);
        const data = response.data.data;
        setNamaLowongan(data.nama_lowongan);
        setSyaratTambahan(data.syarat_tambahan);

        const namaAsistenFromsessionStorage = sessionStorage.getItem("nama");
        setNamaAsisten(namaAsistenFromsessionStorage);
      } catch (error) {
        console.error("Failed to fetch lowongan data:", error);
      }
    };
    fetchLowonganData().catch(console.error);
  }, [lowonganid]);

  useEffect(() => {
    const fetchNilaiMahasiswaData = async (username, lowonganid) => {
      try {
        const response = await GeneralService.getNilaiMahasiswa(
          username,
          lowonganid
        );
        const data = response.data.data;
        setNilaiMahasiswaData(data);
      } catch (error) {
        console.error("Failed to fetch nilai mahasiswa data:", error);
      }
    };

    const username = sessionStorage.getItem("username");
    fetchNilaiMahasiswaData(username, lowonganid).catch(console.error);
  }, [lowonganid]);

  return (
    <Fragment>
      <div className={"view"}>
        <Navbar />
        <div className={"back medium-m8"} style={{ cursor: 'pointer' }} onClick={() => navigate(-1)}>
            ← Kembali
            </div>
        <div className={"content"}>
          <form className={"body-container"}>
            <h2>Daftar Lowongan</h2>
            <div className={"card-form-container container-shadow"}>
              <>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Lowongan</div>
                  <div className="body-p7">{namaLowongan}</div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Nama Asisten</div>
                  <div className="body-p7">{namaAsisten}</div>
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">IPK</div>
                  <NumInput
                    value={lamaranData.ipk}
                    onChange={(e) =>
                      setLamaranData({
                        ...lamaranData,
                        ipk: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">SKS Diambil</div>
                  <NumInput
                    value={lamaranData.sks_diambil}
                    onChange={(e) =>
                      setLamaranData({
                        ...lamaranData,
                        sks_diambil: e.target.value,
                      })
                    }
                  />
                </div>
              </>
              {nilaiMahasiswaData ? (
                <div className="tw-flex tw-flex-col tw-gap-2">
                  <div className="header-h6">Nilai Matakuliah Prasyarat</div>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ width: "90%" }}></th>
                        <th style={{ width: "10%" }}></th>
                      </tr>
                    </thead>

                    <tbody>
                      {nilaiMahasiswaData && nilaiMahasiswaData.prerequisites.map((item, index) => (
                        <tr key={index} className="odd:tw-bg-[#4d8aff] odd:tw-bg-opacity-20">
                          <td className="body-p8 tw-py-2 tw-px-4">
                            {item.tahun
                              ? `${item.kode_matkul} - ${item.nama_matkul} - Tahun ${item.tahun} Term ${item.term}`
                              : `${item.kode_matkul} - ${item.nama_matkul} - Belum Mengambil`}
                          </td>
                          <td className="body-p8">{item.nilai}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}

              <div className="tw-flex tw-flex-col tw-gap-2">
                {syaratTambahan && (
                  <>
                    <div className="header-h6">Syarat Tambahan</div>
                    <div className="body-p7">{syaratTambahan}</div>
                  </>
                )}
              </div>

              <div 
              className="button-container"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingLeft: 0,
                paddingTop: 0,
              }}>
                <Button
                  text={"Daftar"}
                  type="submit"
                  color={"#FFFFFF"}
                  onClick={(e) => {
                    createLamaran();
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default DaftarLowonganPage;
