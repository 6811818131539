import { appServerAxios } from "./http-common";

class LowonganService {
  getLowonganList(role, username, tahun_ajaran, semester, jenjang) {
    return appServerAxios.get("/lowongan", {
      params: {
        role: role,
        username: username,
        tahun_ajaran: tahun_ajaran,
        semester: semester,
        //updated for siasisten-mti
        jenjang: jenjang
      },
    });
  }

  getTerm() {
    return appServerAxios.get("/term");
  }
  
  getItem() {
    return appServerAxios.get("lowongan/spesial-item")
  }

  postLowonganSpecialItem(data) {
    return appServerAxios.post("/lowongan/spesial-item/", data);
  }

  updateLowonganSpesialItem(lowonganId, data) {
    return appServerAxios.put(`/lowongan/spesial-item/${lowonganId}`, data);
  }

  deleteLowonganSpecialItem(lowonganId) {
    return appServerAxios.delete(`/lowongan/spesial-item/${lowonganId}`);
  }

  postLowonganList(data) {
    return appServerAxios.post("/lowongan/", data);
  }

  deleteLowonganList(lowonganId) {
    return appServerAxios.delete(`/lowongan/${lowonganId}`);
  }

  updateLowongan(lowonganId, data) {
    return appServerAxios.put(`/lowongan/${lowonganId}`, data);
  }

  updateLowonganSpesial(lowonganId, data) {
    return appServerAxios.put(`/lowongan/spesial/${lowonganId}`, data);
  }

  getLowonganById(id) {
    return appServerAxios.get(`/lowongan/${id}`);
  }

  getLowonganSpecialById(id) {
    return appServerAxios.get(`/lowongan/spesial/${id}`);
  }

  postLowonganSpecial(data) {
    return appServerAxios.post("/lowongan/spesial/", data)
  }

  deleteLowonganSpecialList(lowonganId) {
    return appServerAxios.delete(`lowongan/spesial/${lowonganId}`)
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LowonganService();
