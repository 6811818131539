import { useEffect, useState } from "react";
import "../../../assets/group1/scss/Table/TableHonor.scss";
import "../../../assets/group1/scss/_variables.scss";

const TableHonor = () => {
  const [honorData, setHonorData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedRates, setSelectedRates] = useState({});
  const [showSaveButton, setShowSaveButton] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5; // Set the number of items per page

  useEffect(() => {
    // Fetch honor data from the API
    fetch(`${process.env.REACT_APP_BASE_URL}/honor/appear-honor/`)
        .then((response) => response.json())
        .then((data) => setHonorData(data))
        .catch((error) => console.error("Error fetching honor data:", error));

    // Fetch user data from the API
    fetch(`${process.env.REACT_APP_BASE_URL}/auth/data/`)
        .then((response) => response.json())
        .then((data) => setUserData(data))
        .catch((error) => console.error("Error fetching user data:", error));
  }, []);

  const handleRateChange = (userId, rate) => {
    setSelectedRates((prevRates) => ({
      ...prevRates,
      [userId]: rate,
    }));
    setShowSaveButton(true);
  };

  const handleSaveChanges = () => {
    const promises = Object.entries(selectedRates).map(([userId, selectedRate]) => {
      const payload = {
        pk: parseInt(userId),
        username: userData.find((user) => user.pk === parseInt(userId)).username,
        honor: selectedRate === "null" ? null : parseInt(selectedRate),
      };

      return fetch(`${process.env.REACT_APP_BASE_URL}/auth/data/${userId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            fetch(`${process.env.REACT_APP_BASE_URL}/auth/data/`)
                .then((response) => response.json())
                .then((data) => setUserData(data))
                .catch((error) => console.error("Error fetching user data:", error));
            return data; // Return the data to be handled later
          })
          .catch((error) => {
            console.error("Error updating user:", error);
            throw error; // Throw the error to be handled later
          });
    });

    // Use Promise.all to wait for all fetch requests to complete
    Promise.all(promises)
        .then(() => {
          setSelectedRates({});
          setShowSaveButton(false);
        })
        .catch((error) => {
          console.error("Error updating users:", error);
        });
  };

  const pageCount = Math.ceil(userData.length / itemsPerPage);

  const pagedContent = userData.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
  );

  return (
      <div className="table-container-honor container-shadow">
        {/* Title */}
        <div className="table-title-honor">
          <div className="title-npm header-h7">Id</div>
          <div className="title-name header-h7">Username</div>
          <div className="title-rate header-h7">Rate</div>
        </div>

        {/* Content */}
        <div className="table-content-container-honor">
          {userData.length > 0 && (
              <div className="content-honor-2">
                {pagedContent.map((user) => (
                    <div key={user.pk} className="table-content-honor">
                      <div className="content-honor-npm medium-m8">{user.pk}</div>
                      <div className="content-honor-nama medium-m8">{user.username}</div>
                      <div className="content-honor-rate medium-m8">
                        <select
                            value={selectedRates[user.pk] || "null"}
                            onChange={(e) => handleRateChange(user.pk, e.target.value)}
                            className="dropdown-honor-select"
                            data-testid={`rate-dropdown-${user.pk}`}
                        >
                          <option value="null">-</option>
                          {honorData.map((item) => (
                              <option key={item.id} value={item.id}>
                                {item.amount}/jam
                              </option>
                          ))}
                        </select>
                      </div>
                    </div>
                ))}
              </div>
          )}
        </div>

        {/* Save Changes Button */}
        {showSaveButton && (
            <button onClick={handleSaveChanges} className="save-changes-button">
              <div className="save-honor-text">Save Changes</div>
            </button>
        )}
        <div className={"pagination"}>
          {/* Page Number Buttons */}
          <div className={"pages"}>
            {Array.from({ length: pageCount }, (_, index) => (
                <button className={currentPage === index ? 'page-disabled medium-m8' : 'page medium-m8'}
                        key={index}
                        onClick={() => setCurrentPage(index)}
                        disabled={currentPage === index}
                >
                  {index + 1}
                </button>
            ))}
          </div>
          {/* Page Information */}
          <div className={"medium-m9"}>
            Page {currentPage + 1} of {pageCount}
          </div>
        </div>
      </div>
  );
};

export default TableHonor;

