import '../../../assets/group1/scss/Buttons/Button.scss';

const LongButton = (props) => {
    return (
        <a className={"space-container"} href={props.href} onClick={props.onClick}>
            <div style={ props.background ? {background: props.background } : {}} className={"long-button-container button-style"} >
                <div style={{color: props.color}} className={"button-text medium-m8"}>
                    {props.text}
                </div>
            </div>
        </a>
    );
}

export default LongButton;