import '../../assets/group1/scss/Table/Table.scss';
import '../../assets/group1/scss/_variables.scss';
import LogsDosen from "./Logs/LogsDosen";
import LogsAssistant from "./Logs/LogsAssistant";
import LogBelumKlaim from './Logs/LogBelumKlaim';
import { useState, useEffect } from 'react';
import SelectAllButton from './Buttons/SelectAllButton';
import LaporanPembayaran from './Logs/LaporanPembayaran';
import HonorPerAsisten from './Honor/HonorPerAsisten';

const Table = ({ items, content, user, jenjang }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const headerItems = items;
  const handleSelectedItemsChange = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  }

  if (user === "asisten") {
    content = content.sort((a, b) => {
      const dateA = new Date(a.datetime);
      const dateB = new Date(b.datetime);
      return dateB - dateA;
    })
  } else {
    content = content.sort((a, b) => {
      const dateA = new Date(a.tahun, a.bulan);
      const dateB = new Date(b.tahun, b.bulan);
      return dateB - dateA;
    })
  }
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const pageCount = Math.ceil(content.length / itemsPerPage);

  const pagedContent = content.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  useEffect(() => {
  }, [selectedItems]);
  return (
    <div className={"table-container-group2 container-shadow tw-pt-6"}>
      <table className="tw-w-full tw-table-auto">
        <thead>
          <tr className={"table-header-group2"}>
            {headerItems.map((item, index) => {
              let styleName;
              if (index === 0) {
                styleName = "tw-px-4 tw-py-4 tw-text-white tw-rounded-l-xl";
              } else if (index === headerItems.length - 1) {
                styleName = "tw-px-4 tw-py-4 tw-text-white tw-rounded-r-xl";
              } else {
                styleName = "tw-px-4 tw-py-4 tw-text-white";
              }

              return (
                <th key={index} className={styleName}>
                  <div className="">{item}</div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {user === "dosen" ? <LogsDosen content={pagedContent} onSelectedItemsChange={handleSelectedItemsChange} jenjang={jenjang} /> : user === "asisten" ? <LogsAssistant content={pagedContent} jenjang={jenjang} /> : user === "admin2" ? <LaporanPembayaran content={pagedContent} /> : user === "admin" ? <LogBelumKlaim content={pagedContent} /> : <HonorPerAsisten content={pagedContent}/>}
        </tbody>
      </table>
      <div className={"pagination"} style={{alignItems: 'center', margin: 'auto'}}>
        {/* Page Number Buttons */}
        <div className={"pages"}>
          {Array.from({ length: pageCount }, (_, index) => (
            <button className={currentPage === index ? 'page-disabled medium-m8' : 'page medium-m8'}
              key={index}
              onClick={() => setCurrentPage(index)}
              disabled={currentPage === index}
            >
              {index + 1}
            </button>
          ))}
        </div>
        {/* Page Information */}
        <div className={"medium-m9"} style={{ margin: 'auto' }}>
          Page {currentPage + 1} of {pageCount}
        </div>
      </div>
      <div className={"table-buttons-container medium-m8"} style={{ alignItems: 'center', width: '100%' }}>

        {user === "dosen" ? (

          <>
            <SelectAllButton ListLogId={selectedItems} />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Table;